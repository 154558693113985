import { createAction, TActionsUnion } from "core"

export enum SignUpActionEnums {
  SET_PHONE = "SIGNUP_SET_PHONE",
  SET_EMAIL = "SIGNUP_SET_EMAIL",
}

export const signUpActions = {
  setPhone: (phone: string) => createAction(SignUpActionEnums.SET_PHONE, phone),
  setEmail: (email: string) => createAction(SignUpActionEnums.SET_EMAIL, email),
}

export type TSignupActions = TActionsUnion<typeof signUpActions>
