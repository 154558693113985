import React, { useState } from "react"
import { Box } from "rebass"
import { useTranslation } from "react-i18next"
import { BaseLayout, BaseScreen, TextInput, SidePanel, ButtonRow } from "shared"
import { TKYBDBAProps } from "./kyb-dba.container"
import { nav } from "core/navigation"
import { Analytics } from "../../../../core/analytics/actions"

export const KYBDBAScreen: React.FC<TKYBDBAProps> = ({ legalName, dba, setDBA, businessType }) => {
  const { t } = useTranslation()
  const [newDBA, setNewDBA] = useState(dba)
  const { prev, next } = nav.onboarding.kyb.pages.KYBDBA

  return (
    <BaseLayout
      sidePanel={<SidePanel section={2} />}
      body={
        <BaseScreen title={t("kyb.dba.title")} subtitle={t("kyb.dba.subtitle")}>
          <Box style={{ marginTop: "3.5rem" }}>
            <TextInput
              placeholderText={t("kyb.dba.placeholder")}
              onChange={setNewDBA}
              value={newDBA}
              autoFocus={true}
            />
          </Box>
        </BaseScreen>
      }
      buttonRow={
        <ButtonRow
          buttonText={newDBA ? t("buttons.continue") : t("buttons.skip")}
          buttonOnClick={() => {
            setDBA(newDBA !== legalName ? newDBA : "")
            Analytics.track(`KYB Business DBA Name Continue Tap`)
            next()
          }}
        />
      }
      onBackClick={() => {
        prev(businessType)
      }}
    />
  )
}
