import React from "react"
import { useTranslation } from "react-i18next"
import { BaseLayout, BaseScreen, SidePanel, ButtonRow } from "shared"
import { nav } from "core/navigation"

export const KYCUnsupportedCountrySuccessScreen: React.FC = () => {
  const { t } = useTranslation()
  const { prev } = nav.onboarding.kyc.pages.KYCUnsupportedCountrySuccess
  return (
    <BaseLayout
      sidePanel={<SidePanel section={1} />}
      body={<BaseScreen title={t("kyc.unsupportedCountrySuccess.title")}></BaseScreen>}
      buttonRow={
        <ButtonRow
          buttonOnClick={() => {
            window.location.href = "http://www.northone.com"
          }}
        />
      }
      onBackClick={prev}
    />
  )
}
