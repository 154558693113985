import {
  ISignUpState,
  initialSignUpState,
  IKYCState,
  initialKYCState,
  IKYBState,
  initialKYBState,
  IUBOState,
  initialUBOState,
  IErrorsState,
  initialErrorsState,
  initialControlPersonState,
  IControlPersonState,
  IDebitFundingState,
  initialDebitFundingState,
} from "components"
import { ICJEVENTState, initialCJEVENTState } from "../cjevent"
import { LocationState } from "redux-first-router"
import { PersistState } from "redux-persist"

type ReduxFirstRouterLocationState = LocationState<string, any>
export interface IRootState {
  page: string
  location: ReduxFirstRouterLocationState
  _persist?: PersistState
  signUp: ISignUpState
  KYC: IKYCState
  KYB: IKYBState
  UBO: IUBOState
  errors: IErrorsState
  CJEVENT: ICJEVENTState
  controlPerson: IControlPersonState
  debitFunding: IDebitFundingState
}

export const initialState: Omit<IRootState, "page" | "location" | "_persist"> = {
  signUp: initialSignUpState,
  KYC: initialKYCState,
  KYB: initialKYBState,
  UBO: initialUBOState,
  errors: initialErrorsState,
  CJEVENT: initialCJEVENTState,
  controlPerson: initialControlPersonState,
  debitFunding: initialDebitFundingState,
}
