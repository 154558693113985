import React, { useState } from "react"
import { BaseLayout, BaseScreen, ButtonRow, SidePanel } from "shared"
import { nav } from "core/navigation"
import { TDebitFundingDetailsProps } from "./debit-funding-details.container"
import { useTranslation } from "react-i18next"
import { DebitCardForm } from "./debit-funding-details-form.component"
import { isValidCardDetails } from "utils"
import moment from "moment"
import { Analytics } from "core/analytics/actions"

export const DebitFundingDetailsScreen: React.FC<TDebitFundingDetailsProps> = ({
  debitCardData,
  setCardDetails,
}) => {
  const { t } = useTranslation()
  const { prev, next } = nav.onboarding.debitFunding.pages.DebitFundingDetails

  const [name, setName] = useState(debitCardData.nameOnCard)
  const [cardNumber, setCardNumber] = useState(debitCardData.cardNumber)
  const [cvv, setCVV] = useState(debitCardData.cvv)
  const [zipCode, setZipCode] = useState(debitCardData.postalCode)
  const [expiryDate, setExpiryDate] = useState(
    debitCardData.expiryMonth && debitCardData.expiryYear
      ? `${debitCardData.expiryMonth}/${debitCardData.expiryYear}`
      : "",
  )

  const cardDetailsState = { name, cardNumber, cvv, expiryDate, zipCode }
  const cardDetailStateSetters = { setName, setCardNumber, setCVV, setExpiryDate, setZipCode }
  return (
    <BaseLayout
      sidePanel={<SidePanel section={2} />}
      body={
        <BaseScreen title={t("debitFunding.cardDetails.title")}>
          <DebitCardForm cardDetails={cardDetailsState} setCardDetails={cardDetailStateSetters} />
        </BaseScreen>
      }
      buttonRow={
        <ButtonRow
          withEnter={false}
          buttonOnClick={() => {
            setCardDetails({
              nameOnCard: name,
              cardNumber,
              cvv,
              expiryMonth: moment(expiryDate, "MM-YY").format("MM"),
              expiryYear: moment(expiryDate, "MM-YY").format("YY"),
              postalCode: zipCode,
            })
            next()
            Analytics.track("Debit Funding Card Details Continue Tap")
          }}
          buttonDisabled={!isValidCardDetails(cardDetailsState)}
        />
      }
      onBackClick={() => {
        setCardDetails({
          nameOnCard: "",
          cardNumber: "",
          cvv: "",
          expiryMonth: "",
          expiryYear: "",
          postalCode: "",
        })
        prev()
      }}
    />
  )
}
