export const signUp = {
  email: {
    title: "What is your email?",
    subtitle: "You'll use this when you log in.",
    placeholder: "Enter your email address",
    emailExistsTitle: "Looks like an application with that email already exists",
    emailExistsSubtitle: "Let's continue your application.",
    GQLError: "Something went wrong, please try again later.",
  },
  emailSent: {
    title: "Check your inbox!",
    subtitle: {
      line1: "A confirmation email is on its way to {{email}}.",
      line2: "Click the link in the email to continue your application.",
    },
  },
  emailResent: {
    title: "A confirmation email has been resent to {{email}}",
    subtitle: {
      line1: "Click the link in the email to continue your application.",
      line2:
        "If you don't receive it within a few minutes, check your spam folder or contact support@northone.com",
    },
  },
  password: {
    title: "Create a password",
    passwordPlaceholder: "Enter your password",
    confirmationPlaceholder: "Re-enter your password",
    validators: {
      characters: "8 characters",
      uppercase: "1 uppercase",
      lowercase: "1 lowercase",
      special: "1 special character",
      match: "Passwords match",
    },
  },
  termsAndConditions: {
    title: "Accept Terms & Conditions",
    subtitle: "By continuing, you are agreeing to our Terms & Conditions",
  },
  confirmIdentity: {
    title: "Log in with your password",
    subtitle: "We want to make sure it's you.",
    placeholder: "Enter your password",
  },
  ownerInvite: {
    title: "Download the NorthOne app to accept your invitation",
    subtitle:
      "To accept your account invitation, make sure to first download the NorthOne app to your mobile device. Once you’ve installed the app, click on the link in your email inbox to continue signing up.",
  },
  ownerInviteCustomSidePanel: {
    header1: "Download the NorthOne app",
    header2: "Accept the invitation",
    text1: "",
    text2:
      "Once you’ve installed the app, click on the same link in your email inbox from your phone to continue signing up.",
  },
}
