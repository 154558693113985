import React from "react"
import { Box, Text, Image } from "rebass"
import { theme } from "theme/theme"
import InfoButtonWhite from "images/InfoButtonWhite.svg"

interface IProps {
  errorText: string
}

export const ErrorBar: React.SFC<IProps> = ({ errorText }) => (
  <Box
    sx={{
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      bottom: "1rem",
      backgroundColor: theme.colors.orange,
      position: "fixed",
      width: "100%",
      height: "4%",
      color: theme.colors.white,
      marginBottom: "-1rem",
      paddingTop: "0.8rem",
      paddingLeft: "1rem",
      fontSize: theme.fontStyles.placeholderTextMinimized.fontSize,
    }}
  >
    <Image marginBottom="0.8rem" width="18px" height="18px" src={InfoButtonWhite} />
    <Text marginLeft="1rem" marginBottom="0.75rem">
      {errorText}
    </Text>
  </Box>
)
