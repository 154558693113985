import { applyMiddleware, compose, createStore, Store } from "redux"
import { connectRoutes } from "redux-first-router"
import thunk from "redux-thunk"

import { persistReducer, persistStore, Persistor } from "redux-persist"
import storage from "redux-persist/lib/storage"

import { routes } from "core/navigation"

import { IRootState } from "./root-state"

import queryString from "query-string"

import { createRootReducer } from "./reducers"

/**
 * helper to create type-checked persist configs.
 */
const _createPersistConfig = <R>(config: {
  key: keyof IRootState | "root"

  whitelist?: Array<keyof R>
}) => ({
  key: config.key,
  storage,
  whitelist: config.whitelist || [],
})

const rootPersistConfig = _createPersistConfig<IRootState>({
  key: "root",
  // only add keys that are NECESSARY to persist and are NOT considered PERSONALLY IDENTIFIABLE VALUES
  whitelist: ["signUp", "CJEVENT"],
})

export const configureStore = (preloadedState?: any): { store: Store; persistor: Persistor } => {
  const { reducer: locationReducer, middleware, enhancer } = connectRoutes(routes, {
    querySerializer: queryString,
  })

  const rootReducer = createRootReducer(locationReducer)
  const persistedReducer = persistReducer(rootPersistConfig, rootReducer)
  const middlewares = applyMiddleware(thunk, middleware)
  const typedWindow: any = window
  const composeEnhancers =
    typeof typedWindow === "object" && typedWindow.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
      ? typedWindow.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({})
      : compose
  const enhancers = composeEnhancers(enhancer, middlewares)

  const store = createStore(persistedReducer, preloadedState, enhancers)
  const persistor = persistStore(store)
  return { store, persistor }
}
