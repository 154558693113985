export const routes = {
  BACKGROUND_CHECK_SUBMIT: "/background-check-submit",
  // for compliance reasons do not put the word the "reject" into the url (implies decisioning)
  BACKGROUND_CHECK_REJECT: "/background-check-status-update",
}

type RoutesAction = keyof typeof routes

export const components: Record<RoutesAction, string> = {
  BACKGROUND_CHECK_SUBMIT: "BackgroundCheckSubmit",
  BACKGROUND_CHECK_REJECT: "BackgroundCheckReject",
}
