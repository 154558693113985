import { initialKYBState, IKYBState } from "./kyb.state"

import { KYBActionEnums, TKYBActions } from "./kyb.actions"

export const KYBReducer = (state: IKYBState = initialKYBState, action: TKYBActions): IKYBState => {
  switch (action.type) {
    case KYBActionEnums.SET_BUSINESS_TYPE:
      return {
        ...state,
        businessType: action.payload,
      }
    case KYBActionEnums.SET_DESCRIPTION:
      return {
        ...state,
        description: action.payload,
      }
    case KYBActionEnums.SET_WEBSITE:
      return {
        ...state,
        website: action.payload,
      }
    case KYBActionEnums.SET_PHONE:
      return {
        ...state,
        phone: action.payload,
      }
    case KYBActionEnums.SET_NAME:
      return {
        ...state,
        name: action.payload,
      }
    case KYBActionEnums.SET_LEGAL_NAME:
      return {
        ...state,
        legalName: action.payload,
      }
    case KYBActionEnums.SET_DBA:
      return {
        ...state,
        dba: action.payload,
      }
    case KYBActionEnums.SET_BUSINESS_ADDRESS:
      return {
        ...state,
        businessAddress: action.payload,
      }
    case KYBActionEnums.SET_DATE:
      return {
        ...state,
        date: action.payload,
      }
    case KYBActionEnums.SET_STATE:
      return {
        ...state,
        state: action.payload,
      }
    case KYBActionEnums.SET_NATURE:
      return {
        ...state,
        nature: action.payload,
      }
    case KYBActionEnums.SET_PERCENT_OWNERSHIP:
      return {
        ...state,
        percentOwnership: action.payload,
      }
    case KYBActionEnums.SET_MAILING_ADDRESS:
      return {
        ...state,
        mailingAddress: action.payload,
      }
    case KYBActionEnums.SET_SUCCESS:
      return {
        ...state,
        success: action.payload,
      }
    case KYBActionEnums.SET_MULTIPLE_OWNERS:
      return {
        ...state,
        hasMultipleOwners: action.payload,
      }
    case KYBActionEnums.SET_EIN:
      return {
        ...state,
        EIN: action.payload,
      }
    case KYBActionEnums.SET_UBO_TYPE:
      return {
        ...state,
        UBOType: action.payload,
      }
    case KYBActionEnums.SET_CORP_TYPE:
      return {
        ...state,
        corpType: action.payload,
      }
    case KYBActionEnums.SET_WEBSITE_TYPE:
      return {
        ...state,
        websiteType: action.payload,
      }
    default:
      return state
  }
}
