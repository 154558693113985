import env, { IOptionalVariable } from "env-var"
import pkg from "../../package.json"

export const RELEASE = pkg ? `${pkg.name}@${pkg.version}` : null

const stage = env
  .get("REACT_APP_STAGE")
  .default("dev")
  .required()
  .asEnum(["dev", "prod", "staging"])

export const isProduction = () => stage === "prod"

/**
 * Wrapper function for env-var to inject default
 * values only for development environments
 *
 * @param variableName Variable name to get from process.env
 * @param defaultValue Value to use as default during development
 * @returns The resulting env-var chain
 */
const envWithDevDefault = (variableName: string, defaultValue: string): IOptionalVariable => {
  const value = env.get(variableName)
  return isProduction() ? value : value.default(defaultValue)
}

export const config = {
  stage,
  env: env
    .get("NODE_ENV")
    .default("development")
    .required()
    .asEnum(["production", "development", "test"]),
  graphQlUri: envWithDevDefault(
    "REACT_APP_GQL_URI",
    "https://dobby-graphql.dev.aws.northoneapps.com/",
  )
    .required()
    .asString(),
  webBankingUrl: env
    .get("REACT_APP_WEB_BANKING_URL")
    .default("https://daffy-banking.dev.aws.northoneapps.com/")
    .asString(),
  auth0: {
    domain: envWithDevDefault("REACT_APP_AUTH0_DOMAIN", "auth.northoneapps.com")
      .required()
      .asString(),
    clientId: envWithDevDefault("REACT_APP_AUTH0_CLIENT_ID", "WEor4yVG4nekCpiwf2XOcuX14zfrPirl")
      .required()
      .asString(),
  },
  sentryDsn: env.get("REACT_APP_SENTRY_DSN").asString(),
  branchKey: env.get("REACT_APP_BRANCH_SDK_KEY").asString(),
  intercomAppId: env.get("REACT_APP_INTERCOM_APP_ID").asString(),
  segmentKey: env.get("REACT_APP_SEGMENT_KEY").asString(),
  adjustToken: env.get("REACT_APP_ADJUST_TOKEN").asString(),
  appUrls: {
    appStore: env
      .get("REACT_APP_APP_STORE_URL")
      .default("https://apps.apple.com/us/app/northone-business-banking/id1466648407")
      .asString(),
    googlePlay: env
      .get("REACT_APP_GOOGLE_PLAY_STORE_URL")
      .default("https://play.google.com/store/apps/details?id=com.mobileapp.prod")
      .asString(),
  },
  dev: {
    // Disable window resize - Allows you to not lose your place in the dev console
    disableWindowSizeRestriction: env
      .get("REACT_APP_DISABLE_WINDOW_SIZE_RESTRICTION")
      .default("false")
      .asBool(),
    // Don't show warning when page reload or close tab
    disableReloadDialog: env
      .get("REACT_APP_DISABLE_RELOAD_DIALOG")
      .default("false")
      .asBool(),
    // Allow developer to jump directly to routes without being redirected
    disableRerouteOnRefresh: env
      .get("REACT_APP_DISABLE_REROUTE_ON_REFRESH")
      .default("false")
      .asBool(),
  },
  release: RELEASE,
}
