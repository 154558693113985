import React from "react"
import { connect } from "react-redux"
import { IRootState, TRootDispatch } from "core"
import { UBOResidencyScreen } from "./ubo-residency.screen"
import { UBOActions } from "../redux"

interface IUBOResidencyStateProps {
  residency: string
  firstName: string
}

interface IUBOResidencyDispatchProps {
  setValue: (key: "citizenship", value: string) => void
}

export type TUBOResidencyProps = IUBOResidencyStateProps & IUBOResidencyDispatchProps

const mapStateToProps = (state: IRootState): IUBOResidencyStateProps => ({
  residency: state.UBO.newOwner.citizenship,
  firstName: state.UBO.newOwner.firstName,
})

const mapDispatchToProps = (dispatch: TRootDispatch): IUBOResidencyDispatchProps => ({
  setValue: (key: "citizenship", value: string) => dispatch(UBOActions.setValue(key, value)),
})

export const UBOResidency = connect(
  mapStateToProps,
  mapDispatchToProps,
)((props: TUBOResidencyProps) => <UBOResidencyScreen {...props} />)
