import React, { useState } from "react"
import { useTranslation } from "react-i18next"
import { InputForm, BaseLayout, BaseScreen, SidePanel, ButtonRow, Button } from "shared"
import { TUBOReviewProps } from "./ubo-review.container"
import { isPostalCode } from "validator"
import validator from "validator"
import { isUSState, isValidSSN, defaultValidationType } from "utils/validators"
import { americanStates } from "utils/americanStates"
import { phoneRegex } from "utils"
import moment from "moment"
import { nav } from "core/navigation"
import { initialNewOwner } from "../redux"

export const UBOReviewScreen: React.FC<TUBOReviewProps> = ({
  editOwner,
  currentTotalOwnershipPercentage,
  additionalOwners,
  deleteOwner,
  allOwnerEmails,
}) => {
  const { prev, getParamOwnerId } = nav.onboarding.ubo.pages.UBOReview

  const ownerId = getParamOwnerId() // owner id from url param i.e) localhost:3000/owner-review/:ownerId

  const editOwnerData = additionalOwners.find((_owner) => _owner.id === ownerId) || {
    ...initialNewOwner,
    ssn: "",
  }

  const allOtherOwnerEmails = allOwnerEmails.filter(
    (ownerEmail) => editOwnerData.emailAddress !== ownerEmail,
  )

  const { t } = useTranslation()
  const [firstName, setFirstName] = useState(editOwnerData.firstName)
  const [lastName, setLastName] = useState(editOwnerData.lastName)
  const [role, setRole] = useState(editOwnerData.role)
  const [jobTitle, setJobTitle] = useState(editOwnerData.jobTitle)
  const [email, setEmail] = useState(editOwnerData.emailAddress)
  const [phone, setPhone] = useState(editOwnerData.phoneNumber)
  const [birthDate, setBirthDate] = useState(editOwnerData.birthDate)
  const [ssn, setSSN] = useState(editOwnerData.ssn)
  const [percentage, setPercentage] = useState(editOwnerData.ownershipPercentage)
  const [streetAddress, setStreetAddress] = useState(editOwnerData.homeAddress.streetAddressLine1)
  const [suite, setSuite] = useState(editOwnerData.homeAddress.streetAddressLine2)
  const [city, setCity] = useState(editOwnerData.homeAddress.city)
  const [state, setState] = useState(editOwnerData.homeAddress.provinceState)
  const [zipcode, setZipcode] = useState(editOwnerData.homeAddress.postalCode)

  const percentageAlreadyOwned =
    currentTotalOwnershipPercentage - Number(editOwnerData.ownershipPercentage)
  const percentageAvailable = 100 - percentageAlreadyOwned
  const newTotalOwnershipPercentage = percentageAlreadyOwned + Number(percentage)

  const percentIsValid = (): boolean => {
    if (percentage) {
      return Boolean(Number(percentage) > 0 && newTotalOwnershipPercentage <= 100)
    }
    return false
  }

  const exceedsTotalPercentage: boolean = newTotalOwnershipPercentage > 100

  const percentValidationErrorMessage = exceedsTotalPercentage
    ? t("ubo.percentage.exceeds", { percentageAvailable })
    : t("ubo.percentage.error")

  const validZIPCode = (value: string, country: "US") => {
    return isPostalCode(value, country)
  }
  const addressIsValid = Boolean(
    streetAddress && city && isUSState(state) && validZIPCode(zipcode, "US"),
  )
  const emailIsValid =
    validator.isEmail(email) &&
    !allOtherOwnerEmails.map((ownerEmail) => ownerEmail.toLowerCase()).includes(email.toLowerCase())
  const phoneIsValid = phoneRegex.test(phone)
  const age = (date: string) => moment().diff(moment(date), "years")
  const inputDateFormat = "MM/DD/YYYY"
  const validDate = (date: string) => {
    return moment(date, inputDateFormat).isValid() && age(date) <= 200
  }
  const isOver18 = (date: string) => {
    return age(date) >= 18 && date.length === 10
  }

  const allValid = Boolean(
    firstName &&
      lastName &&
      role &&
      jobTitle &&
      emailIsValid &&
      addressIsValid &&
      phoneIsValid &&
      percentIsValid() &&
      isValidSSN(ssn) &&
      isOver18(birthDate) &&
      validDate(birthDate),
  )

  const inputFields = [
    {
      field: "firstName",
      value: firstName,
      setValue: setFirstName,
      validationText: !firstName ? t("ubo.review.requiredError") : "",
      validationType: defaultValidationType,
    },
    {
      field: "lastName",
      value: lastName,
      setValue: setLastName,
      validationText: !lastName ? t("ubo.review.requiredError") : "",
      validationType: defaultValidationType,
    },
    {
      field: "jobTitle",
      value: jobTitle,
      setValue: setJobTitle,
      validationText: !jobTitle ? t("ubo.review.requiredError") : "",
      validationType: defaultValidationType,
    },
    {
      field: "role",
      value: role,
      setValue: setRole,
      dropDown: [
        t("ubo.role.director"),
        t("ubo.role.officerCoowner"),
        t("ubo.role.officerEmployee"),
        t("ubo.role.partner"),
      ],
    },
    {
      field: "streetAddress",
      value: streetAddress,
      setValue: setStreetAddress,
      validationText: !streetAddress ? t("ubo.review.requiredError") : "",
      validationType: defaultValidationType,
    },
    {
      field: "suite",
      value: suite || "",
      setValue: setSuite,
    },
    {
      field: "city",
      value: city,
      setValue: setCity,
      validationText: !city ? t("ubo.review.requiredError") : "",
      validationType: defaultValidationType,
    },
    {
      field: "state",
      value: state,
      setValue: setState,
      dropDown: americanStates,
    },
    {
      field: "zipcode",
      value: zipcode,
      validationText: !validZIPCode(zipcode, "US") ? t("ubo.review.zipCodeError") : "",
      validationType: defaultValidationType,
      setValue: setZipcode,
    },
    {
      field: "email",
      value: email,
      setValue: setEmail,
      validationText: !emailIsValid ? t("ubo.review.emailError") : "",
      validationType: defaultValidationType,
    },
    {
      field: "phone",
      value: phone,
      setValue: setPhone,
      validationText: !phoneIsValid ? t("ubo.review.phoneError") : "",
      validationType: defaultValidationType,
      withPhoneNumberMask: true,
    },
    {
      field: "birthDate",
      value: birthDate,
      setValue: setBirthDate,
      validationText:
        !isOver18(birthDate) && !validDate(birthDate) ? t("ubo.review.birthDateError") : "",
      validationType: defaultValidationType,
      withDateMask: true,
      placeholderText: inputDateFormat,
    },
    {
      field: "ssn",
      value: ssn,
      setValue: setSSN,
      validationText: !isValidSSN(ssn) ? t("ubo.review.ssnError") : "",
      validationType: defaultValidationType,
      withObfuscation: true,
      maxLength: 9,
    },
    {
      field: "percentage",
      value: percentage,
      setValue: setPercentage,
      validationText: !percentIsValid() ? percentValidationErrorMessage : "",
      validationType: defaultValidationType,
    },
  ]
  return (
    <BaseLayout
      sidePanel={<SidePanel section={2} />}
      body={
        <BaseScreen title={t("ubo.review.title", { firstName })}>
          <InputForm inputFields={inputFields} />
        </BaseScreen>
      }
      buttonRow={
        <ButtonRow
          buttonOnClick={() => {
            editOwner({
              ownerId,
              sensitive: { ssn },
              owner: {
                id: ownerId,
                firstName,
                lastName,
                birthDate,
                homeAddress: {
                  streetAddressLine1: streetAddress,
                  streetAddressLine2: suite,
                  city,
                  provinceState: state,
                  postalCode: zipcode,
                  country: "US",
                },
                citizenship: "US",
                emailAddress: email,
                ownershipPercentage: percentage,
                phoneNumber: phone,
                role,
                jobTitle,
              },
            })
            prev()
          }}
          buttonDisabled={!allValid}
          secondButton={
            <Button
              type="error"
              onClick={() => {
                deleteOwner(ownerId)
                prev()
              }}
              noSimulatedSavingOnClick={true}
            >
              {t("ubo.review.delete")}
            </Button>
          }
        />
      }
    />
  )
}
