import React from "react"
import { RoleScreen } from "shared"
import { TUBORoleProps } from "./ubo-role.container"
import { nav } from "core/navigation"

export const UBORoleScreen: React.FC<TUBORoleProps> = ({ firstName, jobTitle, role, setValue }) => {
  const { prev, next } = nav.onboarding.ubo.pages.UBORole
  return (
    <RoleScreen
      firstName={firstName}
      initialJobTitle={jobTitle}
      initialRole={role}
      setJobTitle={(newJobTitle) => setValue("jobTitle", newJobTitle)}
      setRole={(newRole) => setValue("role", newRole)}
      prev={prev}
      next={next}
    />
  )
}
