import { initialSignUpState, ISignUpState } from "./sign-up.state"

import { SignUpActionEnums, TSignupActions } from "./sign-up.actions"

export const signUpReducer = (
  state: ISignUpState = initialSignUpState,
  action: TSignupActions,
): ISignUpState => {
  switch (action.type) {
    case SignUpActionEnums.SET_PHONE:
      return {
        ...state,
        phone: action.payload,
      }
    case SignUpActionEnums.SET_EMAIL:
      return {
        ...state,
        email: action.payload,
      }
    default:
      return state
  }
}
