import React from "react"
import { DebitFundingLandingScreen } from "./debit-funding-landing.screen"
import { connect } from "react-redux"
import { CorporationType } from "types"
import { IRootState } from "core"
import { hasMultipleOwners } from "../../ubo/redux"

export interface IDebitFundingLandingStateProps {
  businessType: CorporationType
  hasMultipleOwners: boolean
}

const mapStateToProps = (state: IRootState): IDebitFundingLandingStateProps => ({
  businessType: state.KYB.businessType,
  hasMultipleOwners: hasMultipleOwners(state),
})

export const DebitFundingLanding = connect(
  mapStateToProps,
  null,
)((props: IDebitFundingLandingStateProps) => <DebitFundingLandingScreen {...props} />)
