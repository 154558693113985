import React from "react"
import { connect } from "react-redux"
import { IRootState, TRootDispatch } from "core"
import { IAddress } from "types"
import { UBOAddressScreen } from "./ubo-address.screen"
import { UBOActions } from "../redux"

interface IUBOAddressStateProps {
  homeAddress: IAddress
  firstName: string
}

interface IUBOAddressDispatchProps {
  setAddress: (key: "homeAddress", value: IAddress) => void
}

export type TUBOAddressProps = IUBOAddressStateProps & IUBOAddressDispatchProps

const mapStateToProps = (state: IRootState): IUBOAddressStateProps => ({
  firstName: state.UBO.newOwner.firstName,
  homeAddress: state.UBO.newOwner.homeAddress,
})

const mapDispatchToProps = (dispatch: TRootDispatch): IUBOAddressDispatchProps => ({
  setAddress: (key: "homeAddress", value: IAddress) => dispatch(UBOActions.setValue(key, value)),
})

export const UBOAddress = connect(
  mapStateToProps,
  mapDispatchToProps,
)((props: TUBOAddressProps) => <UBOAddressScreen {...props} />)
