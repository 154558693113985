import { Auth0Client, GetTokenSilentlyOptions } from "@auth0/auth0-spa-js"
import { getPersistedTraitToken } from "@northone/segment-js"
import { _localStorage } from "core/local-storage-service"

import { config } from "../../utils/environment"
import { Analytics } from "../../core/analytics/actions"

// https://auth0.com/docs/libraries/common-auth0-library-authentication-errors
export enum Auth0Error {
  LOGIN_REQUIRED = "login_required",
  BLOCKED = "unauthorized",
}

// SDK by default includes 'openid' in scope
// when useRefreshTokens is set to true, 'offline_access' is also included in the scope.

export const BASE_SCOPES = "openid email profile email address phone"

export const auth = new Auth0Client({
  domain: config.auth0.domain,
  client_id: config.auth0.clientId,
  redirect_uri: window.location.origin,
  audience: "northoneCoreApi",
  advancedOptions: {
    defaultScope: BASE_SCOPES,
  },
})

/**
 * helper function to log the user out and clear application state
 */
let loggedOut = false
export const isLoggedOut = (): boolean => loggedOut
export const logout = () => {
  loggedOut = true
  Analytics.reset()
  _localStorage.clearAll()
  auth.logout({ returnTo: `${window.location.origin}?logout=true` })
}

/**
 * helper function to redirect the user to login
 * @param mode mode paramter to pass to SSO page
 */
export const redirectToLogin = async (mode?: string): Promise<void> => {
  const urlParams = new URLSearchParams(window.location.search)
  return auth.loginWithRedirect({
    mode: mode || urlParams.get("mode") || "signup",
    ptt: await getPersistedTraitToken(),
  })
}

/**
 * attempts to get an access token and throws if it fails
 */
export const getTokenSilently = async (
  options?: GetTokenSilentlyOptions,
): Promise<string | null> => {
  // IMPORTANT: sessions can stay alive if a token is requested during logout
  // see: https://bitbucket.org/northone/northone-web-banking/pull-requests/365/np-5720-return-null-token-after-logout
  if (loggedOut) {
    return null
  }
  return await auth.getTokenSilently(options)
}

/**
 * returns a regular access token or logs the user out
 */
export const getTokenOrLogout = async (
  options?: GetTokenSilentlyOptions,
): Promise<string | null> => {
  try {
    return await getTokenSilently(options)
  } catch (error) {
    if (error.error !== Auth0Error.LOGIN_REQUIRED) {
      console.error(error)
    }
    logout()
    return null
  }
}
