// @ts-ignore
import Scrubber from "./src"

import { GLOBAL_SENSITIVE_FIELDS } from "../constants"

// This module is now being used here and in GQL
// TODO: Move this into an internal npm package.

/**
 * replaces values for sensitive key-value pairs in strings with a placeholder ([REDACTED])
 *  ---- ONLY TO BE USED FOR LOGGING/ REPORTING. DO NOT USE OUTPUT FOR FEATURE CODE ---
 * @param keys filters these keys in addition to global sensitive fields (if not disabled)
 * @param options { omitDefaults: omits default blacklisted values}
 */
export const scrubStr = (keys: string[] = [], options: { omitDefaults?: boolean } = {}) => (
  str: string,
): string => {
  const sensitiveKeys = options.omitDefaults ? keys : [...GLOBAL_SENSITIVE_FIELDS, ...keys]
  try {
    return Scrubber.key_value_pairs(sensitiveKeys)(str)
  } catch (e) {
    console.error("error in scrubStr:", e)
    return str
  }
}

/**
 * replaces values for sensitive key-value pairs in objects with a placeholder ([REDACTED])
 *  ---- ONLY TO BE USED FOR LOGGING/ REPORTING. DO NOT USE OUTPUT FOR FEATURE CODE ---
 * @param keys filters these keys in addition to global sensitive fields (if not disabled)
 * @param options { omitDefaults: omits default blacklisted values}
 */
export const scrubObj = (keys: string[] = [], options: { omitDefaults?: boolean } = {}) => <
  T extends object
>(
  obj: object,
): T => {
  const sensitiveKeys = options.omitDefaults ? keys : [...GLOBAL_SENSITIVE_FIELDS, ...keys]
  try {
    return Scrubber.object_keys(sensitiveKeys)(obj)
  } catch (e) {
    console.error("error in scrubObj:", e)
    return obj as T
  }
}
