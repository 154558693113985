import React, { useState } from "react"
import { useTranslation } from "react-i18next"
import { Box } from "rebass"
import { TKYBPartTimeEmployeesProps } from "./kyb-part-time-employees.container"

import { nav } from "core/navigation"
import { Analytics } from "../../../../core/analytics/actions"

import { BaseLayout, BaseScreen, SidePanel, ButtonRow, RadioButton } from "shared"

export const KYBPartTimeEmployeesScreen: React.FC<TKYBPartTimeEmployeesProps> = ({
  businessType,
}) => {
  const [isSelected, setSelected] = useState<number | null>(null)
  const { t } = useTranslation()
  const { prev, next } = nav.onboarding.kyb.pages.KYBPartTimeEmployees
  const optionsList = [
    { option: "0", index: 0 },
    { option: "1 - 5", index: 1 },
    { option: "6 - 10", index: 2 },
    { option: "11 - 25", index: 3 },
    { option: "26 - 50", index: 4 },
    { option: t(`kyb.numberOfEmployees.option6`), index: 5 },
  ]

  return (
    <BaseLayout
      sidePanel={<SidePanel section={2} />}
      body={
        <BaseScreen title={t(`kyb.numberOfEmployees.partTime`)}>
          <Box style={{ marginTop: "2rem", display: "flex", flexWrap: "wrap" }}>
            <Box>
              {optionsList.slice(0, 3).map((option) => (
                <RadioButton
                  key={option.index}
                  label={option.option}
                  isSelected={option.index === isSelected}
                  onClick={() => setSelected(option.index)}
                  customRadioStyle={{ width: "18rem" }}
                />
              ))}
            </Box>
            <Box>
              {optionsList.slice(3).map((option) => (
                <RadioButton
                  key={option.index}
                  label={option.option}
                  isSelected={option.index === isSelected}
                  onClick={() => setSelected(option.index)}
                  customRadioStyle={{ width: "18rem" }}
                />
              ))}
            </Box>
          </Box>
        </BaseScreen>
      }
      buttonRow={
        <ButtonRow
          buttonDisabled={isSelected === null}
          buttonOnClick={() => {
            if (isSelected) {
              Analytics.track(`KYB Business PT Employee Range Continue Tap`, {
                employee_count_pt:
                  isSelected === 5 ? "More than 50" : optionsList[isSelected].option,
              })
              Analytics.identify({
                employee_count_pt:
                  isSelected === 5 ? "More than 50" : optionsList[isSelected].option,
              })
            }
            next(businessType)
          }}
        />
      }
      onBackClick={() => prev(businessType)}
    />
  )
}
