import React, { useState } from "react"
import { TUBOPercentageProps } from "./ubo-percentage.container"
import { BaseLayout, SidePanel, BaseScreen, TextInput, ButtonRow } from "shared"
import { useTranslation } from "react-i18next"
import { Box } from "rebass"
import { nav } from "core/navigation"

export const UBOPercentageScreen: React.FC<TUBOPercentageProps> = ({
  firstName,
  percentage,
  setValue,
  currentTotalOwnershipPercentage,
}) => {
  const { t } = useTranslation()
  const [newOwnerPercentage, setNewOwnerPercentage] = useState(percentage)

  const newTotalOwnershipPercentage = currentTotalOwnershipPercentage + Number(newOwnerPercentage)

  const valueIsValid = (): boolean => {
    if (newOwnerPercentage) {
      return Boolean(Number(newOwnerPercentage) > 0 && newTotalOwnershipPercentage <= 100)
    }
    return false
  }

  const exceedsTotalPercentage: boolean = newTotalOwnershipPercentage > 100

  const percentageAvailable = 100 - currentTotalOwnershipPercentage

  const { prev, next } = nav.onboarding.ubo.pages.UBOPercentage

  const validationErrorMessage = exceedsTotalPercentage
    ? t("ubo.percentage.exceeds", { percentageAvailable })
    : t("ubo.percentage.error")

  return (
    <BaseLayout
      sidePanel={<SidePanel section={2} />}
      body={
        <BaseScreen title={t("ubo.percentage.title", { firstName })}>
          <Box style={{ marginTop: "2rem" }}>
            <TextInput
              placeholderText={t("ubo.percentage.placeholder")}
              onChange={setNewOwnerPercentage}
              value={newOwnerPercentage}
              autoFocus={true}
              validationText={!valueIsValid() && newOwnerPercentage ? validationErrorMessage : ""}
              validationType={"error"}
            />
          </Box>
        </BaseScreen>
      }
      buttonRow={
        <ButtonRow
          buttonOnClick={() => {
            setValue("ownershipPercentage", newOwnerPercentage)
            next()
          }}
          buttonDisabled={!valueIsValid()}
        />
      }
      onBackClick={prev}
    />
  )
}
