import React from "react"
import { connect } from "react-redux"

import { IRootState, TRootDispatch } from "core"
import { nav } from "core/navigation"
// import { useTranslation } from "react-i18next"
import { ControlPersonActions } from "../redux"
import { PhoneNumberScreen } from "shared"

interface IControlPersonPhoneStateProps {
  firstName: string
  phone: string
}

interface IControlPersonPhoneDispatchProps {
  setPhone: (phone: string) => void
}

export type TControlPersonPhoneProps = IControlPersonPhoneStateProps &
  IControlPersonPhoneDispatchProps

const mapStateToProps = (state: IRootState): IControlPersonPhoneStateProps => ({
  firstName: state.controlPerson.candidate.firstName,
  phone: state.controlPerson.candidate.phone,
})

const mapDispatchToProps = (dispatch: TRootDispatch): IControlPersonPhoneDispatchProps => ({
  setPhone: (phone: string) => dispatch(ControlPersonActions.setPhone(phone)),
})

export const ControlPersonPhoneScreen: React.FC<TControlPersonPhoneProps> = ({
  firstName,
  phone,
  setPhone,
}) => {
  const { prev, next } = nav.onboarding.controlPerson.pages.ControlPersonPhone
  return (
    <PhoneNumberScreen
      firstName={firstName}
      phone={phone}
      setPhoneNumber={setPhone}
      prev={prev}
      next={next}
    />
  )
}

export const ControlPersonPhone = connect(
  mapStateToProps,
  mapDispatchToProps,
)((props: TControlPersonPhoneProps) => <ControlPersonPhoneScreen {...props} />)
