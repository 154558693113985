import React from "react"
import { connect } from "react-redux"
import { IRootState, TRootDispatch } from "core"
import { IOwner } from "types"
import { getCompiledInitialOwner, getUBOAdditionalOwners } from "../../ubo/redux"
import { ControlPersonInviteScreen } from "./control-person-invite.screen"
import { IEditOwnerActionPayload, UBOActions } from "../../ubo/redux/ubo.actions"

interface IControlPersonInviteDispatchProps {
  editOwner: (payload: IEditOwnerActionPayload) => void
}

interface IControlPersonInviteStateProps {
  firstOwner: IOwner
  additionalOwners: IOwner[]
  controlPersonEmail: string
}

export type TControlPersonInviteProps = IControlPersonInviteStateProps &
  IControlPersonInviteDispatchProps

const mapStateToProps = (state: IRootState): IControlPersonInviteStateProps => ({
  firstOwner: getCompiledInitialOwner(state),
  additionalOwners: getUBOAdditionalOwners(state),
  controlPersonEmail: state.controlPerson.selected.email,
})

const mapDispatchToProps = (dispatch: TRootDispatch): IControlPersonInviteDispatchProps => ({
  editOwner: (payload: IEditOwnerActionPayload) => dispatch(UBOActions.editOwner(payload)),
})

export const ControlPersonInvite = connect(
  mapStateToProps,
  mapDispatchToProps,
)((props: TControlPersonInviteProps) => <ControlPersonInviteScreen {...props} />)
