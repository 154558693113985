import React, { useState } from "react"
import { Box } from "rebass"
import { useTranslation } from "react-i18next"
import { BaseLayout, BaseScreen, TextInput, SidePanel, ButtonRow } from "shared"
import { TKYBLegalNameProps } from "./kyb-legal-name.container"
import { nav } from "core/navigation"
import { Analytics } from "../../../../core/analytics/actions"

export const KYBLegalNameScreen: React.FC<TKYBLegalNameProps> = ({ legalName, setLegalName }) => {
  const { t } = useTranslation()
  const [newLegalName, setNewLegalName] = useState(legalName)
  const nameIsValid: boolean = Boolean(newLegalName && newLegalName.length >= 1)

  const { prev, next } = nav.onboarding.kyb.pages.KYBLegalName

  return (
    <BaseLayout
      sidePanel={<SidePanel section={2} />}
      body={
        <BaseScreen title={t("kyb.legalName.title")} subtitle={t("kyb.legalName.subtitle")}>
          <Box style={{ marginTop: "3.5rem" }}>
            <TextInput
              placeholderText={t("kyb.legalName.placeholder")}
              onChange={setNewLegalName}
              value={newLegalName}
              autoFocus={true}
            />
          </Box>
        </BaseScreen>
      }
      buttonRow={
        <ButtonRow
          buttonOnClick={() => {
            setLegalName(newLegalName)
            Analytics.track(`KYB Business Legal Name Continue Tap`)
            next()
          }}
          buttonDisabled={!nameIsValid}
        />
      }
      onBackClick={prev}
    />
  )
}
