import { useEffect, useState } from "react"

export const getWindowDimensions = () => {
  const { innerWidth: width, innerHeight: height } = window
  return {
    width,
    height,
  }
}

export const useWindowDimensions = () => {
  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions())
  useEffect(() => {
    function _handleResize() {
      setWindowDimensions(getWindowDimensions())
    }

    window.addEventListener("resize", _handleResize)
    return () => window.removeEventListener("resize", _handleResize)
  }, [])
  return windowDimensions
}
