import React from "react"
import { useTranslation } from "react-i18next"
import { BaseLayout, BaseScreen, SidePanel, ButtonRow } from "shared"
import { TKYCCompleteProps } from "./kyc-complete.container"
import { nav } from "core/navigation"
import { Analytics } from "../../../../core/analytics/actions"

export const KYCCompleteScreen: React.FC<TKYCCompleteProps> = ({ firstName }) => {
  const { t } = useTranslation()
  const { prev, next } = nav.onboarding.kyc.pages.KYCComplete
  return (
    <BaseLayout
      onBackClick={prev}
      sidePanel={<SidePanel section={1} />}
      body={
        <BaseScreen
          title={t("kyc.complete.title", { firstName })}
          subtitle={t("kyc.complete.subtitle")}
        />
      }
      buttonRow={
        <ButtonRow
          buttonOnClick={() => {
            Analytics.track("KYC Verification Complete Continue Tap")
            next()
          }}
          buttonDisabled={false}
        />
      }
    />
  )
}
