export const routes = {
  KYB_BUSINESS_TYPE: "/kyb/business-type",
  KYB_DESCRIPTION: "/kyb/description",
  KYB_ILLEGAL_BUSINESS_CHECK: "/kyb/illegal",
  KYB_WEBSITE: "/kyb/website",
  KYB_PHONE: "/kyb/phone",
  KYB_LEGAL_NAME: "/kyb/legal-name",
  KYB_SOLEPROP_DBA: "/kyb/soleprop-dba",
  KYB_ADDRESS: "/kyb/address",
  KYB_NATURE: "/kyb/nature",
  KYB_PERCENTAGE: "/kyb/percent-ownership",
  KYB_CARD_SAMPLE: "/kyb/card-sample",
  KYB_EIN: "/kyb/ein",
  KYB_DATE_STATE: "/kyb/date-state",
  KYB_FULL_TIME_EMPLOYEES: "/kyb/full-time-employees",
  KYB_PART_TIME_EMPLOYEES: "/kyb/part-time-employees",
  KYB_UBO_TYPE: "/kyb/ubo-type",
  KYB_DBA: "/kyb/dba",
  KYB_CORP_TYPE: "/kyb/corp-type",
  KYB_SOCIAL_MEDIA: "/kyb/social-media",
  KYB_WEBSITE_TYPE: "/kyb/website-type",
  KYB_UNSUPPORTED_BUSINESS: "/kyb/unsupported-business",
}

type RoutesAction = keyof typeof routes

export const components: Record<RoutesAction, string> = {
  KYB_BUSINESS_TYPE: "KYBBusinessType",
  KYB_DESCRIPTION: "KYBDescription",
  KYB_ILLEGAL_BUSINESS_CHECK: "KYBIllegalBusinessCheck",
  KYB_WEBSITE: "KYBWebsite",
  KYB_PHONE: "KYBPhone",
  KYB_LEGAL_NAME: "KYBLegalName",
  KYB_SOLEPROP_DBA: "KYBSolePropDba",
  KYB_ADDRESS: "KYBAddress",
  KYB_NATURE: "KYBNature",
  KYB_PERCENTAGE: "KYBPercentage",
  KYB_CARD_SAMPLE: "KYBCardSample",
  KYB_EIN: "KYBEIN",
  KYB_DATE_STATE: "KYBDateState",
  KYB_FULL_TIME_EMPLOYEES: "KYBFullTimeEmployees",
  KYB_PART_TIME_EMPLOYEES: "KYBPartTimeEmployees",
  KYB_UBO_TYPE: "KYBUBOType",
  KYB_DBA: "KYBDBA",
  KYB_CORP_TYPE: "KYBCorpType",
  KYB_SOCIAL_MEDIA: "KYBSocialMedia",
  KYB_WEBSITE_TYPE: "KYBWebsiteType",
  KYB_UNSUPPORTED_BUSINESS: "KYBUnsupportedBusiness",
}
