import React, { useState } from "react"
import { useTranslation } from "react-i18next"
import { Box } from "rebass"
import { BaseLayout, BaseScreen, RadioButton, SidePanel, ButtonRow } from "shared"
import { TKYCResidencyProps } from "./kyc-residency.container"
import { nav } from "core/navigation"
import { Analytics } from "../../../../core/analytics/actions"
import { theme } from "theme/theme"

export const KYCResidencyScreen: React.FC<TKYCResidencyProps> = ({ residency, setResidency }) => {
  const { t } = useTranslation()
  const { prev, next } = nav.onboarding.kyc.pages.KYCResidency
  const [newResidency, setNewResidency] = useState(residency)
  const valueIsValid = () => newResidency === "US" || newResidency === "NO"

  return (
    <BaseLayout
      sidePanel={<SidePanel section={1} />}
      body={
        <BaseScreen title={t("kyc.residency.title")}>
          <Box
            style={{
              marginTop: theme.spacing.buttonRowToBody,
              flexDirection: "column",
              display: "flex",
            }}
          >
            {[{ type: "yes", value: "US" }, { type: "no", value: "NO" }].map(({ type, value }) => (
              <RadioButton
                key={value}
                label={t(`kyc.residency.${type}`)}
                isSelected={newResidency === value}
                onClick={() => {
                  setNewResidency(value)
                }}
              />
            ))}
          </Box>
        </BaseScreen>
      }
      buttonRow={
        <ButtonRow
          buttonOnClick={() => {
            setResidency(newResidency)
            Analytics.track(
              newResidency === "NO"
                ? "KYC Verification Manual US Identity No Tap"
                : "KYC Verification Manual US Identity Yes Tap",
            )
            next(newResidency === "US")
          }}
          buttonDisabled={!valueIsValid()}
        />
      }
      onBackClick={prev}
    />
  )
}
