import React from "react"
import { useTranslation } from "react-i18next"
import { BaseLayout, BaseScreen, SidePanel, ButtonRow } from "shared"
import { TSignUpEmailResentProps } from "./sign-up-email-resent.container"
import gql from "graphql-tag"
import {
  useEmailVerifiedResentScreenQuery,
  useResendVerificationEmailResentScreenMutation,
} from "generated/graphql"
import { nav } from "core/navigation"
import { Analytics } from "../../../../core/analytics/actions"

export const EMAIL_VERIFIED_QUERY = gql`
  query emailVerifiedResentScreen($email: String!) {
    emailVerified(email: $email)
  }
`

export const RESEND_EMAIL_VERIFICATION = gql`
  mutation resendVerificationEmailResentScreen($userEmail: String!) {
    resendVerificationEmail(userEmail: $userEmail) {
      success
    }
  }
`

export const SignUpEmailResentScreen: React.FC<TSignUpEmailResentProps> = ({
  email,
  setCurrentErrorMessage,
}) => {
  const TWENTY_MIN_IN_MS = 1200000

  setTimeout(() => {
     nav.onboarding.utils.goToStart()
  }, TWENTY_MIN_IN_MS)

  const { t } = useTranslation()
  const { next } = nav.onboarding.signUp.pages.SignUpEmailResent

  const { error, data: emailVerifiedData } = useEmailVerifiedResentScreenQuery({
    pollInterval: 2000,
    variables: { email: email.toLowerCase() },
  })

  if (emailVerifiedData && emailVerifiedData.emailVerified) {
    next()
  }

  const [
    resendVerificationEmail,
    { loading: resendVerificationEmailLoading, error: resendVerificationEmailError },
  ] = useResendVerificationEmailResentScreenMutation({
    variables: { userEmail: email.toLowerCase() },
  })
  const _handleButtonClick = () => {
    resendVerificationEmail()
  }

  if (error || resendVerificationEmailError) {
    setCurrentErrorMessage(t("errors.generic"))
  }

  return (
    <BaseLayout
      sidePanel={<SidePanel section={1} />}
      body={
        <BaseScreen
          title={t("signUp.emailResent.title", { email })}
          multilineSubtitle={[
            t("signUp.emailResent.subtitle.line1"),
            t("signUp.emailResent.subtitle.line2"),
          ]}
        />
      }
      buttonRow={
        <ButtonRow
          buttonIsLoading={resendVerificationEmailLoading}
          buttonOnClick={() => {
            Analytics.track("Create Profile Resend Email Tap")
            _handleButtonClick()
          }}
          buttonText={t("buttons.resendConfirmation")}
          buttonSupertext={t("buttons.supertext.didNotReceiveEmail")}
        />
      }
      noBackButton
    />
  )
}
