import React from "react"
import ReactDOM from "react-dom"
import { App } from "./app"
import * as serviceWorker from "./service-worker"
import "./normalize.css"
import { Provider } from "react-redux"
import "./i18n/i18n"

import { ApolloProvider } from "@apollo/client"
import { createClient } from "./core/apollo/client"
import { PersistGate } from "redux-persist/integration/react"
import { configureStore } from "./core/redux/configure-store"
import * as Sentry from "@sentry/browser"
import * as Adjust from "@adjustcom/adjust-web-sdk"
import ReactGA from "react-ga"
import { config, isProduction, RELEASE } from "utils"
import { GlobalErrorCatch } from "./globalErrorCatch"
import Segment from "@northone/segment-js"

Sentry.init({
  dsn: config.sentryDsn,
  environment: config.stage,
  ...(RELEASE && { release: RELEASE }),
})

if (config.segmentKey) {
  Segment.load(config.segmentKey)
}

if (isProduction()) {
  ReactGA.initialize("UA-76692344-1")
  Adjust.initSdk({
    appToken: config.adjustToken,
    environment: "production",
  })
}

// Temporary flag until navigation is figured out.
// Disabled by default so that UAT persons won't have keep clearing localStorage manually

export const { store, persistor } = configureStore()
const client = createClient()

ReactDOM.render(
  <GlobalErrorCatch>
    <ApolloProvider client={client}>
      <Provider store={store}>
        <PersistGate persistor={persistor} loading={null}>
          <App />
        </PersistGate>
      </Provider>
    </ApolloProvider>
  </GlobalErrorCatch>,
  document.getElementById("root"),
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
