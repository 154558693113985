import React from "react"
import { Box } from "rebass"
import { InputForm, TextInput } from "shared"
import { useTranslation } from "react-i18next"
import {
  isValidZIPCode,
  isValidCardExpiry,
  ZIP_CODE_LENGTH,
  CVV_LENGTH,
  DEBIT_CARD_NUMBER_LENGTH,
  EXPIRY_DATE_LENGTH,
  defaultValidationType,
} from "utils"
import { theme } from "theme/theme"

interface ICardDetails {
  name: string
  cardNumber: string
  cvv: string
  expiryDate: string
  zipCode: string
}

interface ISetCardDetails {
  setName: React.Dispatch<React.SetStateAction<string>>
  setCardNumber: React.Dispatch<React.SetStateAction<string>>
  setCVV: React.Dispatch<React.SetStateAction<string>>
  setExpiryDate: React.Dispatch<React.SetStateAction<string>>
  setZipCode: React.Dispatch<React.SetStateAction<string>>
}

interface ICardDetailsForm {
  cardDetails: ICardDetails
  setCardDetails: ISetCardDetails
}

export const DebitCardForm: React.SFC<ICardDetailsForm> = ({
  cardDetails: { name, cardNumber, cvv, expiryDate, zipCode },
  setCardDetails: { setName, setCardNumber, setCVV, setExpiryDate, setZipCode },
}) => {
  const { t } = useTranslation()
  const inputFields = [
    {
      field: "debitFunding.name",
      value: name,
      setValue: setName,
    },
    {
      field: "debitFunding.cardNumber",
      value: cardNumber,
      setValue: setCardNumber,
      maxLength: DEBIT_CARD_NUMBER_LENGTH,
      withOnlyNumbers: true,
    },
  ]
  const zipField = [
    {
      field: "debitFunding.zipCode",
      value: zipCode,
      setValue: setZipCode,
      validationText:
        zipCode.length === ZIP_CODE_LENGTH && !isValidZIPCode(zipCode, "US")
          ? t("debitFunding.error.zip")
          : "",
      validationType: defaultValidationType,
      maxLength: ZIP_CODE_LENGTH,
    },
  ]
  return (
    <Box>
      <InputForm inputFields={inputFields} />
      <Box sx={{ display: "flex", marginBottom: theme.spacing.inputFormSpacing }}>
        <TextInput
          value={cvv}
          onChange={setCVV}
          placeholderText={t("debitFunding.cardDetails.placeholders.cvv")}
          customWidth={theme.inputField.width.small}
          maxLength={CVV_LENGTH}
          withOnlyNumbers
        />
        <Box style={{ marginLeft: "33px" }}>
          <TextInput
            value={expiryDate}
            onChange={setExpiryDate}
            placeholderText={t("debitFunding.cardDetails.placeholders.expiry")}
            customWidth={theme.inputField.width.small}
            small
            withCardExpiryMask
            validationText={
              !isValidCardExpiry(expiryDate) && expiryDate.length === EXPIRY_DATE_LENGTH
                ? t("debitFunding.error.expiryDate")
                : ""
            }
          />
        </Box>
      </Box>
      <InputForm inputFields={zipField} autoFocusDisabled />
    </Box>
  )
}
