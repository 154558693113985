import React from "react"
import { connect } from "react-redux"

import { IRootState, TRootDispatch } from "core"

import { UBONameScreen } from "./ubo-name.screen"

import { UBOActions } from "../redux"

interface IUBONameStateProps {
  firstName: string
  lastName: string
}

interface IUBONameDispatchProps {
  setValue: (key: "firstName" | "lastName", value: string) => void
}

export type TUBONameProps = IUBONameStateProps & IUBONameDispatchProps

const mapStateToProps = (state: IRootState): IUBONameStateProps => ({
  firstName: state.UBO.newOwner.firstName,
  lastName: state.UBO.newOwner.lastName,
})

const mapDispatchToProps = (dispatch: TRootDispatch): IUBONameDispatchProps => ({
  setValue: (key: "firstName" | "lastName", value: string) =>
    dispatch(UBOActions.setValue(key, value)),
})

export const UBOName = connect(
  mapStateToProps,
  mapDispatchToProps,
)((props: TUBONameProps) => <UBONameScreen {...props} />)
