import React, { useState } from "react"
import { useTranslation } from "react-i18next"
import { BaseLayout, SingleTextInputScreen, SidePanel, ButtonRow } from "shared"
import { phoneRegex } from "utils"

interface IProps {
  phone: string
  goToNext: () => void
  goToPrev?: () => void
  setPhone: (phone: string) => void
  handleAnalytics: (phoneNumber: string) => void
  onClick?: () => void
}

export const PhoneScreen: React.FC<IProps> = ({
  phone,
  setPhone,
  goToNext,
  handleAnalytics,
  goToPrev,
  onClick,
}) => {
  const { t } = useTranslation()
  const [phoneNumber, setPhoneNumber] = useState(phone)
  const phoneIsValid = phoneRegex.test(phoneNumber)

  return (
    <BaseLayout
      sidePanel={<SidePanel section={1} />}
      body={
        <SingleTextInputScreen
          subtitle={t("phoneScreen.subtitle")}
          title={t("phoneScreen.title")}
          onChange={setPhoneNumber}
          value={phoneNumber}
          placeholderText={"+1"}
          phoneType={true}
          disableMinimizedPlaceholderText
        />
      }
      buttonRow={
        <ButtonRow
          buttonOnClick={() => {
            setPhone(phoneNumber)
            handleAnalytics(phoneNumber)
            if (onClick) {
              onClick()
            }
            goToNext()
          }}
          buttonDisabled={!phoneIsValid}
        />
      }
      noBackButton={!goToPrev}
      onBackClick={goToPrev}
    />
  )
}
