import React from "react"
import { connect } from "react-redux"
import { UnsupportedCountrySuccessScreen } from "shared"
import { nav } from "core/navigation"

export const ControlPersonUnsupportedCountrySuccessScreen: React.FC = () => {
  const { prev } = nav.onboarding.controlPerson.pages.ControlPersonUnsupportedCountrySuccess
  return <UnsupportedCountrySuccessScreen prev={prev} />
}

export const ControlPersonUnsupportedCountrySuccess = connect(null)(() => (
  <ControlPersonUnsupportedCountrySuccessScreen />
))
