import { navigateByBusinessType, navigate } from "core/navigation/utils"
import {
  FIRST_KYB_SCREEN,
  LAST_KYC_SCREEN,
  FIRST_UBO_SCREEN,
} from "../onboarding-navigation-constants"
import { CorporationType, UBOTypeEnum } from "types"

export const KYBNavigator = {
  utils: {
    goToKYBStart: () => navigate(FIRST_KYB_SCREEN),
  },
  pages: {
    KYBBusinessType: {
      next: (corpTypeEnum: CorporationType) =>
        navigateByBusinessType(corpTypeEnum, {
          soleprop: { route: "KYB_NATURE" },
          corp: { route: "KYB_CORP_TYPE" },
          partnership: { route: "KYB_NATURE" },
        }),
      prev: () => navigate(LAST_KYC_SCREEN),
    },
    KYBCorpType: {
      prev: () => navigate("KYB_BUSINESS_TYPE"),
      next: () => navigate("KYB_NATURE"),
    },

    KYBNature: {
      prev: (corpTypeEnum: CorporationType) =>
        navigateByBusinessType(corpTypeEnum, {
          corp: { route: "KYB_CORP_TYPE" },
          soleprop: { route: "KYB_BUSINESS_TYPE" },
          partnership: { route: "KYB_BUSINESS_TYPE" },
        }),
      next: () => navigate("KYB_DESCRIPTION"),
    },

    KYBDescription: {
      prev: () => navigate("KYB_NATURE"),
      next: () => navigate("KYB_ILLEGAL_BUSINESS_CHECK"),
    },

    KYBIllegalBusinessCheck: {
      prev: () => navigate("KYB_DESCRIPTION"),
      next: (corpTypeEnum: CorporationType) => {
        navigateByBusinessType(corpTypeEnum, {
          corp: { route: "KYB_LEGAL_NAME" },
          soleprop: { route: "KYB_SOLEPROP_DBA" },
          partnership: { route: "KYB_LEGAL_NAME" },
        })
      },
    },
    // only for soleprop. Others use legalName + DBA
    KYBSolePropDba: {
      prev: () => navigate("KYB_ILLEGAL_BUSINESS_CHECK"),
      next: () => navigate("KYB_DATE_STATE"),
    },

    KYBLegalName: {
      prev: () => navigate("KYB_ILLEGAL_BUSINESS_CHECK"),
      next: () => navigate("KYB_DBA"),
    },

    KYBDBA: {
      prev: (corpTypeEnum: CorporationType) =>
        navigateByBusinessType(corpTypeEnum, {
          corp: { route: "KYB_LEGAL_NAME" },
          soleprop: { route: "KYB_ILLEGAL_BUSINESS_CHECK" },
          partnership: { route: "KYB_LEGAL_NAME" },
        }),
      next: () => navigate("KYB_DATE_STATE"),
    },
    KYBDateState: {
      next: (corpTypeEnum: CorporationType) =>
        navigateByBusinessType(corpTypeEnum, {
          corp: { route: "KYB_FULL_TIME_EMPLOYEES" },
          soleprop: { route: "KYB_PART_TIME_EMPLOYEES" },
          partnership: { route: "KYB_FULL_TIME_EMPLOYEES" },
        }),
      prev: (corpTypeEnum: CorporationType) =>
        navigateByBusinessType(corpTypeEnum, {
          corp: { route: "KYB_DBA" },
          soleprop: { route: "KYB_SOLEPROP_DBA" },
          partnership: { route: "KYB_DBA" },
        }),
    },
    KYBFullTimeEmployees: {
      next: (corpTypeEnum: CorporationType) =>
        navigateByBusinessType(corpTypeEnum, {
          corp: { route: "KYB_PART_TIME_EMPLOYEES" },
          partnership: { route: "KYB_PART_TIME_EMPLOYEES" },
        }),
      prev: () => navigate("KYB_DATE_STATE"),
    },
    KYBPartTimeEmployees: {
      next: (corpTypeEnum: CorporationType) =>
        navigateByBusinessType(corpTypeEnum, {
          corp: { route: "KYB_EIN" },
          soleprop: { route: "KYB_WEBSITE" },
          partnership: { route: "KYB_EIN" },
        }),
      prev: (corpTypeEnum: CorporationType) =>
        navigateByBusinessType(corpTypeEnum, {
          corp: { route: "KYB_FULL_TIME_EMPLOYEES" },
          soleprop: { route: "KYB_DATE_STATE" },
          partnership: { route: "KYB_FULL_TIME_EMPLOYEES" },
        }),
    },

    KYBEIN: {
      next: () => navigate("KYB_WEBSITE"),
      prev: (corpTypeEnum: CorporationType) =>
        navigateByBusinessType(corpTypeEnum, {
          corp: { route: "KYB_PART_TIME_EMPLOYEES" },
          partnership: { route: "KYB_PART_TIME_EMPLOYEES" },
        }),
    },

    KYBWebsite: {
      next: (corpTypeEnum: CorporationType, hasNoWebsite: boolean) => {
        if (hasNoWebsite && corpTypeEnum === CorporationType.Soleprop) {
          navigate("KYB_WEBSITE_TYPE")
        } else {
          navigate("KYB_PHONE")
        }
      },
      prev: (corpTypeEnum: CorporationType) =>
        navigateByBusinessType(corpTypeEnum, {
          corp: { route: "KYB_EIN" },
          soleprop: { route: "KYB_PART_TIME_EMPLOYEES" },
          partnership: { route: "KYB_EIN" },
        }),
    },

    KYBWebsiteType: {
      prev: () => navigate("KYB_WEBSITE"),
      next: (hasNoWebsite: boolean) => {
        if (hasNoWebsite) {
          navigate("KYB_PHONE")
        } else {
          navigate("KYB_SOCIAL_MEDIA")
        }
      },
    },

    KYBSocialMedia: {
      prev: () => navigate("KYB_WEBSITE_TYPE"),
      next: () => navigate("KYB_PHONE"),
    },

    KYBPhone: {
      next: () => navigate("KYB_ADDRESS"),
      prev: (corpTypeEnum: CorporationType) =>
        navigateByBusinessType(corpTypeEnum, {
          corp: { route: "KYB_WEBSITE" },
          soleprop: { route: "KYB_SOCIAL_MEDIA" },
          partnership: { route: "KYB_WEBSITE" },
        }),
    },

    KYBAddress: {
      next: () => navigate("KYB_CARD_SAMPLE"),
      prev: () => navigate("KYB_PHONE"),
    },

    KYBCardSample: {
      next: (corpTypeEnum: CorporationType) =>
        navigateByBusinessType(corpTypeEnum, {
          corp: { route: "KYB_UBO_TYPE" },
          soleprop: { route: "DEBIT_FUNDING_LANDING" },
          partnership: { route: "KYB_PERCENTAGE" },
        }),
      prev: () => navigate("KYB_ADDRESS"),
    },

    KYBPercentage: {
      prev: (corpType: CorporationType) => {
        navigateByBusinessType(corpType, {
          partnership: { route: "KYB_CARD_SAMPLE" },
          corp: { route: "KYB_UBO_TYPE" },
        })
      },
      next: () => navigate(FIRST_UBO_SCREEN),
    },

    // only for corporation business type
    KYBUBOType: {
      prev: () => navigate("KYB_CARD_SAMPLE"),
      next: (UBOType: UBOTypeEnum) => {
        switch (UBOType) {
          case "SingleOwner":
            navigate("CONTROL_PERSON_SELECT_ROUTE")
            break
          case "MultipleOwners":
            navigate("KYB_PERCENTAGE")
        }
      },
    },
  },
}
