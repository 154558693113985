import React, { useState } from "react"
import { useTranslation } from "react-i18next"
import { Box } from "rebass"
import { BaseLayout, BaseScreen, TextInput, SidePanel, ButtonRow } from "shared"
import { nav } from "core/navigation"
import { Analytics } from "../../../../core/analytics/actions"

import { TKYCNameProps } from "./kyc-name.container"
import { theme } from "theme/theme"
import { isValidName } from "utils"

export const KYCNameScreen: React.FC<TKYCNameProps> = ({
  firstName,
  lastName,
  setFirstName,
  setLastName,
}) => {
  const { t } = useTranslation()
  const [newFirstName, setNewFirstName] = useState(firstName)
  const [newLastName, setNewLastName] = useState(lastName)
  const valueIsValid = () => newFirstName && newLastName
  const { prev, next } = nav.onboarding.kyc.pages.KYCName

  return (
    <BaseLayout
      sidePanel={<SidePanel section={1} />}
      body={
        <BaseScreen title={t("kyc.name.title")}>
          <Box style={{ marginTop: "2rem" }}>
            <TextInput
              placeholderText={t("kyc.name.firstName")}
              onChange={(name: string) =>
                isValidName(name) || !name.length ? setNewFirstName(name) : null
              }
              value={newFirstName}
              autoFocus={true}
            />
          </Box>
          <Box style={{ marginTop: theme.spacing.inputFieldSpacing }}>
            <TextInput
              placeholderText={t("kyc.name.lastName")}
              onChange={(name: string) =>
                isValidName(name) || !name.length ? setNewLastName(name) : null
              }
              value={newLastName}
            />
          </Box>
        </BaseScreen>
      }
      buttonRow={
        <ButtonRow
          buttonOnClick={() => {
            setFirstName(newFirstName)
            setLastName(newLastName)
            Analytics.track("KYC Verification Manual First Last Name Continue Tap", {
              firstName: newFirstName,
              lastName: newLastName,
            })
            Analytics.identify({ name: `${newFirstName} ${newLastName}` })
            next()
          }}
          buttonDisabled={!valueIsValid()}
        />
      }
      onBackClick={prev}
    />
  )
}
