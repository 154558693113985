import { signUp } from "./onboarding/sign-up/en"
import { kyc } from "./onboarding/kyc/en"
import { kyb } from "./onboarding/kyb/en"
import { ubo } from "./onboarding/ubo/en"
import { debitFunding } from "./onboarding/debit-funding/en"
import { login } from "./login/en"
import { controlPerson } from "./onboarding/control-person/en"
import { backgroundCheck } from "./onboarding/background-check/en"
import * as shared from "./shared"
import { passwordReset } from "./password-reset/en"

export const en = {
  buttons: {
    continue: "Continue",
    skip: "Skip",
    orPress: "or press",
    enter: "ENTER",
    saving: "Saving",
    resume: "Continue application",
    info: "I have this information",
    supertext: { didNotReceiveEmail: "Didn't receive the email?" },
    resendConfirmation: "Resend email",
    agree: "I agree",
  },
  signUp,
  kyc,
  kyb,
  ubo,
  login,
  shared,
  backgroundCheck,
  passwordReset,
  debitFunding,
  errors: {
    generic: "Something went wrong",
    phoneValidation: "Phone number must be 10 digits",
    onlyDigits: "Digits only",
    accessDenied: "Wrong email or password",
  },
  smallScreen: {
    title: "Download the NorthOne app to open your business checking account",
  },
  notFound: {
    title: "404",
    subtitle: "We couldn't find the page you were looking for.",
    button: "Go Back to Login",
  },
  phoneScreen: {
    title: "What is your phone number?",
    subtitle: "In case we need to get hold of you.",
  },
  controlPerson,
}
