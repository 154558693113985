import React from "react"
import { connect } from "react-redux"

import { IRootState, TRootDispatch } from "core"

import { KYCSSNScreen } from "./kyc-ssn.screen"

import { KYCActions } from "../redux"

import { nav } from "core/navigation"

interface IKYCSSNStateProps {
  SSN: string
}

interface IKYCSSNDispatchProps {
  setSSN: (SSN: string) => void
}

export type TKYCSSNProps = IKYCSSNStateProps & IKYCSSNDispatchProps

const mapStateToProps = (state: IRootState): IKYCSSNStateProps => ({
  SSN: state.KYC.SSN,
})

const mapDispatchToProps = (dispatch: TRootDispatch): IKYCSSNDispatchProps => ({
  setSSN: (SSN: string) => dispatch(KYCActions.setSSN(SSN)),
})

const { prev: KYCSSNPrev, next: KYCSSNNext } = nav.onboarding.kyc.pages.KYCSSN

export const KYCSSN = connect(
  mapStateToProps,
  mapDispatchToProps,
)((props: TKYCSSNProps) => <KYCSSNScreen {...props} goToPrev={KYCSSNPrev} goToNext={KYCSSNNext} />)

const { next: UBOSSNNext } = nav.onboarding.kyc.pages.KYCSSNMissing

export const KYCSSNMissing = connect(
  mapStateToProps,
  mapDispatchToProps,
)((props: TKYCSSNProps) => <KYCSSNScreen {...props} goToNext={UBOSSNNext} isSSNMissing />)
