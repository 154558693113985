export const routes = {
  LOGIN: "/",
  SIGN_UP_PHONE: "/phone",
  SIGN_UP_EMAIL_SENT: "/signup/email-sent",
  SIGN_UP_EMAIL_RESENT: "/signup/email-resent",
  SIGN_UP_TERMS_AND_CONDITIONS: "/signup/terms-and-conditions",
  OWNER_INVITE: "/owner-invite",
  RENEW_TOKEN_REDIRECT: "/renew-token-redirect",
}

type RoutesAction = keyof typeof routes

export const components: Record<RoutesAction, string> = {
  LOGIN: "",
  SIGN_UP_PHONE: "SignUpPhone",
  SIGN_UP_EMAIL_SENT: "SignUpEmailSent",
  SIGN_UP_EMAIL_RESENT: "SignUpEmailResent",
  SIGN_UP_TERMS_AND_CONDITIONS: "TermsAndConditions",
  OWNER_INVITE: "OwnerInviteScreen",
  RENEW_TOKEN_REDIRECT: "RenewTokenRedirectScreen",
}
