import React, { useState } from "react"
import { Box } from "rebass"
import { useTranslation } from "react-i18next"
import { phoneRegex } from "utils"
import { BaseLayout, BaseScreen, TextInput, SidePanel, ButtonRow } from "shared"
import { TKYBPhoneProps } from "./kyb-phone.container"
import { nav } from "core/navigation"
import { Analytics } from "../../../../core/analytics/actions"

export const KYBPhoneScreen: React.FC<TKYBPhoneProps> = ({ phone, setPhone, businessType }) => {
  const { t } = useTranslation()
  const [newPhone, setNewPhone] = useState(phone)
  const phoneIsValid = phoneRegex.test(newPhone)
  const { prev, next } = nav.onboarding.kyb.pages.KYBPhone

  return (
    <BaseLayout
      sidePanel={<SidePanel section={2} />}
      body={
        <BaseScreen title={t("kyb.phone.title")} subtitle={t("kyb.phone.subtitle")}>
          <Box style={{ marginTop: "3.5rem" }}>
            <TextInput
              placeholderText={"+1"}
              onChange={setNewPhone}
              value={newPhone}
              autoFocus={true}
              withPhoneNumberMask={true}
              disableMinimizedPlaceholderText
            />
          </Box>
        </BaseScreen>
      }
      buttonRow={
        <ButtonRow
          buttonOnClick={() => {
            setPhone(newPhone)
            Analytics.track(`KYB Business Phone Number Continue Tap`)
            next()
          }}
          buttonDisabled={!phoneIsValid}
        />
      }
      onBackClick={() => {
        prev(businessType)
      }}
    />
  )
}
