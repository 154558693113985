import React, { useState } from "react"
import { useTranslation } from "react-i18next"
import { Box } from "rebass"
import { BaseLayout, BaseScreen, RadioButton, SidePanel, ButtonRow } from "shared"

interface IResidencyScreenProps {
  residency: string
  firstName: string
  setResidency: (residency: string) => void
  prev: () => void
  next: (isResident: boolean) => void
}

export const ResidencyScreen: React.FC<IResidencyScreenProps> = ({
  residency,
  firstName,
  setResidency,
  prev,
  next,
}) => {
  const { t } = useTranslation()

  const [newResidency, setNewResidency] = useState(residency)
  const valueIsValid = () => newResidency === "US" || newResidency === "NO"
  return (
    <BaseLayout
      sidePanel={<SidePanel section={2} />}
      body={
        <BaseScreen title={t("shared.screens.residency.title", { firstName })}>
          <Box style={{ marginTop: "2rem", flexDirection: "column", display: "flex" }}>
            {[{ type: "yes", value: "US" }, { type: "no", value: "NO" }].map(({ type, value }) => (
              <RadioButton
                label={t(`shared.screens.residency.${type}`)}
                isSelected={newResidency === value}
                onClick={() => {
                  setNewResidency(value)
                }}
              />
            ))}
          </Box>
        </BaseScreen>
      }
      buttonRow={
        <ButtonRow
          buttonOnClick={() => {
            setResidency(newResidency)
            next(newResidency === "US")
          }}
          buttonDisabled={!valueIsValid()}
        />
      }
      onBackClick={prev}
    />
  )
}
