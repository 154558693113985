import React from "react"
import { useTranslation } from "react-i18next"
import { BaseLayout, BaseScreen, SidePanel, ButtonRow } from "shared"

export const KYBUnsupportedBusinessScreen: React.FC<{}> = () => {
  const { t } = useTranslation()

  return (
    <BaseLayout
      sidePanel={<SidePanel section={2} />}
      body={
        <BaseScreen
          title={t("kyb.unsupportedBusiness.title")}
          subtitle={t("kyb.unsupportedBusiness.subtitle")}
        ></BaseScreen>
      }
      buttonRow={
        <ButtonRow
          buttonOnClick={() => {
            // TODO Add to email waiting list.
          }}
          buttonText={t("kyb.unsupportedBusiness.buttonText")}
          buttonDisabled={false}
        />
      }
    />
  )
}
