import { IControlPerson } from "types"

export type ControlPersonCandidateState = IControlPerson

export interface ISelectedControlPersonState {
  email: string
}

export interface IControlPersonState {
  candidate: ControlPersonCandidateState
  /** the control person that will be submitted on the application */
  selected: ISelectedControlPersonState
}

export const initialControlPersonCandidateState: ControlPersonCandidateState = {
  firstName: "",
  lastName: "",
  email: "",
  phone: "",
  birthDate: "",
  homeAddress: {
    streetAddressLine1: "",
    streetAddressLine2: "",
    city: "",
    provinceState: "",
    postalCode: "",
    country: "",
  },
  jobTitle: "",
  residency: "",
  SSN: "",
  unsupportedCountry: "",
  id: "",
}

export const initialSelectedControlPersonState: ISelectedControlPersonState = {
  email: "",
}

export const initialControlPersonState: IControlPersonState = {
  candidate: initialControlPersonCandidateState,
  selected: initialSelectedControlPersonState,
}
