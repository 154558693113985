import React from "react"
import { connect } from "react-redux"

import { IRootState, TRootDispatch } from "core"
import { nav } from "core/navigation"
import { ControlPersonActions } from "../redux"
import { RoleScreen } from "shared"

interface IControlPersonRoleStateProps {
  firstName: string
  jobTitle: string
}

interface IControlPersonRoleDispatchProps {
  setJobTitle: (jobTitle: string) => void
}

export type TControlPersonRoleProps = IControlPersonRoleStateProps & IControlPersonRoleDispatchProps

const mapStateToProps = (state: IRootState): IControlPersonRoleStateProps => ({
  firstName: state.controlPerson.candidate.firstName,
  jobTitle: state.controlPerson.candidate.jobTitle,
})

const mapDispatchToProps = (dispatch: TRootDispatch): IControlPersonRoleDispatchProps => ({
  setJobTitle: (jobTitle: string) => dispatch(ControlPersonActions.setJobTitle(jobTitle)),
})

export const ControlPersonRoleScreen: React.FC<TControlPersonRoleProps> = ({
  firstName,
  jobTitle,
  setJobTitle,
}) => {
  const { prev, next } = nav.onboarding.controlPerson.pages.ControlPersonRole

  return (
    <RoleScreen
      prev={prev}
      next={next}
      firstName={firstName}
      initialJobTitle={jobTitle}
      setJobTitle={setJobTitle}
      withoutRole
    />
  )
}

export const ControlPersonRole = connect(
  mapStateToProps,
  mapDispatchToProps,
)((props: TControlPersonRoleProps) => <ControlPersonRoleScreen {...props} />)
