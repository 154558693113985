import React from "react"
import { connect } from "react-redux"

import { IRootState, TRootDispatch } from "core"
import { IAddress } from "types"

import { KYCAddressScreen } from "./kyc-address.screen"

import { KYCActions } from "../redux"

interface IKYCAddressStateProps {
  homeAddress: IAddress
}

interface IKYCAddressDispatchProps {
  setHomeAddress: (homeAddress: IAddress) => void
}

export type TKYCAddressProps = IKYCAddressStateProps & IKYCAddressDispatchProps

const mapStateToProps = (state: IRootState): IKYCAddressStateProps => ({
  homeAddress: state.KYC.homeAddress,
})

const mapDispatchToProps = (dispatch: TRootDispatch): IKYCAddressDispatchProps => ({
  setHomeAddress: (homeAddress: IAddress) => dispatch(KYCActions.setHomeAddress(homeAddress)),
})

export const KYCAddress = connect(
  mapStateToProps,
  mapDispatchToProps,
)((props: TKYCAddressProps) => <KYCAddressScreen {...props} />)
