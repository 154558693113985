import React from "react"
import { Box, Heading, Text } from "rebass"
import { Progress } from "../progress-circle"
import { theme } from "theme/theme"
import { getTextWithLineBreaks } from "utils/helpers"

interface IProps {
  heading: string
  color?: "grey" | "accent"
  accentColor?: string
  text: string
  section: number
  active: boolean
  customInactiveTextPaddingTop?: number
}

export const HeadingWithProgress: React.SFC<IProps> = ({
  heading,
  color,
  text,
  section,
  active,
  customInactiveTextPaddingTop,
}) => (
  <Box sx={{ display: "flex", flexDirection: "row", marginX: 20 }}>
    <Box sx={{ width: 35 }}>
      <Box
        sx={{
          marginTop: "-2px",
        }}
      >
        <Progress section={section} active={active} color={color} />
      </Box>
    </Box>
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        width: "90%",
      }}
    >
      <Box>
        <Heading
          sx={{
            color: theme.colors.black,
            ...theme.fontStyles.buttonText,
          }}
        >
          {heading}
        </Heading>
      </Box>
      <Text
        sx={{
          paddingTop: customInactiveTextPaddingTop ? customInactiveTextPaddingTop : active ? 20 : 0,
          color: theme.colors.grey,
          ...theme.fontStyles.h2,
        }}
      >
        {getTextWithLineBreaks(text)}
      </Text>
    </Box>
  </Box>
)
