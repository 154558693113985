export const kyb = {
  businessType: {
    title: "What type of business do you own?",
    LLC: "LLC, C Corp or S Corp",
    soleProp: "Freelance or Sole Proprietorship",
    partnership: "Partnership",
    infoText: {
      LLC: "You must be one of the owners",
      soleProp: "You are the only owner",
      partnership: "You must be one of the partners",
    },
  },
  description: {
    title: "Describe in detail what your business does",
    subtitle: "For example: Marketing agency specializing in web development and logo design",
    placeholder: "Enter your business description",
    validationText: "Provide at least {{numberOfCharacters}} more characters",
    characterCount: "{{numberOfCharacters}} characters",
    warningMessage: "You entered '",
    warningMessage2: "', but we'll need a bit more information about your business.",
  },
  website: {
    title: "What is your business website?",
    placeholder: "Enter your website address",
    check: "My business does not have a website.",
  },
  phone: {
    title: "What is your business phone number?",
    subtitle: "In case we need to get ahold of you.",
  },
  illegal: {
    title:
      "If your business is involved in any of these activities, we may not be able to bank you.",
    marijuana: "The sale/distribution of marijuana",
    gambling: "🎰 Internet gambling",
    money: "💰 A Money Service Business",
    ATM: "🏧 The owner of a private ATM",
    check:
      "I confirm that my business is not involved in or associated with any of the above activities or practices.",
  },
  name: {
    title: "What is the name of your business?",
    subtitle: "This can be your “Doing Business As” (DBA). For example, Sam’s Graphic Design.",
    placeholder: "Business name",
    check: "My business does not have a name.",
  },
  dba: {
    title: "What is your company's trade name or DBA?",
    subtitle:
      "A trade name or DBA ('Doing Business As') is another name your company uses to operate.",
    placeholder: "Enter your company's trade name",
    check: "Use my legal name as trade name",
  },
  address: {
    title: "What is your business address?",
    dropdownPlaceholder: "State",
  },
  nature: {
    title: "What kind of work does your business do?",
    placeholder: "Choose the closest match...",
    error: "Something went wrong, please try again later.",
  },
  percentage: {
    title: "What percentage of the business do you own?",
    placeholder: "Enter your percentage (%) share.",
    error: "Please enter only a whole number between 1 and 99.",
    exceeds: "Only {{percentageAvailable}}% currently available.",
  },
  dateState: {
    title: {
      default: "When was your business incorporated?",
      soleProp: "When and where does your business operate?",
    },
    subtitle: {
      soleProp:
        "Enter the approximate date that your business started operating or generating revenue.",
      default: "",
    },
    inputPlaceholder: "MM/DD/YYYY",
    dropdownPlaceholder: {
      soleProp: "Which state does your business operate in?",
      default: "Which state is your business incorporated in?",
    },
    dateError: "Please enter a valid date.",
  },
  numberOfEmployees: {
    fullTime: "How many full time employees does your business have?",
    partTime: "How many part-time, contracted, or freelance employees does your business have?",
    option6: "More than 50",
  },
  cardSample: {
    title: "Your card is almost ready to go!",
    subtitle: "We'll ship it to this address when your account is approved:",
    button: "Confirm Address",
    useHome: "Use my home address",
    useCompany: "Use company address",
    home: "Home address",
    company: "Company address",
  },
  submitApplication: {
    title: "Submit your application",
    subtitle: "We'll need to verify your information by running a background check.",
    text:
      "This is a standard process for opening any bank account and your credit score will not be affected.",
    button: "Submit application",
    GQLError: "Something went wrong, please try again later.",
    lockedOrFrozen:
      "Your credit file appears to be unavailable. Contact Equifax at 1-888-766-0008.",
  },
  ein: {
    title: "What is your Employer Identification Number (EIN)?",
    placeholder: "Enter your EIN",
    subtitle: "Your EIN is a 9 digit number in this format: XX-XXXXXXX.",
  },
  UBOType: {
    title: "Who owns the company?",
    singleOwner: "I am the only owner",
    multipleOwners: "There are multiple owners",
    infoText: {
      singleOwner: "You are the sole owner",
      multipleOwners: "You have partners, directors, investors, or co-owners",
    },
  },
  corpType: {
    title: "What type of corporation do you own?",
    Llc: "LLC",
    Ccorp: "C Corp",
    Scorp: "S Corp",
    infoText: {
      Llc: "You must be one of the owners.",
      Ccorp: "You must be one of the owners.",
      Scorp: "You're an army of one.",
    },
  },
  legalName: {
    title: "What is your company's legal name?",
    subtitle: "This is the name that was used to incorporate your business.",
    placeholder: "Enter your company's legal name",
  },
  unsupportedBusiness: {
    title: "Sorry, we can't serve you yet.",
    subtitle:
      "We can't serve money service businesses or ATM businesses right now. Tap the button below to get an email when we are able to serve you.",
    buttonText: "Done",
  },
  additionalWebsite: {
    title: "What other online platform does your business use?",
    subTitle: "For example, Instagram or Google Maps.",
    selectPlatform: "Select platform",
    Facebook: "Facebook",
    Instagram: "Instagram",
    LinkedIn: "LinkedIn",
    Twitter: "Twitter",
    Other: "Other",
    validation: "My business is not listed online.",
  },
  additionalSocialMedia: {
    title: {
      Facebook: "What is your Facebook page?",
      Instagram: "What is your Instagram handle?",
      LinkedIn: "What is your LinkedIn page?",
      Twitter: "What is your Twitter handle?",
      Other: "Add a link to your online listing",
    },
    subTitle: {
      Facebook: "",
      Instagram: "",
      LinkedIn: "",
      Twitter: "",
      Other: "For example, Google Maps or Yelp.",
    },
    label: {
      Facebook: "Facebook address",
      Instagram: "Instagram handle",
      LinkedIn: "LinkedIn address",
      Twitter: "Twitter handle",
      Other: "Online listing address",
    },
  },
}
