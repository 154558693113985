import React from "react"
import { Box } from "rebass"
import { theme } from "theme/theme"

interface IProps {
  section: number
  active: boolean
  color?: string
}

export const Progress: React.SFC<IProps> = ({ section, active, color }) => {
  return (
    <Box
      style={{
        width: 27,
        height: 27,
        borderRadius: 100,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        backgroundColor:
          active || section === 1 || (section === 2 && !active && color === "grey")
            ? `${theme.colors.blue}`
            : "rgba( 0, 155, 255, 0.3)",
      }}
    >
      <Box
        sx={{
          color: theme.colors.white,
          ...theme.fontStyles.h2,
          fontWeight: "bold",
        }}
      >
        {section}
      </Box>
    </Box>
  )
}
