import React, { useState } from "react"
import { useTranslation } from "react-i18next"
import { BaseLayout, ButtonRow, SidePanel, SingleTextInputScreen } from "shared"
import { nav } from "core/navigation"
import { TDebitFundingAmountProps } from "./debit-funding-amount.container"
import { Analytics } from "core/analytics/actions"
import {
  MAX_DEBIT_FUNDING_AMOUNT,
  MIN_DEBIT_FUNDING_AMOUNT,
  isValidDebitFundingAmount,
} from "utils"

export const DebitFundingAmountScreen: React.FC<TDebitFundingAmountProps> = ({
  amount,
  setAmount,
}) => {
  const errorToDisplay = (amount: string): string =>
    Number(amount) < MIN_DEBIT_FUNDING_AMOUNT
      ? t("debitFunding.amount.error.minAmountError", { minAmount: MIN_DEBIT_FUNDING_AMOUNT })
      : t("debitFunding.amount.error.maxAmountError", { maxAmount: MAX_DEBIT_FUNDING_AMOUNT })

  const { t } = useTranslation()
  const [newAmount, setNewAmount] = useState(amount.toString())
  const { prev, next } = nav.onboarding.debitFunding.pages.DebitFundingAmount

  return (
    <BaseLayout
      sidePanel={<SidePanel section={2} />}
      body={
        <SingleTextInputScreen
          title={t("debitFunding.amount.title")}
          subtitle={t("debitFunding.amount.subtitle")}
          dollarType
          onChange={setNewAmount}
          value={Number(newAmount) === 0 ? "" : newAmount}
          placeholderText={t("debitFunding.amount.placeholder")}
          validationType={"info"}
          validationText={
            newAmount && !isValidDebitFundingAmount(newAmount) ? errorToDisplay(newAmount) : ""
          }
          disableMinimizedPlaceholderText
        />
      }
      buttonRow={
        <ButtonRow
          buttonOnClick={() => {
            setAmount(Number(newAmount))
            next()
            Analytics.track("Debit Funding Amount Continue Tap", { amount: newAmount })
          }}
          buttonDisabled={!isValidDebitFundingAmount(newAmount)}
        />
      }
      onBackClick={() => {
        setAmount(0)
        prev()
      }}
    />
  )
}
