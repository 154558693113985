import * as Sentry from "@sentry/browser"
import { oc } from "ts-optchain"
import util from "util"
import { onError } from "@apollo/client/link/error"
import { ApolloClient, InMemoryCache, from, HttpLink, ApolloLink } from "@apollo/client"
import pkg from "../../../package.json"

import { osName, osVersion, mobileModel, browserName, browserVersion } from "react-device-detect"
import { isProduction, config } from "../../utils/environment"
import { getTokenOrLogout } from "core/authentication"
import { logger, CLIENT_NAME, scrubObj } from "utils"
import { traceId } from "core/error-logging"
import { setContext } from "@apollo/client/link/context"

const GQL_URI = config.graphQlUri

logger.info("GQL_URI: " + GQL_URI)

const cache = new InMemoryCache()

const withTokenLink = setContext(async () => ({ accessToken: await getTokenOrLogout() }))

const authLink = new ApolloLink((operation, forward) => {
  const accessToken = operation.getContext().accessToken

  if (!accessToken) {
    return null
  }

  operation.setContext({
    headers: {
      ...(accessToken ? { authorization: `Bearer ${accessToken}` } : {}),
      client: CLIENT_NAME,
      os_name: osName,
      os_version: `${osName} ${osVersion}`,
      ...(mobileModel !== "none" && { device_model: mobileModel }),
      browser: `${browserName} ${browserVersion}`,
      trace_id: traceId,
    },
  })
  return forward ? forward(operation) : null
})

const errorLink = onError(({ graphQLErrors, networkError, operation }) => {
  const operationString = oc(operation).query.loc.source.body()
  const operationVariables = isProduction() ? scrubObj()(operation.variables) : operation.variables

  Sentry.configureScope((scope) => {
    scope.setTags({
      operation: operation.operationName,
      service: `GQL`,
      trace_id: traceId,
      client: CLIENT_NAME,
    })

    scope.setExtras({
      ...(operationString && { operationString }),
      ...(operationVariables && { operationVariables }),
    })
  })

  if (networkError) {
    logger.error(`[Network error]: ${networkError}`)
  }

  if (graphQLErrors) {
    graphQLErrors.forEach((error) => {
      logger.error(error)
      Sentry.withScope((scope) => {
        scope.setExtras({
          error: util.inspect(error, { depth: 10 }),
        })
        Sentry.captureMessage(`[GQL-ERROR]: ${operation.operationName} - ${error.message}`)
      })
    })
  }
  if (
    (!graphQLErrors && networkError) ||
    (graphQLErrors && graphQLErrors.length < 1 && networkError)
  ) {
    Sentry.withScope((scope) => {
      scope.setExtras({
        error: networkError,
      })
      Sentry.captureMessage(`[GQL-ERROR]: ${operation.operationName} - Network Error`)
    })
  }
})

const httpLink = new HttpLink({
  credentials: "same-origin",
  fetch,
  uri: config.graphQlUri,
})

export const createClient = () => {
  const link = from([withTokenLink, authLink, errorLink, httpLink])

  return new ApolloClient({
    cache,
    link,
    name: CLIENT_NAME,
    version: pkg.version,
    connectToDevTools: isProduction() ? false : true,
  })
}
