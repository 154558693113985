import React from "react"
import { connect } from "react-redux"

import { IRootState, TRootDispatch } from "core"

import { UBORoleScreen } from "./ubo-role.screen"

import { UBOActions } from "../redux"

interface IUBORoleStateProps {
  firstName: string
  jobTitle: string
  role: string
}

interface IUBORoleDispatchProps {
  setValue: (key: "jobTitle" | "role", value: string) => void
}

export type TUBORoleProps = IUBORoleStateProps & IUBORoleDispatchProps

const mapStateToProps = (state: IRootState): IUBORoleStateProps => ({
  firstName: state.UBO.newOwner.firstName,
  jobTitle: state.UBO.newOwner.jobTitle,
  role: state.UBO.newOwner.role,
})

const mapDispatchToProps = (dispatch: TRootDispatch): IUBORoleDispatchProps => ({
  setValue: (key: "jobTitle" | "role", value: string) => dispatch(UBOActions.setValue(key, value)),
})

export const UBORole = connect(
  mapStateToProps,
  mapDispatchToProps,
)((props: TUBORoleProps) => <UBORoleScreen {...props} />)
