import React from "react"
import { connect } from "react-redux"
import { IRootState, TRootDispatch } from "core"
import { GlabalErrorBarComponent } from "./global-error-bar.component"
import { ErrorsActions } from "../redux"

interface IGlobalErrorBarStateProps {
  currentErrorMessage: string
}

interface IGlobalErrorBarDispatchProps {
  clearCurrentErrorMessage: () => void
}

export type TGlobalErrorBarProps = IGlobalErrorBarStateProps & IGlobalErrorBarDispatchProps

const mapStateToProps = (state: IRootState): IGlobalErrorBarStateProps => ({
  currentErrorMessage: state.errors.currentErrorMessage,
})

const mapDispatchToProps = (dispatch: TRootDispatch): IGlobalErrorBarDispatchProps => ({
  clearCurrentErrorMessage: () => dispatch(ErrorsActions.clearCurrentErrorMessage()),
})

export const GlobalErrorBarContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)((props: TGlobalErrorBarProps) => <GlabalErrorBarComponent {...props} />)
