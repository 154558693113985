import { initialKYCState, IKYCState } from "./kyc.state"

import { KYCActionEnums, TKYCActions } from "./kyc.actions"

export const KYCReducer = (state: IKYCState = initialKYCState, action: TKYCActions): IKYCState => {
  switch (action.type) {
    case KYCActionEnums.SET_FIRSTNAME:
      return {
        ...state,
        firstName: action.payload,
      }
    case KYCActionEnums.SET_LASTNAME:
      return {
        ...state,
        lastName: action.payload,
      }
    case KYCActionEnums.SET_BIRTHDATE:
      return {
        ...state,
        birthDate: action.payload,
      }
    case KYCActionEnums.SET_HOME_ADDRESS:
      return {
        ...state,
        homeAddress: action.payload,
      }
    case KYCActionEnums.SET_JOB_TITLE:
      return {
        ...state,
        jobTitle: action.payload,
      }
    case KYCActionEnums.SET_ROLE:
      return {
        ...state,
        role: action.payload,
      }
    case KYCActionEnums.SET_RESIDENCY:
      return {
        ...state,
        residency: action.payload,
      }
    case KYCActionEnums.SET_SSN:
      return {
        ...state,
        SSN: action.payload,
      }
    case KYCActionEnums.SET_UNSUPPORTED_COUNTRY:
      return {
        ...state,
        unsupportedCountry: action.payload,
      }
    default:
      return state
  }
}
