import { navigate, navigateByBusinessType } from "../../utils"
import { CorporationType } from "types"

export const debitFundingNavigator = {
  pages: {
    DebitFundingLanding: {
      next: ({ skipDebitFunding }: { skipDebitFunding: boolean }) =>
        skipDebitFunding ? navigate("BACKGROUND_CHECK_SUBMIT") : navigate("DEBIT_FUNDING_AMOUNT"),
      prev: (corpTypeEnum: CorporationType, hasMultipleOwners: boolean) => {
        const prevControlPersonScreen = hasMultipleOwners
          ? "CONTROL_PERSON_INVITE"
          : "CONTROL_PERSON_SELECT_ROUTE"
        navigateByBusinessType(corpTypeEnum, {
          soleprop: { route: "KYB_CARD_SAMPLE" },
          corp: { route: prevControlPersonScreen },
          partnership: { route: prevControlPersonScreen },
        })
      },
    },
    DebitFundingAmount: {
      prev: () => navigate("DEBIT_FUNDING_LANDING"),
      next: () => navigate("DEBIT_FUNDING_DETAILS"),
    },
    DebitFundingDetails: {
      prev: () => navigate("DEBIT_FUNDING_AMOUNT"),
      next: () => navigate("DEBIT_FUNDING_SUCCESS"),
    },
    DebitFundingSuccess: {
      prev: () => navigate("DEBIT_FUNDING_DETAILS"),
      next: () => navigate("BACKGROUND_CHECK_SUBMIT"),
    },
  },
}
