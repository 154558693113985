import React from "react"
import { Box, Text } from "rebass"
import { theme } from "theme/theme"

export type TValidationTypes = "error" | "info"

interface IProps {
  text: string
  validationType?: TValidationTypes
}

export const ValidationText: React.SFC<IProps> = ({ text, validationType = "error" }) => (
  <Box>
    <Text
      sx={{
        ...theme.fontStyles.smallText,
        color: validationType === "error" ? theme.colors.red : theme.colors.grey,
      }}
    >
      {text}
    </Text>
  </Box>
)
