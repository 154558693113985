export const sidePanel = {
  header1: "Tell us about yourself",
  header2: "Tell us about your business",
  header3: "Open your bank account",
  text1:
    "We'll need to gather a few personal details to confirm your identity.\n\n Have your Social Security Number (SSN) ready to speed up the process.",
  text2:
    "Let's get to work. Have important company information and details about co-workers ready to go.",
  text3: "Download the app and start banking.",
  info: "A full-featured bank account for your business.",
}
