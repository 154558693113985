export const kyc = {
  landing: {
    title: "Tell us a little bit more about yourself",
    subtitle: "We'll use this information to confirm your identity.",
  },
  name: {
    title: "What's your name?",
    firstName: "Enter your first name",
    lastName: "Enter your last name",
  },
  birthDate: {
    title: "What is your date of birth, {{firstName}}?",
    placeholder: "MM/DD/YYYY",
    error: "You must be 18 or older to open an account",
    info: "Please enter a valid birth date",
  },
  address: {
    title: "What is your home address?",
    dropdownPlaceholder: "State",
    streetAddressError: "Please enter an address less than {{maxStreetAddressLength}} characters",
    suiteError: "Please enter a suite number less than {{maxSuiteLength}} characters",
    cityError: "Please enter a city less than {{maxCityLength}} characters",
  },
  residency: {
    title: "Are you a US citizen or resident?",
    yes: "Yes, I am",
    no: "No, I am not",
  },
  unsupportedCountrySuccess: {
    title: "You'll be hearing from us soon!",
  },
  ssn: {
    title: "What is your Social Security Number (SSN)?",
    subtitle: "We need your SSN to confirm your identity. We'll keep it secure and private.",
    placeholder: "Enter your SSN",
  },
  ssnMissing: {
    title: "Please re-enter your Social Security Number",
    subtitle:
      "For security reasons we don't store your private information between sessions. Please re-enter your SSN to finish your application.",
    placeholder: "SSN",
  },
  complete: {
    title: "{{firstName}}, you're just minutes from your NorthOne account!",
    subtitle: "Next, we'll ask you about your business.",
  },
  role: {
    title: "What is your role in your business?",
    subtitle: "For example, CEO.",
    jobTitlePlaceholder: "Enter your job title",
    rolePlaceholder: "What best describes your role?",
    director: "Director",
    officerCoowner: "Officer / Co-owner",
    officerEmployee: "Officer / Employee",
    partner: "Partner",
  },
  unsupportedCountry: {
    title: "Sorry, we can only serve US citizens or residents",
    subtitle:
      "We're working hard to bring NorthOne to business builders all around the world. Want to keep in touch?",
    placeholder: "Select your country of residence",
    buttonText: "Keep me updated",
  },
}
