import React, { useState } from "react"
import { TKYBNatureProps } from "./kyb-nature.container"
import { BaseLayout, BaseScreen, SidePanel, DropDown, ButtonRow } from "shared"
import { useTranslation } from "react-i18next"
import gql from "graphql-tag"
import { useNaicsSectorsQuery } from "generated/graphql"
import { nav } from "core/navigation"
import { Analytics } from "../../../../core/analytics/actions"

export const NAICS_SECTORS_QUERY = gql`
  query naicsSectors {
    naicsSectors {
      code
      description
    }
  }
`
export const KYBNatureScreen: React.FC<TKYBNatureProps> = ({
  setNature,
  businessType,
  setCurrentErrorMessage,
}) => {
  const { t } = useTranslation()
  const [newNature, setNewNature] = useState("")
  const valueIsValid = () => code !== 0
  const { error, data } = useNaicsSectorsQuery()
  const natures: Set<string> = new Set()
  const { prev, next } = nav.onboarding.kyb.pages.KYBNature

  let code: number = 0
  if (data && data.naicsSectors) {
    data.naicsSectors.forEach((sector) => {
      if (sector) {
        natures.add(sector.description)
      }
      if (newNature && sector && newNature === sector.description) {
        code = sector.code
      }
    })
  }
  // if (loading) {
  //   //make loading
  // }
  if (error) {
    setCurrentErrorMessage(t("kyb.nature.error"))
  }
  return (
    <BaseLayout
      sidePanel={<SidePanel section={2} />}
      body={
        <BaseScreen title={t("kyb.nature.title")}>
          <DropDown
            options={Array.from(natures)}
            newValue={newNature}
            setNewValue={setNewNature}
            placeholder={t("kyb.nature.placeholder")}
          />
        </BaseScreen>
      }
      buttonRow={
        <ButtonRow
          buttonOnClick={() => {
            setNature(code)
            Analytics.track(`KYB Business Industry Continue Tap`)
            next()
          }}
          buttonDisabled={!valueIsValid()}
        />
      }
      onBackClick={() => prev(businessType)}
    />
  )
}
