import React from "react"
import { useTranslation } from "react-i18next"
import { Box } from "rebass"
import { HeadingWithProgress } from "../heading-with-progress"
import { theme } from "theme/theme"

interface IProps {
  section: number
  customSidePanel?: boolean
  customSectionLength?: number
  customHeadingTranslation?: string
  customTextTranslation?: string
  overridenTextDisplaySection?: number
}

export const SidePanel: React.SFC<IProps> = ({
  section: currentSection,
  customSidePanel = false,
  customSectionLength,
  customHeadingTranslation = "",
  customTextTranslation = "",
  overridenTextDisplaySection,
}) => {
  const { t } = useTranslation()
  return (
    <Box
      sx={{
        marginTop: [0, 180, 220],
        marginLeft: [0, -90, -120, -90],
        zIndex: 10,
        display: ["none", "flex"],
        alignItems: "center",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-evenly",
          backgroundColor: theme.colors.white,
          height: [0, 420, 330],
          width: [0, 250, 310],
          borderRadius: 20,
          border: "1px solid #EDF2F8",
          boxShadow: "0px 10px 12px #EDF2F8",
        }}
      >
        {customSidePanel
          ? [...Array(customSectionLength)]
              .map((_, i) => i + 1)
              .map((sectionNumber, index) => (
                <Box key={index}>
                  <HeadingWithProgress
                    key={sectionNumber}
                    heading={t(customHeadingTranslation + sectionNumber)}
                    customInactiveTextPaddingTop={20}
                    text={
                      currentSection < sectionNumber
                        ? t(customTextTranslation + overridenTextDisplaySection)
                        : ""
                    }
                    color={currentSection >= sectionNumber ? "grey" : "accent"}
                    section={sectionNumber}
                    active={currentSection === sectionNumber}
                  />
                </Box>
              ))
          : [1, 2, 3].map((sectionNumber, index) => (
              <Box key={index}>
                <HeadingWithProgress
                  key={sectionNumber}
                  heading={t("shared.sidePanel.header" + sectionNumber)}
                  text={
                    currentSection === sectionNumber
                      ? t("shared.sidePanel.text" + sectionNumber)
                      : ""
                  }
                  color={currentSection >= sectionNumber ? "grey" : "accent"}
                  section={sectionNumber}
                  active={currentSection === sectionNumber}
                />
              </Box>
            ))}
      </Box>
    </Box>
  )
}
