import React from "react"
import { useTranslation } from "react-i18next"
import { BaseLayout, BaseScreen, SidePanel, ButtonRow } from "shared"
import { TSignUpEmailSentProps } from "./sign-up-email-sent.container"
import gql from "graphql-tag"
import { useEmailVerifiedQuery, useResendVerificationEmailMutation } from "generated/graphql"
import { nav } from "core/navigation"
import { logger } from "utils/logger"

export const EMAIL_VERIFIED_QUERY = gql`
  query emailVerified($email: String!) {
    emailVerified(email: $email)
  }
`

export const RESEND_EMAIL_VERIFICATION = gql`
  mutation resendVerificationEmail($userEmail: String!) {
    resendVerificationEmail(userEmail: $userEmail) {
      success
    }
  }
`

export const SignUpEmailSentScreen: React.FC<TSignUpEmailSentProps> = ({
  email,
  setCurrentErrorMessage,
}) => {
  const TWENTY_MIN_IN_MS = 1200000

  setTimeout(() => {
    nav.onboarding.utils.goToStart()
  }, TWENTY_MIN_IN_MS)

  const { t } = useTranslation()
  const { prev, next } = nav.onboarding.signUp.pages.SignUpEmailSent
  const { error, data: emailVerifiedData } = useEmailVerifiedQuery({
    pollInterval: 2000,
    variables: { email: email.toLowerCase() },
  })

  if (emailVerifiedData && emailVerifiedData.emailVerified) {
    window.location.reload()
  }

  const [
    resendVerificationEmail,
    {
      data: resendVerificationEmailData,
      loading: resendVerificationEmailLoading,
      error: resendVerificationEmailError,
    },
  ] = useResendVerificationEmailMutation({
    variables: { userEmail: email.toLowerCase() },
  })

  if (error || resendVerificationEmailError) {
    setCurrentErrorMessage(t("errors.generic"))
  }
  const _handleButtonClick = () => {
    resendVerificationEmail()
    if (
      resendVerificationEmailData &&
      resendVerificationEmailData.resendVerificationEmail &&
      resendVerificationEmailData.resendVerificationEmail.success
    ) {
      logger.info(`Verification email resent for: ${email}`)
      next()
    }
  }
  return (
    <BaseLayout
      sidePanel={<SidePanel section={1} />}
      body={
        <BaseScreen
          title={t("signUp.emailSent.title")}
          multilineSubtitle={[
            t("signUp.emailSent.subtitle.line1", { email }),
            t("signUp.emailSent.subtitle.line2"),
          ]}
        />
      }
      buttonRow={
        <ButtonRow
          buttonIsLoading={resendVerificationEmailLoading}
          buttonText={t("buttons.resendConfirmation")}
          buttonOnClick={_handleButtonClick}
          buttonSupertext={t("buttons.supertext.didNotReceiveEmail")}
        />
      }
      noBackButton
      onBackClick={prev}
    />
  )
}
