import { redirectWithTraits } from "@northone/segment-js"
import { RoutesActionType } from "./index"
import { store } from "index"
import { CorporationType } from "types"
import { logger } from "utils/logger"
import { config, TApplicationStatus } from "utils"

/**
 * Navigates to the given route  ( by dispatching a redux action )
 * Optionally takes params for parameterized routes (i.e users/:id/edit)
 */
export const navigate = <T>(route: RoutesActionType, params?: T): void => {
  logger.info(`Navigating to: ${route}, with params: ${JSON.stringify(params)}`)
  store.dispatch({
    type: route,
    payload: {
      ...params,
    },
  })
}

/**
 * returns url params from redux store i.e) for url `users/:name/edit`would expect to have an object of type
 * { name: string } to be returned
 * @param routeAction -> the route to grab url params for
 */
export const getRouteParams = <T extends object>(route?: RoutesActionType): null | T => {
  const state = store.getState()
  if (state.location) {
    const currentRoute = state.location.type

    if (route !== currentRoute) {
      logger.warn(
        `Current route is: ${currentRoute}, but trying to get params for route: ${route},
          returning null to prevent unexpected behaviour`,
      )
      return null
    }
    const params = state.location.payload
    if (params) {
      logger.info(` returning params: ${JSON.stringify(params)} for route: ${state.location.type}`)
      return params
    }
  }

  return null
}

type BusinessType = "soleprop" | "corp" | "partnership"

/**
 * maps the 5 CorporationType enum values to one of 3 business types
 */
const businessTypeFromCorporationTypeEnum = (corpType: CorporationType): BusinessType => {
  switch (corpType) {
    case CorporationType.Ccorp:
    case CorporationType.Llc:
    case CorporationType.Scorp:
      return "corp"
    case CorporationType.Soleprop:
      return "soleprop"
    case CorporationType.Partnership:
      return "partnership"
  }
}

interface INavOptions<T extends object = {}> {
  route: RoutesActionType
  params?: T
}
/**
 * conditionally navigates using the options matching the current businessType
 * ( current businessType determined using the CorpType enum )
 */
export const navigateByBusinessType = <Params extends object>(
  corpTypeEnum: CorporationType,
  options: {
    soleprop?: INavOptions<Params>
    corp?: INavOptions<Params>
    partnership?: INavOptions<Params>
  },
) => {
  const bizType = businessTypeFromCorporationTypeEnum(corpTypeEnum)

  switch (bizType) {
    case "soleprop":
      if (options.soleprop) {
        navigate(options.soleprop.route, options.soleprop.params)
      }
      break
    case "corp":
      if (options.corp) {
        navigate(options.corp.route, options.corp.params)
      }
      break
    case "partnership":
      if (options.partnership) {
        navigate(options.partnership.route, options.partnership.params)
      }
  }
}

/**
 * Navigates to screen based on application status
 */
export const navigateByApplicationStatus = (
  applicationStatus: TApplicationStatus,
  emailVerified: boolean,
) => {
  logger.info(
    `navigateByApplicationStatus - applicationStatus: ${applicationStatus}, emailVerified: ${emailVerified}`,
  )

  switch (applicationStatus) {
    case "REJECTED":
    case "APPROVED":
    case "SUBMITTED_AND_WAITING":
      redirectWithTraits(config.webBankingUrl)
      break
    case "NOT_SUBMITTED":
      logger.info(`Application not submitted navigating to KYC start`)
      navigate(emailVerified ? "KYC_LANDING" : "SIGN_UP_EMAIL_SENT")
      break
    default:
      break
  }
}
