import React from "react"
import { BaseLayout, BaseScreen, ButtonRow, Button, SidePanel } from "shared"
import { nav } from "core/navigation"
import { useTranslation } from "react-i18next"
import { IDebitFundingLandingStateProps } from "./debit-funding-landing.container"
import { Analytics } from "core/analytics/actions"

export const DebitFundingLandingScreen: React.FC<IDebitFundingLandingStateProps> = ({
  businessType,
  hasMultipleOwners,
}) => {
  const { next, prev } = nav.onboarding.debitFunding.pages.DebitFundingLanding
  const { t } = useTranslation()
  return (
    <BaseLayout
      sidePanel={<SidePanel section={2} />}
      body={
        <BaseScreen
          title={t("debitFunding.landing.title")}
          subtitle={t("debitFunding.landing.subtitle")}
        ></BaseScreen>
      }
      buttonRow={
        <ButtonRow
          noSimulatedSaving
          buttonText={t("debitFunding.landing.continue")}
          buttonOnClick={() => {
            next({ skipDebitFunding: false })
            Analytics.track("Debit Funding Continue Tap")
          }}
          secondButton={
            <Button
              onClick={() => {
                next({ skipDebitFunding: true })
                Analytics.track("Debit Funding Skip Tap")
              }}
              type="secondary"
              noSimulatedSavingOnClick
            >
              {t("debitFunding.landing.skip")}
            </Button>
          }
        />
      }
      onBackClick={() => prev(businessType, hasMultipleOwners)}
    />
  )
}
