import React, { useState } from "react"
import { Box, Text, Image } from "rebass"
import { useTranslation } from "react-i18next"
import leaf from "images/leaf.svg"
import { BaseLayout, BaseScreen, SidePanel, Checkbox, ButtonRow } from "shared"
import { TKYBIllegalBusinessCheckProps } from "./kyb-illegal-business-check.container"
import { theme } from "theme/theme"
import { nav } from "core/navigation"
import { Analytics } from "../../../../core/analytics/actions"

export const KYBIllegalBusinessCheckScreen: React.FC<TKYBIllegalBusinessCheckProps> = ({
  businessType,
}) => {
  const { t } = useTranslation()
  const activities = [t("kyb.illegal.gambling"), t("kyb.illegal.money"), t("kyb.illegal.ATM")]
  const [selection, setSelection] = useState(false)
  const { prev, next } = nav.onboarding.kyb.pages.KYBIllegalBusinessCheck

  return (
    <BaseLayout
      sidePanel={<SidePanel section={2} />}
      body={
        <BaseScreen title={t("kyb.illegal.title")}>
          <Box
            style={{
              fontSize: theme.fontStyles.h2.fontSize,
              color: theme.colors.grey,
              display: "flex",
              flexDirection: "column",
            }}
            sx={{ gridRowStart: 2 }}
          >
            <Box
              style={{
                display: "flex",
                flexDirection: "row",
                margin: "0.3rem",
              }}
            >
              <Image src={leaf} />
              <Text style={{ marginLeft: "0.6rem" }}>{t("kyb.illegal.marijuana")}</Text>
            </Box>
            {activities.map((activity, index) => (
              <Text key={index} style={{ margin: "0.3rem" }}>
                {activity}
              </Text>
            ))}
          </Box>
          <Box style={{ fontSize: theme.fontStyles.h2.fontSize }}>
            <Checkbox
              label={t("kyb.illegal.check")}
              onClick={() => {
                setSelection(selection)
                setSelection(!selection)
              }}
              isSelected={selection}
            />
          </Box>
        </BaseScreen>
      }
      buttonRow={
        <ButtonRow
          buttonOnClick={() => {
            setSelection(selection)
            Analytics.track(`KYB Illegal Business Check Continue Tap`)
            next(businessType)
          }}
          buttonDisabled={!selection}
        />
      }
      onBackClick={prev}
    />
  )
}
