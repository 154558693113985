import React, { ReactNode } from "react"
import { Box } from "rebass"
import { Button } from "shared"
import { TButtonType } from "shared/button"
import { useTranslation } from "react-i18next"

interface IProps {
  buttonText?: string
  buttonOnClick: () => void
  buttonDisabled?: boolean
  buttonType?: TButtonType
  buttonSupertext?: string
  buttonSupertextAction?: () => void
  noButton?: boolean
  buttonIsLoading?: boolean
  withEnter?: boolean
  noSimulatedSaving?: boolean
  secondButton?: ReactNode
}

export const ButtonRow: React.SFC<IProps> = ({
  buttonText,
  buttonOnClick,
  buttonDisabled,
  buttonSupertext,
  buttonSupertextAction,
  buttonType = "primary",
  noButton,
  buttonIsLoading,
  withEnter = true,
  noSimulatedSaving,
  secondButton,
}) => {
  const { t } = useTranslation()
  return (
    <Box
      sx={{
        zIndex: 2,
        display: "flex",
        flexDirection: ["column", "column", "column", "row"],
        alignItems: "flex-start",
        width: "100%",
      }}
    >
      {noButton ? null : (
        <Box sx={{ display: "flex" }}>
          <Button
            disabled={buttonDisabled}
            withEnter={withEnter}
            onClick={buttonOnClick}
            buttonSupertext={buttonSupertext}
            buttonSupertextAction={buttonSupertextAction}
            type={buttonType}
            isLoading={buttonIsLoading}
            noSimulatedSavingOnClick={noSimulatedSaving}
          >
            {buttonText || t("buttons.continue")}
          </Button>
        </Box>
      )}
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-start",
          paddingTop: [35, 35, 35, 0],
          paddingLeft: [0, 0, 0, 20],
        }}
      >
        {secondButton ? secondButton : <Box />}
      </Box>
    </Box>
  )
}
