import { IRootState } from "core"
import { createSelector } from "reselect"
import uuid from "uuid"
import { IOwner } from "types"

export const getOnboarding = (state: IRootState) => ({
  signUp: state.signUp,
  KYC: state.KYC,
  KYB: state.KYB,
  UBO: state.UBO,
})

export const getOwnership = (state: IRootState) => state.UBO

export const getCompiledInitialOwner = createSelector(
  getOnboarding,
  (onboarding) => ({
    id: uuid(),
    iovationBlackbox: "any string",
    firstName: onboarding.KYC.firstName,
    lastName: onboarding.KYC.lastName,
    birthDate: onboarding.KYC.birthDate,
    homeAddress: onboarding.KYC.homeAddress,
    citizenship: onboarding.KYC.residency,
    emailAddress: onboarding.signUp.email,
    ownershipPercentage: onboarding.KYB.percentOwnership,
    phoneNumber: onboarding.signUp.phone,
    role: onboarding.KYC.role,
    jobTitle: onboarding.KYC.jobTitle,
    ssn: onboarding.KYC.SSN,
    unsupportedCountry: onboarding.KYC.unsupportedCountry,
  }),
)

export const getAllOwnerEmails = createSelector(
  getOnboarding,
  (onboarding) =>
    onboarding.UBO.additionalOwners.reduce(
      (acc, curr) => [...acc, curr.emailAddress.toLowerCase()],
      [onboarding.signUp.email.toLowerCase()],
    ),
)

export const getEditingOwner = createSelector(
  getOwnership,
  (ownership) => {
    const { editOwner, additionalOwners } = ownership
    return additionalOwners.filter((owner) => owner.id === editOwner.id)[0]
  },
)

const uboSelector = (state: IRootState) => state.UBO

export const getTotalUBOOwnershipPercentage = (
  additionalOwners: IRootState["UBO"]["additionalOwners"],
) => {
  return additionalOwners.reduce(
    (accumulator, current) => accumulator + Number(current.ownershipPercentage),
    0,
  )
}

export const getUBOAdditionalOwners = createSelector(
  uboSelector,
  (UBO): IOwner[] => {
    return UBO.additionalOwners.map((owner) => ({
      ...owner,
      ssn: UBO.sensitive.additionalOwners[owner.id]
        ? UBO.sensitive.additionalOwners[owner.id].ssn
        : "",
    }))
  },
)

export const getNewOwner = createSelector(
  uboSelector,
  (UBO): IOwner => ({
    ...UBO.newOwner,
    ssn: UBO.sensitive.newOwner.ssn,
  }),
)

export const hasMultipleOwners = (state: IRootState) => {
  const firstOwner = getCompiledInitialOwner(state)
  const additionalOwners = getUBOAdditionalOwners(state)
  return Boolean(parseInt(firstOwner.ownershipPercentage, 10) < 100 && additionalOwners.length)
}
