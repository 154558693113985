import React from "react"
import { redirectWithTraits } from "@northone/segment-js"
import { useTranslation } from "react-i18next"
import { Box, Text } from "rebass"
import { BaseLayout, BaseScreen, SidePanel, ButtonRow } from "shared"
import { TBackgroundCheckSubmitProps } from "./background-check-submit.container"
import { theme } from "theme/theme"
import gql from "graphql-tag"
import { useBusinessBackgroundCheckMutation, PrequalFailureStatusEnum } from "generated/graphql"
import { nav } from "core/navigation"
import { logger } from "utils/logger"
import { Analytics } from "../../../../core/analytics/actions"
import { _localStorage } from "core/local-storage-service"
import ReactGA from "react-ga"
import { config } from "utils"

export const BUSINESS_BACKGROUND_CHECK = gql`
  mutation businessBackgroundCheck(
    $business: BackgroundCheckBusinessInfoInput!
    $owners: [BusinessBackgroundCheckOwnerInfoInput!]!
    $CJEVENT: String
    $debitFundingInfo: DebitFundingInfoInput
  ) {
    businessBackgroundCheckSubmit(
      business: $business
      owners: $owners
      CJEVENT: $CJEVENT
      debitFundingInfo: $debitFundingInfo
    ) {
      checkStatus
      prequalFailureStatus
    }
  }
`

export const BackgroundCheckSubmitScreen: React.FC<TBackgroundCheckSubmitProps> = ({
  owners,
  business,
  setCurrentErrorMessage,
  CJEVENT,
  resetReduxState,
  debitFundingInfo,
}) => {
  const { prev, next } = nav.onboarding.backgroundCheck.pages.BackgroundCheckSubmit

  const { t } = useTranslation()

  const clearAllAppState = () => {
    logger.info("BackgroundCheckSubmitScreen: Clearing all app state")
    resetReduxState()
    _localStorage.clearAll()
  }

  const [
    businessBackgroundCheck,
    { data, loading, error, called },
  ] = useBusinessBackgroundCheckMutation({
    variables: { business, owners, CJEVENT, debitFundingInfo },
  })

  const _handleSubmitClick = () => {
    businessBackgroundCheck()
  }

  if (
    data &&
    data.businessBackgroundCheckSubmit &&
    (data.businessBackgroundCheckSubmit.prequalFailureStatus ===
      PrequalFailureStatusEnum.FileLocked ||
      data.businessBackgroundCheckSubmit.prequalFailureStatus ===
        PrequalFailureStatusEnum.FileFrozen)
  ) {
    setCurrentErrorMessage(t("kyb.submitApplication.lockedOrFrozen"))
  } else if (
    data &&
    data.businessBackgroundCheckSubmit &&
    data.businessBackgroundCheckSubmit.prequalFailureStatus === PrequalFailureStatusEnum.Fail
  ) {
    clearAllAppState()
    next()
  } else if (error) {
    logger.error("error", error)
    setCurrentErrorMessage(t("kyb.submitApplication.GQLError"))
  } else if (called && !loading && data) {
    clearAllAppState()
    redirectWithTraits(config.webBankingUrl)
  }

  return (
    <Box>
      <BaseLayout
        sidePanel={<SidePanel section={2} />}
        body={
          <BaseScreen
            title={t("kyb.submitApplication.title")}
            subtitle={t("kyb.submitApplication.subtitle")}
          >
            <Text
              style={{
                fontSize: theme.fontStyles.h2.fontSize,
                color: theme.colors.grey,
                marginTop: "2rem",
              }}
            >
              {t("backgroundCheck.backgroundCheckSubmit.text")}
            </Text>
          </BaseScreen>
        }
        buttonRow={
          <ButtonRow
            buttonOnClick={() => {
              Analytics.track(`KYB Submit Application Tap`)
              ReactGA.event({
                category: "Application Submission - Desktop Onboarding",
                action: "click",
              })
              _handleSubmitClick()
            }}
            buttonText={t("kyb.submitApplication.button")}
            buttonType="principal"
            buttonIsLoading={loading}
            withEnter={false}
          />
        }
        onBackClick={() => prev({ withDebitFunding: Boolean(debitFundingInfo) })}
      />
    </Box>
  )
}
