import React from "react"
import { connect } from "react-redux"

import { IRootState, TRootDispatch } from "core"
import { nav } from "core/navigation"
import { ControlPersonActions } from "../redux"
import { EmailScreen } from "shared"
import { getAllOwnerEmails } from "components"

interface IControlPersonEmailStateProps {
  firstName: string
  email: string
  allOwnerEmails: string[]
}

interface IControlPersonEmailDispatchProps {
  setEmail: (email: string) => void
}

export type TControlPersonEmailProps = IControlPersonEmailStateProps &
  IControlPersonEmailDispatchProps

const mapStateToProps = (state: IRootState): IControlPersonEmailStateProps => ({
  firstName: state.controlPerson.candidate.firstName,
  email: state.controlPerson.candidate.email,
  allOwnerEmails: getAllOwnerEmails(state),
})

const mapDispatchToProps = (dispatch: TRootDispatch): IControlPersonEmailDispatchProps => ({
  setEmail: (email: string) => dispatch(ControlPersonActions.setEmail(email)),
})

export const ControlPersonEmailScreen: React.FC<TControlPersonEmailProps> = ({
  firstName,
  email,
  allOwnerEmails,
  setEmail,
}) => {
  const { prev, next } = nav.onboarding.controlPerson.pages.ControlPersonEmail

  return (
    <EmailScreen
      firstName={firstName}
      email={email}
      allOwnerEmails={allOwnerEmails}
      setEmail={setEmail}
      prev={prev}
      next={next}
    />
  )
}

export const ControlPersonEmail = connect(
  mapStateToProps,
  mapDispatchToProps,
)((props: TControlPersonEmailProps) => <ControlPersonEmailScreen {...props} />)
