import { navigate, navigateByBusinessType, getRouteParams } from "core/navigation/utils"
import { CorporationType } from "generated/graphql"

export const UBONavigator = {
  pages: {
    OwnershipList: {
      prev: (businessType: CorporationType) =>
        navigateByBusinessType(businessType, {
          corp: { route: "KYB_UBO_TYPE" },
          partnership: { route: "KYB_PERCENTAGE" },
        }),
      startNewOwner: () => navigate("UBO_INFO"),
      uboComplete: () => navigate("CONTROL_PERSON_SELECT_ROUTE"),
      goToOwnerReview: (ownerId: string) =>
        navigate<{ ownerId: string }>("UBO_REVIEW", { ownerId }),
    },
    UBOInfo: {
      prev: () => navigate("OWNERSHIP_LIST"),
      next: () => navigate("UBO_NAME"),
    },
    UBOReview: {
      prev: () => navigate("OWNERSHIP_LIST"),
      next: () => navigate("OWNERSHIP_LIST"),
      getParamOwnerId: (): string => {
        const params = getRouteParams<{ ownerId: string }>("UBO_REVIEW")
        if (params) {
          return params.ownerId
        }
        return ""
      },
    },
    UBOName: {
      prev: () => navigate("UBO_INFO"),
      next: () => navigate("UBO_ROLE"),
    },
    UBORole: {
      prev: () => navigate("UBO_NAME"),
      next: () => navigate("UBO_PERCENTAGE"),
    },
    UBOPercentage: {
      prev: () => navigate("UBO_ROLE"),
      next: () => navigate("UBO_EMAIL"),
    },
    UBOEmail: {
      prev: () => navigate("UBO_PERCENTAGE"),
      next: () => navigate("UBO_ADDRESS"),
    },
    UBOAddress: {
      prev: () => navigate("UBO_EMAIL"),
      next: () => navigate("UBO_PHONE"),
    },
    UBOPhone: {
      prev: () => navigate("UBO_ADDRESS"),
      next: () => navigate("UBO_BIRTHDATE"),
    },
    UBOBirthdate: {
      prev: () => navigate("UBO_PHONE"),
      next: () => navigate("UBO_RESIDENCY"),
    },
    UBOResidency: {
      prev: () => navigate("UBO_BIRTHDATE"),
      next: (isResident: boolean) =>
        isResident ? navigate("UBO_SSN") : navigate("UBO_UNSUPPORTED_COUNTRY"),
    },
    UBOUnsupportedCountry: {
      prev: () => navigate("UBO_RESIDENCY"),
      next: () => navigate("UBO_UNSUPPORTED_COUNTRY_SUCCESS"),
    },
    UBOUnsupportedCountrySuccess: {
      prev: () => navigate("UBO_UNSUPPORTED_COUNTRY"),
    },
    UBOSSN: {
      prev: () => navigate("UBO_RESIDENCY"),
      next: () => navigate("OWNERSHIP_LIST"),
    },
  },
}
