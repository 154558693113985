import React from "react"
import { useTranslation } from "react-i18next"
import { Box } from "rebass"
import { TextInput, DropDown } from "shared"
import { TValidationTypes } from "shared/validation-text"
import { theme } from "theme/theme"

interface IInputFormProps {
  inputFields: IInputField[]
  disabledFields?: boolean
  autoFocusDisabled?: boolean
}

interface IInputField {
  field: string
  value: any
  validationText?: string
  validationType?: TValidationTypes
  isPassword?: boolean
  withDateMask?: boolean
  withObfuscation?: boolean
  withPhoneNumberMask?: boolean
  withOnlyNumbers?: boolean
  placeholderText?: string
  dropDown?: string[]
  setValue: (value: any) => void
  maxLength?: number
}

export const InputForm: React.SFC<IInputFormProps> = ({
  inputFields,
  disabledFields,
  autoFocusDisabled,
}) => {
  const { t } = useTranslation()

  const fieldsColumns = (inputs: IInputField[]) => {
    return (
      <Box
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "flex-start",
        }}
      >
        <Box>
          {inputs.map(
            (
              {
                field,
                value,
                validationText,
                validationType,
                setValue,
                isPassword,
                withObfuscation,
                withPhoneNumberMask,
                withDateMask,
                withOnlyNumbers,
                placeholderText,
                dropDown,
                maxLength,
              },
              idx,
            ) => (
              <Box
                key={field}
                sx={{
                  marginBottom:
                    validationText && inputs[idx + 1] && inputs[idx + 1].value
                      ? theme.spacing.headingToBody
                      : theme.spacing.inputFormSpacing,
                }}
              >
                {dropDown ? (
                  <DropDown
                    options={dropDown}
                    newValue={value}
                    setNewValue={setValue}
                    placeholder={t(`shared.inputForm.placeholder.${field}`)}
                  />
                ) : (
                  <TextInput
                    placeholderText={placeholderText || t(`shared.inputForm.placeholder.${field}`)}
                    onChange={setValue}
                    value={value}
                    autoFocus={!autoFocusDisabled && !idx}
                    disabled={disabledFields}
                    validationText={validationText}
                    validationType={validationType}
                    isPassword={isPassword || false}
                    withObfuscation={withObfuscation || false}
                    withDateMask={withDateMask || false}
                    withPhoneNumberMask={withPhoneNumberMask || false}
                    withOnlyNumbers={withOnlyNumbers || false}
                    maxLength={maxLength}
                  />
                )}
              </Box>
            ),
          )}
        </Box>
      </Box>
    )
  }

  return <Box>{fieldsColumns(inputFields)}</Box>
}
