import React from "react"
import { connect } from "react-redux"

import { IRootState, TRootDispatch } from "core"
import { CorporationType } from "types"

import { KYBCorpTypeScreen } from "./kyb-corp-type.screen"

import { KYBActions } from "../redux"

interface IKYBCorpTypeStateProps {
  corpType: CorporationType
}

interface IKYBCorpTypeDispatchProps {
  setCorpType: (corpType: CorporationType) => void
}

export type TKYBCorpTypeProps = IKYBCorpTypeStateProps & IKYBCorpTypeDispatchProps

const mapStateToProps = (state: IRootState): IKYBCorpTypeStateProps => ({
  corpType: state.KYB.corpType,
})

const mapDispatchToProps = (dispatch: TRootDispatch): IKYBCorpTypeDispatchProps => ({
  setCorpType: (corpType: CorporationType) =>
    dispatch(KYBActions.setCorpType(corpType)),
})

export const KYBCorpType = connect(
  mapStateToProps,
  mapDispatchToProps,
)((props: TKYBCorpTypeProps) => <KYBCorpTypeScreen {...props} />)
