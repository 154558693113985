import { initialDebitFundingState, IDebitFundingState } from "./debit-funding.state"

import { DebitFundingActionEnums, TDebitFundingActions } from "./debit-funding.actions"

export const DebitFundingReducer = (
  state: IDebitFundingState = initialDebitFundingState,
  action: TDebitFundingActions,
): IDebitFundingState => {
  switch (action.type) {
    case DebitFundingActionEnums.DEBIT_FUNDING_SET_AMOUNT:
      return {
        ...state,
        amount: action.payload,
      }
    case DebitFundingActionEnums.DEBIT_FUNDING_SET_CARD_DETAILS:
      return {
        ...state,
        debitCardData: action.payload,
      }
    default:
      return state
  }
}
