export const login = {
  title: "Let's get back to business",
  subtitle: "Log in to continue your application",
  forgot: "Forgot your password? ",
  resetLink: "Reset here",
  newApplication: "Start a new application",
  emailPlaceholder: "Enter your email address",
  passwordPlaceholder: "Enter your password",
  loading: "Loading...",
  sidePanel: {
    point1: "FDIC insured bank account",
    point2: "Routing & Account numbers",
    point3: "Mastercard debit cards",
    point4: "Fast ACH and wire payments",
    point5: "Over 300,000 ATMs nationwide",
    point6: "Mobile check deposits",
    loggedInAs: "Logged in as",
    logoutCTA: "Log out",
    logoutPrompt: "This will sign you out of the application flow and clear all data.",
  },
}
