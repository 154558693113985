import { signUpReducer as signUp } from "components/onboarding/sign-up"
import { KYCReducer as KYC } from "components/onboarding/kyc"
import { KYBReducer as KYB } from "components/onboarding/kyb"
import { UBOReducer as UBO } from "components/onboarding/ubo"
import { controlPersonReducer as controlPerson } from "components/onboarding/control-person"
import { DebitFundingReducer as debitFunding } from "components/onboarding/debit-funding"

import { ErrorsReducer as errors } from "components/errors"
import { CJEVENTReducer as CJEVENT } from "core/cjevent"

import { GlobalActionEnums } from "./actions.types"

import { pageReducer as page } from "./page-reducer"
import { IRootState, initialState } from "./root-state"
import { LocationState } from "redux-first-router"
import { Reducer, combineReducers } from "redux"

type ReduxFirstRouterLocationState = LocationState<string, any>

export const createRootReducer = (locationReducer: Reducer<ReduxFirstRouterLocationState>) => (
  state: IRootState | undefined,
  action: any,
) => {
  switch (action.type) {
    case GlobalActionEnums.RESET_REDUX_STATE:
      state = {
        // keep state added by navigation and other libraries ( e.g redux persist )
        ...state,
        // clear any application defined state
        ...initialState,
      } as IRootState | undefined
      break
    case GlobalActionEnums.RESET_REDUX_STATE_EXCLUDING_SIGNUP:
      const initialStateWithRetainedSignUpState = state
        ? {
            ...state,
            ...initialState,
            // don't clear sign up state (e.g email and phone number)
            ...state.signUp,
          }
        : undefined
      state = { ...initialStateWithRetainedSignUpState } as IRootState | undefined
      break
    default:
      break
  }
  return combineReducers({
    page,
    location: locationReducer,
    signUp,
    KYC,
    KYB,
    UBO,
    errors,
    CJEVENT,
    controlPerson,
    debitFunding,
  })(state, action)
}
