import React from "react"
import { connect } from "react-redux"
import { IRootState, TRootDispatch } from "core"
import { SignUpEmailResentScreen } from "./sign-up-email-resent.screen.gql"
import { ErrorsActions } from "components/errors"

interface ISignUpEmailResentStateProps {
  email: string
}

interface ISignUpEmailDispatchProps {
  setCurrentErrorMessage: (errorMessage: string) => void
}

export type TSignUpEmailResentProps = ISignUpEmailResentStateProps & ISignUpEmailDispatchProps

const mapStateToProps = (state: IRootState): ISignUpEmailResentStateProps => ({
  email: state.signUp.email,
})

const mapDispatchToProps = (dispatch: TRootDispatch): ISignUpEmailDispatchProps => ({
  setCurrentErrorMessage: (errorMessage: string) =>
    dispatch(ErrorsActions.setCurrentErrorMessage(errorMessage)),
})

export const SignUpEmailResent = connect(
  mapStateToProps,
  mapDispatchToProps,
)((props: TSignUpEmailResentProps) => <SignUpEmailResentScreen {...props} />)
