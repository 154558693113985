import React, { useState } from "react"
import { useTranslation } from "react-i18next"
import { Box } from "rebass"
import { BaseLayout, BaseScreen, SidePanel, RadioButton, ButtonRow } from "shared"
import { CorporationType } from "types"
import { TKYBCorpTypeProps } from "./kyb-corp-type.container"
import { nav } from "core/navigation"
import { Analytics } from "../../../../core/analytics/actions"

export const KYBCorpTypeScreen: React.FC<TKYBCorpTypeProps> = ({ setCorpType }) => {
  const { t } = useTranslation()
  const [newCorpType, setNewCorpType] = useState<CorporationType | null>(null)
  const valueIsValid = () => newCorpType
  const { prev, next } = nav.onboarding.kyb.pages.KYBCorpType

  return (
    <BaseLayout
      sidePanel={<SidePanel section={2} />}
      body={
        <BaseScreen title={t("kyb.corpType.title")}>
          <Box style={{ marginTop: "2rem", flexDirection: "column", display: "flex" }}>
            {[
              { type: "Llc", value: CorporationType.Llc },
              { type: "Ccorp", value: CorporationType.Ccorp },
              { type: "Scorp", value: CorporationType.Scorp },
            ].map(({ type, value }, index) => (
              <RadioButton
                key={index}
                label={t(`kyb.corpType.${type}`)}
                isSelected={newCorpType === value}
                onClick={() => {
                  setNewCorpType(value)
                  Analytics.track(`KYB Corporation Type ${value} Select`)
                }}
              />
            ))}
          </Box>
        </BaseScreen>
      }
      buttonRow={
        <ButtonRow
          buttonOnClick={() => {
            if (newCorpType) {
              setCorpType(newCorpType)
            }
            Analytics.track("KYB Corporation Type Continue Tap")
            next()
          }}
          buttonDisabled={!valueIsValid()}
        />
      }
      onBackClick={prev}
    />
  )
}
