import React, { useState, useEffect } from "react"
import { Box, Link, Text } from "rebass"
import { theme } from "theme/theme"

import { logout, auth, TRootDispatch, IRootState } from "core"
import { connect } from "react-redux"
import { useTranslation } from "react-i18next"
import { useWindowDimensions } from "utils"
import { GlobalActions } from "core/redux/actions"
import { Analytics } from "core/analytics/actions"

const GRID_COLUMNS = 14
const STATUS_COLUMNS = 2
const AVERAGE_LETTER_WIDTH = 6

interface IProps {
  customColor?: string
}
interface IStateProps {
  email?: string
}
interface IDispatchProps {
  resetReduxStateExcludingSignUp: () => void
}

type TLoggedInStatusProps = IProps & IStateProps & IDispatchProps

const mapDispatchToProps = (dispatch: TRootDispatch): IDispatchProps => ({
  resetReduxStateExcludingSignUp: () => dispatch(GlobalActions.resetReduxStateExcludingSignUp()),
})

const mapStateToProps = (state: IRootState): IStateProps => ({
  email: state.signUp.email,
})

export const LoggedInStatus = connect(
  mapStateToProps,
  mapDispatchToProps,
)((props: TLoggedInStatusProps) => <LoggedInStatusComponent {...props} />)

const LoggedInStatusComponent: React.SFC<TLoggedInStatusProps> = ({
  customColor,
  resetReduxStateExcludingSignUp,
  email,
}) => {
  const { t } = useTranslation()

  const [loggedIn, setLoggedIn] = useState(false)

  const signOutUser = () => {
    if (window.confirm(t("login.sidePanel.logoutPrompt"))) {
      Analytics.track("Logout Button Tap")
      resetReduxStateExcludingSignUp()
      logout()
    }
  }

  const { width } = useWindowDimensions()

  const responsiveLoggedInStatus = (loggedInEmail: string) => {
    const pixelsAvailable = (width / GRID_COLUMNS) * STATUS_COLUMNS
    const messageLengthAvailable = Math.round(pixelsAvailable / AVERAGE_LETTER_WIDTH)

    const loggedInMessage = `${t("login.sidePanel.loggedInAs")} ${loggedInEmail}`
    if (loggedInMessage.length > messageLengthAvailable) {
      return (
        loggedInMessage.slice(0, messageLengthAvailable / 2) +
        "..." +
        loggedInMessage.slice(
          loggedInMessage.length - messageLengthAvailable / 4.5,
          loggedInMessage.length,
        )
      )
    } else {
      return loggedInMessage
    }
  }
  useEffect(() => {
    const setLoggedInStatus = async () => {
      const isAuthenticated = await auth.isAuthenticated()
      setLoggedIn(isAuthenticated)
    }
    setLoggedInStatus()
  }, [email])

  return (
    <Box
      sx={{
        color: customColor || theme.colors.grey,
        height: 20,
        ...theme.fontStyles.smallText,
        display: "grid",
        gridColumnStart: 12,
        gridColumnEnd: 14,
        gridRowStart: 1,
        marginTop: 48,
        zIndex: 1,
        textAlign: "right",
      }}
    >
      {loggedIn ? (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-end",
          }}
        >
          <Text>{responsiveLoggedInStatus(email || "")}</Text>
          <Link
            sx={{
              color: customColor || theme.colors.black,
              textDecoration: "underline",
              cursor: "pointer",
            }}
            onClick={signOutUser}
          >
            {t("login.sidePanel.logoutCTA")}
          </Link>
        </Box>
      ) : (
        ""
      )}
    </Box>
  )
}
