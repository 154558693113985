import { createAction, TActionsUnion, IRootState } from "core"
import { IEditOwner } from "types"
import { ValueOf } from "utils/ts-utils"
import { ISensitive } from "./ubo.state"

export enum UBOActionEnums {
  UBO_SET_VALUE = "UBO_SET_VALUE",
  UBO_SET_SENSITIVE_VALUE = "UBO_SET_SENSITIVE_VALUE",

  UBO_ADD_OWNER = "UBO_ADD_OWNER",
  UBO_EDIT_OWNER = "UBO_EDIT_OWNER",
  UBO_DELETE_OWNER = "UBO_DELETE_OWNER",

  UBO_SET_READY_FOR_BACKGROUND_CHECK = "UBO_SET_READY_FOR_BACKGROUND_CHECK",
}

type UBONewOwnerState = IRootState["UBO"]["newOwner"]
export type UBONewOwnerKeys = keyof UBONewOwnerState
type UBONewOwnerValues = ValueOf<UBONewOwnerState>

/* SENSITIVE VALUES LOGIC NO LONGER REQUIRED - was an old requirement when persisting the entire state
 TODO: Refactor to put SSNs directly on UBO
 */
type UBOSensitiveNewOwner = IRootState["UBO"]["sensitive"]["newOwner"]
type UBONewOwnerSensitiveKeys = keyof UBOSensitiveNewOwner
type UBONewOwnerSensitiveValues = ValueOf<UBOSensitiveNewOwner>

export interface IEditOwnerActionPayload {
  owner: IEditOwner
  sensitive: ISensitive
  ownerId: string
}

export const UBOActions = {
  setValue: (key: UBONewOwnerKeys, value: UBONewOwnerValues) =>
    createAction(UBOActionEnums.UBO_SET_VALUE, { key, value }),
  setSensitiveValue: (key: UBONewOwnerSensitiveKeys, value: UBONewOwnerSensitiveValues) =>
    createAction(UBOActionEnums.UBO_SET_SENSITIVE_VALUE, { key, value }),
  addOwner: () => createAction(UBOActionEnums.UBO_ADD_OWNER),
  editOwner: (payload: IEditOwnerActionPayload) =>
    createAction(UBOActionEnums.UBO_EDIT_OWNER, payload),
  deleteOwner: (ownerId: string) => createAction(UBOActionEnums.UBO_DELETE_OWNER, ownerId),
}

export type TUBOActions = TActionsUnion<typeof UBOActions>
