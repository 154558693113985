import React from "react"
import { connect } from "react-redux"

import { IRootState, TRootDispatch } from "core"
import { nav } from "core/navigation"
import { ControlPersonActions } from "../redux"
import { SSNScreen } from "shared"

interface IControlPersonSSNStateProps {
  firstName: string
  ssn: string
  email: string
}

interface IControlPersonSSNDispatchProps {
  setSSN: (ssn: string) => void
  setControlPersonEmail: (email: string) => void
}

export type TControlPersonSSNProps = IControlPersonSSNStateProps & IControlPersonSSNDispatchProps

const mapStateToProps = (state: IRootState): IControlPersonSSNStateProps => ({
  firstName: state.controlPerson.candidate.firstName,
  ssn: state.controlPerson.candidate.SSN,
  email: state.controlPerson.candidate.email,
})

const mapDispatchToProps = (dispatch: TRootDispatch): IControlPersonSSNDispatchProps => ({
  setSSN: (ssn: string) => dispatch(ControlPersonActions.setSSN(ssn)),
  // addNewOwner: () => dispatch(ControlPersonActions.addOwner()),
  setControlPersonEmail: (email: string) =>
    dispatch(ControlPersonActions.selectControlPerson(email)),
})

export const ControlPersonSSNScreen: React.FC<TControlPersonSSNProps> = ({
  firstName,
  ssn,
  setSSN,
  setControlPersonEmail,
  email,
  //   addNewOwner
}) => {
  const { prev, next } = nav.onboarding.controlPerson.pages.ControlPersonSSN

  return (
    <SSNScreen
      firstName={firstName}
      ssn={ssn}
      setSSN={setSSN}
      //   addNewOwner
      prev={prev}
      next={() => {
        next()
        setControlPersonEmail(email)
      }}
    />
  )
}

export const ControlPersonSSN = connect(
  mapStateToProps,
  mapDispatchToProps,
)((props: TControlPersonSSNProps) => <ControlPersonSSNScreen {...props} />)
