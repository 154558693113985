import React from "react"
import { connect } from "react-redux"

import { IRootState, TRootDispatch } from "core"
import { nav } from "core/navigation"
import { ControlPersonActions } from "../redux"
import { AddressScreen } from "shared"
import { IAddress } from "types"
import { useTranslation } from "react-i18next"

interface IControlPersonAddressStateProps {
  firstName: string
  homeAddress: IAddress
}

interface IControlPersonAddressDispatchProps {
  setAddress: (address: IAddress) => void
}

export type TControlPersonAddressProps = IControlPersonAddressStateProps &
  IControlPersonAddressDispatchProps

const mapStateToProps = (state: IRootState): IControlPersonAddressStateProps => ({
  firstName: state.controlPerson.candidate.firstName,
  homeAddress: state.controlPerson.candidate.homeAddress,
})

const mapDispatchToProps = (dispatch: TRootDispatch): IControlPersonAddressDispatchProps => ({
  setAddress: (address: IAddress) => dispatch(ControlPersonActions.setHomeAddress(address)),
})

export const ControlPersonAddressScreen: React.FC<TControlPersonAddressProps> = ({
  firstName,
  homeAddress,
  setAddress,
}) => {
  const { prev, next } = nav.onboarding.controlPerson.pages.ControlPersonAddress
  const { t } = useTranslation()

  return (
    <AddressScreen
      title={t("shared.screens.address.title", { firstName })}
      defaultAddress={homeAddress}
      setAddress={setAddress}
      prev={prev}
      next={next}
    />
  )
}

export const ControlPersonAddress = connect(
  mapStateToProps,
  mapDispatchToProps,
)((props: TControlPersonAddressProps) => <ControlPersonAddressScreen {...props} />)
