import React from "react"
import { BirthDateScreen } from "shared"
import { TUBOBirthDateProps } from "./ubo-birthdate.container"

import { nav } from "core/navigation"

export const UBOBirthDateScreen: React.FC<TUBOBirthDateProps> = ({
  firstName,
  birthDate,
  setValue,
}) => {
  const { prev, next } = nav.onboarding.ubo.pages.UBOBirthdate

  return (
    <BirthDateScreen
      firstName={firstName}
      birthDate={birthDate}
      setBirthDate={(newBirthDate) => setValue("birthDate", newBirthDate)}
      prev={prev}
      next={next}
    />
  )
}
