import { config } from "utils"

export const APP_STORE_URL = config.appUrls.appStore

// TO DO ADD GOOGLE PLAY URL
export const GOOGLE_PLAY_STORE_URL = config.appUrls.googlePlay

export const CLIENT_NAME = "northone-desktop-onboarding"

export const GLOBAL_SENSITIVE_FIELDS = [
  "ssn",
  "ein",
  "password",
  "token",
  "bankRoutingNumber",
  "bankAccountNumber",
  "bankInstitutionNumber",
  "routingNumber",
  "accountNumber",
  "first4",
  "last4",
]
