import React, { useState } from "react"
import { InputForm, BaseLayout, BaseScreen, SidePanel, ButtonRow, Checkbox } from "shared"
import { americanStates } from "../../utils/americanStates"
import {
  isValidStreetAddress,
  MAX_STREET_ADDRESS_LENGTH,
  MAX_SUITE_NUMBER_LENGTH,
  isValidSuiteNumber,
  MAX_CITY_LENGTH,
  isValidCity,
  isValidAddress,
  defaultValidationType,
} from "utils/validators"
import { onlyNumbersRegex } from "utils"
import { IAddress } from "types"
import { useTranslation } from "react-i18next"
import { Analytics } from "core/analytics/actions"

interface IAddressScreenProps {
  title: string
  defaultAddress: IAddress
  secondaryAddress?: IAddress
  analyticEventStringOnSubmit?: string
  setAddress: (address: IAddress) => void
  prev: () => void
  next: () => void
}

export const AddressScreen: React.FC<IAddressScreenProps> = ({
  title,
  defaultAddress,
  secondaryAddress,
  analyticEventStringOnSubmit,
  setAddress,
  prev,
  next,
}) => {
  const addressOnLanding = secondaryAddress ? secondaryAddress : defaultAddress
  const [streetAddress, setStreetAddress] = useState(addressOnLanding.streetAddressLine1)
  const [suite, setSuite] = useState(addressOnLanding.streetAddressLine2)
  const [city, setCity] = useState(addressOnLanding.city)
  const [state, setState] = useState(addressOnLanding.provinceState)
  const [zipcode, setZipcode] = useState(addressOnLanding.postalCode)

  const [useDefaultAddress, setUseDefaultAddress] = useState(false)
  const { t } = useTranslation()

  const fields = [
    {
      field: "streetAddress",
      value: streetAddress,
      setValue: setStreetAddress,
      validationText: isValidStreetAddress(streetAddress)
        ? ""
        : t("kyc.address.streetAddressError", {
            maxStreetAddressLength: MAX_STREET_ADDRESS_LENGTH.toString(),
          }),
      validationType: defaultValidationType,
    },
    {
      field: "suite",
      value: suite || "",
      setValue: setSuite,
      validationText: isValidSuiteNumber(suite)
        ? ""
        : t("kyc.address.suiteError", { maxSuiteLength: MAX_SUITE_NUMBER_LENGTH.toString() }),
      validationType: defaultValidationType,
    },
    {
      field: "city",
      value: city,
      setValue: setCity,
      validationText: isValidCity(city)
        ? ""
        : t("kyc.address.cityError", { maxCityLength: MAX_CITY_LENGTH.toString() }),
      validationType: defaultValidationType,
    },
    {
      field: "state",
      dropDown: americanStates,
      value: state,
      setValue: setState,
    },
    {
      field: "zipcode",
      value: zipcode,
      setValue: (newZipcode: string) =>
        newZipcode.length < 1 || (newZipcode.length <= 5 && onlyNumbersRegex.test(newZipcode))
          ? setZipcode(newZipcode)
          : null,
    },
  ]

  const _handleCheckboxClick = () => {
    if (secondaryAddress) {
      if (useDefaultAddress) {
        setStreetAddress(secondaryAddress.streetAddressLine1)
        setSuite(secondaryAddress.streetAddressLine2)
        setCity(secondaryAddress.city)
        setState(secondaryAddress.provinceState)
        setZipcode(secondaryAddress.postalCode)
      } else {
        setStreetAddress(defaultAddress.streetAddressLine1)
        setSuite(defaultAddress.streetAddressLine2)
        setCity(defaultAddress.city)
        setState(defaultAddress.provinceState)
        setZipcode(defaultAddress.postalCode)
      }
    }
    setUseDefaultAddress(!useDefaultAddress)
  }

  return (
    <BaseLayout
      largeBody
      sidePanel={<SidePanel section={2} />}
      body={
        <BaseScreen title={title}>
          {secondaryAddress ? (
            <Checkbox
              isSelected={useDefaultAddress}
              label={t("kyb.address.checkbox")}
              onClick={_handleCheckboxClick}
            />
          ) : null}
          <InputForm inputFields={fields} disabledFields={useDefaultAddress} />
        </BaseScreen>
      }
      buttonRow={
        <ButtonRow
          buttonOnClick={() => {
            setAddress({
              streetAddressLine1: streetAddress,
              streetAddressLine2: suite,
              city,
              provinceState: state,
              postalCode: zipcode,
              country: "US",
            })
            if (analyticEventStringOnSubmit) {
              Analytics.track(analyticEventStringOnSubmit, {
                streetAddressLine1: streetAddress,
                streetAddressLine2: suite,
                city,
                provinceState: state,
                postalCode: zipcode,
                country: "US",
              })
            }
            next()
          }}
          buttonDisabled={!isValidAddress({ streetAddress, suite, city, state, zipcode })}
        />
      }
      onBackClick={prev}
    />
  )
}
