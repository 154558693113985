import React, { Fragment, useEffect } from "react"
import { connect } from "react-redux"
import { ThemeProvider } from "emotion-theming"
import GlobalStyle from "./global-style"
import { theme } from "./theme/theme"
import "./fonts/stylesheet.css"
import * as components from "./components"
import { SmallScreen } from "shared"
import { useWindowDimensions } from "./utils/window"
import { config, logger } from "utils"
import { GlobalErrorBarContainer } from "./components"
import { routes } from "core/navigation"
import Intercom from "react-intercom"
import { TRootDispatch, IRootState } from "core"
import queryString from "query-string"
import { CJEVENTAction } from "core/cjevent"
import { useBeforeunload } from "react-beforeunload"
import { Analytics } from "core/analytics/actions"
import { AuthWrapper } from "core/authentication/auth-wrapper"
import { isLoggedOut } from "core/authentication/auth-service"

const windowSizeRestrictionDisabled = config.dev.disableWindowSizeRestriction

const RELOAD_ALLOWED_PATHS = [
  "",
  routes.SIGN_UP_TERMS_AND_CONDITIONS,
  routes.SIGN_UP_EMAIL_SENT,
  routes.BACKGROUND_CHECK_SUBMIT,
  routes.BACKGROUND_CHECK_REJECT,
  routes.KYC_UNSUPPORTED_COUNTRY_SUCCESS,
]

const AppComponent: React.SFC<TAppProps> = ({ page, intercomUser, setCJEVENT }) => {
  const { height, width } = useWindowDimensions()
  const pageComponents: { [key: string]: any } = components
  const Component: React.ComponentType = pageComponents[page]

  const minSupportedWidth = 800
  const minSupportedHeight = 600

  const windowSizeNotSupported: boolean = height <= minSupportedHeight || width <= minSupportedWidth
  const shouldRenderSmallScreen: boolean = Boolean(
    windowSizeNotSupported && !windowSizeRestrictionDisabled,
  )
  const path = window.location.pathname

  useBeforeunload(() =>
    _shouldShowUnsavedChangesDialog(path) ? "Changes you made may not be saved" : null,
  )

  useEffect(() => {
    Analytics.page(path)
  }, [path])

  useEffect(() => {
    if (config.dev.disableRerouteOnRefresh) {
      logger.info(
        `App reroute behaviour disabled, to re-enable remove REACT_APP_DISABLE_REROUTE_ON_REFRESH from env`,
      )
    }

    const CJEVENT = _getCJEVENTFromQueryString()
    const cjeventQueryParams = queryString.parse(window.location.search, { sort: false })
    const cjeventQueryParamsArray = Object.entries(cjeventQueryParams)

    const setAffiliateLinkProperties = () => {
      cjeventQueryParamsArray.forEach((property) => {
        const propertyName = property[0]
        const propertyValue = property[1]
        Analytics.identify({ [propertyName]: propertyValue })
      })
      Analytics.identify({ "Used Affiliate Link": "Yes" })
      Analytics.identify({ "Full Affiliate Link URL": window.location.href })
    }

    if (CJEVENT) {
      setCJEVENT(CJEVENT)
      setAffiliateLinkProperties()
    }

    const utmCampaign = _getUTMCampaignFromQueryString()
    if (utmCampaign) {
      Analytics.identify({ utm_campaign: utmCampaign })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []) // the empty array argument ensures that useEffect is only fired on mount

  return (
    <ThemeProvider theme={theme}>
      {shouldRenderSmallScreen ? (
        <SmallScreen />
      ) : (
        <AuthWrapper>
          <Fragment>
            <GlobalStyle />
            <Component />
            {config.intercomAppId && <Intercom appID={config.intercomAppId} {...intercomUser} />}
          </Fragment>
          <GlobalErrorBarContainer />
        </AuthWrapper>
      )}
    </ThemeProvider>
  )
}

interface IAppProps {
  page: string
  intercomUser: {
    name: string | null
    email: string | null
  }
}

const mapStateToProps = (state: IRootState) => ({
  page: state.page,
  intercomUser: {
    name: state.KYC.firstName || null,
    email: state.signUp.email || null,
  },
})

interface IAppDispatchProps {
  setCJEVENT: (CJEVENT: string) => void
}

const mapDispatchToProps = (dispatch: TRootDispatch): IAppDispatchProps => ({
  setCJEVENT: (CJEVENT: string) => dispatch(CJEVENTAction.setCJEVENT(CJEVENT)),
})

export type TAppProps = IAppProps & IAppDispatchProps

export const App = connect(
  mapStateToProps,
  mapDispatchToProps,
)((props: TAppProps) => <AppComponent {...props} />)

const _getCJEVENTFromQueryString = (): string | null => {
  const parsed: { cjevent?: string } = queryString.parse(window.location.search)
  return parsed.cjevent || null
}

const _getUTMCampaignFromQueryString = (): string | null => {
  const parsed: { utm_campaign?: string } = queryString.parse(window.location.search)
  return parsed.utm_campaign || null
}

/**
 * decides if user should see a warning before reloading or leaving app
 * to disable dialog permanently set REACT_APP_DISABLE_RELOAD_DIALOG in enviroment
 */
const _shouldShowUnsavedChangesDialog = (currentPath: string): boolean => {
  if (isLoggedOut() || config.dev.disableReloadDialog) {
    return false
  }
  return !RELOAD_ALLOWED_PATHS.includes(_removeTrailingSlash(currentPath))
}

const _removeTrailingSlash = (path: string) => path.replace(/\/$/, "")
