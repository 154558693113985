import React, { useState } from "react"
import { useTranslation } from "react-i18next"
import { Box } from "rebass"
import { BaseLayout, BaseScreen, SidePanel, RadioButton, ButtonRow, CheckboxButton } from "shared"
import { nav } from "core/navigation"
import { TControlPersonInviteProps } from "./control-person-invite.container"

export const ControlPersonInviteScreen: React.FC<TControlPersonInviteProps> = ({
  firstOwner,
  additionalOwners,
  controlPersonEmail,
  editOwner,
}) => {
  const { t } = useTranslation()

  const [emailsOfOwnersToInvite, setEmailsOfOwnersToInvite] = useState<string[]>(
    additionalOwners.filter((owner) => owner.inviteToJoin).map((owner) => owner.emailAddress),
  )
  const handleClick = (email: string) => {
    const isToBeUnselected = emailsOfOwnersToInvite.includes(email)
    if (isToBeUnselected) {
      setEmailsOfOwnersToInvite(
        emailsOfOwnersToInvite.filter((checkedEmail) => checkedEmail !== email),
      )
    } else {
      setEmailsOfOwnersToInvite([...emailsOfOwnersToInvite, email])
    }
  }
  const setOwnersInviteToJoin = () => {
    additionalOwners.forEach((ubo) => {
      editOwner({
        owner: { ...ubo, inviteToJoin: emailsOfOwnersToInvite.includes(ubo.emailAddress) },
        sensitive: { ssn: ubo.ssn },
        ownerId: ubo.id,
      })
    })
  }

  const { prev, next } = nav.onboarding.controlPerson.pages.ControlPersonInvite

  return (
    <BaseLayout
      sidePanel={<SidePanel section={2} />}
      body={
        <BaseScreen
          title={t("controlPerson.invite.title")}
          subtitle={`${t("controlPerson.invite.subtitle1")} ${t("controlPerson.invite.subtitle2")}`}
        >
          <Box style={{ marginTop: "2rem", flexDirection: "column", display: "flex" }}>
            <RadioButton
              label={`
              ${firstOwner.firstName}
              ${firstOwner.lastName}
              ${t("controlPerson.invite.controlPersonIndicator")}
              `}
              isSelected={firstOwner.emailAddress === controlPersonEmail}
              infoLine2={firstOwner.emailAddress}
              infoLine3={firstOwner.phoneNumber}
              checkIcon
            />
            {additionalOwners.map((ubo) => (
              <label>
                <CheckboxButton
                  key={ubo.emailAddress}
                  label={`${ubo.firstName} ${ubo.lastName}`}
                  onClick={() => {
                    handleClick(ubo.emailAddress)
                  }}
                  infoText={`
                    ${ubo.homeAddress.streetAddressLine1}
                    ${ubo.homeAddress.city}
                    ${ubo.homeAddress.postalCode}
                    `}
                  infoLine2={`${ubo.emailAddress}`}
                  infoLine3={`${ubo.phoneNumber}`}
                  isSelected={emailsOfOwnersToInvite.includes(ubo.emailAddress)}
                />
              </label>
            ))}
          </Box>
        </BaseScreen>
      }
      buttonRow={
        <ButtonRow
          buttonOnClick={() => {
            setOwnersInviteToJoin()
            next()
          }}
        />
      }
      onBackClick={prev}
    />
  )
}
