import React from "react"
import { Box } from "rebass"
import { theme } from "theme/theme"
import downArrow from "images/downArrow.svg"

interface IProps {
  options: string[]
  newValue: string
  setNewValue: (value: string) => void
  placeholder: string
  customContainerStyle?: any // TODO: change any
  customStyle?: any // TODO: change any
}

export const DropDown: React.SFC<IProps> = ({
  options,
  newValue,
  setNewValue,
  placeholder,
  customContainerStyle = {},
  customStyle = {},
}) => (
  <Box
    style={{
      marginTop: theme.spacing.inputFieldSpacing,
      height: theme.inputField.height,
      ...customContainerStyle,
    }}
  >
    <Box
      sx={{
        color: theme.colors.darkGrey,
        ...theme.fontStyles.placeholderTextMinimized,
        height: 20,
      }}
    >
      {newValue ? placeholder : ""}
    </Box>
    <select
      style={{
        ...theme.fontStyles.placeholderText,
        backgroundColor: "transparent",
        width: "27rem",
        borderWidth: 0,
        borderBottomWidth: 1,
        borderColor: theme.colors.grey,
        borderStyle: "solid",
        borderRadius: 0,
        WebkitAppearance: "none",
        appearance: "none",
        MozAppearance: "none",
        color: newValue ? theme.colors.black : theme.colors.darkGrey,
        backgroundImage: `url(${downArrow})`,
        backgroundRepeat: "no-repeat",
        backgroundPosition: "right 50%",
        padding: "0.5em",
        paddingLeft: "0em",
        outline: "none",
        ...customStyle,
      }}
      onChange={(e) => {
        setNewValue(e.target.value)
      }}
      value={newValue}
    >
      <option value="" disabled>
        {placeholder}
      </option>
      {options.map((option) => (
        <option key={option} value={option}>
          {option}
        </option>
      ))}
    </select>
  </Box>
)
