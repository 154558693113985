import { initialErrorsState, IErrorsState } from "./errors.state"

import { ErrorsActionEnums, TErrorsActions } from "./errors.actions"

export const ErrorsReducer = (
  state: IErrorsState = initialErrorsState,
  action: TErrorsActions,
): IErrorsState => {
  switch (action.type) {
    case ErrorsActionEnums.SET_CURRENT_ERROR_MESSAGE:
      return {
        ...state,
        currentErrorMessage: action.payload,
      }
    case ErrorsActionEnums.CLEAR_CURRENT_ERROR_MESSAGE:
      return {
        ...state,
        currentErrorMessage: "",
      }
    default:
      return state
  }
}
