import React from "react"
import { connect } from "react-redux"

import { IRootState, TRootDispatch } from "core"

import { KYCRoleScreen } from "./kyc-role.screen"

import { KYCActions } from "../redux"

interface IKYCRoleStateProps {
  jobTitle: string
  role: string
}

interface IKYCRoleDispatchProps {
  setJobTitle: (jobTitle: string) => void
  setRole: (role: string) => void
}

export type TKYCRoleProps = IKYCRoleStateProps & IKYCRoleDispatchProps

const mapStateToProps = (state: IRootState): IKYCRoleStateProps => ({
  jobTitle: state.KYC.jobTitle,
  role: state.KYC.role,
})

const mapDispatchToProps = (dispatch: TRootDispatch): IKYCRoleDispatchProps => ({
  setJobTitle: (jobTitle: string) => dispatch(KYCActions.setJobTitle(jobTitle)),
  setRole: (role: string) => dispatch(KYCActions.setRole(role)),
})

export const KYCRole = connect(
  mapStateToProps,
  mapDispatchToProps,
)((props: TKYCRoleProps) => <KYCRoleScreen {...props} />)
