import { IRootState } from "core/redux/root-state"
import { createSelector } from "reselect"

const getDebitFunding = (state: IRootState) => state.debitFunding

export const getDebitCardLast4 = createSelector(
  getDebitFunding,
  (debitFunding) =>
    debitFunding.debitCardData.cardNumber.slice(debitFunding.debitCardData.cardNumber.length - 4),
)

export const getDebitFundingInfo = createSelector(
  getDebitFunding,
  (debitFunding) =>
    debitFunding.amount &&
    debitFunding.debitCardData.nameOnCard &&
    debitFunding.debitCardData.cardNumber &&
    debitFunding.debitCardData.cvv &&
    debitFunding.debitCardData.expiryMonth &&
    debitFunding.debitCardData.expiryYear &&
    debitFunding.debitCardData.postalCode
      ? debitFunding
      : undefined,
)
