export const controlPerson = {
  landing: {
    title: "To add a Control Person, you will need their...",
    button: "I have this information",
  },
  select: {
    title: "Choose the Control Person for this account",
    subtitle1: "The Control Person is the person with significant or primary ",
    subtitle2: "responsibility for managing the legal entity noted.",
    addNew: "Add a control person",
  },
  name: {
    title: "What is the Control Person’s name?",
  },
  invite: {
    title: "Invite your co-owners",
    subtitle1: "Email invites will be sent to other owners to join NorthOne.",
    subtitle2: "They will receive their own debit card.",
    controlPersonIndicator: "(that's you!)",
  },
}
