export const inputForm = {
  placeholder: {
    firstName: "First name",
    lastName: "Last name",
    role: "Role",
    jobTitle: "Job title",
    streetAddress: "Street address",
    suite: "Suite/Apartment number",
    city: "City",
    state: "State",
    zipcode: "ZIP code",
    email: "Email address",
    phone: "Phone number",
    birthDate: "Date of birth",
    percentage: "Percent of Ownership",
    ssn: "Social Security Number",
    debitFunding: {
      name: "Name of card holder",
      cardNumber: "Card number",
      zipCode: "ZIP code of billing address",
    },
  },
}
