import React from "react"
import { connect } from "react-redux"

import { IRootState, TRootDispatch } from "core"

import { KYBDBAScreen } from "./kyb-dba.screen"

import { KYBActions } from "../redux"
import { CorporationType } from "types"

interface IKYBDBAStateProps {
  legalName: string
  dba: string
  businessType: CorporationType
}

interface IKYBDBADispatchProps {
  setDBA: (dba: string) => void
}

export type TKYBDBAProps = IKYBDBAStateProps & IKYBDBADispatchProps

const mapStateToProps = (state: IRootState): IKYBDBAStateProps => ({
  legalName: state.KYB.legalName,
  dba: state.KYB.dba,
  businessType: state.KYB.businessType,
})

const mapDispatchToProps = (dispatch: TRootDispatch): IKYBDBADispatchProps => ({
  setDBA: (dba: string) => dispatch(KYBActions.setDBA(dba)),
})

export const KYBDBA = connect(
  mapStateToProps,
  mapDispatchToProps,
)((props: TKYBDBAProps) => <KYBDBAScreen {...props} />)
