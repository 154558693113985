import React from "react"
import { connect } from "react-redux"

import { IRootState, TRootDispatch } from "core"
import { UBOTypeEnum } from "types"

import { KYBUBOTypeScreen } from "./kyb-ubo-type.screen"

import { KYBActions } from "../redux"

interface IKYBUBOTypeStateProps {
  UBOType: UBOTypeEnum
}

interface IKYBUBOTypeDispatchProps {
  setUBOType: (UBOType: UBOTypeEnum) => void
  setPercentOwnership: (percent: string) => void
}

export type TKYBUBOTypeProps = IKYBUBOTypeStateProps & IKYBUBOTypeDispatchProps

const mapStateToProps = (state: IRootState): IKYBUBOTypeStateProps => ({
  UBOType: state.KYB.UBOType,
})

const mapDispatchToProps = (dispatch: TRootDispatch): IKYBUBOTypeDispatchProps => ({
  setUBOType: (UBOType: UBOTypeEnum) => dispatch(KYBActions.setUBOType(UBOType)),
  setPercentOwnership: (percent: string) => dispatch(KYBActions.setPercentOwnership(percent)),
})

export const KYBUBOType = connect(
  mapStateToProps,
  mapDispatchToProps,
)((props: TKYBUBOTypeProps) => <KYBUBOTypeScreen {...props} />)
