export const ubo = {
  personInfo: {
    title: "To add another owner, you will need their...",
  },
  ownershipList: {
    title: "List the company's owners",
    subtitle:
      "The government requires applicants to list everyone who owns 25% or more of the business.",
    buttonText: "Add an owner",
    missingInfo: "Missing information",
  },
  name: {
    title: "What is the owner's name?",
  },
  review: {
    title: "Review {{firstName}}'s details",
    requiredError: "This is a required field",
    phoneError: "Phone number must be 10 digits",
    emailError: "Please enter a valid email address",
    percentageError: "Please enter a number less than 100",
    birthDateError: "You must be 18 or older to open an account",
    birthDateInfo: "Please enter a valid birth date",
    ssnError: "Please enter 9 digits",
    citizenshipError: "You must be a US resident",
    delete: "Delete this owner",
    zipCodeError: "Please enter a valid ZIP code",
  },
  percentage: {
    title: "What percentage of the business does {{firstName}} own?",
    placeholder: "Enter their percentage (%) share",
    error: "Please enter only a whole number between 1 and 99.",
    exceeds: "Only {{percentageAvailable}}% currently available.",
  },
  role: {
    director: "Director",
    officerCoowner: "Officer / Co-owner",
    officerEmployee: "Officer / Employee",
    partner: "Partner",
  },
}
