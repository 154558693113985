import React from "react"
import { useTranslation } from "react-i18next"
import { Box } from "rebass"
import { BaseLayout, BaseScreen, SidePanel, RadioButton, ButtonRow } from "shared"
import { nav } from "core/navigation"
import { TControlPersonProps } from "./control-person-select.container"
import { IControlPerson } from "types"

export const ControlPersonSelectScreen: React.FC<TControlPersonProps> = ({
  firstOwner,
  additionalOwners,
  hasMultipleOwners,
  selectControlPerson,
  controlPersonEmail,
  controlPersonCandidate,
  businessType,
  uboType,
}) => {
  const { t } = useTranslation()
  const {
    prev,
    next,
    goToAddControlPerson,
  } = nav.onboarding.controlPerson.pages.ControlPersonSelect
  if (!controlPersonEmail) {
    selectControlPerson(firstOwner.emailAddress)
  }
  return (
    <BaseLayout
      sidePanel={<SidePanel section={2} />}
      body={
        <BaseScreen
          title={t("controlPerson.select.title")}
          subtitle={t("controlPerson.select.subtitle1") + t("controlPerson.select.subtitle2")}
        >
          <Box style={{ marginTop: "2rem", flexDirection: "column", display: "flex" }}>
            <RadioButton
              label={firstOwner.firstName + " " + firstOwner.lastName}
              isSelected={firstOwner.emailAddress === controlPersonEmail}
              onClick={() => selectControlPerson(firstOwner.emailAddress)}
              infoText={
                firstOwner.homeAddress.streetAddressLine1 +
                ", " +
                firstOwner.homeAddress.city +
                ", " +
                firstOwner.homeAddress.postalCode
              }
              infoLine2={firstOwner.emailAddress}
              infoLine3={firstOwner.phoneNumber}
            />
            {additionalOwners.map((owner) => {
              const { id, firstName, lastName, homeAddress, emailAddress, phoneNumber } = owner
              return (
                <RadioButton
                  key={id}
                  label={firstName + " " + lastName}
                  isSelected={emailAddress === controlPersonEmail}
                  onClick={() => selectControlPerson(emailAddress)}
                  infoText={
                    homeAddress.streetAddressLine1 +
                    ", " +
                    homeAddress.city +
                    ", " +
                    homeAddress.postalCode
                  }
                  infoLine2={emailAddress}
                  infoLine3={phoneNumber}
                />
              )
            })}
            {_controlPersonCandidateHasAllFields(controlPersonCandidate) && (
              <RadioButton
                key={"controlPerson"}
                label={controlPersonCandidate.firstName + " " + controlPersonCandidate.lastName}
                isSelected={
                  controlPersonCandidate.email.toLowerCase() === controlPersonEmail.toLowerCase()
                }
                onClick={() => selectControlPerson(controlPersonCandidate.email)}
                infoText={
                  controlPersonCandidate.homeAddress.streetAddressLine1 +
                  ", " +
                  controlPersonCandidate.homeAddress.city +
                  ", " +
                  controlPersonCandidate.homeAddress.postalCode
                }
                infoLine2={controlPersonCandidate.email}
                infoLine3={controlPersonCandidate.phone}
              />
            )}
            <RadioButton
              label={t("controlPerson.select.addNew")}
              isSelected={false}
              onClick={goToAddControlPerson}
              infoText={""}
              ownerList
              addIcon
            />
          </Box>
        </BaseScreen>
      }
      buttonRow={
        <ButtonRow
          buttonDisabled={!controlPersonEmail}
          buttonOnClick={() => next(hasMultipleOwners)}
        />
      }
      onBackClick={() => prev(businessType, uboType)}
    />
  )
}

const _controlPersonCandidateHasAllFields = (data: IControlPerson) =>
  data.firstName &&
  data.lastName &&
  data.email &&
  data.phone &&
  data.birthDate &&
  data.homeAddress &&
  data.jobTitle &&
  data.residency &&
  data.SSN
