export const screens = {
  landing: {
    title: "When adding a Control Person to the application you will need their...",
    button: "I have this information",
  },
  personInfo: {
    buttonText: "I have this information",
    linkText: "Remind me tomorrow",
    name: "Full name",
    percent: "Percentage of ownership",
    job: "Job role and Title",
    email: "Email address",
    address: "Address",
    phone: "Phone number",
    birthDate: "Date of birth",
    ssn: "Social Security Number",
  },
  name: {
    title: "What is the Person’s name?",
    firstName: "Enter their first name",
    lastName: "Enter their last name",
  },
  ssn: {
    title: "What is {{firstName}}'s Social Security Number (SSN)?",
    subTitle: "We need their SSN to confirm their identity. We'll keep it secure and private.",
    placeholder: "Enter their SSN",
  },

  email: {
    title: "What is {{firstName}}'s email?",
    placeholder: "Enter their email address",
  },
  role: {
    title: "What is {{firstName}}'s job title?",
    subtitle: "For example, CEO.",
    jobTitlePlaceholder: "Enter their job title",
    rolePlaceholder: "What best describes their role?",
    director: "Director",
    officerCoowner: "Officer / Co-owner",
    officerEmployee: "Officer / Employee",
    partner: "Partner",
  },
  residency: {
    title: "Is {{firstName}} a US citizen or resident?",
    yes: "Yes",
    no: "No",
  },
  birthDate: {
    title: "What is {{firstName}}'s date of birth?",
    placeholder: "MM/DD/YYYY",
    error: "They must be 18 or older to open an account",
    info: "Please enter a valid birth date",
  },
  phone: {
    title: "What is {{firstName}}'s phone number?",
    subtitle: "In case we need to get a hold of them.",
  },
  address: {
    title: "What is {{firstName}}'s home address?",
    dropdownPlaceholder: "State",
  },
  unsupportedCountrySuccess: {
    title: "You'll be hearing from us soon!",
    button: "Done",
  },
  unsupportedCountry: {
    title: "Sorry, we can only serve US citizens or residents",
    subtitle:
      "We're working hard to bring NorthOne to business builders all around the world. Want to keep in touch?",
    placeholder: "Select your country of residence",
    buttonText: "Keep me updated",
  },
}
