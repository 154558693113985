import { navigate, navigateByBusinessType } from "../../utils"
import { CorporationType, UBOTypeEnum } from "types"

export const controlPersonNavigator = {
  pages: {
    ControlPersonSelect: {
      prev: (corpType: CorporationType, uboType?: UBOTypeEnum) => {
        navigateByBusinessType(corpType, {
          soleprop: { route: "KYB_CARD_SAMPLE" },
          partnership: { route: "OWNERSHIP_LIST" },
          corp: { route: uboType === UBOTypeEnum.singleOwner ? "KYB_UBO_TYPE" : "OWNERSHIP_LIST" },
        })
      },
      next: (hasMultipleOwners: boolean) =>
        navigate(hasMultipleOwners ? "CONTROL_PERSON_INVITE" : "DEBIT_FUNDING_LANDING"),
      goToAddControlPerson: () => navigate("CONTROL_PERSON_LANDING"),
    },
    ControlPersonLanding: {
      prev: () => navigate("CONTROL_PERSON_SELECT_ROUTE"),
      next: () => navigate("CONTROL_PERSON_NAME"),
    },
    ControlPersonName: {
      prev: () => navigate("CONTROL_PERSON_LANDING"),
      next: () => navigate("CONTROL_PERSON_ROLE"),
    },
    ControlPersonRole: {
      prev: () => navigate("CONTROL_PERSON_NAME"),
      next: () => navigate("CONTROL_PERSON_EMAIL"),
    },
    ControlPersonEmail: {
      prev: () => navigate("CONTROL_PERSON_ROLE"),
      next: () => navigate("CONTROL_PERSON_ADDRESS"),
    },
    ControlPersonAddress: {
      prev: () => navigate("CONTROL_PERSON_EMAIL"),
      next: () => navigate("CONTROL_PERSON_PHONE"),
    },
    ControlPersonPhone: {
      prev: () => navigate("CONTROL_PERSON_ADDRESS"),
      next: () => navigate("CONTROL_PERSON_BIRTHDATE"),
    },
    ControlPersonBirthDate: {
      prev: () => navigate("CONTROL_PERSON_PHONE"),
      next: () => navigate("CONTROL_PERSON_RESIDENCY"),
    },
    ControlPersonResidency: {
      prev: () => navigate("CONTROL_PERSON_BIRTHDATE"),
      next: (isResident: boolean) =>
        isResident
          ? navigate("CONTROL_PERSON_SSN")
          : navigate("CONTROL_PERSON_UNSUPPORTED_COUNTRY"),
    },
    ControlPersonSSN: {
      prev: () => navigate("CONTROL_PERSON_RESIDENCY"),
      next: () => navigate("CONTROL_PERSON_SELECT_ROUTE"),
    },
    ControlPersonUnsupportedCountry: {
      prev: () => navigate("CONTROL_PERSON_RESIDENCY"),
      next: () => navigate("CONTROL_PERSON_UNSUPPORTED_COUNTRY_SUCCESS"),
    },
    ControlPersonUnsupportedCountrySuccess: {
      prev: () => navigate("CONTROL_PERSON_UNSUPPORTED_COUNTRY"),
    },
    ControlPersonInvite: {
      prev: () => navigate("CONTROL_PERSON_SELECT_ROUTE"),
      next: () => navigate("DEBIT_FUNDING_LANDING"),
    },
  },
}
