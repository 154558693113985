import React from "react"
import { Box, Image, Text, Heading } from "rebass"
import northOneWhite from "images/NorthOne-White.svg"
import { theme } from "theme/theme"
import { BackButton, Button } from "shared/button"
import checkWhite from "images/checkWhite.svg"
import { LoggedInStatus } from "shared"

export interface IInterstitialLayoutProps {
  title: string
  buttonOnClick: () => void
  buttonText: string
  onBackClick: () => void
  infoArray?: string[]
}

export const InterstitialLayout: React.SFC<IInterstitialLayoutProps> = ({
  title,
  buttonOnClick,
  buttonText,
  onBackClick,
  infoArray = [],
}) => {
  return (
    <Box
      sx={{
        display: "grid",
        gridTemplateColumns: "repeat(14, 1fr)",
        gridTemplateRows: "repeat(7, 1fr)",
        height: "100vh",
        overflowY: "scroll",
        bg: theme.colors.blue,
      }}
    >
      <LoggedInStatus customColor={theme.colors.white} />
      <Box
        sx={{
          gridRowStart: 1,
          gridColumnStart: 3,
          gridColumnEnd: 10,
          marginTop: 35,
        }}
      >
        <Image sx={{ alignSelf: "start", height: 55 }} src={northOneWhite} />
      </Box>
      <Box
        sx={{
          gridRowStart: 2,
          gridRowEnd: 8,
          gridColumnStart: 1,
          gridColumnEnd: 1,
          marginBottom: theme.spacing.verticalElementSpacing,
          display: "flex",
          flexDirection: "column",
          justifyContent: "flex-end",
          alignItems: "flex-end",
        }}
      >
        <BackButton onBackClick={onBackClick} blueScreen />
      </Box>

      <Box
        sx={{
          gridColumnStart: 3,
          gridColumnEnd: 7,
          gridRowStart: 3,
          marginTop: 150,
        }}
      >
        <Box
          sx={{
            gridColumnStart: 3,
            gridColumnEnd: 6,
            gridRowStart: 2,
            gridRowEnd: 7,
            paddingBottom: theme.spacing.verticalElementSpacing,
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            height: 250,
          }}
        >
          <Heading
            sx={{
              textAlign: "left",
              color: theme.colors.white,
              ...theme.fontStyles.h1,
              paddingBottom: theme.spacing.verticalElementSpacing,
            }}
          >
            {title}
          </Heading>
          <Button onClick={buttonOnClick} type={"white"} withEnter={true} noSimulatedSavingOnClick>
            {buttonText}
          </Button>
        </Box>
      </Box>
      <Box
        sx={{
          gridColumnStart: 7,
          gridColumnEnd: 10,
          gridRowStart: 2,
          gridRowEnd: 7,
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          marginLeft: theme.spacing.verticalElementSpacing,
        }}
      >
        {infoArray.map((info) => (
          <Box sx={{ display: "flex", ...theme.fontStyles.h2, marginY: 10 }}>
            <Image marginRight="0.5rem" src={checkWhite} />
            <Text style={{ color: "white" }}>{info}</Text>
          </Box>
        ))}
      </Box>
    </Box>
  )
}
