import React, { useState } from "react"
import { Box } from "rebass"
import { useTranslation } from "react-i18next"
import { BaseLayout, BaseScreen, TextInput, SidePanel, Checkbox, ButtonRow } from "shared"
import { TKYBWebsiteProps } from "./kyb-website.container"
import { nav } from "core/navigation"
import { Analytics } from "../../../../core/analytics/actions"

export const KYBWebsiteScreen: React.FC<TKYBWebsiteProps> = ({
  website,
  setWebsite,
  businessType,
}) => {
  const { t } = useTranslation()
  const [newWebsite, setNewWebsite] = useState(website)
  const [selection, setSelection] = useState(false)
  const hasNoWebsite = selection
  const websiteIsValid =
    (Boolean(newWebsite.length >= 3) && newWebsite.indexOf(".") >= 0) || selection

  const { prev, next } = nav.onboarding.kyb.pages.KYBWebsite
  return (
    <BaseLayout
      sidePanel={<SidePanel section={2} />}
      body={
        <BaseScreen title={t("kyb.website.title")}>
          <Box style={{ marginTop: "3.5rem" }}>
            <TextInput
              placeholderText={t("kyb.website.placeholder")}
              onChange={setNewWebsite}
              value={selection ? "" : newWebsite}
              autoFocus={true}
            />
          </Box>
          <Box>
            <Checkbox
              label={t("kyb.website.check")}
              onClick={() => {
                setNewWebsite("")
                setSelection(!selection)
              }}
              isSelected={selection}
            />
          </Box>
        </BaseScreen>
      }
      buttonRow={
        <ButtonRow
          buttonOnClick={() => {
            setWebsite(newWebsite)
            Analytics.track(`KYB Business Website Continue Tap`)
            next(businessType, hasNoWebsite)
          }}
          buttonDisabled={!websiteIsValid}
        />
      }
      onBackClick={() => prev(businessType)}
    />
  )
}
