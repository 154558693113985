import React, { useState, useEffect } from "react"
import { Box } from "rebass"

import { BaseLayout, BaseScreen, TextInput, SidePanel, ButtonRow } from "shared"
import { TSocialMediaProps } from "./kyb-social-media.container"
import { nav } from "core/navigation"
import { Analytics } from "../../../../core/analytics/actions"
import { useTranslation } from "react-i18next"
import { isValidWebsiteWithPrefix } from "utils"

interface ISocialMediaProps {
  websiteUrl: string
  inputMask: string
}

export const socialMediaProps: { [key: string]: ISocialMediaProps } = {
  Twitter: {
    websiteUrl: "https://www.twitter.com/",
    inputMask: "@",
  },
  Instagram: {
    websiteUrl: "https://www.instagram.com/",
    inputMask: "@",
  },
  Facebook: {
    websiteUrl: "https://www.facebook.com/",
    inputMask: "facebook.com/",
  },
  LinkedIn: {
    websiteUrl: "https://www.linkedin.com/in/",
    inputMask: "linkedin.com/in/",
  },
  Other: {
    websiteUrl: "",
    inputMask: "",
  },
}

export const KYBSocialMediaScreen: React.FC<TSocialMediaProps> = ({
  websiteType,
  website,
  setWebsite,
  setSocialMediaHandle,
}) => {
  const [newWebsite, setNewWebsite] = useState(website)

  useEffect(() => {
    setNewWebsite(socialMediaProps[websiteType].inputMask)
  }, [])

  const { t } = useTranslation()

  const { prev, next } = nav.onboarding.kyb.pages.KYBSocialMedia
  return (
    <BaseLayout
      sidePanel={<SidePanel section={2} />}
      body={
        <BaseScreen
          title={t(`kyb.additionalSocialMedia.title.${websiteType}`)}
          subtitle={t(`kyb.additionalSocialMedia.subTitle.${websiteType}`)}
        >
          <Box style={{ marginTop: "3.5rem" }}>
            <TextInput
              valueIsValid={() =>
                isValidWebsiteWithPrefix(
                  newWebsite,
                  websiteType,
                  socialMediaProps[websiteType].inputMask.length,
                )
              }
              placeholderText={t(`kyb.additionalSocialMedia.label.${websiteType}`)}
              onChange={(input) =>
                setNewWebsite(
                  input.startsWith(socialMediaProps[websiteType].inputMask)
                    ? input
                    : socialMediaProps[websiteType].inputMask.startsWith(input)
                    ? socialMediaProps[websiteType].inputMask
                    : socialMediaProps[websiteType].inputMask + input,
                )
              }
              value={newWebsite}
              autoFocus={true}
            />
          </Box>
        </BaseScreen>
      }
      buttonRow={
        <ButtonRow
          buttonOnClick={() => {
            setSocialMediaHandle(newWebsite)
            setWebsite(getWebsiteFromSocialMediaHandle(websiteType, newWebsite))
            Analytics.track(`KYB Business Online Platform ${websiteType} Continue Tap`)
            next()
          }}
          buttonDisabled={
            !isValidWebsiteWithPrefix(
              newWebsite,
              websiteType,
              socialMediaProps[websiteType].inputMask.length,
            )
          }
        />
      }
      onBackClick={prev}
    />
  )
}

const getWebsiteFromSocialMediaHandle = (websiteType: string, socialMediaHandle: string): string =>
  socialMediaProps[websiteType].websiteUrl +
  socialMediaHandle.substring(socialMediaProps[websiteType].inputMask.length)
