import React from "react"
import { connect } from "react-redux"

import { IRootState, TRootDispatch } from "core"

import { KYBEINScreen } from "./kyb-ein.screen"

import { KYBActions } from "../redux"
import { CorporationType } from "types"

interface IKYBEINStateProps {
  EIN: string
  businessType: CorporationType
}

interface IKYBEINDispatchProps {
  setEIN: (EIN: string) => void
}

export type TKYBEINProps = IKYBEINStateProps & IKYBEINDispatchProps

const mapStateToProps = (state: IRootState): IKYBEINStateProps => ({
  EIN: state.KYB.EIN,
  businessType: state.KYB.businessType,
})

const mapDispatchToProps = (dispatch: TRootDispatch): IKYBEINDispatchProps => ({
  setEIN: (EIN: string) => dispatch(KYBActions.setEIN(EIN)),
})

export const KYBEIN = connect(
  mapStateToProps,
  mapDispatchToProps,
)((props: TKYBEINProps) => <KYBEINScreen {...props} />)
