import React from "react"
import { connect } from "react-redux"

import { IRootState } from "core"

import { KYBIllegalBusinessCheckScreen } from "./kyb-illegal-business-check.screen"
import { CorporationType } from "generated/graphql"

interface IKYBIllegalBusinessCheckStateProps {
  businessType: CorporationType
}

const mapStateToProps = (state: IRootState): IKYBIllegalBusinessCheckStateProps => ({
  businessType: state.KYB.businessType,
})

export type TKYBIllegalBusinessCheckProps = IKYBIllegalBusinessCheckStateProps

export const KYBIllegalBusinessCheck = connect(mapStateToProps)(
  (props: TKYBIllegalBusinessCheckProps) => <KYBIllegalBusinessCheckScreen {...props} />,
)
