import React, { useState } from "react"
import { Box, Text, Flex, Image } from "rebass"
import { Button } from "../button.component"
import { Radio } from "./radio.component"
import { IconWithToolTip } from "./icon-with-tooltip.component"
import { OwnerIcon } from "./owner-icon.component"
import { AddIcon } from "./add-icon.component"
import { EditIcon } from "./edit-icon.component"
import GreenCheck from "images/GreenCheck.svg"
import { theme } from "theme/theme"
import { SxStyleProp } from "rebass"

interface IProps {
  isSelected: boolean
  label: string
  onClick?: () => void
  error?: boolean
  errorText?: string
  infoText?: string
  infoLine2?: string
  infoLine3?: string
  ownerList?: boolean
  addIcon?: boolean
  editIcon?: boolean
  noBorder?: boolean
  addIconColor?: string
  customRadioStyle?: SxStyleProp
  checkIcon?: boolean
}

export const RadioButton: React.FC<IProps> = ({
  isSelected = true,
  label,
  onClick = () => null,
  error,
  errorText,
  infoText,
  infoLine2,
  infoLine3,
  ownerList,
  addIcon,
  editIcon,
  noBorder,
  customRadioStyle,
  addIconColor,
  checkIcon,
}) => {
  const [isHovering, setIsHovering] = useState(false)
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
      }}
    >
      <Button
        onMouseLeave={() => setIsHovering(false)}
        onMouseEnter={() => setIsHovering(true)}
        noSimulatedSavingOnClick
        onClick={onClick}
        type={isSelected ? "blueOutline" : error ? "error" : "secondary"}
        isRadio
        customRadioStyle={customRadioStyle}
        noBorder={noBorder}
        fontWeight={theme.fontStyles.h2.fontWeight}
      >
        <Flex width="100%" padding="0 1rem" justifyContent="space-between">
          <Flex alignItems="center">
            {ownerList ? (
              <Box>
                {addIcon ? (
                  <Box
                    sx={{
                      marginTop: "3px",
                    }}
                  >
                    <AddIcon color={addIconColor} />
                  </Box>
                ) : (
                  <OwnerIcon isHovering={isHovering} isSelected={isSelected} />
                )}
              </Box>
            ) : checkIcon ? (
              <Box
                sx={{
                  marginTop: "0.6rem",
                }}
              >
                <Image src={GreenCheck} />
              </Box>
            ) : (
              <Radio isHovering={isHovering} isSelected={isSelected} />
            )}

            <Text
              ml="0.5rem"
              sx={{
                color: theme.colors.grey,
              }}
            >
              {label}
            </Text>
          </Flex>
          <Flex alignItems="right">
            {error ? (
              <Flex>
                {editIcon ? <EditIcon error /> : null}
                <IconWithToolTip errorText={errorText} />
              </Flex>
            ) : null}
            {!error && infoText ? (
              <Flex>
                {editIcon ? <EditIcon /> : null}
                <IconWithToolTip infoText={infoText} infoLine2={infoLine2} infoLine3={infoLine3} />
              </Flex>
            ) : null}
          </Flex>
        </Flex>
      </Button>
    </Box>
  )
}
