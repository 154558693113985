import { Action } from "redux"
import { ThunkAction, ThunkDispatch } from "redux-thunk"

import { IRootState } from "./root-state"

import {
  SignUpActionEnums,
  KYCActionEnums,
  KYBActionEnums,
  UBOActionEnums,
  ErrorsActionEnums,
  ControlPersonActionEnums,
  DebitFundingActionEnums,
} from "components"
import { CJEVENTActionEnum } from "../cjevent"

export enum GlobalActionEnums {
  NULL = "NULL",
  INIT = "@@INIT",
  RESET_REDUX_STATE = "RESET_REDUX_STATE",
  RESET_REDUX_STATE_EXCLUDING_SIGNUP = "RESET_REDUX_STATE_EXCLUDING_SIGNUP",
}

type ActionTypes =
  | undefined
  | SignUpActionEnums
  | KYCActionEnums
  | KYBActionEnums
  | UBOActionEnums
  | ErrorsActionEnums
  | string
  | CJEVENTActionEnum
  | GlobalActionEnums
  | ControlPersonActionEnums
  | DebitFundingActionEnums

export interface IAction<P> extends Action<ActionTypes> {
  payload?: P
}

export type TThunkAction = ThunkAction<Promise<void>, IRootState, null, IAction<any>>
export type TThunkDispatch = ThunkDispatch<IRootState, null, IAction<any>>

export type TReduxDispatch = (action: IAction<any> | TThunkAction) => void

export type TRootDispatch = TThunkDispatch | TReduxDispatch
