import { CorporationType, IAddress, UBOTypeEnum } from "types"
import { MailingAddressLocationEnum } from "generated/graphql"

export enum WebsiteType {
  FACEBOOK = "Facebook",
  INSTAGRAM = "Instagram",
  TWITTER = "Twitter",
  LINKEDIN = "LinkedIn",
  OTHER = "Other",
}
export interface IKYBState {
  businessType: CorporationType
  description: string
  website: string
  websiteType: WebsiteType
  phone: string
  legalName: string
  name: string
  dba: string
  businessAddress: IAddress
  date: string
  state: string
  nature: number
  percentOwnership: string
  mailingAddress: MailingAddressLocationEnum
  success: boolean
  hasMultipleOwners: boolean
  EIN: string
  UBOType: UBOTypeEnum
  corpType: CorporationType
  socialMediaHandle: string
}

export const initialKYBState: IKYBState = {
  businessType: CorporationType.Soleprop,
  websiteType: WebsiteType.FACEBOOK,
  socialMediaHandle: "",
  description: "",
  website: "",
  phone: "",
  name: "",
  legalName: "",
  dba: "",
  businessAddress: {
    streetAddressLine1: "",
    streetAddressLine2: "",
    city: "",
    provinceState: "",
    postalCode: "",
    country: "",
  },
  date: "",
  state: "",
  nature: 0,
  percentOwnership: "",
  mailingAddress: MailingAddressLocationEnum.Business,
  success: false,
  hasMultipleOwners: true,
  EIN: "",
  UBOType: UBOTypeEnum.singleOwner,
  corpType: CorporationType.Llc,
}
