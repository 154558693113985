import React from "react"
import { Image, Text, Flex } from "rebass"
import northOneWhite from "images/NorthOne-White-wb.svg"
import smallScreenPhone from "images/small-screen-image.png"
import { theme } from "theme/theme"
import { useTranslation } from "react-i18next"
import GooglePlay from "images/GooglePlay.svg"
import AppStore from "images/AppStore.svg"
import { APP_STORE_URL, GOOGLE_PLAY_STORE_URL } from "utils/constants"
import smallScreenBackgroundImage from "images/Small-Screen-Background.svg"
import { useWindowDimensions } from "utils"

export const SmallScreen: React.SFC = () => {
  const { t } = useTranslation()
  const _handleClick = (buttonLink: string) => {
    window.location.assign(buttonLink)
  }
  const { height } = useWindowDimensions()
  const checkIsIphoneXSize = height > 811
  const checkIsIphone8PlusSize = height > 730
  const isExtraSmallMobileScreen = height < 650
  const isExtraSmallDesktopScreen = height > 550
  return (
    <Flex
      alignItems="center"
      flexDirection="column"
      style={{
        backgroundImage: `url(${smallScreenBackgroundImage})`,
        backgroundSize: "cover",
        height: "100vh",
        padding: "0 1.5rem",
      }}
    >
      <Image
        style={{
          margin: isExtraSmallMobileScreen ? "1rem auto" : "2rem auto",
          justifyContent: "center",
        }}
        src={northOneWhite}
      />
      <Flex
        flexDirection="column"
        justifyContent="space-evenly"
        height={"100%"}
        style={{
          maxWidth: "22rem",
        }}
      >
        <Flex flexDirection="column">
          <Text
            color={theme.colors.white}
            style={
              isExtraSmallMobileScreen
                ? { ...theme.fontStyles.h3Small }
                : { ...theme.fontStyles.h3 }
            }
          >
            {t("smallScreen.title")}
          </Text>
          <Flex style={{ marginTop: "1rem", height: "40px" }}>
            <Image onClick={() => _handleClick(APP_STORE_URL)} src={AppStore} />
            <Image
              style={{ marginLeft: "10px" }}
              onClick={() => _handleClick(GOOGLE_PLAY_STORE_URL)}
              src={GooglePlay}
            />
          </Flex>
        </Flex>
        {isExtraSmallDesktopScreen ? (
          <Flex
            justifyContent="center"
            height={checkIsIphoneXSize ? "31rem" : checkIsIphone8PlusSize ? "25rem" : "21rem"}
          >
            <Image sx={{ alignSelf: "center" }} src={smallScreenPhone} />
          </Flex>
        ) : null}
      </Flex>
    </Flex>
  )
}
