import { EventEmitter } from "events"
import { logger } from "utils"

// Please do not export and use GlobalEventEmitter directly, add GlobalEventEmitter logic to GlobalEvents service below
const GlobalEventEmitter = new EventEmitter()

enum GlobalEventTypes {
  API_ACCESS_TOKEN = "API_ACCESS_TOKEN",
  API_ACCESS_TOKEN_INVALID = "API_ACCESS_TOKEN_INVALID",
}

export const GlobalEvents = {
  setAccessToken: (accessToken: string) => {
    logger.info("Dispatching Global Event:", GlobalEventTypes.API_ACCESS_TOKEN)
    return GlobalEventEmitter.emit(GlobalEventTypes.API_ACCESS_TOKEN, accessToken)
  },
  onAccessTokenChange: (cb: (accessToken: string) => any): void => {
    GlobalEventEmitter.on(GlobalEventTypes.API_ACCESS_TOKEN, (token: string) => {
      logger.info("Received Global Event:", GlobalEventTypes.API_ACCESS_TOKEN)
      cb(token)
    })
  },
  accessTokenInvalid: (): void => {
    logger.info("Dispatching Global Event:", GlobalEventTypes.API_ACCESS_TOKEN_INVALID)
    GlobalEventEmitter.emit(GlobalEventTypes.API_ACCESS_TOKEN_INVALID)
  },
  onAccessTokenInvalid: (cb: () => any): void => {
    const event = GlobalEventTypes.API_ACCESS_TOKEN_INVALID
    logger.info(`created event listener for event: ${event}`)
    GlobalEventEmitter.on(event, () => {
      logger.info("Received Global Event:", GlobalEventTypes.API_ACCESS_TOKEN_INVALID)
      cb()
    })
  },
}
