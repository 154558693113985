import React from "react"
import { connect } from "react-redux"

import { IRootState, TRootDispatch } from "core"
import { nav } from "core/navigation"
import { ControlPersonActions } from "../../redux"
import { UnsupportedCountryScreen } from "shared"

interface IControlPersonUnsupportedCountryStateProps {
  unsupportedCountry: string
}

interface IControlPersonUnsupportedCountryDispatchProps {
  setUnsupportedCountry: (unsupportedCountry: string) => void
}

export type TControlPersonUnsupportedCountryProps = IControlPersonUnsupportedCountryStateProps &
  IControlPersonUnsupportedCountryDispatchProps

const mapStateToProps = (state: IRootState): IControlPersonUnsupportedCountryStateProps => ({
  unsupportedCountry: state.controlPerson.candidate.unsupportedCountry,
})

const mapDispatchToProps = (
  dispatch: TRootDispatch,
): IControlPersonUnsupportedCountryDispatchProps => ({
  setUnsupportedCountry: (unsupportedCountry: string) =>
    dispatch(ControlPersonActions.setUnsupportedCountry(unsupportedCountry)),
})

export const ControlPersonUnsupportedCountryScreen: React.FC<
  TControlPersonUnsupportedCountryProps
> = ({ unsupportedCountry, setUnsupportedCountry }) => {
  const { prev, next } = nav.onboarding.controlPerson.pages.ControlPersonUnsupportedCountry

  return (
    <UnsupportedCountryScreen
      unsupportedCountry={unsupportedCountry}
      setUnsupportedCountry={(newUnsupportedCountry) =>
        setUnsupportedCountry(newUnsupportedCountry)
      }
      prev={prev}
      next={next}
    />
  )
}

export const ControlPersonUnsupportedCountry = connect(
  mapStateToProps,
  mapDispatchToProps,
)((props: TControlPersonUnsupportedCountryProps) => (
  <ControlPersonUnsupportedCountryScreen {...props} />
))
