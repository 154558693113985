import React from "react"
import { useTranslation } from "react-i18next"
import { BaseLayout, BaseScreen, SidePanel, ButtonRow } from "shared"

interface IUnsupportedCountrySuccessScreen {
  prev: () => void
}

export const UnsupportedCountrySuccessScreen: React.FC<IUnsupportedCountrySuccessScreen> = ({
  prev,
}) => {
  const { t } = useTranslation()
  return (
    <BaseLayout
      sidePanel={<SidePanel section={2} />}
      body={<BaseScreen title={t("shared.screens.unsupportedCountrySuccess.title")}></BaseScreen>}
      buttonRow={
        <ButtonRow
          buttonOnClick={() => {
            window.location.href = "http://www.northone.com"
          }}
          buttonText={t("shared.screens.unsupportedCountrySuccess.button")}
        />
      }
      onBackClick={prev}
    />
  )
}
