import React, { useState } from "react"
import { useTranslation } from "react-i18next"
import { Box } from "rebass"
import { BaseLayout, SingleTextInputScreen, SidePanel, ButtonRow } from "shared"
import { phoneRegex } from "utils"
import { Analytics } from "core/analytics/actions"
import { getTextWithLineBreaks } from "utils/helpers"
import { AppStoreLinks } from "shared/app-store-links"

interface IProps {
  phone: string
}

// tslint:disable: interface-name
declare global {
  interface Window {
    branch: any
  }
}

window.branch = window.branch || {}

export const OwnerInviteScreen: React.FC<IProps> = ({ phone }) => {
  // TODO: If reusing the TextMeTheApp feature,
  // please refactor into separate component (in OwnerInviteScreen and BackgroundCheckSuccessScreen)
  const { t } = useTranslation()
  const [phoneNumber, setPhoneNumber] = useState(phone)
  const [isSent, setIsSent] = useState(false)
  const [sendingSMS, setSendingSMS] = useState(false)

  const phoneIsValid = phoneRegex.test(phoneNumber)

  const sendSMS = (phoneRecipient: string) => {
    setSendingSMS(true)
    const linkData = {
      tags: [],
      channel: "Website",
      feature: "TextMeTheApp",
    }
    const callback = () => {
      setIsSent(true)
      setSendingSMS(false)
    }
    window.branch.sendSMS(phoneRecipient, linkData, callback)
  }

  return (
    <BaseLayout
      sidePanel={
        <SidePanel
          section={1}
          customSidePanel
          customSectionLength={2}
          customHeadingTranslation={"signUp.ownerInviteCustomSidePanel.header"}
          customTextTranslation={"signUp.ownerInviteCustomSidePanel.text"}
          overridenTextDisplaySection={2}
        />
      }
      body={
        <Box>
          <SingleTextInputScreen
            title={t("signUp.ownerInvite.title")}
            onChange={setPhoneNumber}
            value={phoneNumber}
            placeholderText={"+1"}
            phoneType={true}
            disableMinimizedPlaceholderText
            subtitle={getTextWithLineBreaks(t("signUp.ownerInvite.subtitle"))}
          />
          <AppStoreLinks
            analyticsEventAppStore={`Owner Invitation App Store Tap`}
            analyticsEventGoogleStore={`Owner Invitation Google Store Tap`}
          />
        </Box>
      }
      buttonRow={
        <ButtonRow
          buttonOnClick={() => {
            sendSMS(phoneNumber)
            Analytics.track(`Owner Invitation SMS Link Tap`)
          }}
          buttonDisabled={!phoneIsValid || sendingSMS}
          noSimulatedSaving
          buttonText={isSent ? "Resend link" : "Text me the link"}
          withEnter={false}
          buttonType={isSent ? "primary" : "principal"}
        />
      }
      noBackButton
    />
  )
}
