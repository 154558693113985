import { createAction, TActionsUnion } from "core"

export enum ErrorsActionEnums {
  SET_CURRENT_ERROR_MESSAGE = "SET_CURRENT_ERROR_MESSAGE",
  CLEAR_CURRENT_ERROR_MESSAGE = "CLEAR_CURRENT_ERROR_MESSAGE",
}

export const ErrorsActions = {
  setCurrentErrorMessage: (errorMessage: string) =>
    createAction(ErrorsActionEnums.SET_CURRENT_ERROR_MESSAGE, errorMessage),
  clearCurrentErrorMessage: () => createAction(ErrorsActionEnums.CLEAR_CURRENT_ERROR_MESSAGE),
}

export type TErrorsActions = TActionsUnion<typeof ErrorsActions>
