import React, { useState } from "react"
import { useTranslation } from "react-i18next"
import { Box } from "rebass"
import { BaseLayout, BaseScreen, SidePanel, ButtonRow, TextInput } from "shared"
import { onlyNumbersRegex, isValidSSN } from "utils"
import { TKYCSSNProps } from "./kyc-ssn.container"
import { Analytics } from "../../../../core/analytics/actions"

interface IProps {
  goToNext: () => void
  goToPrev?: () => void
  isSSNMissing?: boolean
}

export const KYCSSNScreen: React.FC<TKYCSSNProps & IProps> = ({
  SSN,
  setSSN,
  goToNext,
  goToPrev,
  isSSNMissing = false,
}) => {
  const { t } = useTranslation()
  const [newSSN, setNewSSN] = useState(SSN)
  const valueIsValid = () => isValidSSN(newSSN)

  return (
    <BaseLayout
      sidePanel={isSSNMissing ? <SidePanel section={2} /> : <SidePanel section={1} />}
      body={
        <BaseScreen
          title={isSSNMissing ? t("kyc.ssnMissing.title") : t("kyc.ssn.title")}
          subtitle={isSSNMissing ? t("kyc.ssnMissing.subtitle") : t("kyc.ssn.subtitle")}
        >
          <Box style={{ marginTop: "2rem" }}>
            <TextInput
              withObfuscation
              placeholderText={
                isSSNMissing ? t("kyc.ssnMissing.placeholder") : t("kyc.ssn.placeholder")
              }
              onChange={(newValue) => (newValue.length <= 9 ? setNewSSN(newValue) : null)}
              value={newSSN}
              autoFocus={true}
              validationType={newSSN && !onlyNumbersRegex.test(newSSN) ? "error" : "info"}
              validationText={
                newSSN && !onlyNumbersRegex.test(newSSN) ? t("errors.onlyDigits") : ""
              }
            />
          </Box>
        </BaseScreen>
      }
      buttonRow={
        <ButtonRow
          buttonOnClick={() => {
            setSSN(newSSN)
            goToNext()
            Analytics.track("KYC Verification Manual SSN Continue Tap")
          }}
          buttonDisabled={!valueIsValid()}
          noSimulatedSaving
        />
      }
      noBackButton={!goToPrev}
      onBackClick={goToPrev}
    />
  )
}
