import { createAction, TActionsUnion } from "../redux/utils"

// ** DO NOT ADD ANY PERSONALLY IDENTIFIABLE FIELDS TO THIS STATE - FOR COMPLIANCE REASONS
export interface ICJEVENTState {
  CJEVENT: string
}
export const initialCJEVENTState: ICJEVENTState = { CJEVENT: "" }

export enum CJEVENTActionEnum {
  SET_CJEVENT = "SET_CJEVENT",
  SET_CJ_AFFILIATE_LINK_URL = "SET_CJ_AFFILIATE_LINK_URL",
}
export const CJEVENTAction = {
  setCJEVENT: (CJEVENT: string) => createAction(CJEVENTActionEnum.SET_CJEVENT, CJEVENT),
}
export type TCJEVENTAction = TActionsUnion<typeof CJEVENTAction>

export const CJEVENTReducer = (
  state: ICJEVENTState = initialCJEVENTState,
  action: TCJEVENTAction,
): ICJEVENTState => {
  switch (action.type) {
    case CJEVENTActionEnum.SET_CJEVENT:
      return {
        ...state,
        CJEVENT: action.payload,
      }
    default:
      return state
  }
}
