import React from "react"
import { connect } from "react-redux"

import { IRootState, TRootDispatch } from "core"

import { KYBDescriptionScreen } from "./kyb-description.screen.gql"

import { KYBActions } from "../redux"

import { ErrorsActions } from "components"

interface IKYBDescriptionStateProps {
  description: string
}

interface IKYBDescriptionDispatchProps {
  setDescription: (description: string) => void
  setCurrentErrorMessage: (errorMessage: string) => void
}

export type TKYBDescriptionProps = IKYBDescriptionStateProps & IKYBDescriptionDispatchProps

const mapStateToProps = (state: IRootState): IKYBDescriptionStateProps => ({
  description: state.KYB.description,
})

const mapDispatchToProps = (dispatch: TRootDispatch): IKYBDescriptionDispatchProps => ({
  setDescription: (description: string) => dispatch(KYBActions.setDescription(description)),
  setCurrentErrorMessage: (errorMessage: string) =>
    dispatch(ErrorsActions.setCurrentErrorMessage(errorMessage)),
})

export const KYBDescription = connect(
  mapStateToProps,
  mapDispatchToProps,
)((props: TKYBDescriptionProps) => <KYBDescriptionScreen {...props} />)
