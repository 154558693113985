import React, { useState, useEffect } from "react"

import { LoadingScreen } from "shared"
import { PostAuthWrapper } from "./post-auth-wrapper"

import {
  auth,
  Auth0Error,
  getTokenOrLogout,
  redirectToLogin,
  getTokenSilently,
} from "./auth-service"

export const AuthWrapper: React.FC = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false)

  useEffect(() => {
    const checkAuthSession = async () => {
      // redirect to login on logout
      const queryParams = new URLSearchParams(window.location.search)
      if (queryParams.get("logout")) {
        return await redirectToLogin("login")
      }

      try {
        // intercept blocked user callbacks
        await auth.handleRedirectCallback()
      } catch (error) {
        if (error.error === Auth0Error.BLOCKED) {
          return await redirectToLogin("unauthorized")
        }
      }

      if (await auth.isAuthenticated()) {
        return setIsAuthenticated(true)
      }

      try {
        const token = await getTokenSilently()
        if (token) {
          return setIsAuthenticated(true)
        }
        return await redirectToLogin()
      } catch (error) {
        if (error.error !== Auth0Error.LOGIN_REQUIRED) {
          console.error(error)
        }

        return await redirectToLogin()
      }
    }

    checkAuthSession()

    // poll to keep auth session alive
    const renewSession = setInterval(async () => {
      await getTokenOrLogout()
    }, 5 * 60 * 1000)
    return () => clearInterval(renewSession)
  }, [])

  return isAuthenticated ? <PostAuthWrapper>{children}</PostAuthWrapper> : <LoadingScreen />
}
