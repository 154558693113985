import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
import * as React from 'react';
import * as ApolloReactComponents from '@apollo/client/react/components';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** A field whose value conforms to the standard internet email address format as specified in RFC822: https://www.w3.org/Protocols/rfc822/. */
  EmailAddress: any;
  GraphQLDate: any;
  GraphQLTime: any;
  GraphQLDateTime: any;
  Upload: any;
};

/** Represents an account's balance on a given date. */
export type AccountBalance = {
  __typename?: 'AccountBalance';
  date: Scalars['String'];
  balance: Scalars['Float'];
};

export type AccountStatement = {
  __typename?: 'AccountStatement';
  statement: Scalars['String'];
  month?: Maybe<Scalars['String']>;
  year?: Maybe<Scalars['String']>;
};

export type AccountTransferMutationResponse = MutationResponse & {
  __typename?: 'AccountTransferMutationResponse';
  code: Scalars['String'];
  success: Scalars['Boolean'];
  message: Scalars['String'];
  transaction?: Maybe<AccountTransferUnion>;
  transactionSchedule?: Maybe<TransactionSchedule>;
};

export type AccountTransferUnion = SubAccountTransfer | Ach | GenericTransaction;

export enum AccountTypeEnum {
  SubAccount = 'SUB_ACCOUNT',
  LinkedAccount = 'LINKED_ACCOUNT'
}

export type Ach = ITransaction & {
  __typename?: 'ACH';
  id: Scalars['ID'];
  description?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  location?: Maybe<Location>;
  /** @deprecated use description instead */
  rawDescription?: Maybe<Scalars['String']>;
  subAccount: SubAccount;
  business: Business;
  estimatedCompletionDate?: Maybe<Scalars['String']>;
  amount: Scalars['Float'];
  note?: Maybe<Scalars['String']>;
  status?: Maybe<TransactionStatusEnum>;
  createdAt?: Maybe<Scalars['String']>;
  transactionType: TransactionTypeEnum;
  emailContacts: Array<TransactionEmailContact>;
  cancelable?: Maybe<Scalars['Boolean']>;
  errorMessage?: Maybe<Scalars['String']>;
  recipient?: Maybe<RecipientDomesticAch>;
  fromAccount?: Maybe<SubAccount>;
  isLinkedAccountTransfer?: Maybe<Scalars['Boolean']>;
  counterparty?: Maybe<AchCounterpartyUnion>;
};

export type AchCancelMutationResponse = MutationResponse & {
  __typename?: 'ACHCancelMutationResponse';
  code: Scalars['String'];
  success: Scalars['Boolean'];
  message: Scalars['String'];
};

export type AchCounterpartyUnion = RecipientDomesticAch | LinkedAccount;

export type AchMutationResponse = MutationResponse & {
  __typename?: 'ACHMutationResponse';
  ach?: Maybe<Ach>;
  transactionSchedule?: Maybe<TransactionSchedule>;
  code: Scalars['String'];
  success: Scalars['Boolean'];
  message: Scalars['String'];
};

export type AdjustAttributionInfoInput = {
  accountApprovedEventToken: Scalars['String'];
  appToken: Scalars['String'];
  /** one of adjustId, gpsAdid, idfa are required */
  adjustId?: Maybe<Scalars['String']>;
  gpsAdid?: Maybe<Scalars['String']>;
  idfa?: Maybe<Scalars['String']>;
};

export type Announcement = {
  __typename?: 'Announcement';
  type?: Maybe<AnnouncementTypeEnum>;
  isInteractionComplete?: Maybe<Scalars['Boolean']>;
  requiredAudience?: Maybe<AnnouncementAudienceRequirementEnum>;
};

export enum AnnouncementActionEventEnum {
  Continue = 'CONTINUE',
  Dismiss = 'DISMISS'
}

export enum AnnouncementAudienceRequirementEnum {
  Business = 'BUSINESS',
  AllUsers = 'ALL_USERS'
}

export type AnnouncementInteractResponse = MutationResponse & {
  __typename?: 'AnnouncementInteractResponse';
  code: Scalars['String'];
  success: Scalars['Boolean'];
  message: Scalars['String'];
};

export enum AnnouncementTypeEnum {
  InvoiceCreateAnInvoice = 'INVOICE_CREATE_AN_INVOICE',
  InvoiceSettings = 'INVOICE_SETTINGS',
  ShareAndEarnHub = 'SHARE_AND_EARN_HUB'
}

export type AtmLocation = {
  __typename?: 'ATMLocation';
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  displayAddress?: Maybe<Scalars['String']>;
  coordinates?: Maybe<Coordinates>;
  isAccessible?: Maybe<Scalars['Boolean']>;
  is24Hr?: Maybe<Scalars['Boolean']>;
  isDepositAvailable?: Maybe<Scalars['Boolean']>;
  source?: Maybe<AtmSourceEnum>;
  distance?: Maybe<Scalars['Float']>;
};

export enum AtmLocationFilterEnum {
  IsAccessible = 'IS_ACCESSIBLE',
  Is_24Hr = 'IS_24HR',
  IsDepositAvailable = 'IS_DEPOSIT_AVAILABLE'
}

export enum AtmSourceEnum {
  Moneypass = 'MONEYPASS',
  Nyce = 'NYCE'
}

export enum AuthTokenTypeEnum {
  RefreshToken = 'REFRESH_TOKEN',
  AccessToken = 'ACCESS_TOKEN'
}

export type BackgroundCheckBusinessInfoInput = {
  /** defaults to using business address */
  mailingAddress?: Maybe<MailingAddressLocationEnum>;
  corporationType: CorporationType;
  /** doing business as - https://en.wikipedia.org/wiki/Trade_name */
  dba?: Maybe<Scalars['String']>;
  /** Two letter abbreviation for the U.S. State where the company is incorporated. */
  incorporationState?: Maybe<Scalars['String']>;
  /** Description of the company */
  description: Scalars['String'];
  /** Employer Identification Number (tax ID) of the company */
  ein: Scalars['String'];
  /** Date business was established. Unix timestamp format. */
  incorporationDate?: Maybe<Scalars['String']>;
  /**
   * NAICS code that accurately describes the industry the company operates in.
   * https://en.wikipedia.org/wiki/North_American_Industry_Classification_System
   */
  naicsCode: Scalars['Int'];
  legalBusinessName: Scalars['String'];
  location: LocationStrictInput;
  websiteUrl?: Maybe<Scalars['String']>;
  phoneNumber: Scalars['String'];
};

export type BackgroundCheckOwnerInfoInput = {
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  /** unix timestamp format */
  dateOfBirth: Scalars['String'];
  homeAddress: LocationStrictInput;
};

export type BankAccount = {
  __typename?: 'BankAccount';
  id: Scalars['ID'];
  capabilities?: Maybe<BankAccountCapabilities>;
};

export type BankAccountCapabilities = {
  __typename?: 'BankAccountCapabilities';
  moveMoney: BankAccountCapabilitiesMoneyMove;
};

export type BankAccountCapabilitiesAch = {
  __typename?: 'BankAccountCapabilitiesACH';
  enabled?: Maybe<Scalars['Boolean']>;
  limit: Scalars['Float'];
};

export type BankAccountCapabilitiesAtm = {
  __typename?: 'BankAccountCapabilitiesATM';
  depositEnabled?: Maybe<Scalars['Boolean']>;
};

export type BankAccountCapabilitiesBillPay = {
  __typename?: 'BankAccountCapabilitiesBillPay';
  ach: BankAccountCapabilitiesBillPayAch;
  chequeDeposit: BankAccountCapabilitiesBillPayCheque;
};

export type BankAccountCapabilitiesBillPayAch = {
  __typename?: 'BankAccountCapabilitiesBillPayACH';
  enabled?: Maybe<Scalars['Boolean']>;
  limit: Scalars['Float'];
};

export type BankAccountCapabilitiesBillPayCheque = {
  __typename?: 'BankAccountCapabilitiesBillPayCheque';
  enabled?: Maybe<Scalars['Boolean']>;
  limit: Scalars['Float'];
};

export type BankAccountCapabilitiesChequeDeposit = {
  __typename?: 'BankAccountCapabilitiesChequeDeposit';
  enabled?: Maybe<Scalars['Boolean']>;
  limit: Scalars['Float'];
};

export type BankAccountCapabilitiesDomesticWire = {
  __typename?: 'BankAccountCapabilitiesDomesticWire';
  enabled?: Maybe<Scalars['Boolean']>;
  limit: Scalars['Float'];
};

export type BankAccountCapabilitiesMoneyMove = {
  __typename?: 'BankAccountCapabilitiesMoneyMove';
  ach: BankAccountCapabilitiesAch;
  domesticWire: BankAccountCapabilitiesDomesticWire;
  chequeDeposit: BankAccountCapabilitiesChequeDeposit;
  billPay: BankAccountCapabilitiesBillPay;
  atm: BankAccountCapabilitiesAtm;
};

export type BankAccountInfo = {
  __typename?: 'BankAccountInfo';
  id: Scalars['ID'];
  routingNumber: Scalars['String'];
  accountNumber: Scalars['String'];
  bankName: Scalars['String'];
  bankAddress: Scalars['String'];
};

export type Billing = {
  __typename?: 'Billing';
  business: Business;
  plan?: Maybe<BillingPlan>;
  invoices: Array<BillingInvoice>;
};

export type BillingInvoice = {
  __typename?: 'BillingInvoice';
  id: Scalars['ID'];
  amountPaid?: Maybe<Scalars['Float']>;
  date?: Maybe<Scalars['String']>;
  pdf?: Maybe<Scalars['String']>;
};

export type BillingPlan = {
  __typename?: 'BillingPlan';
  name?: Maybe<Scalars['String']>;
  price?: Maybe<Scalars['Float']>;
  availableTransactionsCount?: Maybe<Scalars['Int']>;
  maxTransactionsCount?: Maybe<Scalars['Int']>;
  usedTransactionsCount?: Maybe<Scalars['Int']>;
  nextPaymentDate?: Maybe<Scalars['String']>;
};

export type BillPayment = ITransaction & {
  __typename?: 'BillPayment';
  id: Scalars['ID'];
  description?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  location?: Maybe<Location>;
  /** @deprecated use description instead */
  rawDescription?: Maybe<Scalars['String']>;
  subAccount: SubAccount;
  business: Business;
  estimatedCompletionDate?: Maybe<Scalars['String']>;
  amount: Scalars['Float'];
  note?: Maybe<Scalars['String']>;
  status?: Maybe<TransactionStatusEnum>;
  createdAt?: Maybe<Scalars['String']>;
  transactionType: TransactionTypeEnum;
  emailContacts: Array<TransactionEmailContact>;
  cancelable?: Maybe<Scalars['Boolean']>;
  errorMessage?: Maybe<Scalars['String']>;
  recipient?: Maybe<RecipientBillPayment>;
  fromAccount?: Maybe<SubAccount>;
};

export type BillPaymentCreateInput = {
  subAccountId?: Maybe<Scalars['ID']>;
  amount: Scalars['Float'];
  note?: Maybe<Scalars['String']>;
  recipient: Scalars['ID'];
  schedule?: Maybe<TransactionScheduleInput>;
  emailContacts?: Maybe<Array<EmailContactInput>>;
};

export type BillPaymentMutationResponse = MutationResponse & {
  __typename?: 'BillPaymentMutationResponse';
  billPayment?: Maybe<BillPayment>;
  transactionSchedule?: Maybe<TransactionSchedule>;
  code: Scalars['String'];
  success: Scalars['Boolean'];
  message: Scalars['String'];
};

export type BillPaymentVendor = {
  __typename?: 'BillPaymentVendor';
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  category?: Maybe<Scalars['String']>;
};

export type Business = {
  __typename?: 'Business';
  id: Scalars['ID'];
  createdAt: Scalars['String'];
  name: Scalars['String'];
  legalName?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  corporationType?: Maybe<CorporationType>;
  website?: Maybe<Scalars['String']>;
  industry?: Maybe<Scalars['String']>;
  balance: Scalars['Float'];
  availableBalance: Scalars['Float'];
  thumbnail: Scalars['String'];
  bankAccounts: Array<BankAccount>;
  subAccounts: Array<SubAccount>;
  linkedAccounts: Array<LinkedAccount>;
  location?: Maybe<Location>;
  /** Format example: America/Toronto */
  timezone: Scalars['String'];
  billing: Billing;
  cards: Array<Card>;
  /** Card for the currently logged in user */
  myCard?: Maybe<Card>;
  /** Bank account info for depositing funds into account via ACH or wire */
  bankAccountInfo?: Maybe<BankAccountInfo>;
  accountStatementMonthly?: Maybe<AccountStatement>;
  accountStatementHistory: Array<AccountStatement>;
  myAddress?: Maybe<Location>;
  /**
   * This means that funding has been added, i.e user has submitted a cheque deposit or has indicated that they have
   * sent an ACH etc... This does not mean that the account is truly funded (
   * since certain funding sources are not guaranteed or take an extended period of time to complete i.e: Wire / ACH )
   */
  fundingSourceAdded?: Maybe<Scalars['Boolean']>;
  fundingStatus?: Maybe<FundingStatusEnum>;
};


export type BusinessAccountStatementMonthlyArgs = {
  month: Scalars['String'];
  year: Scalars['String'];
};

export type BusinessAccessTokenMutationResponse = MutationResponse & {
  __typename?: 'BusinessAccessTokenMutationResponse';
  code: Scalars['String'];
  message: Scalars['String'];
  success: Scalars['Boolean'];
  businessAccessToken?: Maybe<Scalars['String']>;
};

export type BusinessBackgroundCheck = {
  __typename?: 'BusinessBackgroundCheck';
  id?: Maybe<Scalars['ID']>;
  businessName?: Maybe<Scalars['String']>;
  status: BusinessBackgroundCheckStatus;
};

export type BusinessBackgroundCheckMutationResponse = MutationPayload & {
  __typename?: 'BusinessBackgroundCheckMutationResponse';
  checkStatus: BusinessBackgroundCheckStatus;
  userErrors: Array<UserError>;
};

export type BusinessBackgroundCheckOwnerInfoInput = {
  /** Only required for the owner that is registering */
  iovationBlackbox?: Maybe<Scalars['String']>;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  /** YYYY/MM/DD */
  dateOfBirth: Scalars['String'];
  homeAddress: LocationStrictInput;
  /** 2 characters */
  citizenship: Scalars['String'];
  emailAddress: Scalars['String'];
  ownershipPercentage: Scalars['Int'];
  phoneNumber: Scalars['String'];
  role: Scalars['String'];
  jobTitle: Scalars['String'];
  /** 9 characters */
  ssn: Scalars['String'];
  isControlPerson?: Maybe<Scalars['Boolean']>;
  inviteToJoin?: Maybe<Scalars['Boolean']>;
};

export enum BusinessBackgroundCheckStatus {
  Approved = 'APPROVED',
  Pending = 'PENDING',
  Processing = 'PROCESSING',
  Submitted = 'SUBMITTED',
  Rejected = 'REJECTED',
  BillingError = 'BILLING_ERROR',
  Ready = 'READY',
  Queued = 'QUEUED'
}

export type BusinessBackgroundCheckSubmitMutationResponse = {
  __typename?: 'BusinessBackgroundCheckSubmitMutationResponse';
  success: Scalars['Boolean'];
  message: Scalars['String'];
  checkStatus?: Maybe<BusinessBackgroundCheckStatus>;
  prequalFailureStatus?: Maybe<PrequalFailureStatusEnum>;
};

export type BusinessFundingSourceAddMutationResponse = MutationResponse & {
  __typename?: 'BusinessFundingSourceAddMutationResponse';
  code: Scalars['String'];
  success: Scalars['Boolean'];
  message: Scalars['String'];
  business?: Maybe<Business>;
};

export type BusinessInvitation = {
  __typename?: 'BusinessInvitation';
  id: Scalars['ID'];
  status: BusinessInvitationStatusEnum;
  businessName?: Maybe<Scalars['String']>;
  businessLogo?: Maybe<Scalars['String']>;
  inviterName?: Maybe<Scalars['String']>;
  isExpired?: Maybe<Scalars['Boolean']>;
  inviteeFirstName?: Maybe<Scalars['String']>;
  inviteeLastName?: Maybe<Scalars['String']>;
  inviteePhone?: Maybe<Scalars['String']>;
  inviteeEmail?: Maybe<Scalars['String']>;
};

export enum BusinessInvitationStatusEnum {
  Sent = 'SENT',
  Draft = 'DRAFT',
  Accepted = 'ACCEPTED'
}

export type BusinessMemberCandidate = {
  __typename?: 'BusinessMemberCandidate';
  id: Scalars['ID'];
  invitationId?: Maybe<Scalars['ID']>;
  cardLast4?: Maybe<Scalars['String']>;
  status?: Maybe<BusinessMemberCandidateStatusEnum>;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  email: Scalars['String'];
  phone: Scalars['String'];
};

export enum BusinessMemberCandidateStatusEnum {
  Member = 'MEMBER',
  Invited = 'INVITED',
  NotInvited = 'NOT_INVITED'
}

export enum BusinessMemberStatusEnum {
  Active = 'ACTIVE',
  Invited = 'INVITED'
}

export type BusinessUpdateData = {
  name?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  provinceState?: Maybe<Scalars['String']>;
  streetAddressLine1?: Maybe<Scalars['String']>;
  streetAddressLine2?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  /** Format example: America/Toronto */
  timezone?: Maybe<Scalars['String']>;
  thumbnailUri?: Maybe<Scalars['String']>;
};

export type Card = {
  __typename?: 'Card';
  id: Scalars['ID'];
  ownerId?: Maybe<Scalars['ID']>;
  ownerName?: Maybe<Scalars['String']>;
  businessId?: Maybe<Scalars['ID']>;
  bankAccountId?: Maybe<Scalars['ID']>;
  cardNumber?: Maybe<CardNumber>;
  expYear?: Maybe<Scalars['String']>;
  expMonth?: Maybe<Scalars['String']>;
  activated?: Maybe<Scalars['Boolean']>;
  frozen?: Maybe<Scalars['Boolean']>;
  limit?: Maybe<Scalars['Float']>;
  createdAt?: Maybe<Scalars['String']>;
  type?: Maybe<CardTypeEnum>;
};

export type CardFreezeMutationResponse = MutationResponse & {
  __typename?: 'CardFreezeMutationResponse';
  code: Scalars['String'];
  success: Scalars['Boolean'];
  message: Scalars['String'];
  card?: Maybe<Card>;
};

export type CardNumber = {
  __typename?: 'CardNumber';
  last4?: Maybe<Scalars['String']>;
};

export type CardOrderMutationResponse = MutationResponse & {
  __typename?: 'CardOrderMutationResponse';
  code: Scalars['String'];
  success: Scalars['Boolean'];
  message: Scalars['String'];
  card?: Maybe<Card>;
};

export type CardPurchase = ITransaction & {
  __typename?: 'CardPurchase';
  id: Scalars['ID'];
  description?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  location?: Maybe<Location>;
  /** @deprecated use description instead */
  rawDescription?: Maybe<Scalars['String']>;
  subAccount: SubAccount;
  business: Business;
  estimatedCompletionDate?: Maybe<Scalars['String']>;
  amount: Scalars['Float'];
  note?: Maybe<Scalars['String']>;
  status?: Maybe<TransactionStatusEnum>;
  createdAt?: Maybe<Scalars['String']>;
  transactionType: TransactionTypeEnum;
  emailContacts: Array<TransactionEmailContact>;
  cancelable?: Maybe<Scalars['Boolean']>;
  errorMessage?: Maybe<Scalars['String']>;
  card?: Maybe<Card>;
  merchant?: Maybe<Merchant>;
};

export enum CardTypeEnum {
  MastercardDebit = 'MASTERCARD_DEBIT'
}

export type Category = {
  __typename?: 'Category';
  code: Scalars['String'];
  groupCode?: Maybe<Scalars['String']>;
  emoji?: Maybe<Scalars['String']>;
};

export type ChequeDeposit = ITransaction & {
  __typename?: 'ChequeDeposit';
  id: Scalars['ID'];
  description?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  location?: Maybe<Location>;
  /** @deprecated use description instead */
  rawDescription?: Maybe<Scalars['String']>;
  subAccount: SubAccount;
  business: Business;
  estimatedCompletionDate?: Maybe<Scalars['String']>;
  amount: Scalars['Float'];
  note?: Maybe<Scalars['String']>;
  status?: Maybe<TransactionStatusEnum>;
  createdAt?: Maybe<Scalars['String']>;
  transactionType: TransactionTypeEnum;
  emailContacts: Array<TransactionEmailContact>;
  cancelable?: Maybe<Scalars['Boolean']>;
  errorMessage?: Maybe<Scalars['String']>;
  /** Name of person who wrote the check */
  drawerName?: Maybe<Scalars['String']>;
  frontImage?: Maybe<Scalars['String']>;
  backImage?: Maybe<Scalars['String']>;
};

export type ChequeDepositCreateInput = {
  amount: Scalars['Float'];
  /** Sub account money is being deposited into */
  toAccountId: Scalars['ID'];
  /** Name of person who wrote the cheque */
  senderName: Scalars['String'];
  /** front image of cheque */
  frontImageBase64?: Maybe<Scalars['String']>;
  /** back image of cheque */
  backImageBase64?: Maybe<Scalars['String']>;
  device: DeviceInfoInput;
};

/** Mutation response from creating a cheque deposit */
export type ChequeDepositCreateResponse = MutationResponse & {
  __typename?: 'ChequeDepositCreateResponse';
  code: Scalars['String'];
  success: Scalars['Boolean'];
  message: Scalars['String'];
  deposit?: Maybe<ChequeDeposit>;
};

export type ConcreteMutationResponse = {
  __typename?: 'ConcreteMutationResponse';
  code: Scalars['String'];
  success: Scalars['Boolean'];
  message: Scalars['String'];
};

export type Coordinates = {
  __typename?: 'Coordinates';
  latitude?: Maybe<Scalars['Float']>;
  longitude?: Maybe<Scalars['Float']>;
};

export type CoordinatesInput = {
  latitude?: Maybe<Scalars['Float']>;
  longitude?: Maybe<Scalars['Float']>;
};

export enum CorporationType {
  Ccorp = 'CCORP',
  Llc = 'LLC',
  Scorp = 'SCORP',
  Partnership = 'PARTNERSHIP',
  Soleprop = 'SOLEPROP'
}

export type CounterpartyPaymentHistory = {
  __typename?: 'CounterpartyPaymentHistory';
  lifetimeSpend?: Maybe<Scalars['Float']>;
  yearToDateSpend?: Maybe<Scalars['Float']>;
  previousMonthSpend?: Maybe<Scalars['Float']>;
};

export type CounterpartyUnion = RecipientDomesticAch | RecipientBillPayment | RecipientDomesticWire | SubAccount | LinkedAccount;

export type Country = {
  __typename?: 'Country';
  name?: Maybe<Scalars['String']>;
  /** The ISO 3166-1 alpha-2 country code */
  countryCode?: Maybe<Scalars['String']>;
};

export enum CsvFormatEnum {
  Standard = 'STANDARD',
  Qbo = 'QBO',
  QboDebitCredit = 'QBO_DEBIT_CREDIT'
}

export enum CurrencyCodeEnum {
  Cad = 'CAD',
  Chf = 'CHF',
  Eur = 'EUR',
  Gbp = 'GBP',
  Usd = 'USD'
}

export type CurrencyPair = {
  __typename?: 'CurrencyPair';
  baseCurrency: CurrencyCodeEnum;
  counterCurrency: CurrencyCodeEnum;
  quote: Scalars['Float'];
};

export type DatetimeRangeInput = {
  /** datetime in ISO 8601 format */
  startDatetime?: Maybe<Scalars['String']>;
  /** datetime in ISO 8601 format */
  endDatetime?: Maybe<Scalars['String']>;
};

export type DebitCardDataInput = {
  nameOnCard: Scalars['String'];
  cardNumber: Scalars['String'];
  /** Three digit card verification code */
  cvv: Scalars['String'];
  /** 2-digit month (MM) */
  expiryMonth: Scalars['String'];
  /** 2-digit year (YY) */
  expiryYear: Scalars['String'];
  postalCode: Scalars['String'];
};

export type DebitFundingInfoInput = {
  amount: Scalars['Float'];
  debitCardData: DebitCardDataInput;
};

export type DeleteMutationResponse = MutationPayload & {
  __typename?: 'DeleteMutationResponse';
  success: Scalars['Boolean'];
  userErrors: Array<UserError>;
};

export type DeviceInfoInput = {
  os: OperatingSystemEnum;
  /** i.e) for ios 12.1.2 major version would be '12' */
  osMajorVersion: Scalars['String'];
};

export type DomesticAchCreateInput = {
  amount: Scalars['Float'];
  /** Sub account that the payment is coming out of */
  fromAccountId: Scalars['ID'];
  domesticACHRecipientId: Scalars['ID'];
  schedule?: Maybe<TransactionScheduleInput>;
  emailContacts?: Maybe<Array<EmailContactInput>>;
};

export type DomesticWire = ITransaction & {
  __typename?: 'DomesticWire';
  id: Scalars['ID'];
  description?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  location?: Maybe<Location>;
  /** @deprecated use description instead */
  rawDescription?: Maybe<Scalars['String']>;
  subAccount: SubAccount;
  business: Business;
  estimatedCompletionDate?: Maybe<Scalars['String']>;
  amount: Scalars['Float'];
  note?: Maybe<Scalars['String']>;
  status?: Maybe<TransactionStatusEnum>;
  createdAt?: Maybe<Scalars['String']>;
  transactionType: TransactionTypeEnum;
  emailContacts: Array<TransactionEmailContact>;
  cancelable?: Maybe<Scalars['Boolean']>;
  errorMessage?: Maybe<Scalars['String']>;
  recipient?: Maybe<RecipientDomesticWire>;
  fromAccount?: Maybe<SubAccount>;
};

export type DomesticWireCreateInput = {
  amount: Scalars['Float'];
  schedule?: Maybe<TransactionScheduleInput>;
  recipient: Scalars['ID'];
  /** Sub account that the payment is coming out of */
  sourceBankAccount: Scalars['ID'];
  emailContacts?: Maybe<Array<EmailContactInput>>;
};

export type DomesticWireMutationResponse = MutationResponse & {
  __typename?: 'DomesticWireMutationResponse';
  domesticWire?: Maybe<DomesticWire>;
  transactionSchedule?: Maybe<TransactionSchedule>;
  code: Scalars['String'];
  success: Scalars['Boolean'];
  message: Scalars['String'];
};


export type EmailContact = {
  __typename?: 'EmailContact';
  id: Scalars['ID'];
  name: Scalars['String'];
  email: Scalars['String'];
};

export type EmailContactCreateDataInput = {
  name: Scalars['String'];
  email: Scalars['String'];
};

export type EmailContactInput = {
  name: Scalars['String'];
  email: Scalars['String'];
};

export type EmailContactMutationResponse = MutationResponse & {
  __typename?: 'EmailContactMutationResponse';
  code: Scalars['String'];
  success: Scalars['Boolean'];
  message: Scalars['String'];
  emailContact?: Maybe<EmailContact>;
};

export type EmailContactUpdateDataInput = {
  name?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
};

export type EmailContactWhereInput = {
  businessId?: Maybe<Scalars['ID']>;
};

export enum ErrorCodeEnum {
  /** registerUser mutation error */
  AuthUserAlreadyExists = 'AUTH_USER_ALREADY_EXISTS',
  /** Not authorized to access resource */
  AuthUnauthorized = 'AUTH_UNAUTHORIZED',
  /** Resource not found */
  GeneralNotFound = 'GENERAL_NOT_FOUND',
  /** Inputs not valid */
  GeneralValidationFailed = 'GENERAL_VALIDATION_FAILED',
  /** Account does not have sufficient funds for transaction */
  TransactionsInsufficientFunds = 'TRANSACTIONS_INSUFFICIENT_FUNDS',
  /** Transaction schedule not valid */
  TransactionsInvalidSchedule = 'TRANSACTIONS_INVALID_SCHEDULE',
  TransactionsLimitExceeded = 'TRANSACTIONS_LIMIT_EXCEEDED',
  InvalidRoutingNumber = 'INVALID_ROUTING_NUMBER',
  BillPaymentRecipientCreateError = 'BILL_PAYMENT_RECIPIENT_CREATE_ERROR',
  InvalidBusinessBackgroundCheck = 'INVALID_BUSINESS_BACKGROUND_CHECK',
  SupportUnavailableTimeSlot = 'SUPPORT_UNAVAILABLE_TIME_SLOT',
  AvailableBalanceMinusPendingError = 'AVAILABLE_BALANCE_MINUS_PENDING_ERROR',
  BusinessBackgroundCheckPrequalNotPassed = 'BUSINESS_BACKGROUND_CHECK_PREQUAL_NOT_PASSED',
  InvalidInvitationToken = 'INVALID_INVITATION_TOKEN'
}

export enum FileTypeEnum {
  Csv = 'CSV',
  Pdf = 'PDF'
}

export type FinancialInstitutions = {
  __typename?: 'FinancialInstitutions';
  id: Scalars['ID'];
  name: Scalars['String'];
  institutionNumber: Scalars['String'];
};

export type FloatRangeField = {
  greaterThan?: Maybe<Scalars['Float']>;
  greaterThanOrEqual?: Maybe<Scalars['Float']>;
  lessThan?: Maybe<Scalars['Float']>;
  lessThanOrEqual?: Maybe<Scalars['Float']>;
};

export type FloatRangeInput = {
  minimum?: Maybe<Scalars['Float']>;
  maximum?: Maybe<Scalars['Float']>;
};

/** Input shape for range search fields of type float. */
export type FloatRangeSearchInput = {
  values?: Maybe<Array<Scalars['Float']>>;
  greaterThan?: Maybe<Scalars['Float']>;
  greaterThanOrEqual?: Maybe<Scalars['Float']>;
  lessThan?: Maybe<Scalars['Float']>;
  lessThanOrEqual?: Maybe<Scalars['Float']>;
  range?: Maybe<FloatRangeField>;
};

export enum FundingStatusEnum {
  NotFunded = 'NOT_FUNDED',
  HasFundingIntent = 'HAS_FUNDING_INTENT',
  Funded = 'FUNDED'
}

export type GenericTransaction = ITransaction & {
  __typename?: 'GenericTransaction';
  id: Scalars['ID'];
  description?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  location?: Maybe<Location>;
  /** @deprecated use description instead */
  rawDescription?: Maybe<Scalars['String']>;
  subAccount: SubAccount;
  business: Business;
  estimatedCompletionDate?: Maybe<Scalars['String']>;
  amount: Scalars['Float'];
  note?: Maybe<Scalars['String']>;
  status?: Maybe<TransactionStatusEnum>;
  createdAt?: Maybe<Scalars['String']>;
  transactionType: TransactionTypeEnum;
  emailContacts: Array<TransactionEmailContact>;
  cancelable?: Maybe<Scalars['Boolean']>;
  errorMessage?: Maybe<Scalars['String']>;
  merchant?: Maybe<Merchant>;
};




export enum GroupTypeEnum {
  Owner = 'OWNER'
}

export type HomeAnnouncement = {
  __typename?: 'HomeAnnouncement';
  id: Scalars['ID'];
  title: Scalars['String'];
  color: Scalars['String'];
  body: Scalars['String'];
  cta: HomeAnnouncementCta;
  featureFlag?: Maybe<Scalars['String']>;
};

export type HomeAnnouncementCta = {
  __typename?: 'HomeAnnouncementCTA';
  text: Scalars['String'];
  url: Scalars['String'];
};

export type IAccount = {
  id: Scalars['ID'];
  type: AccountTypeEnum;
  name: Scalars['String'];
  balance: Scalars['Float'];
  thumbnail?: Maybe<Scalars['String']>;
};

export type IncentiveCampaignMutationAcceptResponse = MutationResponse & {
  __typename?: 'IncentiveCampaignMutationAcceptResponse';
  code: Scalars['String'];
  success: Scalars['Boolean'];
  message: Scalars['String'];
};

export type InsightChartEntry = {
  __typename?: 'InsightChartEntry';
  date: Scalars['String'];
  value: Scalars['Float'];
};

export type InsightsChart = {
  __typename?: 'InsightsChart';
  entries: Array<InsightChartEntry>;
};

export type InsightsInterval = {
  startDate?: Maybe<Scalars['String']>;
  endDate?: Maybe<Scalars['String']>;
};

export enum InsightTypeEnum {
  Balance = 'BALANCE',
  Revenue = 'REVENUE',
  Payments = 'PAYMENTS'
}

export enum IntegrationCategoryEnum {
  Accounting = 'ACCOUNTING',
  Expenses = 'EXPENSES',
  ECommerce = 'E_COMMERCE',
  PaymentProcessor = 'PAYMENT_PROCESSOR'
}

export enum IntegrationStatusEnum {
  Inactive = 'INACTIVE',
  Active = 'ACTIVE',
  Pending = 'PENDING',
  Error = 'ERROR'
}

export type Invoice = {
  __typename?: 'Invoice';
  id: Scalars['ID'];
  invoiceNumber?: Maybe<Scalars['Int']>;
  /** datetime string */
  createdAt: Scalars['String'];
  /** date in format YYYY-MM-DD */
  dueDate?: Maybe<Scalars['String']>;
  amountDue: Scalars['Float'];
  status: InvoiceStatusEnum;
  recipient?: Maybe<InvoiceRecipient>;
  lineItems: Array<InvoiceLineItem>;
  taxes: Array<InvoiceTax>;
  /** datetime in ISO 8601 format */
  fullPaymentDate?: Maybe<Scalars['String']>;
  lastSentAt?: Maybe<Scalars['String']>;
  term?: Maybe<InvoiceTermEnum>;
  payments: Array<InvoicePayment>;
};

export type InvoiceData = {
  invoiceNumber: Scalars['Int'];
  /** date in format YYYY-MM-DD */
  dueDate: Scalars['String'];
  recipientId: Scalars['ID'];
  lineItems: Array<InvoiceLineItemData>;
  taxes?: Maybe<Array<InvoiceTaxData>>;
  term?: Maybe<InvoiceTermEnum>;
};

export type InvoiceDeleteMutationResponse = MutationResponse & {
  __typename?: 'InvoiceDeleteMutationResponse';
  code: Scalars['String'];
  success: Scalars['Boolean'];
  message: Scalars['String'];
};

export type InvoiceDraftSaveMutationResponse = MutationResponse & {
  __typename?: 'InvoiceDraftSaveMutationResponse';
  code: Scalars['String'];
  success: Scalars['Boolean'];
  message: Scalars['String'];
  invoice?: Maybe<Invoice>;
};

export type InvoiceLineItem = {
  __typename?: 'InvoiceLineItem';
  id: Scalars['String'];
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  unitPrice: Scalars['Float'];
  quantity: Scalars['Int'];
};

export type InvoiceLineItemData = {
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  unitPrice: Scalars['Float'];
  quantity: Scalars['Int'];
};

export type InvoiceListFilters = {
  status?: Maybe<InvoiceStatusEnum>;
};

export type InvoiceMarkAsPaidMutationResponse = MutationResponse & {
  __typename?: 'InvoiceMarkAsPaidMutationResponse';
  code: Scalars['String'];
  success: Scalars['Boolean'];
  message: Scalars['String'];
  invoice?: Maybe<Invoice>;
};

export type InvoicePayment = {
  __typename?: 'InvoicePayment';
  id: Scalars['ID'];
  invoiceId: Scalars['String'];
  createdAt: Scalars['String'];
  amount: Scalars['Float'];
};

export enum InvoicePaymentOptionsEnum {
  Ach = 'ACH',
  DomesticWire = 'DOMESTIC_WIRE',
  ChequeDeposit = 'CHEQUE_DEPOSIT'
}

export type InvoicePreviewGenerateByIdMutationResponse = MutationResponse & {
  __typename?: 'InvoicePreviewGenerateByIdMutationResponse';
  code: Scalars['String'];
  success: Scalars['Boolean'];
  message: Scalars['String'];
  previewURL?: Maybe<Scalars['String']>;
};

export type InvoicePreviewGenerateMutationResponse = MutationResponse & {
  __typename?: 'InvoicePreviewGenerateMutationResponse';
  code: Scalars['String'];
  success: Scalars['Boolean'];
  message: Scalars['String'];
  previewURL?: Maybe<Scalars['String']>;
};

export type InvoiceRecipient = {
  __typename?: 'InvoiceRecipient';
  id: Scalars['ID'];
  name: Scalars['String'];
  email: Scalars['String'];
  phone?: Maybe<Scalars['String']>;
  address?: Maybe<Location>;
};

export type InvoiceRecipientCreateData = {
  name: Scalars['String'];
  email: Scalars['String'];
  phone?: Maybe<Scalars['String']>;
  address?: Maybe<LocationInput>;
};

export type InvoiceRecipientCreateMutationResponse = MutationResponse & {
  __typename?: 'InvoiceRecipientCreateMutationResponse';
  code: Scalars['String'];
  success: Scalars['Boolean'];
  message: Scalars['String'];
  invoiceRecipient?: Maybe<InvoiceRecipient>;
};

export type InvoiceRecipientDeleteMutationResponse = MutationResponse & {
  __typename?: 'InvoiceRecipientDeleteMutationResponse';
  code: Scalars['String'];
  success: Scalars['Boolean'];
  message: Scalars['String'];
};

export type InvoiceRecipientUpdateData = {
  name?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  address?: Maybe<LocationInput>;
};

export type InvoiceRecipientUpdateMutationResponse = MutationResponse & {
  __typename?: 'InvoiceRecipientUpdateMutationResponse';
  code: Scalars['String'];
  success: Scalars['Boolean'];
  message: Scalars['String'];
  invoiceRecipient?: Maybe<InvoiceRecipient>;
};

export type InvoiceRecordPaymentMutationResponse = MutationResponse & {
  __typename?: 'invoiceRecordPaymentMutationResponse';
  code: Scalars['String'];
  success: Scalars['Boolean'];
  message: Scalars['String'];
};

export type InvoiceResendMutationResponse = MutationResponse & {
  __typename?: 'InvoiceResendMutationResponse';
  code: Scalars['String'];
  success: Scalars['Boolean'];
  message: Scalars['String'];
  invoice?: Maybe<Invoice>;
};

export type InvoiceSendCopyMutationResponse = MutationResponse & {
  __typename?: 'InvoiceSendCopyMutationResponse';
  code: Scalars['String'];
  success: Scalars['Boolean'];
  message: Scalars['String'];
  invoice?: Maybe<Invoice>;
};

export type InvoiceSendMutationResponse = MutationResponse & {
  __typename?: 'InvoiceSendMutationResponse';
  code: Scalars['String'];
  success: Scalars['Boolean'];
  message: Scalars['String'];
  invoice?: Maybe<Invoice>;
};

export type InvoiceSettings = {
  __typename?: 'InvoiceSettings';
  paymentPreferences: Array<InvoicePaymentOptionsEnum>;
};

export type InvoiceSettingsData = {
  paymentPreferences?: Maybe<Array<InvoicePaymentOptionsEnum>>;
};

export type InvoiceSettingsSetOrUpdateMutationResponse = MutationResponse & {
  __typename?: 'InvoiceSettingsSetOrUpdateMutationResponse';
  code: Scalars['String'];
  success: Scalars['Boolean'];
  message: Scalars['String'];
  invoiceSettings?: Maybe<InvoiceSettings>;
};

export type InvoicesPaginatedResponse = {
  __typename?: 'InvoicesPaginatedResponse';
  invoices: Array<Invoice>;
  nextPage?: Maybe<Scalars['Int']>;
};

export enum InvoiceStatusEnum {
  Paid = 'PAID',
  Sent = 'SENT',
  Draft = 'DRAFT',
  PartiallyPaid = 'PARTIALLY_PAID'
}

export type InvoiceTax = {
  __typename?: 'InvoiceTax';
  name: Scalars['String'];
  percent: Scalars['Float'];
};

export type InvoiceTaxData = {
  name: Scalars['String'];
  percent: Scalars['Float'];
};

export enum InvoiceTermEnum {
  UponReceipt = 'UPON_RECEIPT',
  FifteenDays = 'FIFTEEN_DAYS',
  ThirtyDays = 'THIRTY_DAYS'
}

export type ITransaction = {
  id: Scalars['ID'];
  description?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  location?: Maybe<Location>;
  /** @deprecated use description instead */
  rawDescription?: Maybe<Scalars['String']>;
  business: Business;
  subAccount: SubAccount;
  estimatedCompletionDate?: Maybe<Scalars['String']>;
  amount: Scalars['Float'];
  note?: Maybe<Scalars['String']>;
  status?: Maybe<TransactionStatusEnum>;
  createdAt?: Maybe<Scalars['String']>;
  transactionType: TransactionTypeEnum;
  emailContacts: Array<TransactionEmailContact>;
  cancelable?: Maybe<Scalars['Boolean']>;
  errorMessage?: Maybe<Scalars['String']>;
};

export type LinkedAccount = IAccount & {
  __typename?: 'LinkedAccount';
  id: Scalars['ID'];
  type: AccountTypeEnum;
  name: Scalars['String'];
  balance: Scalars['Float'];
  thumbnail?: Maybe<Scalars['String']>;
  hasPlaidItemError?: Maybe<Scalars['Boolean']>;
  accountNumLast4?: Maybe<Scalars['String']>;
  institutionName?: Maybe<Scalars['String']>;
};

export type LinkedAccountCreateResponse = MutationResponse & {
  __typename?: 'LinkedAccountCreateResponse';
  code: Scalars['String'];
  success: Scalars['Boolean'];
  message: Scalars['String'];
  linkedAccount?: Maybe<LinkedAccount>;
};

export type LinkedAccountDeleteResponse = MutationResponse & {
  __typename?: 'LinkedAccountDeleteResponse';
  code: Scalars['String'];
  success: Scalars['Boolean'];
  message: Scalars['String'];
};

export type Location = {
  __typename?: 'Location';
  streetAddressLine1?: Maybe<Scalars['String']>;
  streetAddressLine2?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  provinceState?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  coordinates?: Maybe<Coordinates>;
};

/** We need to use this input when creating or updating locations. */
export type LocationInput = {
  streetAddressLine1?: Maybe<Scalars['String']>;
  streetAddressLine2?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  provinceState?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
};

export type LocationStrictInput = {
  streetAddressLine1: Scalars['String'];
  streetAddressLine2?: Maybe<Scalars['String']>;
  city: Scalars['String'];
  provinceState: Scalars['String'];
  country: Scalars['String'];
  postalCode: Scalars['String'];
};

/** The logo URI for a given merchant. */
export type LogoSearchTwitterResponse = {
  __typename?: 'LogoSearchTwitterResponse';
  logo?: Maybe<Scalars['String']>;
};

export enum MailingAddressLocationEnum {
  Business = 'BUSINESS',
  Personal = 'PERSONAL'
}

/** Data to be used for displaying a map */
export type MapDisplay = {
  __typename?: 'MapDisplay';
  latitude?: Maybe<Scalars['Float']>;
  longitude?: Maybe<Scalars['Float']>;
  address?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  provinceState?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
};

export type Merchant = {
  __typename?: 'Merchant';
  id: Scalars['ID'];
  business?: Maybe<Business>;
  name: Scalars['String'];
  logo?: Maybe<Scalars['String']>;
  category: Category;
  mapDisplay?: Maybe<MapDisplay>;
  paymentHistory?: Maybe<CounterpartyPaymentHistory>;
};

/** Merchant suggestion result, used for merchant search / autocomplete. */
export type MerchantSuggestion = {
  __typename?: 'MerchantSuggestion';
  merchant: Scalars['String'];
};

/** Mutation response from creating a domestic ACH */
export type MmDomesticAchCreateResponse = MutationResponse & {
  __typename?: 'MMDomesticACHCreateResponse';
  code: Scalars['String'];
  success: Scalars['Boolean'];
  message: Scalars['String'];
  domesticACH?: Maybe<Ach>;
  transactionSchedule?: Maybe<TransactionSchedule>;
};

export type MmTransferBetweenAccountsInput = {
  businessId?: Maybe<Scalars['ID']>;
  amount: Scalars['Float'];
  toAccountId: Scalars['ID'];
  fromAccountId: Scalars['ID'];
  /** schedule one transfer or a repeated cycle of transfers */
  schedule?: Maybe<TransactionScheduleInput>;
  emailContacts?: Maybe<Array<EmailContactInput>>;
};

export type MmTransferBetweenAccountsResponse = MutationResponse & {
  __typename?: 'MMTransferBetweenAccountsResponse';
  code: Scalars['String'];
  success: Scalars['Boolean'];
  message: Scalars['String'];
  transaction?: Maybe<SubAccountTransfer>;
  transactionSchedule?: Maybe<TransactionSchedule>;
};

export type Mutation = {
  __typename?: 'Mutation';
  cardOrder: CardOrderMutationResponse;
  cardFreeze: CardFreezeMutationResponse;
  cardUnfreeze: CardFreezeMutationResponse;
  transactionsBillPaymentCreate?: Maybe<BillPaymentMutationResponse>;
  transactionsDomesticWireCreate?: Maybe<DomesticWireMutationResponse>;
  transactionNoteUpdate?: Maybe<ITransaction>;
  transactionsChequeDepositCreate?: Maybe<ChequeDepositCreateResponse>;
  mmTransferBetweenAccounts?: Maybe<MmTransferBetweenAccountsResponse>;
  mmDomesticACHCreate?: Maybe<MmDomesticAchCreateResponse>;
  transactionsACHCancel?: Maybe<AchCancelMutationResponse>;
  transactionsAccountTransferCreate?: Maybe<AccountTransferMutationResponse>;
  registerUser?: Maybe<RegisterUserResponse>;
  registerUserAndTermsAccept?: Maybe<RegisterUserResponse>;
  registerUserAcceptShareEarnAndTermsAccept?: Maybe<RegisterUserResponse>;
  /** ** deprecated ** use businessBackgroundCheckSubmit instead */
  businessBackgroundCheck?: Maybe<BusinessBackgroundCheckMutationResponse>;
  businessBackgroundCheckSubmit?: Maybe<BusinessBackgroundCheckSubmitMutationResponse>;
  verifyIdentityUploadDocuments?: Maybe<VerifyIdentityUploadDocumentsMutationResponse>;
  businessInvitationAccept?: Maybe<RegisterUserResponse>;
  businessInvitationAndTermsAccept?: Maybe<RegisterUserResponse>;
  emailContactCreate?: Maybe<EmailContactMutationResponse>;
  emailContactUpdate?: Maybe<EmailContactMutationResponse>;
  emailContactDelete?: Maybe<DeleteMutationResponse>;
  requestPasswordReset?: Maybe<ConcreteMutationResponse>;
  resetMyPassword?: Maybe<ConcreteMutationResponse>;
  /** userEmail must be same email that the user signed up with */
  resendVerificationEmail?: Maybe<ConcreteMutationResponse>;
  businessAccessTokenGenerate?: Maybe<BusinessAccessTokenMutationResponse>;
  userUpdate?: Maybe<UserMutationResponse>;
  notificationToggle?: Maybe<NotificationToggleMutationResponse>;
  notificationsEnableAll?: Maybe<NotificationsEnableMutationResponse>;
  notificationsEnable?: Maybe<NotificationsEnableMutationResponse>;
  notificationsDisableAll?: Maybe<NotificationsDisableMutationResponse>;
  notificationsDisable?: Maybe<NotificationsDisableMutationResponse>;
  userAcceptTermsAndConditions?: Maybe<UserAcceptTermsAndConditionsMutationResponse>;
  recipientDomesticACHCreate?: Maybe<RecipientDomesticAchCreateResponse>;
  recipientDomesticACHUpdate?: Maybe<RecipientDomesticAchUpdateResponse>;
  recipientBillPaymentCreate?: Maybe<RecipientBillPaymentCreateMutationResponse>;
  recipientBillPaymentACHUpdate?: Maybe<RecipientBillPaymentAchUpdateMutationResponse>;
  recipientBillPaymentChequeUpdate?: Maybe<RecipientBillPaymentChequeUpdateMutationResponse>;
  recipientInternationalWireCreate?: Maybe<RecipientInternationalWireMutationResponse>;
  recipientInternationalWireUpdate?: Maybe<RecipientInternationalWireMutationResponse>;
  recipientsDomesticWireCreate?: Maybe<RecipientDomesticWireMutationResponse>;
  recipientsDomesticWireUpdate?: Maybe<RecipientDomesticWireMutationResponse>;
  /** This mutation is used to delete any recipient. */
  recipientDelete?: Maybe<DeleteMutationResponse>;
  subAccountCreate?: Maybe<SubAccountCreateResponse>;
  subAccountUpdate?: Maybe<SubAccountUpdateResponse>;
  subAccountDelete?: Maybe<SubAccountDeleteResponse>;
  linkedAccountCreate?: Maybe<LinkedAccountCreateResponse>;
  linkedAccountDelete?: Maybe<LinkedAccountDeleteResponse>;
  plaidPublicTokenGenerate?: Maybe<PlaidPublicTokenGenerateMutationResponse>;
  subAccountRulePercentCreate?: Maybe<SubAccountRulePercentCreateMutationResponse>;
  subAccountRulePercentUpdate?: Maybe<SubAccountRulePercentUpdateMutationResponse>;
  subAccountRulePercentDelete?: Maybe<SubAccountRulePercentDeleteMutationResponse>;
  subAccountsReconcile?: Maybe<SubAccountsReconcileResponse>;
  businessUpdate?: Maybe<Business>;
  /** Sets Business.fundingSourceAdded to true */
  businessFundingSourceAdd?: Maybe<BusinessFundingSourceAddMutationResponse>;
  supportTicketUpdate?: Maybe<SupportTicketMutationResponse>;
  supportTicketCreate: SupportTicketCreateMutationResponse;
  supportPhoneCallBookingCreate?: Maybe<SupportPhoneCallBookingMutationResponse>;
  transactionSchedulePause?: Maybe<TransactionSchedulePauseResponse>;
  transactionScheduleRestart?: Maybe<TransactionScheduleRestartResponse>;
  transactionScheduleDelete?: Maybe<ConcreteMutationResponse>;
  transactionScheduleUpdate?: Maybe<TransactionScheduleUpdateResponse>;
  xeroIntegrationRequest?: Maybe<XeroIntegrationRequestResponse>;
  xeroIntegrationCreate?: Maybe<XeroIntegrationCreateResponse>;
  xeroFeedConnectionCreate?: Maybe<XeroFeedConnectionCreateResponse>;
  xeroFeedConnectionDelete?: Maybe<XeroFeedConnectionDeleteResponse>;
  invoiceSettingsSetOrUpdate?: Maybe<InvoiceSettingsSetOrUpdateMutationResponse>;
  invoiceSend?: Maybe<InvoiceSendMutationResponse>;
  invoiceDraftSave?: Maybe<InvoiceDraftSaveMutationResponse>;
  invoicePreviewGenerate?: Maybe<InvoicePreviewGenerateMutationResponse>;
  invoicePreviewGenerateById?: Maybe<InvoicePreviewGenerateByIdMutationResponse>;
  invoiceDelete?: Maybe<InvoiceDeleteMutationResponse>;
  invoiceRecipientCreate?: Maybe<InvoiceRecipientCreateMutationResponse>;
  invoiceRecipientUpdate?: Maybe<InvoiceRecipientUpdateMutationResponse>;
  invoiceRecipientDelete?: Maybe<InvoiceRecipientDeleteMutationResponse>;
  invoiceMarkAsPaid?: Maybe<InvoiceMarkAsPaidMutationResponse>;
  invoiceSendCopyToMyself?: Maybe<InvoiceSendCopyMutationResponse>;
  invoiceResend?: Maybe<InvoiceResendMutationResponse>;
  invoiceRecordPayment?: Maybe<InvoiceRecordPaymentMutationResponse>;
  announcementInteract?: Maybe<AnnouncementInteractResponse>;
  homeAnnouncementInteract?: Maybe<AnnouncementInteractResponse>;
  ownerInvitationSend?: Maybe<OwnerInvitationSendMutationResponse>;
  transferWiseTemporaryQuoteCreate?: Maybe<TransferWiseTemporaryQuoteCreateMutationResponse>;
  transferWiseQuoteCreate?: Maybe<TransferWiseQuoteCreateMutationResponse>;
  transferWiseQuoteUpdate?: Maybe<TransferWiseQuoteUpdateMutationResponse>;
  transferWiseRecipientRequirementsDynamicForm?: Maybe<TransferWiseRecipientRequirementsDynamicFormMutationResponse>;
  transferWiseRecipientCreate?: Maybe<TransferWiseRecipientCreateMutationResponse>;
  transferWiseRecipientDelete?: Maybe<TransferWiseRecipientDeleteMutationResponse>;
  transferWiseTransferRequirementsDynamicForm?: Maybe<TransferWiseTransferRequirementsDynamicFormMutationResponse>;
  transferWiseTransferCreate?: Maybe<TransferWiseTransferCreateMutationResponse>;
  incentiveCampaignAccept?: Maybe<IncentiveCampaignMutationAcceptResponse>;
  shareAndEarnRedeemedOfferMarkAsSeen?: Maybe<ShareAndEarnRedeemedOfferMarkAsSeenMutationResponse>;
};


export type MutationCardOrderArgs = {
  businessId: Scalars['ID'];
};


export type MutationCardFreezeArgs = {
  cardId: Scalars['ID'];
};


export type MutationCardUnfreezeArgs = {
  cardId: Scalars['ID'];
};


export type MutationTransactionsBillPaymentCreateArgs = {
  businessId: Scalars['ID'];
  data: BillPaymentCreateInput;
};


export type MutationTransactionsDomesticWireCreateArgs = {
  businessId: Scalars['ID'];
  data: DomesticWireCreateInput;
};


export type MutationTransactionNoteUpdateArgs = {
  organizationId: Scalars['ID'];
  transactionId: Scalars['ID'];
  note: Scalars['String'];
};


export type MutationTransactionsChequeDepositCreateArgs = {
  businessId: Scalars['ID'];
  data?: Maybe<ChequeDepositCreateInput>;
};


export type MutationMmTransferBetweenAccountsArgs = {
  businessId: Scalars['ID'];
  input: MmTransferBetweenAccountsInput;
};


export type MutationMmDomesticAchCreateArgs = {
  businessId: Scalars['ID'];
  data?: Maybe<DomesticAchCreateInput>;
};


export type MutationTransactionsAchCancelArgs = {
  businessId: Scalars['ID'];
  transactionId: Scalars['ID'];
};


export type MutationTransactionsAccountTransferCreateArgs = {
  businessId: Scalars['ID'];
  data: TransactionsAccountTransferInput;
};


export type MutationRegisterUserArgs = {
  input: RegisterUserInput;
};


export type MutationRegisterUserAndTermsAcceptArgs = {
  input: RegisterUserInput;
};


export type MutationRegisterUserAcceptShareEarnAndTermsAcceptArgs = {
  input: RegisterUserInput;
  advocateId: Scalars['ID'];
  campaignId: Scalars['ID'];
};


export type MutationBusinessBackgroundCheckArgs = {
  business: BackgroundCheckBusinessInfoInput;
  owners: Array<BusinessBackgroundCheckOwnerInfoInput>;
  CJEVENT?: Maybe<Scalars['String']>;
  affiseClickId?: Maybe<Scalars['String']>;
  adjustAttributionInfo?: Maybe<AdjustAttributionInfoInput>;
  debitFundingInfo?: Maybe<DebitFundingInfoInput>;
};


export type MutationBusinessBackgroundCheckSubmitArgs = {
  business: BackgroundCheckBusinessInfoInput;
  owners: Array<BusinessBackgroundCheckOwnerInfoInput>;
  CJEVENT?: Maybe<Scalars['String']>;
  adjustAttributionInfo?: Maybe<AdjustAttributionInfoInput>;
  debitFundingInfo?: Maybe<DebitFundingInfoInput>;
};


export type MutationVerifyIdentityUploadDocumentsArgs = {
  frontImageBase64: Scalars['String'];
  backImageBase64: Scalars['String'];
};


export type MutationBusinessInvitationAcceptArgs = {
  input: RegisterInvitedOwnerInput;
};


export type MutationBusinessInvitationAndTermsAcceptArgs = {
  input: RegisterInvitedOwnerInput;
};


export type MutationEmailContactCreateArgs = {
  businessId: Scalars['ID'];
  data?: Maybe<EmailContactCreateDataInput>;
};


export type MutationEmailContactUpdateArgs = {
  businessId: Scalars['ID'];
  id: Scalars['ID'];
  data?: Maybe<EmailContactUpdateDataInput>;
};


export type MutationEmailContactDeleteArgs = {
  businessId: Scalars['ID'];
  id: Scalars['ID'];
};


export type MutationRequestPasswordResetArgs = {
  email?: Maybe<Scalars['String']>;
};


export type MutationResetMyPasswordArgs = {
  newPassword: Scalars['String'];
};


export type MutationResendVerificationEmailArgs = {
  userEmail: Scalars['String'];
};


export type MutationBusinessAccessTokenGenerateArgs = {
  businessId: Scalars['ID'];
  token: Scalars['String'];
  tokenType: AuthTokenTypeEnum;
  clientId: Scalars['String'];
};


export type MutationUserUpdateArgs = {
  userId: Scalars['ID'];
  data?: Maybe<UserUpdateData>;
};


export type MutationNotificationToggleArgs = {
  notificationType: NotificationTypeEnum;
  enabled: Scalars['Boolean'];
};


export type MutationNotificationsEnableAllArgs = {
  deviceInfo: NotificationsDeviceInfoInput;
};


export type MutationNotificationsEnableArgs = {
  deviceInfo: NotificationsDeviceInfoInput;
};


export type MutationRecipientDomesticAchCreateArgs = {
  businessId: Scalars['ID'];
  data: RecipientDomesticAchCreateDataInput;
};


export type MutationRecipientDomesticAchUpdateArgs = {
  businessId: Scalars['ID'];
  recipientId: Scalars['ID'];
  data: RecipientDomesticAchUpdateDataInput;
};


export type MutationRecipientBillPaymentCreateArgs = {
  businessId: Scalars['ID'];
  data: RecipientBillPaymentCreateDataInput;
};


export type MutationRecipientBillPaymentAchUpdateArgs = {
  businessId: Scalars['ID'];
  recipientId: Scalars['ID'];
  data: RecipientBillPaymentAchUpdateDataInput;
};


export type MutationRecipientBillPaymentChequeUpdateArgs = {
  businessId: Scalars['ID'];
  recipientId: Scalars['ID'];
  data: RecipientBillPaymentChequeUpdateDataInput;
};


export type MutationRecipientInternationalWireCreateArgs = {
  businessId: Scalars['ID'];
  data: RecipientInternationalWireCreateDataInput;
};


export type MutationRecipientInternationalWireUpdateArgs = {
  businessId: Scalars['ID'];
  recipientId: Scalars['ID'];
  data: RecipientInternationalWireUpdateDataInput;
};


export type MutationRecipientsDomesticWireCreateArgs = {
  businessId: Scalars['ID'];
  data: RecipientDomesticWireCreateDataInput;
};


export type MutationRecipientsDomesticWireUpdateArgs = {
  businessId: Scalars['ID'];
  recipientId: Scalars['ID'];
  data: RecipientDomesticWireUpdateDataInput;
};


export type MutationRecipientDeleteArgs = {
  businessId: Scalars['ID'];
  recipientId: Scalars['ID'];
  recipientType: RecipientTypeEnum;
};


export type MutationSubAccountCreateArgs = {
  name: Scalars['String'];
  businessId: Scalars['ID'];
};


export type MutationSubAccountUpdateArgs = {
  businessId: Scalars['ID'];
  subAccountId: Scalars['ID'];
  data: SubAccountUpdateDataInput;
};


export type MutationSubAccountDeleteArgs = {
  businessId: Scalars['ID'];
  subAccountId: Scalars['ID'];
};


export type MutationLinkedAccountCreateArgs = {
  businessId: Scalars['ID'];
  plaidPublicToken: Scalars['String'];
  plaidAccountId?: Maybe<Scalars['ID']>;
};


export type MutationLinkedAccountDeleteArgs = {
  businessId: Scalars['ID'];
  linkedAccountId: Scalars['ID'];
};


export type MutationPlaidPublicTokenGenerateArgs = {
  businessId: Scalars['ID'];
  linkedAccountId: Scalars['ID'];
};


export type MutationSubAccountRulePercentCreateArgs = {
  businessId: Scalars['ID'];
  subAccountId: Scalars['ID'];
  data: SubAccountRulePercentCreateData;
};


export type MutationSubAccountRulePercentUpdateArgs = {
  businessId: Scalars['ID'];
  subAccountId: Scalars['ID'];
  ruleId: Scalars['ID'];
  data: SubAccountRulePercentUpdateData;
};


export type MutationSubAccountRulePercentDeleteArgs = {
  businessId: Scalars['ID'];
  ruleId: Scalars['ID'];
};


export type MutationSubAccountsReconcileArgs = {
  businessId: Scalars['ID'];
  data: Array<SubAccountReconcileDataInput>;
};


export type MutationBusinessUpdateArgs = {
  businessId: Scalars['ID'];
  data: BusinessUpdateData;
};


export type MutationBusinessFundingSourceAddArgs = {
  businessId: Scalars['ID'];
};


export type MutationSupportTicketUpdateArgs = {
  ticketId: Scalars['ID'];
  data: SupportTicketUpdateDataInput;
};


export type MutationSupportTicketCreateArgs = {
  data: SupportTicketCreateInput;
};


export type MutationSupportPhoneCallBookingCreateArgs = {
  data: SupportPhoneCallCreateInput;
};


export type MutationTransactionSchedulePauseArgs = {
  businessId: Scalars['ID'];
  transactionScheduleId: Scalars['ID'];
};


export type MutationTransactionScheduleRestartArgs = {
  businessId: Scalars['ID'];
  transactionScheduleId: Scalars['ID'];
};


export type MutationTransactionScheduleDeleteArgs = {
  businessId: Scalars['ID'];
  transactionScheduleId: Scalars['ID'];
};


export type MutationTransactionScheduleUpdateArgs = {
  businessId: Scalars['ID'];
  transactionScheduleId: Scalars['ID'];
  data: TransactionScheduleUpdateDataInput;
};


export type MutationXeroIntegrationRequestArgs = {
  businessId: Scalars['ID'];
};


export type MutationXeroIntegrationCreateArgs = {
  businessId: Scalars['ID'];
  xeroOrganizationId: Scalars['ID'];
  oauthVerifier: Scalars['String'];
};


export type MutationXeroFeedConnectionCreateArgs = {
  integrationId: Scalars['ID'];
  account: XeroFeedConnectionAccountData;
};


export type MutationXeroFeedConnectionDeleteArgs = {
  integrationId: Scalars['ID'];
  feedConnectionId: Scalars['ID'];
};


export type MutationInvoiceSettingsSetOrUpdateArgs = {
  data: InvoiceSettingsData;
};


export type MutationInvoiceSendArgs = {
  data: InvoiceData;
};


export type MutationInvoiceDraftSaveArgs = {
  data: InvoiceData;
};


export type MutationInvoicePreviewGenerateArgs = {
  data: InvoiceData;
};


export type MutationInvoicePreviewGenerateByIdArgs = {
  id: Scalars['ID'];
};


export type MutationInvoiceDeleteArgs = {
  id: Scalars['ID'];
};


export type MutationInvoiceRecipientCreateArgs = {
  data: InvoiceRecipientCreateData;
};


export type MutationInvoiceRecipientUpdateArgs = {
  id: Scalars['ID'];
  data: InvoiceRecipientUpdateData;
};


export type MutationInvoiceRecipientDeleteArgs = {
  id: Scalars['ID'];
};


export type MutationInvoiceMarkAsPaidArgs = {
  id: Scalars['ID'];
};


export type MutationInvoiceSendCopyToMyselfArgs = {
  id: Scalars['ID'];
};


export type MutationInvoiceResendArgs = {
  id: Scalars['ID'];
};


export type MutationInvoiceRecordPaymentArgs = {
  id: Scalars['ID'];
  amount: Scalars['Float'];
};


export type MutationAnnouncementInteractArgs = {
  type: AnnouncementTypeEnum;
  actionEvent: AnnouncementActionEventEnum;
};


export type MutationHomeAnnouncementInteractArgs = {
  announcementId: Scalars['ID'];
  actionEvent: AnnouncementActionEventEnum;
};


export type MutationOwnerInvitationSendArgs = {
  uboEmail: Scalars['String'];
};


export type MutationTransferWiseTemporaryQuoteCreateArgs = {
  data: TransferWiseTemporaryQuoteCreateData;
};


export type MutationTransferWiseQuoteCreateArgs = {
  data: TransferWiseQuoteCreateData;
};


export type MutationTransferWiseQuoteUpdateArgs = {
  data: TransferWiseQuoteUpdateData;
};


export type MutationTransferWiseRecipientRequirementsDynamicFormArgs = {
  data: TransferWiseRecipientRequirementsDynamicFormData;
};


export type MutationTransferWiseRecipientCreateArgs = {
  data: TransferWiseRecipientCreateData;
};


export type MutationTransferWiseRecipientDeleteArgs = {
  recipientId: Scalars['ID'];
};


export type MutationTransferWiseTransferRequirementsDynamicFormArgs = {
  data: TransferWiseTransferRequirementsDynamicFormData;
};


export type MutationTransferWiseTransferCreateArgs = {
  data: TransferWiseTransferCreateData;
};


export type MutationIncentiveCampaignAcceptArgs = {
  campaignId: Scalars['ID'];
};


export type MutationShareAndEarnRedeemedOfferMarkAsSeenArgs = {
  offerId: Scalars['ID'];
};

export type MutationPayload = {
  userErrors: Array<UserError>;
};

export type MutationResponse = {
  code: Scalars['String'];
  success: Scalars['Boolean'];
  message: Scalars['String'];
};

export type NaicsSector = {
  __typename?: 'NaicsSector';
  code: Scalars['Int'];
  description: Scalars['String'];
};

export type Notification = {
  __typename?: 'Notification';
  id: Scalars['ID'];
  notificationType?: Maybe<NotificationTypeEnum>;
  enabled?: Maybe<Scalars['Boolean']>;
};

export type NotificationsDeviceInfoInput = {
  os: OperatingSystemEnum;
  deviceToken: Scalars['String'];
};

export type NotificationsDisableMutationResponse = MutationResponse & {
  __typename?: 'NotificationsDisableMutationResponse';
  success: Scalars['Boolean'];
  code: Scalars['String'];
  message: Scalars['String'];
  notificationSettings?: Maybe<NotificationSettings>;
};

export type NotificationsEnableMutationResponse = MutationResponse & {
  __typename?: 'NotificationsEnableMutationResponse';
  success: Scalars['Boolean'];
  code: Scalars['String'];
  message: Scalars['String'];
  notificationSettings?: Maybe<NotificationSettings>;
};

export type NotificationSettings = {
  __typename?: 'NotificationSettings';
  notificationsEnabled?: Maybe<Scalars['Boolean']>;
  userNotifications: Array<Notification>;
  transactionNotifications: Array<Notification>;
  user?: Maybe<User>;
};

export type NotificationToggleMutationResponse = MutationResponse & {
  __typename?: 'NotificationToggleMutationResponse';
  success: Scalars['Boolean'];
  code: Scalars['String'];
  message: Scalars['String'];
  notificationSettings?: Maybe<NotificationSettings>;
};

export enum NotificationTypeEnum {
  TransactionsNewPayment = 'TRANSACTIONS_NEW_PAYMENT',
  TransactionsNewDeposit = 'TRANSACTIONS_NEW_DEPOSIT',
  TransactionsNewTransfer = 'TRANSACTIONS_NEW_TRANSFER',
  UserNewOwnerAdded = 'USER_NEW_OWNER_ADDED',
  UserNewReaderAdded = 'USER_NEW_READER_ADDED',
  UserReaderAccessEnds = 'USER_READER_ACCESS_ENDS'
}

export enum OperatingSystemEnum {
  Ios = 'IOS',
  Android = 'ANDROID'
}

export type OwnerIdentityCheckMutationResponse = MutationPayload & {
  __typename?: 'OwnerIdentityCheckMutationResponse';
  checkSuccessful: Scalars['Boolean'];
  userErrors: Array<UserError>;
};

export type OwnerInvitationSendMutationResponse = MutationResponse & {
  __typename?: 'OwnerInvitationSendMutationResponse';
  code: Scalars['String'];
  success: Scalars['Boolean'];
  message: Scalars['String'];
};

export type PlaidAccount = {
  __typename?: 'PlaidAccount';
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  balance?: Maybe<Scalars['Float']>;
  accountNumLast4?: Maybe<Scalars['String']>;
  thumbnail?: Maybe<Scalars['String']>;
  isLinkedAccount?: Maybe<Scalars['Boolean']>;
};

export type PlaidPublicTokenGenerateMutationResponse = MutationResponse & {
  __typename?: 'PlaidPublicTokenGenerateMutationResponse';
  code: Scalars['String'];
  success: Scalars['Boolean'];
  message: Scalars['String'];
  plaidPublicToken?: Maybe<Scalars['String']>;
};

export enum PrequalFailureStatusEnum {
  Fail = 'FAIL',
  FileFrozen = 'FILE_FROZEN',
  FileLocked = 'FILE_LOCKED'
}

export type Query = {
  __typename?: 'Query';
  financialInstitutions: Array<FinancialInstitutions>;
  exchangeRates: Array<CurrencyPair>;
  releaseVersion?: Maybe<Scalars['String']>;
  atmLocations: Array<AtmLocation>;
  intercomUserHasUnreadMessage?: Maybe<Scalars['Boolean']>;
  cards: Array<Card>;
  card?: Maybe<Card>;
  transaction?: Maybe<ITransaction>;
  transactions?: Maybe<TransactionsResponse>;
  transactionSearch?: Maybe<TransactionSearchResponse>;
  transactionSearchCSV?: Maybe<TransactionSearchCsvResponse>;
  merchant?: Maybe<Merchant>;
  merchantCategories: Array<Category>;
  /** Search for counterparties */
  merchantSuggest: Array<MerchantSuggestion>;
  logoSearchTwitter?: Maybe<LogoSearchTwitterResponse>;
  routingNumberACHIsValid?: Maybe<Scalars['Boolean']>;
  routingNumberWireIsValid?: Maybe<Scalars['Boolean']>;
  transactionsList?: Maybe<TransactionsListResponse>;
  naicsSectors?: Maybe<Array<Maybe<NaicsSector>>>;
  unsupportedCountries: Array<Country>;
  /** returns null if no check is pending */
  businessBackgroundCheckStatus?: Maybe<BusinessBackgroundCheck>;
  verifyIdentifyInfo?: Maybe<VerifyIdentityInfo>;
  verifyBusinessDescription?: Maybe<VerifyBusinessDescription>;
  businessInvitation: BusinessInvitation;
  verifyBusinessNameIsValid?: Maybe<Scalars['Boolean']>;
  emailContact?: Maybe<EmailContact>;
  emailContacts: Array<EmailContact>;
  emailExists?: Maybe<Scalars['Boolean']>;
  emailVerified?: Maybe<Scalars['Boolean']>;
  me?: Maybe<User>;
  userExists?: Maybe<Scalars['Boolean']>;
  insights?: Maybe<InsightsChart>;
  recipientsDomesticACH: Array<RecipientDomesticAch>;
  recipientsDomesticWire: Array<RecipientDomesticWire>;
  recipientsInternationalWire: Array<RecipientInternationalWire>;
  recipientsBillPayment: Array<RecipientBillPayment>;
  recipientBillPayment?: Maybe<RecipientBillPayment>;
  billPaymentVendors: Array<BillPaymentVendor>;
  subAccount?: Maybe<SubAccount>;
  subAccounts: Array<SubAccount>;
  accountBalanceHistory: Array<AccountBalance>;
  accountStatement?: Maybe<AccountStatement>;
  wireInfo?: Maybe<WireInfo>;
  plaidAccounts: Array<PlaidAccount>;
  subAccountRulePercentAvailable?: Maybe<Scalars['Int']>;
  billingInvoice?: Maybe<BillingInvoice>;
  business?: Maybe<Business>;
  supportTickets: SupportTicketsService;
  supportTicket: SupportTicket;
  supportHelpCenter: SupportHelpCenterService;
  supportPhoneCall: SupportPhoneCallService;
  transactionSchedules: Array<TransactionSchedule>;
  transactionSchedule?: Maybe<TransactionSchedule>;
  xeroIntegrations: Array<XeroIntegration>;
  xeroIntegration?: Maybe<XeroIntegration>;
  invoiceSettings?: Maybe<InvoiceSettings>;
  invoice?: Maybe<Invoice>;
  invoices?: Maybe<InvoicesPaginatedResponse>;
  invoiceRecipients: Array<InvoiceRecipient>;
  invoiceRecipient?: Maybe<InvoiceRecipient>;
  invoiceNumberSuggest?: Maybe<Scalars['Int']>;
  invoiceNumberExists?: Maybe<Scalars['Boolean']>;
  announcement?: Maybe<Announcement>;
  homeAnnouncements: Array<HomeAnnouncement>;
  ownerCandidates: Array<BusinessMemberCandidate>;
  transferWiseRecipientFields?: Maybe<TransferWiseRecipientRequiredFields>;
  transferWiseRecipients: Array<TransferWiseRecipient>;
  shareEarnReferralMicrositeCopy?: Maybe<ShareEarnOfferCopy>;
  shareAndEarn?: Maybe<ShareAndEarn>;
};


export type QueryAtmLocationsArgs = {
  radius: Scalars['Int'];
  coordinates: CoordinatesInput;
  filters?: Maybe<Array<AtmLocationFilterEnum>>;
};


export type QueryCardsArgs = {
  businessId: Scalars['ID'];
};


export type QueryCardArgs = {
  businessId: Scalars['ID'];
  cardId: Scalars['ID'];
};


export type QueryTransactionArgs = {
  businessId: Scalars['ID'];
  transactionId: Scalars['ID'];
};


export type QueryTransactionsArgs = {
  businessId: Scalars['ID'];
  options?: Maybe<TransactionsOptions>;
};


export type QueryTransactionSearchArgs = {
  businessId: Scalars['ID'];
  filters: TransactionSearchFiltersInput;
};


export type QueryTransactionSearchCsvArgs = {
  businessId: Scalars['ID'];
  filters: TransactionSearchFiltersInput;
  csvFormat?: Maybe<CsvFormatEnum>;
};


export type QueryMerchantArgs = {
  businessId: Scalars['ID'];
  merchantId: Scalars['ID'];
};


export type QueryMerchantCategoriesArgs = {
  businessId?: Maybe<Scalars['ID']>;
};


export type QueryMerchantSuggestArgs = {
  businessId: Scalars['ID'];
  term: Scalars['String'];
};


export type QueryLogoSearchTwitterArgs = {
  twitterName: Scalars['String'];
};


export type QueryRoutingNumberAchIsValidArgs = {
  routingNumber: Scalars['String'];
};


export type QueryRoutingNumberWireIsValidArgs = {
  routingNumber: Scalars['String'];
};


export type QueryTransactionsListArgs = {
  pagination?: Maybe<TransactionsPaginationOptionsInput>;
  filters?: Maybe<TransactionsListFiltersInput>;
};


export type QueryVerifyIdentifyInfoArgs = {
  frontImageDocumentToken: Scalars['String'];
  backImageDocumentToken: Scalars['String'];
};


export type QueryVerifyBusinessDescriptionArgs = {
  description: Scalars['String'];
};


export type QueryBusinessInvitationArgs = {
  invitationToken: Scalars['ID'];
};


export type QueryVerifyBusinessNameIsValidArgs = {
  name: Scalars['String'];
};


export type QueryEmailContactArgs = {
  businessId: Scalars['ID'];
  id: Scalars['ID'];
};


export type QueryEmailContactsArgs = {
  where?: Maybe<EmailContactWhereInput>;
};


export type QueryEmailExistsArgs = {
  email: Scalars['String'];
};


export type QueryEmailVerifiedArgs = {
  email: Scalars['String'];
};


export type QueryInsightsArgs = {
  businessId: Scalars['ID'];
  insightType?: Maybe<InsightTypeEnum>;
  interval?: Maybe<InsightsInterval>;
};


export type QueryRecipientsDomesticAchArgs = {
  businessId: Scalars['ID'];
};


export type QueryRecipientsDomesticWireArgs = {
  businessId: Scalars['ID'];
};


export type QueryRecipientsInternationalWireArgs = {
  businessId: Scalars['ID'];
};


export type QueryRecipientsBillPaymentArgs = {
  businessId: Scalars['ID'];
};


export type QueryRecipientBillPaymentArgs = {
  businessId: Scalars['ID'];
  recipientId: Scalars['ID'];
};


export type QueryBillPaymentVendorsArgs = {
  businessId: Scalars['ID'];
  name: Scalars['String'];
};


export type QuerySubAccountArgs = {
  businessId: Scalars['ID'];
  subAccountId: Scalars['ID'];
};


export type QuerySubAccountsArgs = {
  businessId: Scalars['ID'];
};


export type QueryAccountBalanceHistoryArgs = {
  businessId: Scalars['ID'];
  accountId?: Maybe<Scalars['String']>;
  subAccountId?: Maybe<Scalars['String']>;
  startDate: Scalars['String'];
  endDate?: Maybe<Scalars['String']>;
};


export type QueryAccountStatementArgs = {
  businessId: Scalars['String'];
  accountId: Scalars['String'];
  startDate: Scalars['String'];
  endDate: Scalars['String'];
  format: FileTypeEnum;
};


export type QueryWireInfoArgs = {
  businessId: Scalars['ID'];
};


export type QueryPlaidAccountsArgs = {
  businessId: Scalars['ID'];
  plaidPublicToken: Scalars['String'];
};


export type QuerySubAccountRulePercentAvailableArgs = {
  businessId: Scalars['ID'];
};


export type QueryBillingInvoiceArgs = {
  businessId: Scalars['ID'];
  billingInvoiceId: Scalars['ID'];
};


export type QueryBusinessArgs = {
  businessId: Scalars['ID'];
};


export type QuerySupportTicketArgs = {
  ticketId: Scalars['ID'];
};


export type QueryTransactionSchedulesArgs = {
  businessId: Scalars['ID'];
};


export type QueryTransactionScheduleArgs = {
  businessId: Scalars['ID'];
  transactionScheduleId: Scalars['ID'];
};


export type QueryXeroIntegrationsArgs = {
  businessId: Scalars['ID'];
  filters?: Maybe<XeroIntegrationsFilters>;
};


export type QueryXeroIntegrationArgs = {
  integrationId: Scalars['ID'];
};


export type QueryInvoiceArgs = {
  id: Scalars['ID'];
};


export type QueryInvoicesArgs = {
  filters?: Maybe<InvoiceListFilters>;
  page?: Maybe<Scalars['Int']>;
};


export type QueryInvoiceRecipientArgs = {
  id: Scalars['ID'];
};


export type QueryInvoiceNumberExistsArgs = {
  invoiceNumber: Scalars['Int'];
};


export type QueryAnnouncementArgs = {
  type: AnnouncementTypeEnum;
};


export type QueryOwnerCandidatesArgs = {
  filter?: Maybe<BusinessMemberCandidateStatusEnum>;
};


export type QueryTransferWiseRecipientFieldsArgs = {
  quoteId: Scalars['ID'];
};


export type QueryTransferWiseRecipientsArgs = {
  filters?: Maybe<TransferWiseRecipientListFilters>;
  profileId: Scalars['ID'];
};


export type QueryShareEarnReferralMicrositeCopyArgs = {
  advocateId?: Maybe<Scalars['ID']>;
};

export type Recipient = {
  id: Scalars['ID'];
  business?: Maybe<Business>;
  name: Scalars['String'];
  category?: Maybe<Category>;
  thumbnail?: Maybe<Scalars['String']>;
  recipientType: RecipientTypeEnum;
  paymentHistory?: Maybe<CounterpartyPaymentHistory>;
  counterpartyId?: Maybe<Scalars['ID']>;
};

export type RecipientBillPayment = Recipient & {
  __typename?: 'RecipientBillPayment';
  id: Scalars['ID'];
  business?: Maybe<Business>;
  name: Scalars['String'];
  category?: Maybe<Category>;
  thumbnail?: Maybe<Scalars['String']>;
  recipientType: RecipientTypeEnum;
  paymentHistory?: Maybe<CounterpartyPaymentHistory>;
  counterpartyId?: Maybe<Scalars['ID']>;
  alias?: Maybe<Scalars['String']>;
  vendorRef: Scalars['String'];
  vendorName: Scalars['String'];
  vendorAccountRef: Scalars['String'];
  accountNumber?: Maybe<Scalars['String']>;
  /** if false, cheques are used to pay bills, if true ACH is used */
  ach?: Maybe<Scalars['Boolean']>;
  lastPaymentAmount?: Maybe<Scalars['Float']>;
  lastPaymentDate?: Maybe<Scalars['String']>;
  nextScheduledAmount?: Maybe<Scalars['Float']>;
  nextScheduledDate?: Maybe<Scalars['String']>;
  address?: Maybe<Location>;
};

export type RecipientBillPaymentAchUpdateDataInput = {
  accountNumber?: Maybe<Scalars['String']>;
};

export type RecipientBillPaymentAchUpdateMutationResponse = MutationResponse & {
  __typename?: 'RecipientBillPaymentACHUpdateMutationResponse';
  code: Scalars['String'];
  success: Scalars['Boolean'];
  message: Scalars['String'];
  recipient?: Maybe<RecipientBillPayment>;
};

export type RecipientBillPaymentChequeUpdateDataInput = {
  name?: Maybe<Scalars['String']>;
  accountNumber?: Maybe<Scalars['String']>;
  address?: Maybe<LocationInput>;
};

export type RecipientBillPaymentChequeUpdateInfoInput = {
  name?: Maybe<Scalars['String']>;
  accountNumber?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  address?: Maybe<LocationInput>;
};

export type RecipientBillPaymentChequeUpdateMutationResponse = MutationResponse & {
  __typename?: 'RecipientBillPaymentChequeUpdateMutationResponse';
  code: Scalars['String'];
  success: Scalars['Boolean'];
  message: Scalars['String'];
  recipient?: Maybe<RecipientBillPayment>;
};

export type RecipientBillPaymentCreateDataInput = {
  name: Scalars['String'];
  accountNumber: Scalars['String'];
  postalCode?: Maybe<Scalars['String']>;
  address?: Maybe<LocationInput>;
};

export type RecipientBillPaymentCreateMutationResponse = MutationResponse & {
  __typename?: 'RecipientBillPaymentCreateMutationResponse';
  code: Scalars['String'];
  success: Scalars['Boolean'];
  message: Scalars['String'];
  recipient?: Maybe<RecipientBillPayment>;
  errorCode?: Maybe<RecipientBillPaymentErrorEnum>;
};

export enum RecipientBillPaymentErrorEnum {
  MoreInfoRequired = 'MORE_INFO_REQUIRED',
  NoMatch = 'NO_MATCH'
}

export type RecipientBillPaymentMutationResponse = MutationResponse & {
  __typename?: 'RecipientBillPaymentMutationResponse';
  code: Scalars['String'];
  success: Scalars['Boolean'];
  message: Scalars['String'];
  recipient?: Maybe<RecipientBillPayment>;
};

export type RecipientDomesticAch = Recipient & {
  __typename?: 'RecipientDomesticACH';
  id: Scalars['ID'];
  business?: Maybe<Business>;
  name: Scalars['String'];
  thumbnail?: Maybe<Scalars['String']>;
  recipientType: RecipientTypeEnum;
  category?: Maybe<Category>;
  paymentHistory?: Maybe<CounterpartyPaymentHistory>;
  counterpartyId?: Maybe<Scalars['ID']>;
  bankRoutingNumber: Scalars['String'];
  bankAccountNumber: Scalars['String'];
};

export type RecipientDomesticAchBankInfoInput = {
  bankName: Scalars['String'];
  bankInstitutionNumber: Scalars['String'];
  bankRoutingNumber: Scalars['String'];
  bankAccountNumber: Scalars['String'];
};

export type RecipientDomesticAchCreateDataInput = {
  name: Scalars['String'];
  bankName?: Maybe<Scalars['String']>;
  bankInstitutionNumber?: Maybe<Scalars['String']>;
  bankRoutingNumber: Scalars['String'];
  bankAccountNumber: Scalars['String'];
};

export type RecipientDomesticAchCreateResponse = MutationResponse & {
  __typename?: 'RecipientDomesticACHCreateResponse';
  code: Scalars['String'];
  success: Scalars['Boolean'];
  message: Scalars['String'];
  recipientDomesticACH?: Maybe<RecipientDomesticAch>;
};

export type RecipientDomesticAchDeleteResponse = MutationResponse & {
  __typename?: 'RecipientDomesticACHDeleteResponse';
  code: Scalars['String'];
  success: Scalars['Boolean'];
  message: Scalars['String'];
};

export type RecipientDomesticAchUpdateDataInput = {
  name?: Maybe<Scalars['String']>;
  category?: Maybe<Scalars['String']>;
  thumbnail?: Maybe<Scalars['String']>;
  bankName?: Maybe<Scalars['String']>;
  bankInstitutionNumber?: Maybe<Scalars['String']>;
  bankRoutingNumber?: Maybe<Scalars['String']>;
  bankAccountNumber?: Maybe<Scalars['String']>;
};

export type RecipientDomesticAchUpdateResponse = MutationResponse & {
  __typename?: 'RecipientDomesticACHUpdateResponse';
  code: Scalars['String'];
  success: Scalars['Boolean'];
  message: Scalars['String'];
  recipientDomesticACH?: Maybe<RecipientDomesticAch>;
};

export type RecipientDomesticWire = Recipient & {
  __typename?: 'RecipientDomesticWire';
  id: Scalars['ID'];
  business?: Maybe<Business>;
  name: Scalars['String'];
  thumbnail?: Maybe<Scalars['String']>;
  recipientType: RecipientTypeEnum;
  paymentHistory?: Maybe<CounterpartyPaymentHistory>;
  counterpartyId?: Maybe<Scalars['ID']>;
  category?: Maybe<Category>;
  address: Location;
  routingNumber: Scalars['String'];
  accountNumber: Scalars['String'];
  bankName: Scalars['String'];
  bankAddress: Location;
};

export type RecipientDomesticWireCreateDataInput = {
  name: Scalars['String'];
  thumbnail?: Maybe<Scalars['String']>;
  address: LocationInput;
  routingNumber: Scalars['String'];
  accountNumber: Scalars['String'];
  bankName: Scalars['String'];
  bankAddress: LocationInput;
};

export type RecipientDomesticWireMutationResponse = MutationPayload & {
  __typename?: 'RecipientDomesticWireMutationResponse';
  recipient?: Maybe<RecipientDomesticWire>;
  userErrors: Array<UserError>;
};

export type RecipientDomesticWireUpdateDataInput = {
  name?: Maybe<Scalars['String']>;
  category?: Maybe<Scalars['String']>;
  thumbnail?: Maybe<Scalars['String']>;
  address?: Maybe<LocationInput>;
  routingNumber?: Maybe<Scalars['String']>;
  accountNumber?: Maybe<Scalars['String']>;
  bankName?: Maybe<Scalars['String']>;
  bankAddress?: Maybe<LocationInput>;
};

export type RecipientInternationalWire = Recipient & {
  __typename?: 'RecipientInternationalWire';
  id: Scalars['ID'];
  business?: Maybe<Business>;
  name: Scalars['String'];
  thumbnail?: Maybe<Scalars['String']>;
  recipientType: RecipientTypeEnum;
  category?: Maybe<Category>;
  paymentHistory?: Maybe<CounterpartyPaymentHistory>;
  counterpartyId?: Maybe<Scalars['ID']>;
  address: Location;
  routingNumber: Scalars['String'];
  accountNumber: Scalars['String'];
  bankName: Scalars['String'];
  bankAddress: Location;
  /** Only for european recipients */
  IBAN?: Maybe<Scalars['String']>;
  currency: CurrencyCodeEnum;
};

export type RecipientInternationalWireCreateDataInput = {
  name: Scalars['String'];
  thumbnail?: Maybe<Scalars['String']>;
  address: LocationInput;
  routingNumber: Scalars['String'];
  accountNumber: Scalars['String'];
  bankName: Scalars['String'];
  bankAddress: LocationInput;
  /** Only for european recipients */
  IBAN?: Maybe<Scalars['String']>;
  currency?: Maybe<CurrencyCodeEnum>;
};

export type RecipientInternationalWireMutationResponse = MutationPayload & {
  __typename?: 'RecipientInternationalWireMutationResponse';
  recipient?: Maybe<RecipientInternationalWire>;
  userErrors: Array<UserError>;
};

export type RecipientInternationalWireUpdateDataInput = {
  name?: Maybe<Scalars['String']>;
  category?: Maybe<Scalars['String']>;
  thumbnail?: Maybe<Scalars['String']>;
  address?: Maybe<LocationInput>;
  routingNumber?: Maybe<Scalars['String']>;
  accountNumber?: Maybe<Scalars['String']>;
  bankName?: Maybe<Scalars['String']>;
  bankAddress?: Maybe<LocationInput>;
};

export enum RecipientTypeEnum {
  DomesticAch = 'DOMESTIC_ACH',
  InternationalWire = 'INTERNATIONAL_WIRE',
  BillPayment = 'BILL_PAYMENT',
  DomesticWire = 'DOMESTIC_WIRE'
}

export type RegisterInvitedOwnerInput = {
  email: Scalars['String'];
  invitationToken: Scalars['ID'];
  phone?: Maybe<Scalars['String']>;
};

export type RegisterUserData = {
  __typename?: 'RegisterUserData';
  id: Scalars['ID'];
  email: Scalars['String'];
};

export type RegisterUserInput = {
  email: Scalars['String'];
  phone?: Maybe<Scalars['String']>;
};

export type RegisterUserResponse = MutationResponse & {
  __typename?: 'RegisterUserResponse';
  code: Scalars['String'];
  success: Scalars['Boolean'];
  message: Scalars['String'];
  user?: Maybe<RegisterUserData>;
};

export type ScheduledTransactionsUnion = Ach | BillPayment;

export type ScheduledTransactionUpdateMutationResponse = {
  __typename?: 'ScheduledTransactionUpdateMutationResponse';
  code: Scalars['String'];
  success: Scalars['Boolean'];
  message: Scalars['String'];
  many?: Maybe<Scalars['Boolean']>;
};

export enum ScheduleFrequencyEnum {
  Once = 'ONCE',
  EveryDay = 'EVERY_DAY',
  EveryWeek = 'EVERY_WEEK',
  EveryTwoWeeks = 'EVERY_TWO_WEEKS',
  EveryMonth = 'EVERY_MONTH'
}

export type ShareAndEarn = {
  __typename?: 'ShareAndEarn';
  /** as a user can you can only be a referral once */
  referral?: Maybe<ShareAndEarnReferral>;
  /** A user acting as an advocate can have multiple offers */
  advocate?: Maybe<ShareAndEarnAdvocate>;
};

export type ShareAndEarnAdvocate = {
  __typename?: 'ShareAndEarnAdvocate';
  lastUnseenShareAndEarnOffer?: Maybe<ShareAndEarnOffer>;
  totalRedeemedOffers: Scalars['Int'];
  totalRedeemedAmount: Scalars['Float'];
  latestCampaignId: Scalars['String'];
  offerUrl: Scalars['String'];
  copyJson: Scalars['String'];
};

export type ShareAndEarnOffer = {
  __typename?: 'ShareAndEarnOffer';
  id: Scalars['ID'];
  campaignId: Scalars['String'];
  copyJson: Scalars['String'];
};

export type ShareAndEarnRedeemedOfferMarkAsSeenMutationResponse = MutationResponse & {
  __typename?: 'ShareAndEarnRedeemedOfferMarkAsSeenMutationResponse';
  code: Scalars['String'];
  success: Scalars['Boolean'];
  message: Scalars['String'];
};

export type ShareAndEarnReferral = {
  __typename?: 'ShareAndEarnReferral';
  status?: Maybe<ShareAndEarnReferralOfferStatusEnum>;
  hasUnseenRedeemedOffer?: Maybe<Scalars['Boolean']>;
  campaignId: Scalars['String'];
  offerId: Scalars['String'];
  copyJson: Scalars['String'];
};

export enum ShareAndEarnReferralOfferStatusEnum {
  /** Referral accepted offer but has not fulfilled the criteria for being redeemed */
  AcceptedOffer = 'ACCEPTED_OFFER',
  RedeemedOffer = 'REDEEMED_OFFER',
  NoOffer = 'NO_OFFER'
}

export type ShareEarnOfferCopy = {
  __typename?: 'ShareEarnOfferCopy';
  copyJson?: Maybe<Scalars['String']>;
};

export type SourceTargetAmount = {
  /** the amount to be taken from the business in the currency the business operates in. */
  source?: Maybe<Scalars['Float']>;
  /** the amount to be taken from the business in a currency other than the business' currency */
  target?: Maybe<Scalars['Float']>;
  /** the currecy for the target amount */
  targetCurrency?: Maybe<CurrencyCodeEnum>;
};

/** Input shape for standard search fields. */
export type StandardSearchInput = {
  values: Array<Scalars['String']>;
};

export type StringRangeField = {
  greaterThanOrEqual?: Maybe<Scalars['String']>;
  lessThan?: Maybe<Scalars['String']>;
};

/** Input shape for range search fields of type string. */
export type StringRangeSearchInput = {
  values?: Maybe<Array<Scalars['String']>>;
  greaterThanOrEqual?: Maybe<Scalars['String']>;
  lessThan?: Maybe<Scalars['String']>;
  range?: Maybe<StringRangeField>;
};

export type SubAccount = IAccount & {
  __typename?: 'SubAccount';
  id: Scalars['ID'];
  type: AccountTypeEnum;
  name: Scalars['String'];
  balance: Scalars['Float'];
  thumbnail?: Maybe<Scalars['String']>;
  isDefault?: Maybe<Scalars['Boolean']>;
  businessId: Scalars['ID'];
  percentRule?: Maybe<SubAccountRulePercent>;
};

export type SubAccountCreateResponse = MutationPayload & {
  __typename?: 'SubAccountCreateResponse';
  userErrors: Array<UserError>;
  subAccount?: Maybe<SubAccount>;
};

export type SubAccountDeleteResponse = MutationResponse & {
  __typename?: 'SubAccountDeleteResponse';
  code: Scalars['String'];
  success: Scalars['Boolean'];
  message: Scalars['String'];
};

export type SubAccountReconcileDataInput = {
  subAccountId: Scalars['ID'];
  amount: Scalars['Float'];
};

export type SubAccountRulePercent = {
  __typename?: 'SubAccountRulePercent';
  id: Scalars['ID'];
  subAccount: SubAccount;
  allIncoming?: Maybe<Scalars['Boolean']>;
  percent?: Maybe<Scalars['Int']>;
  availableUpdateLimit?: Maybe<Scalars['Int']>;
};

export type SubAccountRulePercentCreateData = {
  percent: Scalars['Int'];
};

export type SubAccountRulePercentCreateMutationResponse = {
  __typename?: 'SubAccountRulePercentCreateMutationResponse';
  code: Scalars['String'];
  success: Scalars['Boolean'];
  message: Scalars['String'];
  subAccountRulePercent?: Maybe<SubAccountRulePercent>;
};

export type SubAccountRulePercentDeleteMutationResponse = {
  __typename?: 'SubAccountRulePercentDeleteMutationResponse';
  code: Scalars['String'];
  success: Scalars['Boolean'];
  message: Scalars['String'];
};

export type SubAccountRulePercentUpdateData = {
  percent?: Maybe<Scalars['Int']>;
};

export type SubAccountRulePercentUpdateMutationResponse = {
  __typename?: 'SubAccountRulePercentUpdateMutationResponse';
  code: Scalars['String'];
  success: Scalars['Boolean'];
  message: Scalars['String'];
  subAccountRulePercent?: Maybe<SubAccountRulePercent>;
};

export type SubAccountsReconcileResponse = MutationResponse & {
  __typename?: 'SubAccountsReconcileResponse';
  code: Scalars['String'];
  success: Scalars['Boolean'];
  message: Scalars['String'];
  subAccounts: Array<SubAccount>;
};

export type SubAccountTransfer = ITransaction & {
  __typename?: 'SubAccountTransfer';
  id: Scalars['ID'];
  description?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  location?: Maybe<Location>;
  /** @deprecated use description instead */
  rawDescription?: Maybe<Scalars['String']>;
  subAccount: SubAccount;
  business: Business;
  estimatedCompletionDate?: Maybe<Scalars['String']>;
  amount: Scalars['Float'];
  note?: Maybe<Scalars['String']>;
  status?: Maybe<TransactionStatusEnum>;
  createdAt?: Maybe<Scalars['String']>;
  transactionType: TransactionTypeEnum;
  emailContacts: Array<TransactionEmailContact>;
  cancelable?: Maybe<Scalars['Boolean']>;
  errorMessage?: Maybe<Scalars['String']>;
  correspondingTransferId?: Maybe<Scalars['String']>;
  correspondingTransfer?: Maybe<SubAccountTransfer>;
};

export type SubAccountUpdateDataInput = {
  name?: Maybe<Scalars['String']>;
};

export type SubAccountUpdateResponse = MutationResponse & {
  __typename?: 'SubAccountUpdateResponse';
  code: Scalars['String'];
  success: Scalars['Boolean'];
  message: Scalars['String'];
  subAccount?: Maybe<SubAccount>;
};

export type SupportAgent = {
  __typename?: 'SupportAgent';
  id: Scalars['ID'];
  photo?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

export type SupportArticle = {
  __typename?: 'SupportArticle';
  id: Scalars['ID'];
  title?: Maybe<Scalars['String']>;
  body?: Maybe<Scalars['String']>;
  htmlBody?: Maybe<Scalars['String']>;
};

export enum SupportArticleCategoryEnum {
  Faqs = 'FAQS'
}

export enum SupportArticleLocaleEnum {
  EnUs = 'EN_US'
}

export type SupportArticleSearch = {
  __typename?: 'SupportArticleSearch';
  faqs: Array<SupportArticleSearchResult>;
};


export type SupportArticleSearchFaqsArgs = {
  searchTerm: Scalars['String'];
};

export type SupportArticleSearchResult = {
  __typename?: 'SupportArticleSearchResult';
  id: Scalars['ID'];
  title?: Maybe<Scalars['String']>;
  body?: Maybe<Scalars['String']>;
  htmlBody?: Maybe<Scalars['String']>;
  /** Portion of the article that is relevant to the search query */
  snippet?: Maybe<Scalars['String']>;
};

export type SupportArticleSection = {
  __typename?: 'SupportArticleSection';
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  /** Array of emojis to be displayed in UI */
  emoji: Array<Scalars['String']>;
};

export type SupportHelpCenterService = {
  __typename?: 'SupportHelpCenterService';
  /** List of support article sections */
  sections: Array<SupportArticleSection>;
  /** List of support FAQ articles */
  faqs: Array<SupportArticle>;
  /** View a single article */
  article?: Maybe<SupportArticle>;
  /** Search for articles */
  search?: Maybe<SupportArticleSearch>;
};


export type SupportHelpCenterServiceFaqsArgs = {
  sectionId?: Maybe<Scalars['ID']>;
};


export type SupportHelpCenterServiceArticleArgs = {
  articleId: Scalars['ID'];
};

export type SupportPhoneCallBooking = {
  __typename?: 'SupportPhoneCallBooking';
  /** dateTime formatted as a unix timestamp */
  dateTime?: Maybe<Scalars['String']>;
  confirmationEmail?: Maybe<Scalars['String']>;
};

export type SupportPhoneCallBookingMutationResponse = MutationPayload & {
  __typename?: 'SupportPhoneCallBookingMutationResponse';
  supportPhoneCallBooking?: Maybe<SupportPhoneCallBooking>;
  userErrors: Array<UserError>;
};

export type SupportPhoneCallCreateInput = {
  /** dateTime formatted as a unix timestamp */
  dateTime: Scalars['String'];
  phone: Scalars['String'];
  email: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  notes: Scalars['String'];
  /** Format example: America/New_York */
  timezone?: Maybe<Scalars['String']>;
};

export type SupportPhoneCallService = {
  __typename?: 'SupportPhoneCallService';
  /** list total number of slots available per day for a week from startDate */
  availableSlotsForWeek: Array<SupportPhoneCallSlots>;
  /** list all available slots for a specific day */
  availableSlotsForDay: Array<Scalars['String']>;
};


export type SupportPhoneCallServiceAvailableSlotsForWeekArgs = {
  startDate?: Maybe<Scalars['String']>;
  timezone: Scalars['String'];
};


export type SupportPhoneCallServiceAvailableSlotsForDayArgs = {
  date: Scalars['String'];
  timezone: Scalars['String'];
};

export type SupportPhoneCallSlots = {
  __typename?: 'SupportPhoneCallSlots';
  date?: Maybe<Scalars['String']>;
  slots?: Maybe<Scalars['Int']>;
};

export type SupportTicket = {
  __typename?: 'SupportTicket';
  id: Scalars['ID'];
  agent?: Maybe<SupportAgent>;
  status?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  subject?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  /** Comments between users and agents */
  comments: Array<SupportTicketComment>;
  lastComment?: Maybe<SupportTicketComment>;
  /**
   * name of the last agent to reply
   * @deprecated please use SupportTicket.agent.name instead
   */
  agentName?: Maybe<Scalars['String']>;
};

export type SupportTicketComment = {
  __typename?: 'SupportTicketComment';
  id: Scalars['ID'];
  body?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['String']>;
  authorId: Scalars['ID'];
  authorName?: Maybe<Scalars['String']>;
  agentPhoto?: Maybe<Scalars['String']>;
  /** @deprecated please use existence of SupportTicket.agent instead */
  isAgent?: Maybe<Scalars['Boolean']>;
  agent?: Maybe<SupportAgent>;
};

export type SupportTicketCreateInput = {
  name?: Maybe<Scalars['String']>;
  comment: Scalars['String'];
};

export type SupportTicketCreateMutationResponse = MutationResponse & {
  __typename?: 'SupportTicketCreateMutationResponse';
  supportTicket?: Maybe<SupportTicket>;
  code: Scalars['String'];
  success: Scalars['Boolean'];
  message: Scalars['String'];
};

export type SupportTicketMutationResponse = MutationPayload & {
  __typename?: 'SupportTicketMutationResponse';
  supportTicket?: Maybe<SupportTicket>;
  userErrors: Array<UserError>;
};

export type SupportTicketsPaginatedResponse = {
  __typename?: 'SupportTicketsPaginatedResponse';
  tickets: Array<SupportTicket>;
  nextPage?: Maybe<Scalars['Int']>;
};

/** Includes support tickets grouped by status and additional meta data */
export type SupportTicketsService = {
  __typename?: 'SupportTicketsService';
  /** Returns up to 100 results */
  openTickets: Array<SupportTicket>;
  /** Returns up to 100 results */
  resolvedTickets: Array<SupportTicket>;
  resolvedTicketsPaginated?: Maybe<SupportTicketsPaginatedResponse>;
  totalTicketsResolved?: Maybe<Scalars['Int']>;
};


/** Includes support tickets grouped by status and additional meta data */
export type SupportTicketsServiceResolvedTicketsPaginatedArgs = {
  page?: Maybe<Scalars['Int']>;
};

export enum SupportTicketStatusEnum {
  Open = 'OPEN',
  Resolved = 'RESOLVED'
}

export type SupportTicketUpdateDataInput = {
  comment?: Maybe<Scalars['String']>;
};

export enum TransactionDirectionEnum {
  Credit = 'CREDIT',
  Debit = 'DEBIT'
}

export type TransactionEmailContact = {
  __typename?: 'TransactionEmailContact';
  name: Scalars['String'];
  email: Scalars['String'];
};

export type TransactionListItem = {
  __typename?: 'TransactionListItem';
  id: Scalars['ID'];
  description?: Maybe<Scalars['String']>;
  amount: Scalars['Float'];
  status?: Maybe<TransactionStatusEnum>;
  /** datetime in ISO 8601 format */
  createdAt?: Maybe<Scalars['String']>;
  transactionType: TransactionTypeEnum;
  errorMessage?: Maybe<Scalars['String']>;
};

export type TransactionsAccountTransferDestinationInput = {
  accountType: AccountTypeEnum;
  accountId: Scalars['ID'];
};

export type TransactionsAccountTransferInput = {
  source?: Maybe<TransactionsAccountTransferSourceInput>;
  destination?: Maybe<TransactionsAccountTransferDestinationInput>;
  emailContacts?: Maybe<Array<EmailContactInput>>;
  amount: Scalars['Float'];
  schedule?: Maybe<TransactionScheduleInput>;
};

export type TransactionsAccountTransferSourceInput = {
  accountType: AccountTypeEnum;
  accountId: Scalars['ID'];
};

export type TransactionsAmountFilterOptionsInput = {
  exact?: Maybe<Scalars['Float']>;
  range?: Maybe<FloatRangeInput>;
};

export type TransactionSchedule = {
  __typename?: 'TransactionSchedule';
  id: Scalars['ID'];
  business?: Maybe<Business>;
  amount?: Maybe<Scalars['Float']>;
  startDate?: Maybe<Scalars['String']>;
  endDate?: Maybe<Scalars['String']>;
  transactionType?: Maybe<TransactionTypeEnum>;
  subAccount?: Maybe<SubAccount>;
  counterparty?: Maybe<CounterpartyUnion>;
  paused?: Maybe<Scalars['Boolean']>;
  frequency?: Maybe<ScheduleFrequencyEnum>;
  nextScheduledDate?: Maybe<Scalars['String']>;
  emailContacts: Array<TransactionEmailContact>;
  isLinkedAccountTransfer?: Maybe<Scalars['Boolean']>;
  direction?: Maybe<TransactionDirectionEnum>;
};

export type TransactionScheduleInput = {
  frequency?: Maybe<ScheduleFrequencyEnum>;
  startDate: Scalars['String'];
  endDate?: Maybe<Scalars['String']>;
};

export type TransactionSchedulePauseResponse = MutationResponse & {
  __typename?: 'TransactionSchedulePauseResponse';
  code: Scalars['String'];
  success: Scalars['Boolean'];
  message: Scalars['String'];
  transactionSchedule?: Maybe<TransactionSchedule>;
};

export type TransactionScheduleRestartResponse = MutationResponse & {
  __typename?: 'TransactionScheduleRestartResponse';
  code: Scalars['String'];
  success: Scalars['Boolean'];
  message: Scalars['String'];
  transactionSchedule?: Maybe<TransactionSchedule>;
};

export type TransactionScheduleUpdateDataInput = {
  amount?: Maybe<Scalars['Float']>;
  frequency?: Maybe<ScheduleFrequencyEnum>;
  nextScheduledDate?: Maybe<Scalars['String']>;
  subAccountId?: Maybe<Scalars['String']>;
  counterpartyId?: Maybe<Scalars['String']>;
  emailContacts?: Maybe<Array<EmailContactInput>>;
};

export type TransactionScheduleUpdateResponse = MutationResponse & {
  __typename?: 'TransactionScheduleUpdateResponse';
  code: Scalars['String'];
  success: Scalars['Boolean'];
  message: Scalars['String'];
  transactionSchedule?: Maybe<TransactionSchedule>;
};

export type TransactionsDatetimeFilterOptionsInput = {
  /** datetime in ISO 8601 format */
  exactDatetime?: Maybe<Scalars['String']>;
  range?: Maybe<DatetimeRangeInput>;
};

export type TransactionSearchCsvResponse = {
  __typename?: 'TransactionSearchCSVResponse';
  csvURL: Scalars['String'];
};

/** Input for transaction searches. These are the filters to apply to our search. */
export type TransactionSearchFiltersInput = {
  counterparty?: Maybe<StandardSearchInput>;
  /** search by card id */
  card?: Maybe<StandardSearchInput>;
  state?: Maybe<StandardSearchInput>;
  amount?: Maybe<FloatRangeSearchInput>;
  country?: Maybe<StandardSearchInput>;
  region?: Maybe<StandardSearchInput>;
  city?: Maybe<StandardSearchInput>;
  /** search by transactionType filter takes in TransactionTypeEnum values only */
  transactionType?: Maybe<StandardSearchInput>;
  /** category labels */
  labels?: Maybe<StandardSearchInput>;
  category?: Maybe<StandardSearchInput>;
  /** search by date takes in unix timestamp strings only */
  date?: Maybe<StringRangeSearchInput>;
};

export type TransactionSearchResponse = {
  __typename?: 'TransactionSearchResponse';
  transactions: Array<ITransaction>;
};

export type TransactionsListFiltersInput = {
  /** Limits results to a particular sub account */
  subAccountId?: Maybe<Scalars['ID']>;
  /** Limits results to one or more transaction statuses. */
  statuses?: Maybe<Array<TransactionStatusEnum>>;
  /** Limits results to one or more transaction statuses. */
  amount?: Maybe<TransactionsAmountFilterOptionsInput>;
  datetime?: Maybe<TransactionsDatetimeFilterOptionsInput>;
  direction?: Maybe<TransactionDirectionEnum>;
  transactionTypes?: Maybe<Array<TransactionTypeEnum>>;
  text?: Maybe<Scalars['String']>;
};

export type TransactionsListResponse = {
  __typename?: 'TransactionsListResponse';
  pagination?: Maybe<TransactionsPagination>;
  transactionListItems: Array<TransactionListItem>;
};

export type TransactionsOptions = {
  /** The cursor to fetch the next set of results, if any. Not available when fetching transactions with PENDING status. */
  cursor?: Maybe<Scalars['String']>;
  /** Limits results to a particular sub account */
  subAccountId?: Maybe<Scalars['ID']>;
  /** Limits results to a particular transaction status. Only SUCCESS and PENDING are supported */
  status?: Maybe<TransactionStatusEnum>;
  /** Date field to sort results by */
  sortBy?: Maybe<TransactionSortByDateEnum>;
  /** returns transactions with a TransactionStatusEnum of FAILURE */
  includeFailed?: Maybe<Scalars['Boolean']>;
  /** returns transactions with a TransactionStatusEnum of PENDING */
  includePending?: Maybe<Scalars['Boolean']>;
};

export enum TransactionSortByDateEnum {
  CreatedAt = 'CREATED_AT',
  EstimatedCompletion = 'ESTIMATED_COMPLETION'
}

export type TransactionsPagination = {
  __typename?: 'TransactionsPagination';
  cursor?: Maybe<Scalars['String']>;
};

export type TransactionsPaginationOptionsInput = {
  cursor?: Maybe<Scalars['String']>;
};

export type TransactionsResponse = {
  __typename?: 'TransactionsResponse';
  cursor?: Maybe<Scalars['String']>;
  transactions: Array<ITransaction>;
};

export enum TransactionStatusEnum {
  /** transation is scheduled */
  Scheduled = 'SCHEDULED',
  /** transaction is in progress */
  Pending = 'PENDING',
  /** transaction is complete */
  Complete = 'COMPLETE',
  /** transaction has been successfully created */
  Success = 'SUCCESS',
  /** transaction was processed but resulted in failure, decline, etc. */
  Failure = 'FAILURE',
  /** transaction was not processed due to system error */
  Error = 'ERROR',
  Canceled = 'CANCELED'
}

export enum TransactionTypeEnum {
  Ach = 'ACH',
  BillPayment = 'BILL_PAYMENT',
  InternationalWire = 'INTERNATIONAL_WIRE',
  SubAccountTransfer = 'SUB_ACCOUNT_TRANSFER',
  ChequeDeposit = 'CHEQUE_DEPOSIT',
  DomesticWire = 'DOMESTIC_WIRE',
  CardPurchase = 'CARD_PURCHASE',
  Generic = 'GENERIC'
}

export type TransferWiseDynamicFieldsValidationAsync = {
  __typename?: 'TransferWiseDynamicFieldsValidationAsync';
  url: Scalars['String'];
  params: Array<TransferWiseRecipientValidationAsyncParams>;
};

export type TransferWiseDynamicFieldsValuesAllowed = {
  __typename?: 'TransferWiseDynamicFieldsValuesAllowed';
  key: Scalars['String'];
  name: Scalars['String'];
};

export type TransferWiseDynamicFormField = {
  __typename?: 'TransferWiseDynamicFormField';
  name: Scalars['String'];
  key: Scalars['String'];
  required: Scalars['Boolean'];
  refreshRequirementsOnChange: Scalars['Boolean'];
  type: TransferWiseDynamicFormTypesEnum;
  minLength?: Maybe<Scalars['Int']>;
  maxLength?: Maybe<Scalars['Int']>;
  validationRegexp?: Maybe<Scalars['String']>;
  example?: Maybe<Scalars['String']>;
  validationAsync?: Maybe<TransferWiseDynamicFieldsValidationAsync>;
  valuesAllowed: Array<TransferWiseDynamicFieldsValuesAllowed>;
};

export enum TransferWiseDynamicFormTypesEnum {
  Text = 'TEXT',
  Select = 'SELECT',
  Radio = 'RADIO'
}

export type TransferWiseQuote = {
  __typename?: 'TransferWiseQuote';
  quoteId: Scalars['ID'];
  targetAmount?: Maybe<Scalars['Float']>;
  sourceAmount?: Maybe<Scalars['Float']>;
  rate: Scalars['Float'];
  estimatedDelivery: Scalars['String'];
  totalFees: Scalars['Float'];
};

export type TransferWiseQuoteCreateData = {
  profileId: Scalars['ID'];
  sourceCurrency: TransferWiseSupportedCurrenciesEnum;
  targetCurrency: TransferWiseSupportedCurrenciesEnum;
  targetAmount?: Maybe<Scalars['Float']>;
  sourceAmount?: Maybe<Scalars['Float']>;
};

export type TransferWiseQuoteCreateMutationResponse = MutationResponse & {
  __typename?: 'TransferWiseQuoteCreateMutationResponse';
  code: Scalars['String'];
  success: Scalars['Boolean'];
  message: Scalars['String'];
  quote?: Maybe<TransferWiseQuote>;
};

export type TransferWiseQuoteUpdateData = {
  targetAccountId: Scalars['ID'];
  profileId: Scalars['ID'];
};

export type TransferWiseQuoteUpdateMutationResponse = MutationResponse & {
  __typename?: 'TransferWiseQuoteUpdateMutationResponse';
  code: Scalars['String'];
  success: Scalars['Boolean'];
  message: Scalars['String'];
  quote?: Maybe<TransferWiseQuote>;
};

export type TransferWiseRecipient = {
  __typename?: 'TransferWiseRecipient';
  id: Scalars['ID'];
  fullName: Scalars['String'];
  currency: TransferWiseSupportedCurrenciesEnum;
};

export type TransferWiseRecipientCreateData = {
  currency: TransferWiseSupportedCurrenciesEnum;
  type: Scalars['String'];
  profileId: Scalars['ID'];
  accountHolderName: Scalars['String'];
  details: Scalars['String'];
};

export type TransferWiseRecipientCreateMutationResponse = MutationResponse & {
  __typename?: 'TransferWiseRecipientCreateMutationResponse';
  code: Scalars['String'];
  success: Scalars['Boolean'];
  message: Scalars['String'];
};

export type TransferWiseRecipientDeleteMutationResponse = MutationResponse & {
  __typename?: 'TransferWiseRecipientDeleteMutationResponse';
  code: Scalars['String'];
  success: Scalars['Boolean'];
  message: Scalars['String'];
};

export type TransferWiseRecipientListFilters = {
  currency?: Maybe<TransferWiseSupportedCurrenciesEnum>;
};

export type TransferWiseRecipientRequiredFields = {
  __typename?: 'TransferWiseRecipientRequiredFields';
  fields: Array<TransferWiseDynamicFormField>;
};

export type TransferWiseRecipientRequirementsDynamicFormData = {
  iterativeRequestBody: Scalars['String'];
};

export type TransferWiseRecipientRequirementsDynamicFormMutationResponse = MutationResponse & {
  __typename?: 'TransferWiseRecipientRequirementsDynamicFormMutationResponse';
  code: Scalars['String'];
  success: Scalars['Boolean'];
  message: Scalars['String'];
  recipientRequiredFields?: Maybe<TransferWiseRecipientRequiredFields>;
};

export type TransferWiseRecipientValidationAsyncParams = {
  __typename?: 'TransferWiseRecipientValidationAsyncParams';
  key: Scalars['String'];
  parameterName: Scalars['String'];
  required: Scalars['Boolean'];
};

export enum TransferWiseSupportedCurrenciesEnum {
  Cad = 'CAD',
  Mxn = 'MXN',
  Gbp = 'GBP',
  Eur = 'EUR'
}

export type TransferWiseTemporaryQuote = {
  __typename?: 'TransferWiseTemporaryQuote';
  targetAmount?: Maybe<Scalars['Float']>;
  sourceAmount?: Maybe<Scalars['Float']>;
  rate: Scalars['Float'];
  estimatedDelivery: Scalars['String'];
  totalFees: Scalars['Float'];
};

export type TransferWiseTemporaryQuoteCreateData = {
  sourceCurrency: TransferWiseSupportedCurrenciesEnum;
  targetCurrency: TransferWiseSupportedCurrenciesEnum;
  targetAmount?: Maybe<Scalars['Float']>;
  sourceAmount?: Maybe<Scalars['Float']>;
};

export type TransferWiseTemporaryQuoteCreateMutationResponse = MutationResponse & {
  __typename?: 'TransferWiseTemporaryQuoteCreateMutationResponse';
  code: Scalars['String'];
  success: Scalars['Boolean'];
  message: Scalars['String'];
  temporaryQuote?: Maybe<TransferWiseTemporaryQuote>;
};

export type TransferWiseTransferCreateData = {
  sourceAccount?: Maybe<Scalars['ID']>;
  targetAccount: Scalars['ID'];
  quoteUuid: Scalars['ID'];
  customerTransactionId: Scalars['ID'];
  details: Scalars['String'];
};

export type TransferWiseTransferCreateMutationResponse = MutationResponse & {
  __typename?: 'TransferWiseTransferCreateMutationResponse';
  code: Scalars['String'];
  success: Scalars['Boolean'];
  message: Scalars['String'];
};

export type TransferWiseTransferRequiredFields = {
  __typename?: 'TransferWiseTransferRequiredFields';
  fields: Array<TransferWiseDynamicFormField>;
};

/** Expand on the implementation of dynamic forms. Details at: https://docs.google.com/document/d/1Q_0s1U3rHwMnvPIOm0U9OUXqC3pVpNVJ2_-2QFu5lv0/edit */
export type TransferWiseTransferRequirementsDynamicFormData = {
  iterativeRequestBody: Scalars['String'];
};

export type TransferWiseTransferRequirementsDynamicFormMutationResponse = MutationResponse & {
  __typename?: 'TransferWiseTransferRequirementsDynamicFormMutationResponse';
  code: Scalars['String'];
  success: Scalars['Boolean'];
  message: Scalars['String'];
};


export type User = {
  __typename?: 'User';
  id: Scalars['ID'];
  email?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  dateOfBirth?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  ownerBusinesses: Array<Business>;
  readerBusinesses: Array<Business>;
  /** unix timestamp */
  signUpDate: Scalars['String'];
  kycCompletedDate?: Maybe<Scalars['String']>;
  notificationSettings?: Maybe<NotificationSettings>;
  businessBackgroundCheck?: Maybe<BusinessBackgroundCheck>;
  acceptedTermsAndConditions?: Maybe<Scalars['Boolean']>;
};

export type UserAcceptTermsAndConditionsMutationResponse = MutationResponse & {
  __typename?: 'UserAcceptTermsAndConditionsMutationResponse';
  success: Scalars['Boolean'];
  code: Scalars['String'];
  message: Scalars['String'];
};

export type UserError = {
  __typename?: 'UserError';
  code: ErrorCodeEnum;
  message: Scalars['String'];
  /** Key path to the relevant argument, e.g. that failed validation */
  field: Array<Scalars['String']>;
};

export type UserMutationResponse = MutationResponse & {
  __typename?: 'UserMutationResponse';
  user?: Maybe<User>;
  code: Scalars['String'];
  success: Scalars['Boolean'];
  message: Scalars['String'];
};

export type UserSettings = {
  __typename?: 'UserSettings';
  id?: Maybe<Scalars['ID']>;
  notificationsEnabled?: Maybe<Scalars['Boolean']>;
};

export type UserUpdateData = {
  email?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
};

export type VerifyBusinessDescription = {
  __typename?: 'VerifyBusinessDescription';
  isValidDescription: Scalars['Boolean'];
  inputResponse?: Maybe<Scalars['String']>;
  suggestedDescription?: Maybe<Scalars['String']>;
};

export type VerifyIdentityInfo = {
  __typename?: 'VerifyIdentityInfo';
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  middleName?: Maybe<Scalars['String']>;
  /** unix timestamp format */
  dateOfBirth?: Maybe<Scalars['String']>;
  location?: Maybe<Location>;
  citizenship?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
};

export type VerifyIdentityUpload = {
  __typename?: 'VerifyIdentityUpload';
  frontImageDocumentToken?: Maybe<Scalars['String']>;
  backImageDocumentToken?: Maybe<Scalars['String']>;
};

export type VerifyIdentityUploadDocumentsMutationResponse = MutationResponse & {
  __typename?: 'VerifyIdentityUploadDocumentsMutationResponse';
  code: Scalars['String'];
  success: Scalars['Boolean'];
  message: Scalars['String'];
  upload?: Maybe<VerifyIdentityUpload>;
};

export type WireInfo = {
  __typename?: 'WireInfo';
  id: Scalars['ID'];
  routingNumber: Scalars['String'];
  accountNumber: Scalars['String'];
};

export type XeroAccount = {
  __typename?: 'XeroAccount';
  accountId: Scalars['ID'];
  feedConnectionId?: Maybe<Scalars['ID']>;
};

export type XeroFeedConnectionAccountData = {
  accountId: Scalars['ID'];
  accountNumber: Scalars['String'];
};

export type XeroFeedConnectionCreateResponse = MutationResponse & {
  __typename?: 'XeroFeedConnectionCreateResponse';
  code: Scalars['String'];
  success: Scalars['Boolean'];
  message: Scalars['String'];
  xeroIntegration?: Maybe<XeroIntegration>;
};

export type XeroFeedConnectionDeleteResponse = MutationResponse & {
  __typename?: 'XeroFeedConnectionDeleteResponse';
  code: Scalars['String'];
  success: Scalars['Boolean'];
  message: Scalars['String'];
  xeroIntegration?: Maybe<XeroIntegration>;
};

export type XeroIntegration = {
  __typename?: 'XeroIntegration';
  id: Scalars['ID'];
  status?: Maybe<XeroIntegrationStatusEnum>;
  business?: Maybe<Business>;
  xeroOrganization?: Maybe<XeroOrganization>;
  accounts: Array<XeroAccount>;
};

export type XeroIntegrationCreateResponse = MutationResponse & {
  __typename?: 'XeroIntegrationCreateResponse';
  code: Scalars['String'];
  success: Scalars['Boolean'];
  message: Scalars['String'];
  xeroIntegration?: Maybe<XeroIntegration>;
};

export type XeroIntegrationRequestResponse = MutationResponse & {
  __typename?: 'XeroIntegrationRequestResponse';
  code: Scalars['String'];
  success: Scalars['Boolean'];
  message: Scalars['String'];
  authURL?: Maybe<Scalars['String']>;
};

export type XeroIntegrationsFilters = {
  xeroOrganizationId?: Maybe<Scalars['ID']>;
};

export enum XeroIntegrationStatusEnum {
  Active = 'ACTIVE',
  Inactive = 'INACTIVE'
}

export type XeroOrganization = {
  __typename?: 'XeroOrganization';
  id: Scalars['ID'];
  integrationId: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  shortCode?: Maybe<Scalars['String']>;
};

export type BusinessBackgroundCheckMutationVariables = Exact<{
  business: BackgroundCheckBusinessInfoInput;
  owners: Array<BusinessBackgroundCheckOwnerInfoInput>;
  CJEVENT?: Maybe<Scalars['String']>;
  debitFundingInfo?: Maybe<DebitFundingInfoInput>;
}>;


export type BusinessBackgroundCheckMutation = (
  { __typename?: 'Mutation' }
  & { businessBackgroundCheckSubmit?: Maybe<(
    { __typename?: 'BusinessBackgroundCheckSubmitMutationResponse' }
    & Pick<BusinessBackgroundCheckSubmitMutationResponse, 'checkStatus' | 'prequalFailureStatus'>
  )> }
);

export type VerifyBusinessDescriptionQueryVariables = Exact<{
  description: Scalars['String'];
}>;


export type VerifyBusinessDescriptionQuery = (
  { __typename?: 'Query' }
  & { verifyBusinessDescription?: Maybe<(
    { __typename?: 'VerifyBusinessDescription' }
    & Pick<VerifyBusinessDescription, 'isValidDescription' | 'suggestedDescription'>
  )> }
);

export type NaicsSectorsQueryVariables = Exact<{ [key: string]: never; }>;


export type NaicsSectorsQuery = (
  { __typename?: 'Query' }
  & { naicsSectors?: Maybe<Array<Maybe<(
    { __typename?: 'NaicsSector' }
    & Pick<NaicsSector, 'code' | 'description'>
  )>>> }
);

export type EmailVerifiedResentScreenQueryVariables = Exact<{
  email: Scalars['String'];
}>;


export type EmailVerifiedResentScreenQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'emailVerified'>
);

export type ResendVerificationEmailResentScreenMutationVariables = Exact<{
  userEmail: Scalars['String'];
}>;


export type ResendVerificationEmailResentScreenMutation = (
  { __typename?: 'Mutation' }
  & { resendVerificationEmail?: Maybe<(
    { __typename?: 'ConcreteMutationResponse' }
    & Pick<ConcreteMutationResponse, 'success'>
  )> }
);

export type EmailVerifiedQueryVariables = Exact<{
  email: Scalars['String'];
}>;


export type EmailVerifiedQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'emailVerified'>
);

export type ResendVerificationEmailMutationVariables = Exact<{
  userEmail: Scalars['String'];
}>;


export type ResendVerificationEmailMutation = (
  { __typename?: 'Mutation' }
  & { resendVerificationEmail?: Maybe<(
    { __typename?: 'ConcreteMutationResponse' }
    & Pick<ConcreteMutationResponse, 'success'>
  )> }
);

export type RegisterUserAndTermsAcceptMutationVariables = Exact<{
  email: Scalars['String'];
  phone: Scalars['String'];
}>;


export type RegisterUserAndTermsAcceptMutation = (
  { __typename?: 'Mutation' }
  & { registerUserAndTermsAccept?: Maybe<(
    { __typename?: 'RegisterUserResponse' }
    & Pick<RegisterUserResponse, 'code' | 'success'>
    & { user?: Maybe<(
      { __typename?: 'RegisterUserData' }
      & Pick<RegisterUserData, 'id' | 'email'>
    )> }
  )> }
);

export type ExampleQueryQueryVariables = Exact<{ [key: string]: never; }>;


export type ExampleQueryQuery = (
  { __typename?: 'Query' }
  & { naicsSectors?: Maybe<Array<Maybe<(
    { __typename?: 'NaicsSector' }
    & Pick<NaicsSector, 'code' | 'description'>
  )>>> }
);

export type AuthUserQueryVariables = Exact<{ [key: string]: never; }>;


export type AuthUserQuery = (
  { __typename?: 'Query' }
  & { me?: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'id' | 'email' | 'acceptedTermsAndConditions'>
    & { businessBackgroundCheck?: Maybe<(
      { __typename?: 'BusinessBackgroundCheck' }
      & Pick<BusinessBackgroundCheck, 'status'>
    )>, ownerBusinesses: Array<(
      { __typename?: 'Business' }
      & Pick<Business, 'id'>
    )> }
  )> }
);


export const BusinessBackgroundCheckDocument = gql`
    mutation businessBackgroundCheck($business: BackgroundCheckBusinessInfoInput!, $owners: [BusinessBackgroundCheckOwnerInfoInput!]!, $CJEVENT: String, $debitFundingInfo: DebitFundingInfoInput) {
  businessBackgroundCheckSubmit(business: $business, owners: $owners, CJEVENT: $CJEVENT, debitFundingInfo: $debitFundingInfo) {
    checkStatus
    prequalFailureStatus
  }
}
    `;
export type BusinessBackgroundCheckMutationFn = Apollo.MutationFunction<BusinessBackgroundCheckMutation, BusinessBackgroundCheckMutationVariables>;
export type BusinessBackgroundCheckComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<BusinessBackgroundCheckMutation, BusinessBackgroundCheckMutationVariables>, 'mutation'>;

    export const BusinessBackgroundCheckComponent = (props: BusinessBackgroundCheckComponentProps) => (
      <ApolloReactComponents.Mutation<BusinessBackgroundCheckMutation, BusinessBackgroundCheckMutationVariables> mutation={BusinessBackgroundCheckDocument} {...props} />
    );
    

/**
 * __useBusinessBackgroundCheckMutation__
 *
 * To run a mutation, you first call `useBusinessBackgroundCheckMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBusinessBackgroundCheckMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [businessBackgroundCheckMutation, { data, loading, error }] = useBusinessBackgroundCheckMutation({
 *   variables: {
 *      business: // value for 'business'
 *      owners: // value for 'owners'
 *      CJEVENT: // value for 'CJEVENT'
 *      debitFundingInfo: // value for 'debitFundingInfo'
 *   },
 * });
 */
export function useBusinessBackgroundCheckMutation(baseOptions?: Apollo.MutationHookOptions<BusinessBackgroundCheckMutation, BusinessBackgroundCheckMutationVariables>) {
        return Apollo.useMutation<BusinessBackgroundCheckMutation, BusinessBackgroundCheckMutationVariables>(BusinessBackgroundCheckDocument, baseOptions);
      }
export type BusinessBackgroundCheckMutationHookResult = ReturnType<typeof useBusinessBackgroundCheckMutation>;
export type BusinessBackgroundCheckMutationResult = Apollo.MutationResult<BusinessBackgroundCheckMutation>;
export type BusinessBackgroundCheckMutationOptions = Apollo.BaseMutationOptions<BusinessBackgroundCheckMutation, BusinessBackgroundCheckMutationVariables>;
export const VerifyBusinessDescriptionDocument = gql`
    query verifyBusinessDescription($description: String!) {
  verifyBusinessDescription(description: $description) {
    isValidDescription
    suggestedDescription
  }
}
    `;
export type VerifyBusinessDescriptionComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<VerifyBusinessDescriptionQuery, VerifyBusinessDescriptionQueryVariables>, 'query'> & ({ variables: VerifyBusinessDescriptionQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const VerifyBusinessDescriptionComponent = (props: VerifyBusinessDescriptionComponentProps) => (
      <ApolloReactComponents.Query<VerifyBusinessDescriptionQuery, VerifyBusinessDescriptionQueryVariables> query={VerifyBusinessDescriptionDocument} {...props} />
    );
    

/**
 * __useVerifyBusinessDescriptionQuery__
 *
 * To run a query within a React component, call `useVerifyBusinessDescriptionQuery` and pass it any options that fit your needs.
 * When your component renders, `useVerifyBusinessDescriptionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useVerifyBusinessDescriptionQuery({
 *   variables: {
 *      description: // value for 'description'
 *   },
 * });
 */
export function useVerifyBusinessDescriptionQuery(baseOptions?: Apollo.QueryHookOptions<VerifyBusinessDescriptionQuery, VerifyBusinessDescriptionQueryVariables>) {
        return Apollo.useQuery<VerifyBusinessDescriptionQuery, VerifyBusinessDescriptionQueryVariables>(VerifyBusinessDescriptionDocument, baseOptions);
      }
export function useVerifyBusinessDescriptionLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<VerifyBusinessDescriptionQuery, VerifyBusinessDescriptionQueryVariables>) {
          return Apollo.useLazyQuery<VerifyBusinessDescriptionQuery, VerifyBusinessDescriptionQueryVariables>(VerifyBusinessDescriptionDocument, baseOptions);
        }
export type VerifyBusinessDescriptionQueryHookResult = ReturnType<typeof useVerifyBusinessDescriptionQuery>;
export type VerifyBusinessDescriptionLazyQueryHookResult = ReturnType<typeof useVerifyBusinessDescriptionLazyQuery>;
export type VerifyBusinessDescriptionQueryResult = Apollo.QueryResult<VerifyBusinessDescriptionQuery, VerifyBusinessDescriptionQueryVariables>;
export const NaicsSectorsDocument = gql`
    query naicsSectors {
  naicsSectors {
    code
    description
  }
}
    `;
export type NaicsSectorsComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<NaicsSectorsQuery, NaicsSectorsQueryVariables>, 'query'>;

    export const NaicsSectorsComponent = (props: NaicsSectorsComponentProps) => (
      <ApolloReactComponents.Query<NaicsSectorsQuery, NaicsSectorsQueryVariables> query={NaicsSectorsDocument} {...props} />
    );
    

/**
 * __useNaicsSectorsQuery__
 *
 * To run a query within a React component, call `useNaicsSectorsQuery` and pass it any options that fit your needs.
 * When your component renders, `useNaicsSectorsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNaicsSectorsQuery({
 *   variables: {
 *   },
 * });
 */
export function useNaicsSectorsQuery(baseOptions?: Apollo.QueryHookOptions<NaicsSectorsQuery, NaicsSectorsQueryVariables>) {
        return Apollo.useQuery<NaicsSectorsQuery, NaicsSectorsQueryVariables>(NaicsSectorsDocument, baseOptions);
      }
export function useNaicsSectorsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<NaicsSectorsQuery, NaicsSectorsQueryVariables>) {
          return Apollo.useLazyQuery<NaicsSectorsQuery, NaicsSectorsQueryVariables>(NaicsSectorsDocument, baseOptions);
        }
export type NaicsSectorsQueryHookResult = ReturnType<typeof useNaicsSectorsQuery>;
export type NaicsSectorsLazyQueryHookResult = ReturnType<typeof useNaicsSectorsLazyQuery>;
export type NaicsSectorsQueryResult = Apollo.QueryResult<NaicsSectorsQuery, NaicsSectorsQueryVariables>;
export const EmailVerifiedResentScreenDocument = gql`
    query emailVerifiedResentScreen($email: String!) {
  emailVerified(email: $email)
}
    `;
export type EmailVerifiedResentScreenComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<EmailVerifiedResentScreenQuery, EmailVerifiedResentScreenQueryVariables>, 'query'> & ({ variables: EmailVerifiedResentScreenQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const EmailVerifiedResentScreenComponent = (props: EmailVerifiedResentScreenComponentProps) => (
      <ApolloReactComponents.Query<EmailVerifiedResentScreenQuery, EmailVerifiedResentScreenQueryVariables> query={EmailVerifiedResentScreenDocument} {...props} />
    );
    

/**
 * __useEmailVerifiedResentScreenQuery__
 *
 * To run a query within a React component, call `useEmailVerifiedResentScreenQuery` and pass it any options that fit your needs.
 * When your component renders, `useEmailVerifiedResentScreenQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEmailVerifiedResentScreenQuery({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useEmailVerifiedResentScreenQuery(baseOptions?: Apollo.QueryHookOptions<EmailVerifiedResentScreenQuery, EmailVerifiedResentScreenQueryVariables>) {
        return Apollo.useQuery<EmailVerifiedResentScreenQuery, EmailVerifiedResentScreenQueryVariables>(EmailVerifiedResentScreenDocument, baseOptions);
      }
export function useEmailVerifiedResentScreenLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<EmailVerifiedResentScreenQuery, EmailVerifiedResentScreenQueryVariables>) {
          return Apollo.useLazyQuery<EmailVerifiedResentScreenQuery, EmailVerifiedResentScreenQueryVariables>(EmailVerifiedResentScreenDocument, baseOptions);
        }
export type EmailVerifiedResentScreenQueryHookResult = ReturnType<typeof useEmailVerifiedResentScreenQuery>;
export type EmailVerifiedResentScreenLazyQueryHookResult = ReturnType<typeof useEmailVerifiedResentScreenLazyQuery>;
export type EmailVerifiedResentScreenQueryResult = Apollo.QueryResult<EmailVerifiedResentScreenQuery, EmailVerifiedResentScreenQueryVariables>;
export const ResendVerificationEmailResentScreenDocument = gql`
    mutation resendVerificationEmailResentScreen($userEmail: String!) {
  resendVerificationEmail(userEmail: $userEmail) {
    success
  }
}
    `;
export type ResendVerificationEmailResentScreenMutationFn = Apollo.MutationFunction<ResendVerificationEmailResentScreenMutation, ResendVerificationEmailResentScreenMutationVariables>;
export type ResendVerificationEmailResentScreenComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<ResendVerificationEmailResentScreenMutation, ResendVerificationEmailResentScreenMutationVariables>, 'mutation'>;

    export const ResendVerificationEmailResentScreenComponent = (props: ResendVerificationEmailResentScreenComponentProps) => (
      <ApolloReactComponents.Mutation<ResendVerificationEmailResentScreenMutation, ResendVerificationEmailResentScreenMutationVariables> mutation={ResendVerificationEmailResentScreenDocument} {...props} />
    );
    

/**
 * __useResendVerificationEmailResentScreenMutation__
 *
 * To run a mutation, you first call `useResendVerificationEmailResentScreenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResendVerificationEmailResentScreenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resendVerificationEmailResentScreenMutation, { data, loading, error }] = useResendVerificationEmailResentScreenMutation({
 *   variables: {
 *      userEmail: // value for 'userEmail'
 *   },
 * });
 */
export function useResendVerificationEmailResentScreenMutation(baseOptions?: Apollo.MutationHookOptions<ResendVerificationEmailResentScreenMutation, ResendVerificationEmailResentScreenMutationVariables>) {
        return Apollo.useMutation<ResendVerificationEmailResentScreenMutation, ResendVerificationEmailResentScreenMutationVariables>(ResendVerificationEmailResentScreenDocument, baseOptions);
      }
export type ResendVerificationEmailResentScreenMutationHookResult = ReturnType<typeof useResendVerificationEmailResentScreenMutation>;
export type ResendVerificationEmailResentScreenMutationResult = Apollo.MutationResult<ResendVerificationEmailResentScreenMutation>;
export type ResendVerificationEmailResentScreenMutationOptions = Apollo.BaseMutationOptions<ResendVerificationEmailResentScreenMutation, ResendVerificationEmailResentScreenMutationVariables>;
export const EmailVerifiedDocument = gql`
    query emailVerified($email: String!) {
  emailVerified(email: $email)
}
    `;
export type EmailVerifiedComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<EmailVerifiedQuery, EmailVerifiedQueryVariables>, 'query'> & ({ variables: EmailVerifiedQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const EmailVerifiedComponent = (props: EmailVerifiedComponentProps) => (
      <ApolloReactComponents.Query<EmailVerifiedQuery, EmailVerifiedQueryVariables> query={EmailVerifiedDocument} {...props} />
    );
    

/**
 * __useEmailVerifiedQuery__
 *
 * To run a query within a React component, call `useEmailVerifiedQuery` and pass it any options that fit your needs.
 * When your component renders, `useEmailVerifiedQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEmailVerifiedQuery({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useEmailVerifiedQuery(baseOptions?: Apollo.QueryHookOptions<EmailVerifiedQuery, EmailVerifiedQueryVariables>) {
        return Apollo.useQuery<EmailVerifiedQuery, EmailVerifiedQueryVariables>(EmailVerifiedDocument, baseOptions);
      }
export function useEmailVerifiedLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<EmailVerifiedQuery, EmailVerifiedQueryVariables>) {
          return Apollo.useLazyQuery<EmailVerifiedQuery, EmailVerifiedQueryVariables>(EmailVerifiedDocument, baseOptions);
        }
export type EmailVerifiedQueryHookResult = ReturnType<typeof useEmailVerifiedQuery>;
export type EmailVerifiedLazyQueryHookResult = ReturnType<typeof useEmailVerifiedLazyQuery>;
export type EmailVerifiedQueryResult = Apollo.QueryResult<EmailVerifiedQuery, EmailVerifiedQueryVariables>;
export const ResendVerificationEmailDocument = gql`
    mutation resendVerificationEmail($userEmail: String!) {
  resendVerificationEmail(userEmail: $userEmail) {
    success
  }
}
    `;
export type ResendVerificationEmailMutationFn = Apollo.MutationFunction<ResendVerificationEmailMutation, ResendVerificationEmailMutationVariables>;
export type ResendVerificationEmailComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<ResendVerificationEmailMutation, ResendVerificationEmailMutationVariables>, 'mutation'>;

    export const ResendVerificationEmailComponent = (props: ResendVerificationEmailComponentProps) => (
      <ApolloReactComponents.Mutation<ResendVerificationEmailMutation, ResendVerificationEmailMutationVariables> mutation={ResendVerificationEmailDocument} {...props} />
    );
    

/**
 * __useResendVerificationEmailMutation__
 *
 * To run a mutation, you first call `useResendVerificationEmailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResendVerificationEmailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resendVerificationEmailMutation, { data, loading, error }] = useResendVerificationEmailMutation({
 *   variables: {
 *      userEmail: // value for 'userEmail'
 *   },
 * });
 */
export function useResendVerificationEmailMutation(baseOptions?: Apollo.MutationHookOptions<ResendVerificationEmailMutation, ResendVerificationEmailMutationVariables>) {
        return Apollo.useMutation<ResendVerificationEmailMutation, ResendVerificationEmailMutationVariables>(ResendVerificationEmailDocument, baseOptions);
      }
export type ResendVerificationEmailMutationHookResult = ReturnType<typeof useResendVerificationEmailMutation>;
export type ResendVerificationEmailMutationResult = Apollo.MutationResult<ResendVerificationEmailMutation>;
export type ResendVerificationEmailMutationOptions = Apollo.BaseMutationOptions<ResendVerificationEmailMutation, ResendVerificationEmailMutationVariables>;
export const RegisterUserAndTermsAcceptDocument = gql`
    mutation registerUserAndTermsAccept($email: String!, $phone: String!) {
  registerUserAndTermsAccept(input: {email: $email, phone: $phone}) {
    code
    success
    user {
      id
      email
    }
  }
}
    `;
export type RegisterUserAndTermsAcceptMutationFn = Apollo.MutationFunction<RegisterUserAndTermsAcceptMutation, RegisterUserAndTermsAcceptMutationVariables>;
export type RegisterUserAndTermsAcceptComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<RegisterUserAndTermsAcceptMutation, RegisterUserAndTermsAcceptMutationVariables>, 'mutation'>;

    export const RegisterUserAndTermsAcceptComponent = (props: RegisterUserAndTermsAcceptComponentProps) => (
      <ApolloReactComponents.Mutation<RegisterUserAndTermsAcceptMutation, RegisterUserAndTermsAcceptMutationVariables> mutation={RegisterUserAndTermsAcceptDocument} {...props} />
    );
    

/**
 * __useRegisterUserAndTermsAcceptMutation__
 *
 * To run a mutation, you first call `useRegisterUserAndTermsAcceptMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRegisterUserAndTermsAcceptMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [registerUserAndTermsAcceptMutation, { data, loading, error }] = useRegisterUserAndTermsAcceptMutation({
 *   variables: {
 *      email: // value for 'email'
 *      phone: // value for 'phone'
 *   },
 * });
 */
export function useRegisterUserAndTermsAcceptMutation(baseOptions?: Apollo.MutationHookOptions<RegisterUserAndTermsAcceptMutation, RegisterUserAndTermsAcceptMutationVariables>) {
        return Apollo.useMutation<RegisterUserAndTermsAcceptMutation, RegisterUserAndTermsAcceptMutationVariables>(RegisterUserAndTermsAcceptDocument, baseOptions);
      }
export type RegisterUserAndTermsAcceptMutationHookResult = ReturnType<typeof useRegisterUserAndTermsAcceptMutation>;
export type RegisterUserAndTermsAcceptMutationResult = Apollo.MutationResult<RegisterUserAndTermsAcceptMutation>;
export type RegisterUserAndTermsAcceptMutationOptions = Apollo.BaseMutationOptions<RegisterUserAndTermsAcceptMutation, RegisterUserAndTermsAcceptMutationVariables>;
export const ExampleQueryDocument = gql`
    query exampleQuery {
  naicsSectors {
    code
    description
  }
}
    `;
export type ExampleQueryComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<ExampleQueryQuery, ExampleQueryQueryVariables>, 'query'>;

    export const ExampleQueryComponent = (props: ExampleQueryComponentProps) => (
      <ApolloReactComponents.Query<ExampleQueryQuery, ExampleQueryQueryVariables> query={ExampleQueryDocument} {...props} />
    );
    

/**
 * __useExampleQueryQuery__
 *
 * To run a query within a React component, call `useExampleQueryQuery` and pass it any options that fit your needs.
 * When your component renders, `useExampleQueryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useExampleQueryQuery({
 *   variables: {
 *   },
 * });
 */
export function useExampleQueryQuery(baseOptions?: Apollo.QueryHookOptions<ExampleQueryQuery, ExampleQueryQueryVariables>) {
        return Apollo.useQuery<ExampleQueryQuery, ExampleQueryQueryVariables>(ExampleQueryDocument, baseOptions);
      }
export function useExampleQueryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ExampleQueryQuery, ExampleQueryQueryVariables>) {
          return Apollo.useLazyQuery<ExampleQueryQuery, ExampleQueryQueryVariables>(ExampleQueryDocument, baseOptions);
        }
export type ExampleQueryQueryHookResult = ReturnType<typeof useExampleQueryQuery>;
export type ExampleQueryLazyQueryHookResult = ReturnType<typeof useExampleQueryLazyQuery>;
export type ExampleQueryQueryResult = Apollo.QueryResult<ExampleQueryQuery, ExampleQueryQueryVariables>;
export const AuthUserDocument = gql`
    query authUser {
  me {
    id
    email
    businessBackgroundCheck {
      status
    }
    ownerBusinesses {
      id
    }
    acceptedTermsAndConditions
  }
}
    `;
export type AuthUserComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<AuthUserQuery, AuthUserQueryVariables>, 'query'>;

    export const AuthUserComponent = (props: AuthUserComponentProps) => (
      <ApolloReactComponents.Query<AuthUserQuery, AuthUserQueryVariables> query={AuthUserDocument} {...props} />
    );
    

/**
 * __useAuthUserQuery__
 *
 * To run a query within a React component, call `useAuthUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useAuthUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAuthUserQuery({
 *   variables: {
 *   },
 * });
 */
export function useAuthUserQuery(baseOptions?: Apollo.QueryHookOptions<AuthUserQuery, AuthUserQueryVariables>) {
        return Apollo.useQuery<AuthUserQuery, AuthUserQueryVariables>(AuthUserDocument, baseOptions);
      }
export function useAuthUserLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AuthUserQuery, AuthUserQueryVariables>) {
          return Apollo.useLazyQuery<AuthUserQuery, AuthUserQueryVariables>(AuthUserDocument, baseOptions);
        }
export type AuthUserQueryHookResult = ReturnType<typeof useAuthUserQuery>;
export type AuthUserLazyQueryHookResult = ReturnType<typeof useAuthUserLazyQuery>;
export type AuthUserQueryResult = Apollo.QueryResult<AuthUserQuery, AuthUserQueryVariables>;