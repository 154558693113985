import React from "react"
import { connect } from "react-redux"

import { IRootState, TRootDispatch } from "core"

import { KYBSolePropDbaScreen } from "./kyb-sole-prop-dba.screen"

import { KYBActions } from "../redux"

interface IKYBSolePropDbaStateProps {
  dba: string
}

interface IKYBSolePropDbaDispatchProps {
  setDBA: (dba: string) => void
}

export type TKYBSolePropDbaProps = IKYBSolePropDbaStateProps & IKYBSolePropDbaDispatchProps

const mapStateToProps = (state: IRootState): IKYBSolePropDbaStateProps => ({
  dba: state.KYB.dba,
})

const mapDispatchToProps = (dispatch: TRootDispatch): IKYBSolePropDbaDispatchProps => ({
  setDBA: (dba: string) => dispatch(KYBActions.setDBA(dba)),
})

export const KYBSolePropDba = connect(
  mapStateToProps,
  mapDispatchToProps,
)((props: TKYBSolePropDbaProps) => <KYBSolePropDbaScreen {...props} />)
