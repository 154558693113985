import React, { useState } from "react"
import { useTranslation } from "react-i18next"
import { BaseLayout, SingleTextInputScreen, SidePanel, ButtonRow } from "shared"
import { isValidDateAndAge } from "utils/validators"

interface IBirthDateScreenProps {
  firstName: string
  birthDate: string
  setBirthDate: (birthDate: string) => void
  prev: () => void
  next: () => void
}

export const BirthDateScreen: React.FC<IBirthDateScreenProps> = ({
  firstName,
  birthDate,
  setBirthDate,
  prev,
  next,
}) => {
  const { t } = useTranslation()
  const [newBirthDate, setNewBirthDate] = useState(birthDate)

  const validDate = () => isValidDateAndAge(newBirthDate, { maxAge: 200 })
  const isOver18 = () => isValidDateAndAge(newBirthDate, { minAge: 18 })
  const inputIsValid = () => validDate() && isOver18()

  const getValidationText = () => {
    if (newBirthDate.length >= 10) {
      if (!validDate()) {
        return t("kyc.birthDate.info")
      }
      if (!isOver18()) {
        return t("kyc.birthDate.error")
      }
    }
    return ""
  }

  return (
    <BaseLayout
      sidePanel={<SidePanel section={2} />}
      body={
        <SingleTextInputScreen
          title={t("shared.screens.birthDate.title", { firstName })}
          onChange={setNewBirthDate}
          value={newBirthDate}
          placeholderText={t("shared.screens.birthDate.placeholder")}
          dateType={true}
          validationType={!inputIsValid() ? "error" : undefined}
          validationText={getValidationText()}
        />
      }
      buttonRow={
        <ButtonRow
          buttonOnClick={() => {
            setBirthDate(newBirthDate)
            next()
          }}
          buttonDisabled={!inputIsValid()}
        />
      }
      onBackClick={prev}
    />
  )
}
