import React from "react"
import { PhoneNumberScreen } from "shared"
import { nav } from "core/navigation"
import { TUBOPhoneProps } from "./ubo-phone.container"

export const UBOPhoneScreen: React.FC<TUBOPhoneProps> = ({ firstName, phone, setValue }) => {
  const { prev, next } = nav.onboarding.ubo.pages.UBOPhone

  return (
    <PhoneNumberScreen
      firstName={firstName}
      phone={phone}
      setPhoneNumber={(newPhone) => setValue("phoneNumber", newPhone)}
      prev={prev}
      next={next}
    />
  )
}
