import React, { useState } from "react"
import { useTranslation } from "react-i18next"
import { BaseLayout, SingleTextInputScreen, SidePanel, ButtonRow } from "shared"
import { phoneRegex } from "utils"

interface IPhoneNumberScreenProps {
  firstName: string
  phone: string
  setPhoneNumber: (phone: string) => void
  prev: () => void
  next: () => void
}

export const PhoneNumberScreen: React.FC<IPhoneNumberScreenProps> = ({
  firstName,
  phone: phoneInitial,
  setPhoneNumber,
  prev,
  next,
}) => {
  const { t } = useTranslation()
  const [phoneNumber, _setPhoneNumber] = useState(phoneInitial)
  const phoneIsValid = phoneRegex.test(phoneNumber)

  return (
    <BaseLayout
      sidePanel={<SidePanel section={2} />}
      body={
        <SingleTextInputScreen
          subtitle={t("shared.screens.phone.subtitle")}
          title={t("shared.screens.phone.title", { firstName })}
          onChange={_setPhoneNumber}
          value={phoneNumber}
          placeholderText={"+1"}
          phoneType={true}
          disableMinimizedPlaceholderText
        />
      }
      buttonRow={
        <ButtonRow
          buttonDisabled={!phoneIsValid}
          buttonOnClick={() => {
            setPhoneNumber(phoneNumber)
            next()
          }}
        />
      }
      onBackClick={prev}
    />
  )
}
