import React from "react"
import { connect } from "react-redux"

import { IRootState, TRootDispatch } from "core"
import { CorporationType } from "types"

import { KYBBusinessTypeScreen } from "./kyb-business-type.screen"

import { KYBActions } from "../redux"

interface IKYBBusinessTypeStateProps {
  firstName: string
  businessType: CorporationType
  hasMultipleOwners: boolean
}

interface IKYBBusinessTypeDispatchProps {
  setBusinessType: (businessType: CorporationType) => void
  setMultipleOwners: (hasMultipleOwners: boolean) => void
}

export type TKYBBusinessTypeProps = IKYBBusinessTypeStateProps & IKYBBusinessTypeDispatchProps

const mapStateToProps = (state: IRootState): IKYBBusinessTypeStateProps => ({
  firstName: state.KYC.firstName,
  businessType: state.KYB.businessType,
  hasMultipleOwners: state.KYB.hasMultipleOwners,
})

const mapDispatchToProps = (dispatch: TRootDispatch): IKYBBusinessTypeDispatchProps => ({
  setBusinessType: (businessType: CorporationType) =>
    dispatch(KYBActions.setBusinessType(businessType)),
  setMultipleOwners: (hasMultipleOwners: boolean) =>
    dispatch(KYBActions.setMultipleOwners(hasMultipleOwners)),
})

export const KYBBusinessType = connect(
  mapStateToProps,
  mapDispatchToProps,
)((props: TKYBBusinessTypeProps) => <KYBBusinessTypeScreen {...props} />)
