import React from "react"
import { connect } from "react-redux"

import { IRootState, TRootDispatch } from "core"

import { KYBDateStateScreen } from "./kyb-date-state.screen"

import { KYBActions } from "../redux"
import { CorporationType } from "types"

interface IKYBDateStateStateProps {
  date: string
  state: string
  businessType: CorporationType
}

interface IKYBDateStateDispatchProps {
  setDate: (date: string) => void
  setState: (state: string) => void
}

export type TKYBDateStateProps = IKYBDateStateStateProps & IKYBDateStateDispatchProps

const mapStateToProps = (state: IRootState): IKYBDateStateStateProps => ({
  date: state.KYB.date,
  state: state.KYB.state,
  businessType: state.KYB.businessType,
})

const mapDispatchToProps = (dispatch: TRootDispatch): IKYBDateStateDispatchProps => ({
  setDate: (date: string) => dispatch(KYBActions.setDate(date)),
  setState: (state: string) => dispatch(KYBActions.setState(state)),
})

export const KYBDateState = connect(
  mapStateToProps,
  mapDispatchToProps,
)((props: TKYBDateStateProps) => <KYBDateStateScreen {...props} />)
