import React, { useState } from "react"
import { useTranslation } from "react-i18next"
import { Box } from "rebass"
import { BaseLayout, BaseScreen, SidePanel, RadioButton, ButtonRow } from "shared"
import { UBOTypeEnum } from "types"
import { TKYBUBOTypeProps } from "./kyb-ubo-type.container"
import { nav } from "core/navigation"
import { Analytics } from "../../../../core/analytics/actions"

export const KYBUBOTypeScreen: React.FC<TKYBUBOTypeProps> = ({
  UBOType,
  setUBOType,
  setPercentOwnership,
}) => {
  const { t } = useTranslation()
  const [newUBOType, setNewUBOType] = useState<UBOTypeEnum>(UBOType)
  const valueIsValid = () => newUBOType
  const { prev, next } = nav.onboarding.kyb.pages.KYBUBOType
  const isSingleOwner = Boolean(newUBOType === UBOTypeEnum.singleOwner)

  return (
    <BaseLayout
      sidePanel={<SidePanel section={2} />}
      body={
        <BaseScreen title={t("kyb.UBOType.title")}>
          <Box style={{ marginTop: "2rem", flexDirection: "column", display: "flex" }}>
            {[
              { type: "singleOwner", value: UBOTypeEnum.singleOwner },
              { type: "multipleOwners", value: UBOTypeEnum.multipleOwners },
            ].map(({ type, value }, index) => (
              <RadioButton
                key={index}
                label={t(`kyb.UBOType.${type}`)}
                isSelected={newUBOType === value}
                onClick={() => {
                  setNewUBOType(value)
                  Analytics.track(`KYB UBO Type ${value} Continue Tap`)
                }}
                infoText={t(`kyb.UBOType.infoText.${type}`)}
              />
            ))}
          </Box>
        </BaseScreen>
      }
      buttonRow={
        <ButtonRow
          buttonOnClick={() => {
            if (newUBOType) {
              if (isSingleOwner) {
                setPercentOwnership("100")
              }
              setUBOType(newUBOType)
            }
            Analytics.track(`KYB UBO Type Continue Tap`)
            next(newUBOType)
          }}
          buttonDisabled={!valueIsValid()}
        />
      }
      onBackClick={prev}
    />
  )
}
