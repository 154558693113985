import React from "react"
import { connect } from "react-redux"
import { IRootState, TRootDispatch } from "core"
import { BackgroundCheckSubmitScreen } from "./background-check-submit.screen.gql"

import {
  getBusinessForBackgroundCheck,
  getOwnersForBackgroundCheck,
} from "components/onboarding/redux"
import { BusinessBackgroundCheckMutationVariables } from "generated/graphql"
import { ErrorsActions } from "components"

import { GlobalActions } from "core/redux/actions"
import { IDebitFundingState } from "components/onboarding/debit-funding"
import { getDebitFundingInfo } from "components/onboarding/debit-funding/redux/debit-funding.selectors"

interface IBackgroundCheckSubmitStateProps {
  business: BusinessBackgroundCheckMutationVariables["business"]
  owners: BusinessBackgroundCheckMutationVariables["owners"]
  debitFundingInfo?: IDebitFundingState
  applicantEmail: string
  CJEVENT: string
}

interface IBackgroundCheckSubmitDispatchProps {
  setCurrentErrorMessage: (errorMessage: string) => void
  resetReduxState: () => void
}
export type TBackgroundCheckSubmitProps = IBackgroundCheckSubmitStateProps &
  IBackgroundCheckSubmitDispatchProps

const mapStateToProps = (state: IRootState): IBackgroundCheckSubmitStateProps => ({
  business: getBusinessForBackgroundCheck(state),
  owners: getOwnersForBackgroundCheck(state),
  debitFundingInfo: getDebitFundingInfo(state),
  applicantEmail: state.signUp.email,
  CJEVENT: state.CJEVENT.CJEVENT,
})

const mapDispatchToProps = (dispatch: TRootDispatch): IBackgroundCheckSubmitDispatchProps => ({
  setCurrentErrorMessage: (errorMessage: string) =>
    dispatch(ErrorsActions.setCurrentErrorMessage(errorMessage)),
  resetReduxState: () => dispatch(GlobalActions.resetReduxState()),
})

export const BackgroundCheckSubmit = connect(
  mapStateToProps,
  mapDispatchToProps,
)((props: TBackgroundCheckSubmitProps) => <BackgroundCheckSubmitScreen {...props} />)
