import React from "react"
import { connect } from "react-redux"

import { IRootState, TRootDispatch } from "core"
import { nav } from "core/navigation"
import { ControlPersonActions } from "../redux"
import { ResidencyScreen } from "shared"

interface IControlPersonResidencyStateProps {
  residency: string
  firstName: string
}

interface IControlPersonResidencyDispatchProps {
  setResidency: (residency: string) => void
}

export type TControlPersonResidencyProps = IControlPersonResidencyStateProps &
  IControlPersonResidencyDispatchProps

const mapStateToProps = (state: IRootState): IControlPersonResidencyStateProps => ({
  firstName: state.controlPerson.candidate.firstName,
  residency: state.controlPerson.candidate.residency,
})

const mapDispatchToProps = (dispatch: TRootDispatch): IControlPersonResidencyDispatchProps => ({
  setResidency: (residency: string) => dispatch(ControlPersonActions.setResidency(residency)),
})

export const ControlPersonResidencyScreen: React.FC<TControlPersonResidencyProps> = ({
  firstName,
  residency,
  setResidency,
}) => {
  const { prev, next } = nav.onboarding.controlPerson.pages.ControlPersonResidency

  return (
    <ResidencyScreen
      firstName={firstName}
      residency={residency}
      setResidency={setResidency}
      prev={prev}
      next={next}
    />
  )
}

export const ControlPersonResidency = connect(
  mapStateToProps,
  mapDispatchToProps,
)((props: TControlPersonResidencyProps) => <ControlPersonResidencyScreen {...props} />)
