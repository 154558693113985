import React from "react"
import { connect } from "react-redux"

import { IRootState, TRootDispatch } from "core"
import { nav } from "core/navigation"
import { ControlPersonActions } from "../redux"
import { BirthDateScreen } from "shared"

interface IControlPersonBirthDateStateProps {
  firstName: string
  birthDate: string
}

interface IControlPersonBirthDateDispatchProps {
  setBirthDate: (birthDate: string) => void
}

export type TControlPersonBirthDateProps = IControlPersonBirthDateStateProps &
  IControlPersonBirthDateDispatchProps

const mapStateToProps = (state: IRootState): IControlPersonBirthDateStateProps => ({
  firstName: state.controlPerson.candidate.firstName,
  birthDate: state.controlPerson.candidate.birthDate,
})

const mapDispatchToProps = (dispatch: TRootDispatch): IControlPersonBirthDateDispatchProps => ({
  setBirthDate: (birthDate: string) => dispatch(ControlPersonActions.setBirthDate(birthDate)),
})

export const ControlPersonBirthDateScreen: React.FC<TControlPersonBirthDateProps> = ({
  firstName,
  birthDate,
  setBirthDate,
}) => {
  const { prev, next } = nav.onboarding.controlPerson.pages.ControlPersonBirthDate

  return (
    <BirthDateScreen
      firstName={firstName}
      birthDate={birthDate}
      setBirthDate={setBirthDate}
      prev={prev}
      next={next}
    />
  )
}

export const ControlPersonBirthDate = connect(
  mapStateToProps,
  mapDispatchToProps,
)((props: TControlPersonBirthDateProps) => <ControlPersonBirthDateScreen {...props} />)
