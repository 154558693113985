import { IAddress } from "types"

export interface IKYCState {
  firstName: string
  lastName: string
  birthDate: string
  homeAddress: IAddress
  jobTitle: string
  role: string
  residency: string
  SSN: string
  unsupportedCountry: string
}

export const initialKYCState: IKYCState = {
  firstName: "",
  lastName: "",
  birthDate: "",
  homeAddress: {
    streetAddressLine1: "",
    streetAddressLine2: "",
    city: "",
    provinceState: "",
    postalCode: "",
    country: "",
  },
  jobTitle: "",
  role: "",
  residency: "",
  SSN: "",
  unsupportedCountry: "",
}
