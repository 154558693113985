import React from "react"
import { Box, Image } from "rebass"
import { Analytics } from "core/analytics/actions"
import GooglePlay from "images/GooglePlay.svg"
import AppStore from "images/AppStore.svg"
import { APP_STORE_URL, GOOGLE_PLAY_STORE_URL } from "utils/constants"

interface IProps {
  analyticsEventAppStore: string
  analyticsEventGoogleStore: string
}

export const AppStoreLinks: React.FC<IProps> = ({
  analyticsEventAppStore,
  analyticsEventGoogleStore,
}) => {
  const handleClick = (buttonLink: string) => {
    window.location.assign(buttonLink)
  }

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "left",
        alignItems: "center",
      }}
    >
      <Image
        src={AppStore}
        sx={{ cursor: "pointer" }}
        height="40px"
        onClick={() => {
          handleClick(APP_STORE_URL)
          Analytics.track(analyticsEventAppStore)
        }}
      />
      <Image
        src={GooglePlay}
        sx={{ cursor: "pointer", paddingLeft: "2" }}
        height="63px"
        onClick={() => {
          handleClick(GOOGLE_PLAY_STORE_URL)
          Analytics.track(analyticsEventGoogleStore)
        }}
      />
    </Box>
  )
}
