import React from "react"
import { connect } from "react-redux"

import { IRootState, TRootDispatch } from "core"

import { KYBPhoneScreen } from "./kyb-phone.screen"

import { KYBActions } from "../redux"
import { CorporationType } from "generated/graphql"

interface IKYBPhoneStateProps {
  personalPhone: string
  phone: string
  businessType: CorporationType
}

interface IKYBPhoneDispatchProps {
  setPhone: (phone: string) => void
}

export type TKYBPhoneProps = IKYBPhoneStateProps & IKYBPhoneDispatchProps

const mapStateToProps = (state: IRootState): IKYBPhoneStateProps => ({
  personalPhone: state.signUp.phone,
  phone: state.KYB.phone,
  businessType: state.KYB.businessType,
})

const mapDispatchToProps = (dispatch: TRootDispatch): IKYBPhoneDispatchProps => ({
  setPhone: (phone: string) => dispatch(KYBActions.setPhone(phone)),
})

export const KYBPhone = connect(
  mapStateToProps,
  mapDispatchToProps,
)((props: TKYBPhoneProps) => <KYBPhoneScreen {...props} />)
