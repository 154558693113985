import React from "react"
import { nav } from "core/navigation"
import { TButtonType, Button } from "shared"
import { useTranslation } from "react-i18next"
import { Box, Image, Text, Heading } from "rebass"
import northOneColor from "images/NorthOne-Color.svg"
import notFound from "images/notFound.png"
import { theme } from "theme/theme"

interface IProps {
  header?: React.ReactNode
  buttonDisabled?: boolean
  buttonType?: TButtonType
  buttonSupertext?: string
  buttonSupertextAction?: () => void
  buttonIsLoading?: boolean
}

export const NotFound: React.SFC<IProps> = ({
  header,
  buttonDisabled,
  buttonSupertext,
  buttonSupertextAction,
  buttonType = "primary",
  buttonIsLoading,
}) => {
  const { t } = useTranslation()

  return (
    <Box
      sx={{
        display: "grid",
        gridTemplateColumns: "repeat(14, 1fr)",
        gridTemplateRows: "repeat(7, 1fr)",
        height: "100vh",
      }}
    >
      <Box sx={{ gridRowStart: 1, gridColumnStart: 2, gridColumnEnd: 5, marginTop: "2rem" }}>
        <Image src={northOneColor} />
      </Box>
      <Box sx={{ gridColumnStart: [2, 2, 3, 3], gridColumnEnd: [8, 8, 9, 9], gridRow: 1 }}>
        {header}
      </Box>
      <Box
        sx={{
          gridColumnStart: [3, 3, 3, 3],
          gridColumnEnd: [6, 6, 6, 6],
          gridRowStart: [3, 3, 3, 3],
          gridRowEnd: 6,
          display: "flex",
        }}
      >
        <Image
          style={{
            objectFit: "contain",
          }}
          mb={5}
          src={notFound}
        />
      </Box>
      <Box
        sx={{
          gridColumnStart: [7, 7, 7, 7],
          gridColumnEnd: [14, 14, 14, 14],
          gridRowStart: [3, 3, 3, 3],
          gridRowEnd: 5,
          marginTop: 5,
        }}
      >
        <Heading marginBottom="1rem" fontSize={theme.fontStyles.h1} color={theme.colors.black}>
          {t("notFound.title")}
        </Heading>
        <Text color={theme.colors.grey} {...theme.fontStyles.h1}>
          {t("notFound.subtitle")}
        </Text>

        <Box sx={{ display: "flex", marginTop: 4 }}>
          <Button
            disabled={buttonDisabled}
            withEnter
            onClick={nav.onboarding.utils.goToStart}
            buttonSupertext={buttonSupertext}
            buttonSupertextAction={buttonSupertextAction}
            type={buttonType}
            isLoading={buttonIsLoading}
          >
            {t("notFound.button")}
          </Button>
        </Box>
      </Box>
    </Box>
  )
}
