import React, { useState } from "react"
import { useTranslation } from "react-i18next"
import { CardInterstitialLayout, AddressBlock } from "shared"
import { CorporationType } from "types"
import { TKYBCardSampleProps } from "./kyb-card-sample.container"
import { MailingAddressLocationEnum } from "generated/graphql"
import { nav } from "core/navigation"
import { Analytics } from "../../../../core/analytics/actions"

export const KYBCardSampleScreen: React.FC<TKYBCardSampleProps> = ({
  homeAddress,
  businessAddress,
  mailingAddress,
  setMailingAddress,
  businessType,
  firstName,
  lastName,
  dba,
  legalName,
}) => {
  const { t } = useTranslation()
  const [addressType, setAddressType] = useState(mailingAddress)
  const showAddressToggle =
    homeAddress.streetAddressLine1 === businessAddress.streetAddressLine1 &&
    homeAddress.streetAddressLine2 === businessAddress.streetAddressLine2 &&
    homeAddress.city === businessAddress.city &&
    homeAddress.provinceState === businessAddress.provinceState &&
    homeAddress.postalCode === businessAddress.postalCode
      ? false
      : true

  const _handleAddressToggle = () => {
    if (addressType === MailingAddressLocationEnum.Personal) {
      setAddressType(MailingAddressLocationEnum.Business)
    } else {
      setAddressType(MailingAddressLocationEnum.Personal)
    }
  }
  const { prev, next } = nav.onboarding.kyb.pages.KYBCardSample

  const personName = `${firstName} ${lastName}`
  const businessName = businessType === CorporationType.Soleprop ? dba || personName : legalName

  const addressDetails = {
    name: addressType === MailingAddressLocationEnum.Business ? businessName : personName,
    address: addressType === MailingAddressLocationEnum.Business ? businessAddress : homeAddress,
    type:
      addressType === MailingAddressLocationEnum.Business && showAddressToggle
        ? t("kyb.cardSample.company")
        : t("kyb.cardSample.home"),
    toggle:
      addressType === MailingAddressLocationEnum.Personal
        ? t("kyb.cardSample.useCompany")
        : t("kyb.cardSample.useHome"),
  }

  return (
    <CardInterstitialLayout
      title={t("kyb.cardSample.title")}
      subtitle={t("kyb.cardSample.subtitle")}
      buttonOnClick={() => {
        setMailingAddress(addressType)
        Analytics.track(`KYB Card Order Address Confirm Tap`)
        next(businessType)
      }}
      onBackClick={prev}
      buttonText={t("kyb.cardSample.button")}
      addressToggleOnClick={_handleAddressToggle}
      showAddressToggle={showAddressToggle}
      addressToggleText={addressDetails.toggle}
      addressBlock={
        <AddressBlock
          name={addressDetails.name}
          address={addressDetails.address}
          addressType={addressDetails.type}
        />
      }
    />
  )
}
