import { onlyNumbersRegex, allSameCharacterRegex, onlyLetters, printableChars } from "./regex"
import { usStates } from "./regex"
import { isPostalCode } from "validator"
import { WebsiteType } from "components/onboarding/kyb/redux/kyb.state"
import moment from "moment"

export const MAX_STREET_ADDRESS_LENGTH = 35
export const MAX_SUITE_NUMBER_LENGTH = 35
export const MAX_CITY_LENGTH = 21
export const MIN_DEBIT_FUNDING_AMOUNT = 50
export const MAX_DEBIT_FUNDING_AMOUNT = 1500
export const DEBIT_CARD_NUMBER_LENGTH = 16
export const ZIP_CODE_LENGTH = 5
export const CVV_LENGTH = 3
export const EXPIRY_DATE_LENGTH = 5
export const defaultValidationType = "error" as "error"

interface IAddress {
  streetAddress: string
  suite: string
  city: string
  state: string
  zipcode: string
}

interface IAgeRange {
  minAge?: number
  maxAge?: number
}

interface IStringAgeRangeParams extends IAgeRange {
  dateFormat?: string
}

interface IDebitCardDetails {
  name: string
  cardNumber: string
  cvv: string
  expiryDate: string
  zipCode: string
}

export const isValidName = (name: string) => printableChars.test(name) || onlyLetters.test(name)

export const isValidEIN = (ein: string): boolean => {
  const einNumbersOnly = ein.replace("-", "")
  return Boolean(
    ein &&
      ein.length === 10 &&
      onlyNumbersRegex.test(einNumbersOnly) &&
      !allSameCharacterRegex.test(einNumbersOnly),
  )
}

export const isValidDebitFundingAmount = (amount: string): boolean =>
  Number(amount) >= MIN_DEBIT_FUNDING_AMOUNT && Number(amount) <= MAX_DEBIT_FUNDING_AMOUNT

export const isUSState = (state: string): boolean => {
  return usStates.test(state)
}

export const isValidSSN = (ssn: string): boolean =>
  Boolean(ssn && ssn.length === 9 && onlyNumbersRegex.test(ssn) && !allSameCharacterRegex.test(ssn))

export const isPasswordMatching = (password: string, passwordConfirmation: string) =>
  Boolean(password && passwordConfirmation && password === passwordConfirmation)

export const isValidCity = (city: string) => Boolean(city.length <= MAX_CITY_LENGTH)

export const isValidStreetAddress = (streetAddress: string) =>
  Boolean(streetAddress.length <= MAX_STREET_ADDRESS_LENGTH)

export const isValidSuiteNumber = (suiteNumber: string) =>
  Boolean(suiteNumber.length <= MAX_SUITE_NUMBER_LENGTH)

export const isValidZIPCode = (value: string, country: "US") => {
  return isPostalCode(value, country)
}

export const isValidWebsiteWithPrefix = (
  value: string,
  type: WebsiteType,
  prefixLength: number,
) => {
  if (type === WebsiteType.FACEBOOK) {
    return Boolean(value.length <= 30 && value.length >= prefixLength + 1)
  }
  if (type === WebsiteType.TWITTER) {
    return Boolean(value.length <= 15 && value.length >= prefixLength + 1)
  }
  if (type === WebsiteType.INSTAGRAM) {
    return Boolean(value.length <= 50 && value.length >= prefixLength + 1)
  }
  if (type === WebsiteType.LINKEDIN) {
    return Boolean(value.length <= 30 && value.length >= prefixLength + 1)
  }
  if (type === WebsiteType.OTHER) {
    return Boolean(value.length >= prefixLength + 1) && value.indexOf(".") >= 0
  }
}

export const isValidAddress = ({ streetAddress, suite, city, state, zipcode }: IAddress) =>
  Boolean(
    streetAddress &&
      streetAddress &&
      isValidStreetAddress(streetAddress) &&
      isValidSuiteNumber(suite) &&
      city &&
      isValidCity(city) &&
      isUSState(state) &&
      isValidZIPCode(zipcode, "US"),
  )

export const isValidDate = (dateString: string, dateFormat: string = "MM/DD/YYYY"): boolean =>
  Boolean(
    dateString &&
      (!dateFormat || dateFormat.length === dateString.length) &&
      moment(dateString, dateFormat).isValid(),
  )

export const isValidCardExpiry = (expiry: string) => {
  const today = new Date()
  const expiryDate = moment(expiry, "MM-YY")
    .endOf("month")
    .startOf("day")
    .toDate()

  return today < expiryDate
}

export const isValidAge = (date: moment.Moment, { minAge, maxAge }: IAgeRange): boolean => {
  const age = moment().diff(date, "years")
  return (minAge === undefined || minAge <= age) && (maxAge === undefined || maxAge >= age)
}

export const isValidDateAndAge = (dateString: string, params: IStringAgeRangeParams): boolean =>
  Boolean(
    isValidDate(dateString, params.dateFormat) &&
      isValidAge(moment(dateString, params.dateFormat), params),
  )

export const isValidCardDetails = ({
  name,
  cardNumber,
  cvv,
  expiryDate,
  zipCode,
}: IDebitCardDetails) => {
  return (
    name &&
    cardNumber.length === DEBIT_CARD_NUMBER_LENGTH &&
    cvv.length === CVV_LENGTH &&
    expiryDate.length === EXPIRY_DATE_LENGTH &&
    isValidCardExpiry(expiryDate) &&
    isValidZIPCode(zipCode, "US")
  )
}
