import React from "react"
import { useTranslation } from "react-i18next"
import { TUBONameProps } from "./ubo-name.container"
import { nav } from "core/navigation"
import { NameScreen } from "shared/name-screen"
import { onlyLetters, printableChars } from "utils"

export const UBONameScreen: React.FC<TUBONameProps> = ({ firstName, lastName, setValue }) => {
  const { t } = useTranslation()
  const { prev, next } = nav.onboarding.ubo.pages.UBOName
  const setNameWithValidation = (key: "firstName" | "lastName") => (newName: string) =>
    onlyLetters.test(newName) || printableChars.test(newName) || newName.length < 1
      ? setValue(key, newName)
      : null
  return (
    <NameScreen
      title={t("ubo.name.title")}
      initialFirstName={firstName}
      initialLastName={lastName}
      prev={prev}
      next={next}
      setFirstName={setNameWithValidation("firstName")}
      setLastName={setNameWithValidation("lastName")}
    />
  )
}
