export const routes = {
  OWNERSHIP_LIST: "/ubo/ownership-list",
  UBO_INFO: "/ubo/info",
  UBO_NAME: "/ubo/name",
  UBO_REVIEW: "/ubo/review/:ownerId",
  UBO_SSN: "/ubo/ssn",
  UBO_EMAIL: "/ubo/email",
  UBO_ROLE: "/ubo/role",
  UBO_BIRTHDATE: "/ubo/birthdate",
  UBO_PHONE: "/ubo/phone",
  UBO_PERCENTAGE: "/ubo/percentage",
  UBO_ADDRESS: "/ubo/address",
  UBO_RESIDENCY: "/ubo/residency",
  UBO_UNSUPPORTED_COUNTRY: "/ubo/unsupported-country",
  UBO_UNSUPPORTED_COUNTRY_SUCCESS: "/ubo/unsupported-country-success",
}

type RoutesAction = keyof typeof routes

export const components: Record<RoutesAction, string> = {
  OWNERSHIP_LIST: "OwnershipList",
  UBO_INFO: "UBOInfo",
  UBO_NAME: "UBOName",
  UBO_REVIEW: "UBOReview",
  UBO_SSN: "UBOSSN",
  UBO_EMAIL: "UBOEmail",
  UBO_ROLE: "UBORole",
  UBO_BIRTHDATE: "UBOBirthDate",
  UBO_PHONE: "UBOPhone",
  UBO_PERCENTAGE: "UBOPercentage",
  UBO_ADDRESS: "UBOAddress",
  UBO_RESIDENCY: "UBOResidency",
  UBO_UNSUPPORTED_COUNTRY: "UBOUnsupportedCountry",
  UBO_UNSUPPORTED_COUNTRY_SUCCESS: "UBOUnsupportedCountrySuccess",
}
