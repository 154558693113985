import React from "react"
import { AddressScreen } from "shared"
import { TUBOAddressProps } from "./ubo-address.container"
import { nav } from "core/navigation"
import { useTranslation } from "react-i18next"

export const UBOAddressScreen: React.FC<TUBOAddressProps> = ({
  homeAddress,
  firstName,
  setAddress,
}) => {
  const { prev, next } = nav.onboarding.ubo.pages.UBOAddress
  const { t } = useTranslation()
  return (
    <AddressScreen
      defaultAddress={homeAddress}
      title={t("shared.screens.address.title", { firstName })}
      setAddress={(homeAddress) => setAddress("homeAddress", homeAddress)}
      prev={prev}
      next={next}
    />
  )
}
