export enum CorporationType {
  Ccorp = "CCORP",
  Llc = "LLC",
  Scorp = "SCORP",
  Partnership = "PARTNERSHIP",
  Soleprop = "SOLEPROP",
}

export interface IAddress {
  streetAddressLine1: string
  streetAddressLine2: string
  city: string
  provinceState: string
  postalCode: string
  country: string
}

export interface IOwner {
  id: string
  iovationBlackbox: string
  firstName: string
  lastName: string
  birthDate: string
  homeAddress: IAddress
  citizenship: string
  emailAddress: string
  ownershipPercentage: string
  phoneNumber: string
  role: string
  jobTitle: string
  ssn: string
  unsupportedCountry: string
  inviteToJoin?: boolean
}

export interface IControlPerson {
  firstName: string
  lastName: string
  jobTitle: string
  email: string
  homeAddress: IAddress
  phone: string
  birthDate: string
  residency: string
  unsupportedCountry: string
  SSN: string
  id: string
}

export interface IEditOwner {
  id?: string
  firstName?: string
  lastName?: string
  birthDate?: string
  homeAddress?: IAddress
  citizenship?: string
  emailAddress?: string
  ownershipPercentage?: string
  phoneNumber?: string
  role?: string
  jobTitle?: string
  unsupportedCountry?: string
  inviteToJoin?: boolean
}

export enum UBOTypeEnum {
  singleOwner = "SingleOwner",
  multipleOwners = "MultipleOwners",
}

export enum CJEVENTTypeEnum {
  CJEVENT = "CJEVENT",
}
