import React from "react"
import { connect } from "react-redux"

import { IRootState, TRootDispatch } from "core"

import { KYBWebsiteScreen } from "./kyb-website.screen"

import { KYBActions } from "../redux"
import { CorporationType } from "types"

interface IKYBWebsiteStateProps {
  website: string
  businessType: CorporationType
}

interface IKYBWebsiteDispatchProps {
  setWebsite: (website: string) => void
}

export type TKYBWebsiteProps = IKYBWebsiteStateProps & IKYBWebsiteDispatchProps

const mapStateToProps = (state: IRootState): IKYBWebsiteStateProps => ({
  website: state.KYB.website,
  businessType: state.KYB.businessType,
})

const mapDispatchToProps = (dispatch: TRootDispatch): IKYBWebsiteDispatchProps => ({
  setWebsite: (website: string) => dispatch(KYBActions.setWebsite(website)),
})

export const KYBWebsite = connect(
  mapStateToProps,
  mapDispatchToProps,
)((props: TKYBWebsiteProps) => <KYBWebsiteScreen {...props} />)
