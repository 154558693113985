import React, { useState, useCallback } from "react"
import { isNumber } from "util"

interface IInputSelection {
  start: number
  end: number
}

interface IUseObfuscatedParams {
  isHidden: boolean
  obfuscationChar?: string
}

interface IUseObfuscatedResult {
  onSelect: (event: React.ChangeEvent<HTMLInputElement>) => void
  onChange: (
    onChange: (value: string | number) => void,
    prevValue: string | number,
  ) => (event: React.ChangeEvent<HTMLInputElement>) => void
  display: (value: string | number) => string | number
}

/**
 * React hook to conditionally obfuscate an input value while keeping track of state
 */
export const useObfuscatedInput = ({
  isHidden,
  obfuscationChar = "•",
}: IUseObfuscatedParams): IUseObfuscatedResult => {
  const [selection, setSelection] = useState<IInputSelection | null>(null)

  /**
   * onSelect listener that will be used to
   * keep track of the cursor selection before changes
   */
  const onSelect = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setSelection({
        start: event.target.selectionStart as number,
        end: event.target.selectionEnd as number,
      })
    },
    [setSelection],
  )

  /**
   * Wraps an onChange event from input element and uses the cursor's selection range
   * before and after the change to calculate the new value
   * -
   * Because once the obfuscated value is passed in to the input, future onChange events
   * include the obfuscationChar instead of proper values - thus we need to splice the changes
   * -
   * ex. inputing another value of 5 might result in •••••5
   * -
   * We use the selection indexes to extract the new characters (which may be none) and
   * delete the old characters
   * -
   * This will allow the user to change the input through any normal method: copy+paste,
   * selection+replace, and keyboard events
   * -
   * Ex. If the user selects the first 3 chars within the input and types in a new single value,
   * selection will be {start: 0, end: 3} before input and {start: 1, end: 1} after the input.
   * We will append the first char from the passed event as it will be our new character and append
   * to it the other digits of our value, starting from the 3rd index to account for the deletion
   * -
   * @param event The default input change event from the dom element
   */
  const handleChange = (onChange: (val: string | number) => void, prevValue: string | number) => (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const alertOnChange = (val: string) => onChange(isNumber(prevValue) ? parseInt(val, 10) : val)
    const newValue = event.target.value
    const prevValueString = prevValue.toString()
    if (!isHidden) {
      alertOnChange(newValue)
    } else if (selection) {
      // Uses cursor selection to splice existing value with new value
      // Get the start of the newly entered values - minimum to account for deletions
      const startIndex = Math.min(event.target.selectionStart as number, selection.start)
      // Get the final index of the newly entered values
      const endIndex = event.target.selectionEnd as number
      alertOnChange(
        prevValueString.substring(0, startIndex) +
          newValue.substring(startIndex, endIndex) +
          prevValueString.substr(selection.end),
      )
    }
  }

  /**
   * Conditionally selects the obfuscated or natural value based on isHidden
   * @param value Value to obfuscate
   */
  const display = (value: string | number) =>
    isHidden ? obfuscationChar.repeat(value.toString().length) : value

  return {
    onSelect,
    onChange: handleChange,
    display,
  }
}
