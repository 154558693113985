import React from "react"
import { connect } from "react-redux"
import { IRootState, TRootDispatch } from "core"
import { IOwner, IControlPerson, UBOTypeEnum } from "types"
import { getCompiledInitialOwner, getUBOAdditionalOwners, hasMultipleOwners } from "../../ubo/redux"
import { ControlPersonSelectScreen } from "./control-person-select.screen"
import { ControlPersonActions, getControlPersonCandidate } from "../redux"
import { CorporationType } from "generated/graphql"

interface IControlPersonStateProps {
  firstOwner: IOwner
  additionalOwners: IOwner[]
  controlPersonCandidate: IControlPerson
  hasMultipleOwners: boolean
  controlPersonEmail: string
  businessType: CorporationType
  uboType?: UBOTypeEnum
}

export interface IControlPersonDispatchProps {
  selectControlPerson: (email: string) => void
}

export type TControlPersonProps = IControlPersonStateProps & IControlPersonDispatchProps

const mapStateToProps = (state: IRootState): IControlPersonStateProps => ({
  businessType: state.KYB.businessType,
  firstOwner: getCompiledInitialOwner(state),
  additionalOwners: getUBOAdditionalOwners(state),
  hasMultipleOwners: hasMultipleOwners(state),
  controlPersonCandidate: getControlPersonCandidate(state),
  controlPersonEmail: state.controlPerson.selected.email,
  uboType: state.KYB.UBOType,
})

const mapDispatchToProps = (dispatch: TRootDispatch): IControlPersonDispatchProps => ({
  selectControlPerson: (email: string) => dispatch(ControlPersonActions.selectControlPerson(email)),
})

export const ControlPersonSelect = connect(
  mapStateToProps,
  mapDispatchToProps,
)((props: TControlPersonProps) => <ControlPersonSelectScreen {...props} />)
