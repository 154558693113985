import React from "react"
import { Box, Image, Text } from "rebass"
import Checkedbox from "images/Checkedbox.svg"
import EmptyCheckbox from "images/EmptyCheckbox.svg"
import { theme } from "theme/theme"

interface IProps {
  isSelected: boolean
  label: string
  onClick: () => void
}

export const Checkbox: React.SFC<IProps> = ({ isSelected, label, onClick }) => {
  return (
    <Box
      style={{ display: "flex", flexDirection: "row", alignItems: "baseline", marginTop: "2rem" }}
      onClick={onClick}
    >
      <Image src={isSelected ? Checkedbox : EmptyCheckbox} />
      <Text style={{ alignSelf: "flex-end" }} marginLeft="0.5rem" color={theme.colors.grey}>
        {label}
      </Text>
    </Box>
  )
}
