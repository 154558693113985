import React, { useState } from "react"
import { Box } from "rebass"
import { useTranslation } from "react-i18next"
import { BaseLayout, BaseScreen, TextInput, SidePanel, Checkbox, ButtonRow } from "shared"
import { TKYBSolePropDbaProps } from "./kyb-sole-prop-dba.container"
import { nav } from "core/navigation"

export const KYBSolePropDbaScreen: React.FC<TKYBSolePropDbaProps> = ({ dba, setDBA }) => {
  const { t } = useTranslation()
  const [newName, setNewName] = useState(dba)
  const [useNoName, setNoName] = useState(false)
  const nameIsValid = newName || useNoName
  const { prev, next } = nav.onboarding.kyb.pages.KYBSolePropDba
  const noName = ""
  return (
    <BaseLayout
      sidePanel={<SidePanel section={2} />}
      body={
        <BaseScreen title={t("kyb.name.title")} subtitle={t("kyb.name.subtitle")}>
          <Box style={{ marginTop: "3.5rem" }}>
            <TextInput
              placeholderText={useNoName ? noName : t("kyb.name.placeholder")}
              onChange={setNewName}
              value={useNoName ? noName : newName}
              autoFocus={true}
              disabled={useNoName}
            />
          </Box>
          <Box>
            <Checkbox
              label={t("kyb.name.check")}
              onClick={() => {
                setNoName(!useNoName)
              }}
              isSelected={useNoName}
            />
          </Box>
        </BaseScreen>
      }
      buttonRow={
        <ButtonRow
          buttonOnClick={() => {
            setDBA(useNoName ? noName : newName)
            next()
          }}
          buttonDisabled={!nameIsValid}
        />
      }
      onBackClick={prev}
    />
  )
}
