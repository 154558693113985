import React from "react"
import { connect } from "react-redux"

import { IRootState, TRootDispatch } from "core"

import { KYCBirthDateScreen } from "./kyc-birthdate.screen"

import { KYCActions } from "../redux"

interface IKYCBirthDateStateProps {
  firstName: string
  birthDate: string
}

interface IKYCBirthDateDispatchProps {
  setBirthDate: (birthDate: string) => void
}

export type TKYCBirthDateProps = IKYCBirthDateStateProps & IKYCBirthDateDispatchProps

const mapStateToProps = (state: IRootState): IKYCBirthDateStateProps => ({
  firstName: state.KYC.firstName,
  birthDate: state.KYC.birthDate,
})

const mapDispatchToProps = (dispatch: TRootDispatch): IKYCBirthDateDispatchProps => ({
  setBirthDate: (birthDate: string) => dispatch(KYCActions.setBirthDate(birthDate)),
})

export const KYCBirthDate = connect(
  mapStateToProps,
  mapDispatchToProps,
)((props: TKYCBirthDateProps) => <KYCBirthDateScreen {...props} />)
