import React from "react"
import { connect } from "react-redux"
import { IRootState, TRootDispatch } from "core"
import { IAddress } from "types"
import { KYBAddressScreen } from "./kyb-address.screen"
import { KYBActions } from "../redux"

interface IKYBAddressStateProps {
  businessAddress: IAddress
  homeAddress: IAddress
}

interface IKYBAddressDispatchProps {
  setBusinessAddress: (businessAddress: IAddress) => void
}

export type TKYBAddressProps = IKYBAddressStateProps & IKYBAddressDispatchProps

const mapStateToProps = (state: IRootState): IKYBAddressStateProps => ({
  businessAddress: state.KYB.businessAddress,
  homeAddress: state.KYC.homeAddress,
})

const mapDispatchToProps = (dispatch: TRootDispatch): IKYBAddressDispatchProps => ({
  setBusinessAddress: (businessAddress: IAddress) =>
    dispatch(KYBActions.setBusinessAddress(businessAddress)),
})

export const KYBAddress = connect(
  mapStateToProps,
  mapDispatchToProps,
)((props: TKYBAddressProps) => <KYBAddressScreen {...props} />)
