import React from "react"
import { connect } from "react-redux"
import { IRootState, TRootDispatch } from "core"
import { IAddress, CorporationType } from "types"
import { MailingAddressLocationEnum } from "generated/graphql"
import { KYBCardSampleScreen } from "./kyb-card-sample.screen"
import { KYBActions } from "../redux"

interface IKYBCardSampleStateProps {
  homeAddress: IAddress
  mailingAddress: MailingAddressLocationEnum
  businessAddress: IAddress
  businessType: CorporationType
  legalName: string
  dba: string
  firstName: string
  lastName: string
}

interface IKYBCardSampleDispatchProps {
  setMailingAddress: (mailingAddress: MailingAddressLocationEnum) => void
}

export type TKYBCardSampleProps = IKYBCardSampleStateProps & IKYBCardSampleDispatchProps

const mapStateToProps = (state: IRootState): IKYBCardSampleStateProps => ({
  mailingAddress: state.KYB.mailingAddress,
  homeAddress: state.KYC.homeAddress,
  businessAddress: state.KYB.businessAddress,
  businessType: state.KYB.businessType,
  legalName: state.KYB.legalName,
  dba: state.KYB.dba,
  firstName: state.KYC.firstName,
  lastName: state.KYC.lastName,
})

const mapDispatchToProps = (dispatch: TRootDispatch): IKYBCardSampleDispatchProps => ({
  setMailingAddress: (mailingAddress: MailingAddressLocationEnum) =>
    dispatch(KYBActions.setMailingAddress(mailingAddress)),
})

export const KYBCardSample = connect(
  mapStateToProps,
  mapDispatchToProps,
)((props: TKYBCardSampleProps) => <KYBCardSampleScreen {...props} />)
