export const routes = {
  DEBIT_FUNDING_LANDING: "/debit-funding",
  DEBIT_FUNDING_AMOUNT: "/debit-funding/amount",
  DEBIT_FUNDING_DETAILS: "/debit-funding/details",
  DEBIT_FUNDING_SUCCESS: "/debit-funding/success",
}

type RoutesAction = keyof typeof routes

export const components: Record<RoutesAction, string> = {
  DEBIT_FUNDING_LANDING: "DebitFundingLanding",
  DEBIT_FUNDING_AMOUNT: "DebitFundingAmount",
  DEBIT_FUNDING_DETAILS: "DebitFundingDetails",
  DEBIT_FUNDING_SUCCESS: "DebitFundingSuccess",
}
