import React from "react"
import { useTranslation } from "react-i18next"
import { BaseLayout, BaseScreen, SidePanel, ButtonRow } from "shared"

export const BackgroundCheckReject: React.FC<{}> = () => {
  const { t } = useTranslation()
  return (
    <BaseLayout
      sidePanel={<SidePanel section={2} />}
      body={
        <BaseScreen
          title={t("backgroundCheck.backgroundCheckReject.title")}
          subtitle={t("backgroundCheck.backgroundCheckReject.subtitle")}
        />
      }
      buttonRow={<ButtonRow buttonOnClick={() => null} noButton />}
      noBackButton
    />
  )
}
