import React from "react"
import { connect } from "react-redux"

import { IRootState } from "core"

import { KYCCompleteScreen } from "./kyc-complete.screen"

interface IKYCCompleteStateProps {
  firstName: string
}

export type TKYCCompleteProps = IKYCCompleteStateProps

const mapStateToProps = (state: IRootState): IKYCCompleteStateProps => ({
  firstName: state.KYC.firstName,
})

export const KYCComplete = connect(mapStateToProps)((props: TKYCCompleteProps) => (
  <KYCCompleteScreen {...props} />
))
