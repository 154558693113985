import React from "react"
import { connect } from "react-redux"

import { IRootState, TRootDispatch } from "core"

import { SignUpEmailSentScreen } from "./sign-up-email-sent.screen.gql"
import { ErrorsActions } from "components/errors"

interface ISignUpEmailSentStateProps {
  email: string
}

interface ISignUpEmailSentDispatchProps {
  setCurrentErrorMessage: (errorMessage: string) => void
}

export type TSignUpEmailSentProps = ISignUpEmailSentStateProps & ISignUpEmailSentDispatchProps

const mapStateToProps = (state: IRootState): ISignUpEmailSentStateProps => ({
  email: state.signUp.email,
})

const mapDispatchToProps = (dispatch: TRootDispatch): ISignUpEmailSentDispatchProps => ({
  setCurrentErrorMessage: (errorMessage: string) =>
    dispatch(ErrorsActions.setCurrentErrorMessage(errorMessage)),
})

export const SignUpEmailSent = connect(
  mapStateToProps,
  mapDispatchToProps,
)((props: TSignUpEmailSentProps) => <SignUpEmailSentScreen {...props} />)
