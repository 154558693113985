import React from "react"
import { Box, Text } from "rebass"
import { theme } from "theme/theme"
import { IAddress } from "types"

interface IAddressBlockProps {
  address: IAddress
  addressType: string
  name: string
}

export const AddressBlock: React.SFC<IAddressBlockProps> = ({
  address: { streetAddressLine1, streetAddressLine2, city, provinceState, postalCode },
  name,
}) => (
  <Box
    fontSize={theme.fontStyles.h2.fontSize}
    sx={{
      gridRowStart: [3, 3, 5, 5],
      gridRowEnd: 7,
      gridColumnStart: 5,
      gridColumnEnd: [7, 7, 6, 6],
    }}
    color={theme.colors.white}
    marginTop="2rem"
  >
    <Text>{name}</Text>
    <Text>{streetAddressLine1}</Text>
    <Text>{streetAddressLine2}</Text>
    <Text>
      {city}, {provinceState} {postalCode}
    </Text>
  </Box>
)
