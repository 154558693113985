import React from "react"
import { DebitFundingDetailsScreen } from "./debit-funding-details.screen"
import { connect } from "react-redux"
import { DebitFundingActions } from "../redux"
import { TRootDispatch, IRootState } from "core"
import { IDebitCardDetails } from "../redux"
interface IDebitFundingDetailsStateProps {
  debitCardData: IDebitCardDetails
}

interface IDebitFundingDetailsDispatchProps {
  setCardDetails: (cardDetails: IDebitCardDetails) => void
}

const mapStateToProps = (state: IRootState): IDebitFundingDetailsStateProps => ({
  debitCardData: state.debitFunding.debitCardData,
})

const mapDispatchToProps = (dispatch: TRootDispatch): IDebitFundingDetailsDispatchProps => ({
  setCardDetails: (debitCardData: IDebitCardDetails) =>
    dispatch(DebitFundingActions.setCardDetails(debitCardData)),
})

export type TDebitFundingDetailsProps = IDebitFundingDetailsStateProps &
  IDebitFundingDetailsDispatchProps

export const DebitFundingDetails = connect(
  mapStateToProps,
  mapDispatchToProps,
)((props: TDebitFundingDetailsProps) => <DebitFundingDetailsScreen {...props} />)
