import React from "react"
import { UnsupportedCountryScreen } from "shared"
import { nav } from "core/navigation"

import { TUBOUnsupportedCountryProps } from "./ubo-unsupported-country.container"

export const UBOUnsupportedCountryScreen: React.FC<TUBOUnsupportedCountryProps> = ({
  unsupportedCountry,
  setValue,
}) => {
  const { prev, next } = nav.onboarding.ubo.pages.UBOUnsupportedCountry

  return (
    <UnsupportedCountryScreen
      unsupportedCountry={unsupportedCountry}
      setUnsupportedCountry={(unsupportedCountry) =>
        setValue("unsupportedCountry", unsupportedCountry)
      }
      prev={prev}
      next={next}
    />
  )
}
