import React from "react"
import { useTranslation } from "react-i18next"
import { BaseLayout, BaseScreen, ButtonRow, SidePanel } from "shared"
import { nav } from "core/navigation"
import { TDebitFundingSuccessStateProps } from "./debit-funding-success.container"
import { Analytics } from "core/analytics/actions"

export const DebitFundingSuccessScreen: React.FC<TDebitFundingSuccessStateProps> = ({
  amount,
  debitCardLastFourDigits,
}) => {
  const { prev, next } = nav.onboarding.debitFunding.pages.DebitFundingSuccess
  const { t } = useTranslation()
  return (
    <BaseLayout
      sidePanel={<SidePanel section={2} />}
      body={
        <BaseScreen
          title={t("debitFunding.success.title")}
          subtitle={t("debitFunding.success.subtitle", {
            amount,
            debitCardLastFourDigits,
          })}
        ></BaseScreen>
      }
      buttonRow={
        <ButtonRow
          buttonOnClick={() => {
            next()
            Analytics.track("Debit Funding Approved Continue Tap")
          }}
        />
      }
      onBackClick={prev}
    />
  )
}
