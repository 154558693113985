import React from "react"
import { connect } from "react-redux"
import { IRootState } from "core"
import { OwnershipListScreen } from "./ownership-list.screen"
import { IOwner, CorporationType } from "types"
import { getCompiledInitialOwner, getUBOAdditionalOwners, getNewOwner } from "../redux"

interface IOwnershipListStateProps {
  firstOwner: IOwner
  additionalOwners: IOwner[]
  businessType: CorporationType
  newOwner: IOwner
}

export type TOwnershipListProps = IOwnershipListStateProps

const mapStateToProps = (state: IRootState): IOwnershipListStateProps => ({
  firstOwner: getCompiledInitialOwner(state),
  additionalOwners: getUBOAdditionalOwners(state),
  businessType: state.KYB.businessType,
  newOwner: getNewOwner(state),
})

export const OwnershipList = connect(mapStateToProps)((props: TOwnershipListProps) => (
  <OwnershipListScreen {...props} />
))
