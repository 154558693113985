// ** DO NOT ADD ANY PERSONALLY IDENTIFIABLE FIELDS TO THIS STATE - FOR COMPLIANCE REASONS
export interface ISignUpState {
  phone: string
  email: string
}

export const initialSignUpState: ISignUpState = {
  phone: "",
  email: "",
}
