import React from "react"
import { connect } from "react-redux"

import { IRootState, TRootDispatch } from "core"

import { UBOEmailScreen } from "./ubo-email.screen"

import { UBOActions, getAllOwnerEmails } from "../redux"

interface IUBOEmailStateProps {
  firstName: string
  email: string
  allOwnerEmails: string[]
}

interface IUBOEmailDispatchProps {
  setValue: (key: "emailAddress", value: string) => void
}

export type TUBOEmailProps = IUBOEmailStateProps & IUBOEmailDispatchProps

const mapStateToProps = (state: IRootState): IUBOEmailStateProps => ({
  firstName: state.UBO.newOwner.firstName,
  email: state.UBO.newOwner.emailAddress,
  allOwnerEmails: getAllOwnerEmails(state),
})

const mapDispatchToProps = (dispatch: TRootDispatch): IUBOEmailDispatchProps => ({
  setValue: (key: "emailAddress", value: string) => dispatch(UBOActions.setValue(key, value)),
})

export const UBOEmail = connect(
  mapStateToProps,
  mapDispatchToProps,
)((props: TUBOEmailProps) => <UBOEmailScreen {...props} />)
