import React from "react"
import { AddressScreen } from "shared"
import { TKYCAddressProps } from "./kyc-address.container"
import { nav } from "core/navigation"
import { useTranslation } from "react-i18next"
const { prev, next } = nav.onboarding.kyc.pages.KYCAddress

export const KYCAddressScreen: React.FC<TKYCAddressProps> = ({ homeAddress, setHomeAddress }) => {
  const { t } = useTranslation()
  return (
    <AddressScreen
      title={t("kyc.address.title")}
      defaultAddress={homeAddress}
      setAddress={setHomeAddress}
      analyticEventStringOnSubmit={"KYC Verification Manual Home Address Confirm Tap"}
      next={next}
      prev={prev}
    />
  )
}
