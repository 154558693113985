import React from "react"
import { theme } from "theme/theme"

interface IProps {
  color?: string
}

export const AddIcon: React.SFC<IProps> = ({ color = theme.colors.blue }) => (
  <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="20.0893" height="20" rx="10" fill={color} />
    <path
      d="M9.31662 10.7349H5.16378V9.27881H9.31662V5.14648H10.7727V9.27881H14.905V10.7349H10.7727V14.8877H9.31662V10.7349Z"
      fill="white"
    />
  </svg>
)
