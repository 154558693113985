import { createAction, TActionsUnion } from "core"
import { CorporationType, IAddress, UBOTypeEnum } from "types"
import { MailingAddressLocationEnum } from "generated/graphql"
import { WebsiteType } from "./kyb.state"

export enum KYBActionEnums {
  SET_BUSINESS_TYPE = "SET_BUSINESS_TYPE",
  SET_DESCRIPTION = "SET_DESCRIPTION",
  SET_WEBSITE = "SET_WEBSITE",
  SET_PHONE = "SET_PHONE",
  SET_NAME = "SET_NAME",
  SET_LEGAL_NAME = "SET_LEGAL_NAME",
  SET_DBA = "SET_DBA",
  SET_BUSINESS_ADDRESS = "SET_BUSINESS_ADDRESS",
  SET_DATE = "SET_DATE",
  SET_STATE = "SET_STATE",
  SET_NATURE = "SET_NATURE",
  SET_PERCENT_OWNERSHIP = "SET_PERCENT_OWNERSHIP",
  SET_VALUE = "KYB_SET_VALUE",
  SET_MAILING_ADDRESS = "SET_MAILING_ADDRESS",
  SET_SUCCESS = "SET_SUCCESS",
  SET_MULTIPLE_OWNERS = "SET_MULTIPLE_OWNERS",
  SET_EIN = "SET_EIN",
  SET_UBO_TYPE = "SET_UBO_TYPE",
  SET_CORP_TYPE = "SET_CORP_TYPE",
  SET_WEBSITE_TYPE = "SET_WEBSITE_TYPE",
}

export const KYBActions = {
  setBusinessType: (businessType: CorporationType) =>
    createAction(KYBActionEnums.SET_BUSINESS_TYPE, businessType),
  setDescription: (description: string) =>
    createAction(KYBActionEnums.SET_DESCRIPTION, description),
  setWebsite: (website: string) => createAction(KYBActionEnums.SET_WEBSITE, website),
  setPhone: (phone: string) => createAction(KYBActionEnums.SET_PHONE, phone),
  setName: (name: string) => createAction(KYBActionEnums.SET_NAME, name),
  setLegalName: (legalName: string) => createAction(KYBActionEnums.SET_LEGAL_NAME, legalName),
  setDBA: (dba: string) => createAction(KYBActionEnums.SET_DBA, dba),
  setBusinessAddress: (businessAddress: IAddress) =>
    createAction(KYBActionEnums.SET_BUSINESS_ADDRESS, businessAddress),
  setDate: (date: string) => createAction(KYBActionEnums.SET_DATE, date),
  setState: (state: string) => createAction(KYBActionEnums.SET_STATE, state),
  setNature: (nature: number) => createAction(KYBActionEnums.SET_NATURE, nature),
  setPercentOwnership: (percent: string) =>
    createAction(KYBActionEnums.SET_PERCENT_OWNERSHIP, percent),
  setMailingAddress: (mailingAddress: MailingAddressLocationEnum) =>
    createAction(KYBActionEnums.SET_MAILING_ADDRESS, mailingAddress),
  setSuccess: (success: boolean) => createAction(KYBActionEnums.SET_SUCCESS, success),
  setMultipleOwners: (hasMultipleOwners: boolean) =>
    createAction(KYBActionEnums.SET_MULTIPLE_OWNERS, hasMultipleOwners),
  setEIN: (EIN: string) => createAction(KYBActionEnums.SET_EIN, EIN),
  setUBOType: (UBOType: UBOTypeEnum) => createAction(KYBActionEnums.SET_UBO_TYPE, UBOType),
  setCorpType: (corpType: CorporationType) => createAction(KYBActionEnums.SET_CORP_TYPE, corpType),
  setWebsiteType: (websiteType: WebsiteType) =>
    createAction(KYBActionEnums.SET_WEBSITE_TYPE, websiteType),
}

export type TKYBActions = TActionsUnion<typeof KYBActions>
