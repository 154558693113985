import React, { useState } from "react"
import { Box, Text, Flex } from "rebass"
import { theme } from "theme/theme"
import { ValidationIcon } from "shared"

interface IProps {
  infoText?: string
  infoLine2?: string
  infoLine3?: string
  errorText?: string
}

export const IconWithToolTip: React.FC<IProps> = ({
  infoText,
  infoLine2,
  infoLine3,
  errorText,
}) => {
  const [isHovering, setIsHovering] = useState(false)
  return (
    <Box sx={{ position: "relative", marginTop: "5px" }}>
      <Flex onMouseEnter={() => setIsHovering(true)} onMouseLeave={() => setIsHovering(false)}>
        <ValidationIcon type={errorText ? "error" : "info"} />
      </Flex>
      {isHovering ? (
        <Box
          backgroundColor={theme.colors.white}
          sx={{
            position: "absolute",
            width: infoLine2 ? "15rem" : "8rem",
            padding: "1rem 1rem",
            borderRadius: 15,
            boxShadow: "tooltip",
            left: 40,
            top: infoLine2 ? "-1.6rem" : "-1.1rem",
          }}
        >
          <Text
            fontSize={theme.fontStyles.smallText.fontSize}
            textAlign="left"
            color={errorText ? theme.colors.red : theme.colors.grey}
          >
            {errorText || infoText}
            <Text>{infoLine2}</Text>
            <Text>{infoLine3}</Text>
          </Text>
        </Box>
      ) : null}
    </Box>
  )
}
