import React from "react"
import { TUBOEmailProps } from "./ubo-email.container"
import { EmailScreen } from "shared"
import { nav } from "core/navigation"

export const UBOEmailScreen: React.FC<TUBOEmailProps> = ({
  firstName,
  email,
  setValue,
  allOwnerEmails,
}) => {
  const { prev, next } = nav.onboarding.ubo.pages.UBOEmail
  return (
    <EmailScreen
      firstName={firstName}
      email={email}
      setEmail={(newEmail) => setValue("emailAddress", newEmail)}
      allOwnerEmails={allOwnerEmails}
      prev={prev}
      next={next}
    />
  )
}
