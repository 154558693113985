import React from "react"
import * as Sentry from "@sentry/browser"

import { CLIENT_NAME } from "utils"
import { traceId } from "core/error-logging"

export class GlobalErrorCatch extends React.Component<{}> {
  public componentDidCatch(error: any, errorInfo: any) {
    Sentry.withScope((scope) => {
      scope.setTags({
        trace_id: traceId,
        client: CLIENT_NAME,
      })
      Object.keys(errorInfo).forEach((key) => {
        scope.setExtra(key, errorInfo[key])
      })
      Sentry.captureException(error)
    })
  }
  public render() {
    return <React.Fragment>{this.props.children}</React.Fragment>
  }
}
