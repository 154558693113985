import { navigate } from "../../utils"

export const signUpNavigator = {
  pages: {
    SignUpPhone: {
      next: () => navigate("SIGN_UP_TERMS_AND_CONDITIONS"),
    },
    SignUpEmailSent: {
      prev: () => navigate("SIGN_UP_TERMS_AND_CONDITIONS"),
      next: () => navigate("SIGN_UP_EMAIL_RESENT"),
    },
    SignUpEmailResent: {
      next: () => navigate("SIGN_UP_EMAIL_SENT"),
    },
  },
}
