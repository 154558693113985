/**
 * Fully type-checked Redux action creators
 * https://github.com/Hotell/rex-tilshttps://github.com/Hotell/rex-tils
 *
 * Example:
 * ```
 * const EDIT_FIELD = "EDIT_FIELD"
 * export const myActions = {
 *   setField: (value: string) => createAction(EDIT_FIELD, value)
 * }
 * export const TMyActions = TActionsUnion<typeof myActions>
 * ```
 */

type FunctionType = (...args: any[]) => void
interface IActionCreatorsMapObject {
  [actionCreator: string]: FunctionType
}

export type TActionsUnion<A extends IActionCreatorsMapObject> = ReturnType<A[keyof A]>

interface IAction<T extends string> {
  type: T
}

interface IActionWithPayload<T extends string, P> extends IAction<T> {
  payload: P
}

export function createAction<T extends string>(type: T): IAction<T>
export function createAction<T extends string, P>(type: T, payload: P): IActionWithPayload<T, P>
export function createAction<T extends string, P>(type: T, payload?: P) {
  return payload === undefined ? { type } : { type, payload }
}
