import { createAction, TActionsUnion } from "core"
import { IDebitCardDetails } from "./debit-funding.state"

export enum DebitFundingActionEnums {
  DEBIT_FUNDING_SET_AMOUNT = "SET_AMOUNT",
  DEBIT_FUNDING_SET_CARD_DETAILS = "SET_CARD_DETAILS",
}

export const DebitFundingActions = {
  setAmount: (amount: number) =>
    createAction(DebitFundingActionEnums.DEBIT_FUNDING_SET_AMOUNT, amount),
  setCardDetails: (cardDetails: IDebitCardDetails) =>
    createAction(DebitFundingActionEnums.DEBIT_FUNDING_SET_CARD_DETAILS, cardDetails),
}

export type TDebitFundingActions = TActionsUnion<typeof DebitFundingActions>
