import React from "react"
import { connect } from "react-redux"

import { IRootState, TRootDispatch } from "core"

import { KYCUnsupportedCountryScreen } from "./kyc-unsupported-country.screen.gql"

import { KYCActions } from "../../redux"

interface IKYCUnsupportedCountryStateProps {
  unsupportedCountry: string
}

interface IKYCUnsupportedCountryDispatchProps {
  setUnsupportedCountry: (unsupportedCountry: string) => void
}

export type TKYCUnsupportedCountryProps = IKYCUnsupportedCountryStateProps & IKYCUnsupportedCountryDispatchProps

const mapStateToProps = (state: IRootState): IKYCUnsupportedCountryStateProps => ({
  unsupportedCountry: state.KYC.unsupportedCountry,
})

const mapDispatchToProps = (dispatch: TRootDispatch): IKYCUnsupportedCountryDispatchProps => ({
  setUnsupportedCountry: (unsupportedCountry: string) => dispatch(KYCActions.setUnsupportedCountry(unsupportedCountry)),
})

export const KYCUnsupportedCountry = connect(
  mapStateToProps,
  mapDispatchToProps,
)((props: TKYCUnsupportedCountryProps) => <KYCUnsupportedCountryScreen {...props} />)
