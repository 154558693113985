import {
  initialControlPersonCandidateState,
  ControlPersonCandidateState,
  initialSelectedControlPersonState,
  ISelectedControlPersonState,
  IControlPersonState,
} from "./control-person.state"

import { ControlPersonActionEnums, TControlPersonActions } from "./control-person.actions"
import { combineReducers } from "redux"

export const controlPersonCandidateReducer = (
  state: ControlPersonCandidateState = initialControlPersonCandidateState,
  action: TControlPersonActions,
): ControlPersonCandidateState => {
  switch (action.type) {
    case ControlPersonActionEnums.SET_FIRSTNAME:
      return {
        ...state,
        firstName: action.payload,
      }
    case ControlPersonActionEnums.SET_LASTNAME:
      return {
        ...state,
        lastName: action.payload,
      }
    case ControlPersonActionEnums.SET_BIRTHDATE:
      return {
        ...state,
        birthDate: action.payload,
      }
    case ControlPersonActionEnums.SET_HOME_ADDRESS:
      return {
        ...state,
        homeAddress: action.payload,
      }
    case ControlPersonActionEnums.SET_JOB_TITLE:
      return {
        ...state,
        jobTitle: action.payload,
      }
    case ControlPersonActionEnums.SET_EMAIL:
      return {
        ...state,
        email: action.payload,
      }
    case ControlPersonActionEnums.SET_PHONE:
      return {
        ...state,
        phone: action.payload,
      }
    case ControlPersonActionEnums.SET_RESIDENCY:
      return {
        ...state,
        residency: action.payload,
      }
    case ControlPersonActionEnums.SET_SSN:
      return {
        ...state,
        SSN: action.payload,
      }
    case ControlPersonActionEnums.SET_UNSUPPORTED_COUNTRY:
      return {
        ...state,
        unsupportedCountry: action.payload,
      }
    default:
      return state
  }
}

export const selectedControlPersonReducer = (
  state: ISelectedControlPersonState = initialSelectedControlPersonState,
  action: TControlPersonActions,
): ISelectedControlPersonState => {
  switch (action.type) {
    case ControlPersonActionEnums.SELECT_CONTROL_PERSON:
      return { ...state, email: action.payload }
    default:
      return state
  }
}

export const controlPersonReducer = combineReducers<IControlPersonState>({
  candidate: controlPersonCandidateReducer,
  selected: selectedControlPersonReducer,
})
