import { signUpNavigator, routes as signUpRoutes, components as signUpComponents } from "./sign-up"
import { KYCNavigator, routes as kycRoutes, components as kycComponents } from "./kyc"
import { KYBNavigator, routes as kybRoutes, components as kybComponents } from "./kyb"
import { UBONavigator, routes as uboRoutes, components as uboComponents } from "./ubo"
import {
  debitFundingNavigator,
  routes as debitFundingRoutes,
  components as debitFundingComponents,
} from "./debit-funding"
import {
  controlPersonNavigator,
  routes as controlPersonRoutes,
  components as controlPersonComponents,
} from "./control-person"
import {
  navigator as backgroundCheckNavigator,
  routes as backgroundCheckRoutes,
  components as backgroundCheckComponents,
} from "./background-check"
import { navigate } from "../utils"

export const routes = {
  ...signUpRoutes,
  ...kycRoutes,
  ...kybRoutes,
  ...uboRoutes,
  ...debitFundingRoutes,
  ...backgroundCheckRoutes,
  ...controlPersonRoutes,
}

export const components = {
  ...signUpComponents,
  ...kycComponents,
  ...kybComponents,
  ...uboComponents,
  ...debitFundingComponents,
  ...backgroundCheckComponents,
  ...controlPersonComponents,
}

export const OnboardingNavigator = {
  utils: { goToStart: () => navigate("SIGN_UP_PHONE") },
  signUp: signUpNavigator,
  kyc: KYCNavigator,
  kyb: KYBNavigator,
  ubo: UBONavigator,
  debitFunding: debitFundingNavigator,
  backgroundCheck: backgroundCheckNavigator,
  controlPerson: controlPersonNavigator,
}
