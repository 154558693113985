import React, { useState, useEffect } from "react"
import { Box } from "rebass"
import { Button } from "./button.component"
import { theme } from "theme/theme"

interface IProps {
  onBackClick?: () => void
  blueScreen?: boolean
}

const LEFT_ARROW_KEY_CODE = 37
export const INPUT_TAG_NAME = "INPUT"

export const BackButton: React.FC<IProps> = ({
  onBackClick = () => window.history.back(),
  blueScreen = false,
}) => {
  const [iconColor, setIconColor] = useState(blueScreen ? theme.colors.white : theme.colors.blue)
  useEffect(() => {
    const onKeyDown = (event: KeyboardEvent) => {
      if (event.target) {
        const target = event.target as Element
        if (event.keyCode === LEFT_ARROW_KEY_CODE && target.tagName !== INPUT_TAG_NAME) {
          onBackClick()
        }
      }
    }
    document.addEventListener("keydown", onKeyDown)
    return () => {
      document.removeEventListener("keydown", onKeyDown)
    }
  })
  return (
    <Box
      onMouseLeave={() => setIconColor(blueScreen ? theme.colors.white : theme.colors.blue)}
      onMouseEnter={() => setIconColor(blueScreen ? theme.colors.white : theme.colors.grey)}
    >
      <Button
        noSimulatedSavingOnClick
        disabled={false}
        type={blueScreen ? "whiteOutline" : "blueOutline"}
        isCircle
        onClick={onBackClick}
      >
        <svg
          width="19"
          height="20"
          viewBox="0 0 19 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M10.0003 1.6665L1.66699 9.99984L10.0003 18.3332"
            stroke={iconColor}
            strokeWidth="2"
            strokeLinecap="square"
          />
          <path d="M18 10L3 10" stroke={iconColor} strokeWidth="2" strokeLinecap="square" />
        </svg>
      </Button>
    </Box>
  )
}
