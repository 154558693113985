import React from "react"
import { connect } from "react-redux"

import { IRootState, TRootDispatch } from "core"

import { KYCResidencyScreen } from "./kyc-residency.screen"

import { KYCActions } from "../redux"

interface IKYCResidencyStateProps {
  residency: string
}

interface IKYCResidencyDispatchProps {
  setResidency: (residency: string) => void
}

export type TKYCResidencyProps = IKYCResidencyStateProps & IKYCResidencyDispatchProps

const mapStateToProps = (state: IRootState): IKYCResidencyStateProps => ({
  residency: state.KYC.residency,
})

const mapDispatchToProps = (dispatch: TRootDispatch): IKYCResidencyDispatchProps => ({
  setResidency: (residency: string) => dispatch(KYCActions.setResidency(residency)),
})

export const KYCResidency = connect(
  mapStateToProps,
  mapDispatchToProps,
)((props: TKYCResidencyProps) => <KYCResidencyScreen {...props} />)
