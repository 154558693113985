import { createAction, TActionsUnion } from "core"
import { IAddress } from "types"

export enum KYCActionEnums {
  SET_FIRSTNAME = "KYC_SET_FIRSTNAME",
  SET_LASTNAME = "KYC_SET_LASTNAME",
  SET_BIRTHDATE = "KYC_SET_BIRTHDATE",
  SET_HOME_ADDRESS = "KYC_SET_HOME_ADDRESS",
  SET_JOB_TITLE = "KYC_SET_JOB_TITLE",
  SET_ROLE = "KYC_SET_ROLE",
  SET_RESIDENCY = "SET_RESIDENCY",
  SET_SSN = "SET_SSN",
  SET_UNSUPPORTED_COUNTRY = "SET_UNSUPPORTED_COUNTRY",
}

export const KYCActions = {
  setFirstName: (firstName: string) => createAction(KYCActionEnums.SET_FIRSTNAME, firstName),
  setLastName: (lastName: string) => createAction(KYCActionEnums.SET_LASTNAME, lastName),
  setBirthDate: (birthDate: string) => createAction(KYCActionEnums.SET_BIRTHDATE, birthDate),
  setHomeAddress: (homeAddress: IAddress) =>
    createAction(KYCActionEnums.SET_HOME_ADDRESS, homeAddress),
  setJobTitle: (jobTitle: string) => createAction(KYCActionEnums.SET_JOB_TITLE, jobTitle),
  setRole: (role: string) => createAction(KYCActionEnums.SET_ROLE, role),
  setResidency: (residency: string) => createAction(KYCActionEnums.SET_RESIDENCY, residency),
  setSSN: (SSN: string) => createAction(KYCActionEnums.SET_SSN, SSN),
  setUnsupportedCountry: (unsupportedCountry: string) =>
    createAction(KYCActionEnums.SET_UNSUPPORTED_COUNTRY, unsupportedCountry),
}

export type TKYCActions = TActionsUnion<typeof KYCActions>
