import { navigate } from "../../utils"
import { FIRST_KYB_SCREEN } from "../onboarding-navigation-constants"

export const KYCNavigator = {
  pages: {
    KYCLanding: {
      next: () => navigate("KYC_NAME"),
    },
    KYCUnsupportedCountry: {
      prev: () => navigate("KYC_RESIDENCY"),
      next: () => navigate("KYC_UNSUPPORTED_COUNTRY_SUCCESS"),
    },
    KYCName: {
      prev: () => navigate("KYC_LANDING"),
      next: () => navigate("KYC_BIRTHDATE"),
    },
    KYCUnsupportedCountrySuccess: {
      prev: () => navigate("KYC_UNSUPPORTED_COUNTRY"),
    },
    KYCComplete: {
      prev: () => navigate("KYC_ROLE"),
      next: () => navigate(FIRST_KYB_SCREEN),
    },
    KYCSSN: {
      prev: () => navigate("KYC_RESIDENCY"),
      next: () => navigate("KYC_ROLE"),
    },
    KYCRole: {
      prev: () => navigate("KYC_SSN"),
      next: () => navigate("KYC_COMPLETE"),
    },
    KYCBirthDate: {
      prev: () => navigate("KYC_NAME"),
      next: () => navigate("KYC_ADDRESS"),
    },
    KYCAddress: {
      prev: () => navigate("KYC_BIRTHDATE"),
      next: () => navigate("KYC_PHONE"),
    },
    KYCPhone: {
      prev: () => navigate("KYC_ADDRESS"),
      next: () => navigate("KYC_RESIDENCY"),
    },
    KYCResidency: {
      prev: () => navigate("KYC_PHONE"),
      next: (isResident: boolean) =>
        isResident ? navigate("KYC_SSN") : navigate("KYC_UNSUPPORTED_COUNTRY"),
    },
    KYCSSNMissing: {
      next: () => navigate("DEBIT_FUNDING_LANDING"),
    },
  },
}
