import React from "react"
import { connect } from "react-redux"

import { IRootState } from "core"

import { KYBFullTimeEmployeesScreen } from "./kyb-full-time-employees.screen"

import { CorporationType } from "types"

interface IKYBFullTimeEmployeesStateProps {
  businessType: CorporationType
}

export type TKYBFullTimeEmployeesProps = IKYBFullTimeEmployeesStateProps

const mapStateToProps = (state: IRootState): IKYBFullTimeEmployeesStateProps => ({
  businessType: state.KYB.businessType,
})

export const KYBFullTimeEmployees = connect(mapStateToProps)(
  (props: TKYBFullTimeEmployeesProps) => <KYBFullTimeEmployeesScreen {...props} />,
)
