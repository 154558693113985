import React, { useState } from "react"
import { useTranslation } from "react-i18next"
import { Box } from "rebass"
import { BaseLayout, BaseScreen, TextInput, SidePanel, DropDown, ButtonRow } from "shared"
import { TKYBDateStateProps } from "./kyb-date-state.container"
import { americanStates } from "utils/americanStates"
import { nav } from "core/navigation"
import { Analytics } from "core/analytics/actions"
import { CorporationType } from "types"
import { isValidDateAndAge } from "utils/validators"

export const KYBDateStateScreen: React.FC<TKYBDateStateProps> = ({
  date,
  state,
  setDate,
  setState,
  businessType,
}) => {
  const { t } = useTranslation()
  const [newDate, setNewDate] = useState(date)
  const [newState, setNewState] = useState(state)

  const validDate = () => isValidDateAndAge(newDate, { minAge: 0 })

  const { prev, next } = nav.onboarding.kyb.pages.KYBDateState
  const businessTypeString = businessType === CorporationType.Soleprop ? "soleProp" : "default"

  return (
    <BaseLayout
      sidePanel={<SidePanel section={2} />}
      body={
        <BaseScreen
          title={t(`kyb.dateState.title.${businessTypeString}`)}
          subtitle={t(`kyb.dateState.subtitle.${businessTypeString}`)}
        >
          <Box style={{ marginTop: "2rem" }}>
            <TextInput
              placeholderText={t("kyb.dateState.inputPlaceholder")}
              onChange={setNewDate}
              value={newDate}
              withDateMask={true}
              autoFocus={true}
              valueIsValid={() => validDate()}
              validationType={!validDate() ? "error" : undefined}
              validationText={
                !validDate() && newDate && newDate.length >= 10 ? t("kyb.dateState.dateError") : ""
              }
            />
          </Box>
          <DropDown
            options={americanStates}
            newValue={newState}
            setNewValue={setNewState}
            placeholder={t(`kyb.dateState.dropdownPlaceholder.${businessTypeString}`)}
          />
        </BaseScreen>
      }
      buttonRow={
        <ButtonRow
          buttonOnClick={() => {
            setDate(newDate)
            setState(newState)
            Analytics.track(`KYB Business Incorporated State Continue Tap`)
            next(businessType)
          }}
          buttonDisabled={!validDate() || !newState}
        />
      }
      onBackClick={() => prev(businessType)}
    />
  )
}
