import React from "react"
import { Box, Image } from "rebass"
import { theme } from "theme/theme"
import northOneColor from "images/NorthOne-Color.svg"
import { BackButton, LoggedInStatus } from "shared"

interface IProps {
  sidePanel?: React.ReactNode
  body?: React.ReactNode
  buttonRow?: JSX.Element
  largeBody?: boolean
  noBackButton?: boolean
  onBackClick?: () => void
}

export const BaseLayout: React.SFC<IProps> = ({
  sidePanel,
  body,
  largeBody,
  buttonRow,
  noBackButton,
  onBackClick,
}) => (
  <Box
    sx={{
      display: "grid",
      gridTemplateColumns: "repeat(14, 1fr)",
      gridTemplateRows: "repeat(7, 1fr)",
      height: "100vh",
      overflowY: "scroll",
    }}
  >
    <LoggedInStatus />
    <Box
      sx={{
        gridRowStart: 1,
        gridColumnStart: 3,
        gridColumnEnd: 10,
        marginTop: 35,
      }}
    >
      <Image sx={{ alignSelf: "start", height: 55 }} src={northOneColor} />
    </Box>
    <Box
      sx={{
        gridRowStart: 2,
        gridRowEnd: 8,
        gridColumnStart: 1,
        gridColumnEnd: 1,
        marginBottom: theme.spacing.verticalElementSpacing,
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-end",
        alignItems: "flex-end",
      }}
    >
      {noBackButton ? "" : <BackButton onBackClick={onBackClick} />}
    </Box>

    <Box
      sx={{
        gridColumnStart: 3,
        gridColumnEnd: [13, 10, 10, 10],
        gridRowStart: 2,
        marginTop: 150,
      }}
    >
      <Box
        sx={{
          gridColumnStart: 3,
          gridColumnEnd: [13, 10, 10, 10],
          gridRowStart: largeBody ? 2 : 3,
          gridRowEnd: 8,
          paddingBottom: theme.spacing.verticalElementSpacing,
        }}
      >
        {body}
        {buttonRow}
      </Box>
    </Box>
    <Box
      sx={{
        gridColumnStart: [14, 12],
        gridColumnEnd: 15,
        gridRowStart: 1,
        gridRowEnd: 8,
        bg: ["", theme.colors.lightGrey],
      }}
    >
      {sidePanel}
    </Box>
  </Box>
)
