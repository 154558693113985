export const debitFunding = {
  landing: {
    title: "Ready to fund your account?",
    subtitle:
      "Get access to your money faster by adding your debit card details. Your funds will be transferred after your account is opened.\n\nNote: Adding funds with your debit card is only available in this step of the application. You'll need to use another funding option after your account is open.",
    continue: "Fund with a debit card",
    skip: "I'll add funds later",
  },
  amount: {
    title: "How much would you like to add to your account?",
    subtitle:
      "Top up your account with at least $50. We won't transfer any funds until the account is opened.",
    placeholder: "Amount to top up with ($ USD)",
    error: {
      minAmountError: "Top up with at least ${{minAmount}}",
      maxAmountError: "${{maxAmount}} maximum deposit",
    },
  },
  cardDetails: {
    title: "Enter your card details",
    placeholders: {
      expiry: "Expiry MM/YY",
      cvv: "CVV",
    },
  },
  error: {
    zip: "Please enter a valid ZIP code.",
    expiryDate: "Please enter a valid expiry date.",
  },
  success: {
    title: "Debit funding was approved! 🎉",
    subtitle:
      "We will top up your account with ${{amount}} from card ending {{debitCardLastFourDigits}}. Don't worry, we won't transfer funds until your new account is opened.",
  },
}
