import React from "react"
import { connect } from "react-redux"

import { IRootState } from "core"

import { KYBPartTimeEmployeesScreen } from "./kyb-part-time-employees.screen"

import { CorporationType } from "types"

interface IKYBPartTimeEmployeesStateProps {
  businessType: CorporationType
}

export type TKYBPartTimeEmployeesProps = IKYBPartTimeEmployeesStateProps

const mapStateToProps = (state: IRootState): IKYBPartTimeEmployeesStateProps => ({
  businessType: state.KYB.businessType,
})

export const KYBPartTimeEmployees = connect(mapStateToProps)(
  (props: TKYBPartTimeEmployeesProps) => <KYBPartTimeEmployeesScreen {...props} />,
)
