import React from "react"
import { Box } from "rebass"

export const getTextWithLineBreaks = (text: string) =>
  text.split(/\n/).map((t, index, arr) => (
    <React.Fragment key={index}>
      <span>{t}</span>
      {index !== arr.length - 1 && (
        <Box as="br" sx={{ height: 0, display: ["inline", "inline"] }} />
      )}
    </React.Fragment>
  ))
