// Generated by CoffeeScript 1.6.3

// This code comes from: https://github.com/Clever/loofah
// TODO: create TS implementation / fix limitations that are exposed in data_scrubber.test.ts

var clone_error,
  deep_map_objects_and_arrays,
  deep_map_strings,
  key_value_pairs,
  object_keys,
  string_key_value_pairs,
  string_substrings,
  string_url_query_params,
  url_query_params,
  _

_ = require("underscore")

_.mixin(require("underscore.deep"))

const PLACEHOLDER = "[REDACTED]"

module.exports = {
  default: function() {
    return _.compose(
      key_value_pairs(),
      url_query_params(),
      object_keys(),
    )
  },
  object_keys: object_keys = function(b_keys) {
    if (b_keys == null) {
      b_keys = ["password", "user", "email", "api", "secret"]
    }
    return function(val) {
      return deep_map_strings(val, function(subval, subkey) {
        if (subkey == null) {
          return subval
        }
        _.each(b_keys, function(b_key) {
          if (!_.isRegExp(b_key)) {
            b_key = new RegExp("(\\.|^)" + b_key + "(\\.|$)", "i")
          }
          if (b_key.test(subkey)) {
            return (subval = PLACEHOLDER)
          }
        })
        return subval
      })
    }
  },
  substrings: function(sstrings) {
    return function(val) {
      return deep_map_strings(val, function(subval) {
        return string_substrings(subval, sstrings)
      })
    }
  },
  url_query_params: url_query_params = function(query_params) {
    if (query_params == null) {
      query_params = ["client_id", "client_secret", "refresh_token"]
    }
    return function(val) {
      return deep_map_strings(val, function(subval) {
        return string_url_query_params(subval, query_params)
      })
    }
  },
  key_value_pairs: key_value_pairs = function(keywords, delims) {
    if (keywords == null) {
      keywords = ["user", "username", "password", "email"]
    }
    if (delims == null) {
      delims = "\\s:="
    }
    return function(val) {
      return deep_map_strings(val, function(subval) {
        return string_key_value_pairs(subval, keywords, delims)
      })
    }
  },
}

deep_map_strings = function(val, fn) {
  return deep_map_objects_and_arrays(val, function(subval, subkey) {
    if (_.isString(subval)) {
      return fn(subval, subkey)
    } else {
      return fn(subval, subkey) // [CHANGED LINE]: TO ALLOW NUMBERS TO BE REPLACED IN STRINGS
    }
  })
}

clone_error = function(error) {
  var new_error, props
  new_error = Object.create(Object.getPrototypeOf(error))
  props = Object.getOwnPropertyNames(error)
  return Object.defineProperties(
    new_error,
    _.object(
      props,
      _.map(props, function(prop) {
        return Object.getOwnPropertyDescriptor(error, prop)
      }),
    ),
  )
}

deep_map_objects_and_arrays = function(val, fn) {
  var key_helper
  key_helper = function(val, fn, key) {
    var props_to_scrub
    switch (false) {
      case !_.isPlainObject(val):
        return _.deepMapValues(val, function(subval, subkey) {
          return key_helper(subval, fn, key ? "" + key + "." + subkey : subkey)
        })
      case !_.isArray(val):
        return _.map(val, function(subval) {
          return key_helper(subval, fn, key)
        })
      case !(val instanceof Error):
        props_to_scrub = _.keys(val).concat(["message", "stack"])
        return _.extend(clone_error(val), key_helper(_.pick(val, props_to_scrub), fn, key))
      default:
        return fn(val, key)
    }
  }
  return key_helper(val, fn, "")
}

string_substrings = function(string, sstrings) {
  _.each(sstrings, function(sstring) {
    if (!_.isRegExp(sstring)) {
      sstring = new RegExp(sstring, "g")
    }
    return (string = string.replace(sstring, PLACEHOLDER))
  })
  return string
}

string_url_query_params = function(string, query_params) {
  var val
  val = string.split(/([=.&/]+)/)
  _.each(query_params, function(qparam) {
    if (!_.isRegExp(qparam)) {
      qparam = new RegExp("(^|[=.&?])" + qparam, "i")
    }
    return _.each(val, function(v, i) {
      if (val[i + 2] != null && val[i + 1] === "=" && qparam.test(v)) {
        return (val[i + 2] = PLACEHOLDER)
      }
    })
  })
  return val.join("")
}

string_key_value_pairs = function(string, keywords, delims) {
  var val
  val = string.split(new RegExp("([" + delims + "]+)"))
  _.each(keywords, function(keyword) {
    if (!_.isRegExp(keyword)) {
      keyword = new RegExp("^" + keyword + "$", "i")
    }
    return _.each(val, function(v, i) {
      if (val[i + 1] !== "=" && val[i + 2] != null && keyword.test(v)) {
        return (val[i + 2] = PLACEHOLDER)
      }
    })
  })
  return val.join("")
}
