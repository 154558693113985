import React from "react"
import { SSNScreen } from "shared"
import { nav } from "core/navigation"
import { TUBOSSNProps } from "./ubo-ssn.container"

export const UBOSSNScreen: React.FC<TUBOSSNProps> = ({ ssn, setValue, firstName, addNewOwner }) => {
  const { prev, next } = nav.onboarding.ubo.pages.UBOSSN
  return (
    <SSNScreen
      firstName={firstName}
      ssn={ssn}
      setSSN={(ssn) => setValue("ssn", ssn)}
      onSubmit={addNewOwner}
      prev={prev}
      next={next}
    />
  )
}
