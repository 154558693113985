import React, { ReactNode } from "react"
import { Box, Text, Heading } from "rebass"
import { theme } from "theme/theme"
import { getTextWithLineBreaks } from "utils/helpers"

interface IProps {
  title: string
  subtitle?: string | ReactNode
  multilineSubtitle?: string[]
}

export const HeadingWithIcon: React.SFC<IProps> = ({
  title,
  subtitle,
  multilineSubtitle,
  children,
}) => (
  <Box
    sx={{
      marginBottom: theme.spacing.headingToBody,
    }}
  >
    <Heading sx={{ ...theme.fontStyles.h1 }} marginBottom="1rem" color={theme.colors.black}>
      {title}
    </Heading>
    {multilineSubtitle ? (
      multilineSubtitle.map((line, idx) => (
        <Text
          color={theme.colors.grey}
          fontSize="subtitle"
          marginTop={idx ? theme.spacing.inputFieldSpacing : 0}
        >
          {line}
        </Text>
      ))
    ) : subtitle ? (
      <Text sx={{ ...theme.fontStyles.h2 }} color={theme.colors.grey}>
        {typeof subtitle === "string" ? getTextWithLineBreaks(subtitle) : subtitle}
      </Text>
    ) : null}
    {children}
  </Box>
)
