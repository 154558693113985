export interface IDebitCardDetails {
  nameOnCard: string
  cardNumber: string
  cvv: string
  expiryMonth: string
  expiryYear: string
  postalCode: string
}

export interface IDebitFundingState {
  amount: number
  debitCardData: IDebitCardDetails
}

export const initialDebitFundingState: IDebitFundingState = {
  amount: 0,
  debitCardData: {
    nameOnCard: "",
    cardNumber: "",
    cvv: "",
    expiryMonth: "",
    expiryYear: "",
    postalCode: "",
  },
}
