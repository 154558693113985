import React from "react"
import { useTranslation } from "react-i18next"
import { BaseLayout, BaseScreen, SidePanel, ButtonRow } from "shared"
import { nav } from "core/navigation"
import { Analytics } from "../../../../core/analytics/actions"

export const KYCLandingScreen: React.FC<{}> = () => {
  const { t } = useTranslation()
  const { next } = nav.onboarding.kyc.pages.KYCLanding
  return (
    <BaseLayout
      sidePanel={<SidePanel section={1} />}
      body={<BaseScreen title={t("kyc.landing.title")} subtitle={t("kyc.landing.subtitle")} />}
      buttonRow={
        <ButtonRow
          buttonOnClick={() => {
            Analytics.track("KYC Start Continue Tap")
            next()
          }}
          buttonDisabled={false}
        />
      }
      noBackButton
    />
  )
}
