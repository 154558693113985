export const passwordReset = {
  request: {
    title: "Reset your password",
    subtitle: "We'll email you a link so you can change your password securely.",
    placeholder: "Enter your email address",
    button: "Send reset email",
  },
  emailSent: {
    emoji: "📧",
    title: "Check your email!",
    subtitle: "We sent you a link to reset your password",
    button: "Back to login",
  },
}
