import React from "react"

import { IRootState, TRootDispatch } from "core"
import { KYBActions, WebsiteType } from "../redux"
import { connect } from "react-redux"

import { KYBSocialMediaScreen } from "./kyb-social-media.screen"
import { CorporationType } from "types"

export interface ISocialMediaStateProps {
  websiteType: WebsiteType
  website: string
  socialMediaHandle: string
  businessType: CorporationType
}

export interface ISocialMediaDispatchToProps {
  setWebsite: (value: string) => void
  setSocialMediaHandle: (value: string) => void
}

export type TSocialMediaProps = ISocialMediaStateProps & ISocialMediaDispatchToProps

const mapStateToProps = (state: IRootState): ISocialMediaStateProps => ({
  websiteType: state.KYB.websiteType,
  website: state.KYB.website,
  socialMediaHandle: state.KYB.socialMediaHandle,
  businessType: state.KYB.businessType,
})

const mapDispatchToProps = (dispatch: TRootDispatch): ISocialMediaDispatchToProps => ({
  setWebsite: (value: string) => {
    dispatch(KYBActions.setWebsite(value))
  },
  setSocialMediaHandle: (value: string) => {
    dispatch(KYBActions.setWebsite(value))
  },
})

export const KYBSocialMedia = connect(
  mapStateToProps,
  mapDispatchToProps,
)((props: ISocialMediaStateProps & ISocialMediaDispatchToProps) => (
  <KYBSocialMediaScreen {...props} />
))
