import React from "react"
import { DebitFundingAmountScreen } from "./debit-funding-amount.screen"
import { connect } from "react-redux"
import { DebitFundingActions } from "../redux"
import { IRootState, TRootDispatch } from "core"

interface IDebitFundingAmountStateProps {
  amount: number
}

interface IDebitFundingAmountDispatchProps {
  setAmount: (amount: number) => void
}

export type TDebitFundingAmountProps = IDebitFundingAmountStateProps &
  IDebitFundingAmountDispatchProps

const mapStateToProps = (state: IRootState): IDebitFundingAmountStateProps => ({
  amount: state.debitFunding.amount,
})

const mapDispatchToProps = (dispatch: TRootDispatch): IDebitFundingAmountDispatchProps => ({
  setAmount: (amount: number) => dispatch(DebitFundingActions.setAmount(amount)),
})

export const DebitFundingAmount = connect(
  mapStateToProps,
  mapDispatchToProps,
)((props: TDebitFundingAmountProps) => <DebitFundingAmountScreen {...props} />)
