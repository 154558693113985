import React from "react"
import { connect } from "react-redux"
import { IRootState, TRootDispatch } from "core"
import { UBOPhoneScreen } from "./ubo-phone.screen"
import { UBOActions } from "../redux"

interface IUBOPhoneStateProps {
  phone: string
  firstName: string
}

interface IUBOPhoneDispatchProps {
  setValue: (key: "phoneNumber", value: string) => void
}

export type TUBOPhoneProps = IUBOPhoneStateProps & IUBOPhoneDispatchProps

const mapStateToProps = (state: IRootState): IUBOPhoneStateProps => ({
  phone: state.UBO.newOwner.phoneNumber,
  firstName: state.UBO.newOwner.firstName,
})

const mapDispatchToProps = (dispatch: TRootDispatch): IUBOPhoneDispatchProps => ({
  setValue: (key: "phoneNumber", value: string) => dispatch(UBOActions.setValue(key, value)),
})

export const UBOPhone = connect(
  mapStateToProps,
  mapDispatchToProps,
)((props: TUBOPhoneProps) => <UBOPhoneScreen {...props} />)
