import React from "react"
import { connect } from "react-redux"

import { IRootState, TRootDispatch } from "core"

import { UBOBirthDateScreen } from "./ubo-birthdate.screen"

import { UBOActions } from "../redux"

interface IUBOBirthDateStateProps {
  firstName: string
  birthDate: string
}

interface IUBOBirthDateDispatchProps {
  setValue: (key: "birthDate", value: string) => void
}

export type TUBOBirthDateProps = IUBOBirthDateStateProps & IUBOBirthDateDispatchProps

const mapStateToProps = (state: IRootState): IUBOBirthDateStateProps => ({
  firstName: state.UBO.newOwner.firstName,
  birthDate: state.UBO.newOwner.birthDate,
})

const mapDispatchToProps = (dispatch: TRootDispatch): IUBOBirthDateDispatchProps => ({
  setValue: (key: "birthDate", value: string) => dispatch(UBOActions.setValue(key, value)),
})

export const UBOBirthDate = connect(
  mapStateToProps,
  mapDispatchToProps,
)((props: TUBOBirthDateProps) => <UBOBirthDateScreen {...props} />)
