import React from "react"
import { connect } from "react-redux"
import { IRootState, TRootDispatch } from "core"
import { KYBLegalNameScreen } from "./kyb-legal-name.screen"
import { KYBActions } from "../redux"

interface IKYBLegalNameStateProps {
  legalName: string
}

interface IKYBLegalNameDispatchProps {
  setLegalName: (legalName: string) => void
}

export type TKYBLegalNameProps = IKYBLegalNameStateProps & IKYBLegalNameDispatchProps

const mapStateToProps = (state: IRootState): IKYBLegalNameStateProps => ({
  legalName: state.KYB.legalName,
})

const mapDispatchToProps = (dispatch: TRootDispatch): IKYBLegalNameDispatchProps => ({
  setLegalName: (legalName: string) => dispatch(KYBActions.setLegalName(legalName)),
})

export const KYBLegalName = connect(
  mapStateToProps,
  mapDispatchToProps,
)((props: TKYBLegalNameProps) => <KYBLegalNameScreen {...props} />)
