import React from "react"
import { connect } from "react-redux"
import { IRootState, TRootDispatch } from "core"
import { signUpActions } from "../redux"
import { nav } from "core/navigation"
import { PhoneScreen } from "shared"
import { Analytics } from "core/analytics/actions"
import * as Adjust from "@adjustcom/adjust-web-sdk"

interface ISignUpPhoneStateProps {
  phone: string
}

interface ISignUpPhoneDispatchProps {
  setPhone: (phone: string) => void
}

export type TSignUpPhoneProps = ISignUpPhoneStateProps & ISignUpPhoneDispatchProps

const mapStateToProps = (state: IRootState): ISignUpPhoneStateProps => ({
  phone: state.signUp.phone,
})

const mapDispatchToProps = (dispatch: TRootDispatch): ISignUpPhoneDispatchProps => ({
  setPhone: (phone: string) => dispatch(signUpActions.setPhone(phone)),
})

const handleAnalytics = (phoneNumber: string) => {
  Analytics.phoneNumber(phoneNumber)
  Analytics.track("Create Profile Phone Number Continue Tap", { phone: phoneNumber })
}

export const SignUpPhone = connect(
  mapStateToProps,
  mapDispatchToProps,
)((props: TSignUpPhoneProps) => (
  <PhoneScreen
    {...props}
    onClick={() => {
      Adjust.trackEvent({
        eventToken: "ys0v6i",
      })
    }}
    goToNext={nav.onboarding.signUp.pages.SignUpPhone.next}
    handleAnalytics={handleAnalytics}
  />
))
