import React from "react"
import { useTranslation } from "react-i18next"
import { InterstitialLayout } from "shared"
import { nav } from "core/navigation"

export const ControlPersonLanding: React.FC<{}> = () => {
  const { t } = useTranslation()
  const infoArray = [
    t("shared.screens.personInfo.name"),
    t("shared.screens.personInfo.job"),
    t("shared.screens.personInfo.email"),
    t("shared.screens.personInfo.address"),
    t("shared.screens.personInfo.phone"),
    t("shared.screens.personInfo.birthDate"),
    t("shared.screens.personInfo.ssn"),
  ]
  const { prev, next } = nav.onboarding.controlPerson.pages.ControlPersonLanding
  return (
    <InterstitialLayout
      title={t("controlPerson.landing.title")}
      buttonOnClick={next}
      onBackClick={prev}
      buttonText={t("controlPerson.landing.button")}
      infoArray={infoArray}
    />
  )
}
