import React, { useState } from "react"
import { useTranslation } from "react-i18next"
import { Box } from "rebass"
import { BaseLayout, BaseScreen, TextInput, SidePanel, DropDown, ButtonRow } from "shared"
import { TKYCRoleProps } from "./kyc-role.container"
import { nav } from "core/navigation"
import { Analytics } from "../../../../core/analytics/actions"

export const KYCRoleScreen: React.FC<TKYCRoleProps> = ({
  jobTitle,
  role,
  setJobTitle,
  setRole,
}) => {
  const { t } = useTranslation()
  const options = [
    t("kyc.role.director"),
    t("kyc.role.officerCoowner"),
    t("kyc.role.officerEmployee"),
    t("kyc.role.partner"),
  ]
  const [newJobTitle, setNewJobTitle] = useState(jobTitle)
  const [newRole, setNewRole] = useState(role)
  const valueIsValid = () => newJobTitle && newRole
  const { prev, next } = nav.onboarding.kyc.pages.KYCRole

  return (
    <BaseLayout
      sidePanel={<SidePanel section={1} />}
      body={
        <BaseScreen title={t("kyc.role.title")} subtitle={t("kyc.role.subtitle")}>
          <Box style={{ marginTop: "2rem" }}>
            <TextInput
              placeholderText={t("kyc.role.jobTitlePlaceholder")}
              onChange={setNewJobTitle}
              value={newJobTitle}
              autoFocus={true}
            />
          </Box>
          <DropDown
            options={options}
            newValue={newRole}
            setNewValue={setNewRole}
            placeholder={t("kyc.role.rolePlaceholder")}
          />
        </BaseScreen>
      }
      buttonRow={
        <ButtonRow
          buttonOnClick={() => {
            setJobTitle(newJobTitle)
            setRole(newRole)
            Analytics.track("KYC Verification Manual Role Continue Tap")
            next()
          }}
          buttonDisabled={!valueIsValid()}
        />
      }
      onBackClick={prev}
    />
  )
}
