import React from "react"
import { connect } from "react-redux"
import { IRootState, TRootDispatch } from "core"
import { KYBNatureScreen } from "./kyb-nature.screen.gql"
import { KYBActions } from "../redux"
import { CorporationType } from "generated/graphql"
import { ErrorsActions } from "components/errors"

interface IKYBNatureStateProps {
  nature: number
  businessType: CorporationType
}

interface IKYBNatureDispatchProps {
  setNature: (nature: number) => void
  setCurrentErrorMessage: (errorMessage: string) => void
}

export type TKYBNatureProps = IKYBNatureStateProps & IKYBNatureDispatchProps

const mapStateToProps = (state: IRootState): IKYBNatureStateProps => ({
  nature: state.KYB.nature,
  businessType: state.KYB.businessType,
})

const mapDispatchToProps = (dispatch: TRootDispatch): IKYBNatureDispatchProps => ({
  setNature: (nature: number) => dispatch(KYBActions.setNature(nature)),
  setCurrentErrorMessage: (errorMessage: string) =>
    dispatch(ErrorsActions.setCurrentErrorMessage(errorMessage)),
})

export const KYBNature = connect(
  mapStateToProps,
  mapDispatchToProps,
)((props: TKYBNatureProps) => <KYBNatureScreen {...props} />)
