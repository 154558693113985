import React from "react"
import { BaseScreen, TextInput, IBaseScreenProps } from "shared"
import { TValidationTypes } from "shared/validation-text"
import { Box } from "rebass"

interface IProps extends IBaseScreenProps {
  value: string
  onChange: (value: string) => void
  placeholderText: string
  valueIsValid?: (value: string) => boolean
  validationText?: string
  validationType?: TValidationTypes
  phoneType?: boolean
  dateType?: boolean
  einType?: boolean
  dollarType?: boolean
  disableMinimizedPlaceholderText?: boolean
  textInputContainerStyles?: object // TODO: Find actual data type for styling
}

const defaultValueIsValid = (v: string) => Boolean(v)

export const SingleTextInputScreen: React.SFC<IProps> = (props) => {
  const {
    value,
    valueIsValid = defaultValueIsValid,
    onChange,
    placeholderText,
    validationText,
    validationType,
    phoneType = false,
    dateType = false,
    einType = false,
    dollarType = false,
    disableMinimizedPlaceholderText = false,
    textInputContainerStyles = {},
  } = props
  return (
    <BaseScreen {...props} buttonDisabled={!valueIsValid(value)}>
      <Box
        sx={{
          marginTop: 30,
          ...textInputContainerStyles,
        }}
      >
        <TextInput
          validationType={validationType}
          validationText={validationText}
          placeholderText={placeholderText}
          onChange={onChange}
          value={value}
          withPhoneNumberMask={phoneType}
          withDateMask={dateType}
          withEINMask={einType}
          withDollarMask={dollarType}
          autoFocus={true}
          disableMinimizedPlaceholderText={disableMinimizedPlaceholderText}
        />
      </Box>
    </BaseScreen>
  )
}
