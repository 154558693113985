import React, { useState } from "react"
import { TKYBPercentageProps } from "./kyb-percentage.container"
import { BaseLayout, SidePanel, BaseScreen, TextInput, ButtonRow } from "shared"
import { useTranslation } from "react-i18next"
import { Box } from "rebass"
import { nav } from "core/navigation"
import { Analytics } from "../../../../core/analytics/actions"

export const KYBPercentageScreen: React.FC<TKYBPercentageProps> = ({
  percentOwnership,
  uboOwnershipPercentage,
  setPercentOwnership,
  businessType,
}) => {
  const { t } = useTranslation()
  const [percentage, setPercentage] = useState(percentOwnership)

  const newTotalOwnershipPercentage = uboOwnershipPercentage + Number(percentage)

  const valueIsValid = (): boolean => {
    if (percentage) {
      return Boolean(Number(percentage) > 0 && newTotalOwnershipPercentage <= 100)
    }
    return false
  }
  const exceedsTotalPercentage: boolean = newTotalOwnershipPercentage > 100
  const percentageAvailable = 100 - uboOwnershipPercentage

  const validationErrorMessage = exceedsTotalPercentage
    ? t("kyb.percentage.exceeds", { percentageAvailable })
    : t("kyb.percentage.error")

  const { prev, next } = nav.onboarding.kyb.pages.KYBPercentage

  return (
    <BaseLayout
      sidePanel={<SidePanel section={2} />}
      body={
        <BaseScreen title={t("kyb.percentage.title")}>
          <Box style={{ marginTop: "2rem" }}>
            <TextInput
              placeholderText={t("kyb.percentage.placeholder")}
              onChange={setPercentage}
              value={percentage}
              autoFocus={true}
              validationText={!valueIsValid() && percentage ? validationErrorMessage : ""}
              validationType={"error"}
            />
          </Box>
        </BaseScreen>
      }
      buttonRow={
        <ButtonRow
          buttonOnClick={() => {
            setPercentOwnership(percentage)
            Analytics.track(`KYB UBO Type Percentage Ownership Continue Tap`)
            next()
          }}
          buttonDisabled={!valueIsValid()}
        />
      }
      onBackClick={() => prev(businessType)}
    />
  )
}
