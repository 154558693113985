import React from "react"
import { ResidencyScreen } from "shared"
import { TUBOResidencyProps } from "./ubo-residency.container"
import { nav } from "core/navigation"

export const UBOResidencyScreen: React.FC<TUBOResidencyProps> = ({
  residency,
  setValue,
  firstName,
}) => {
  const { prev, next } = nav.onboarding.ubo.pages.UBOResidency
  return (
    <ResidencyScreen
      residency={residency}
      firstName={firstName}
      setResidency={(newResidency) => setValue("citizenship", newResidency)}
      prev={prev}
      next={next}
    />
  )
}
