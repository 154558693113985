import React from "react"
import { connect } from "react-redux"

import { IRootState, TRootDispatch } from "core"

import { UBOPercentageScreen } from "./ubo-percentage.screen.gql"

import { UBOActions } from "../redux"
import { getTotalOwnershipPercentage } from "components/onboarding/redux/ownership.selectors"

interface IUBOPercentageStateProps {
  firstName: string
  percentage: string
  currentTotalOwnershipPercentage: number
}

interface IUBOPercentageDispatchProps {
  setValue: (key: "ownershipPercentage", value: string) => void
}

export type TUBOPercentageProps = IUBOPercentageStateProps & IUBOPercentageDispatchProps

const mapStateToProps = (state: IRootState): IUBOPercentageStateProps => ({
  firstName: state.UBO.newOwner.firstName,
  percentage: state.UBO.newOwner.ownershipPercentage,
  currentTotalOwnershipPercentage: getTotalOwnershipPercentage(
    state.KYB.percentOwnership,
    state.UBO.additionalOwners,
  ),
})

const mapDispatchToProps = (dispatch: TRootDispatch): IUBOPercentageDispatchProps => ({
  setValue: (key: "ownershipPercentage", value: string) =>
    dispatch(UBOActions.setValue(key, value)),
})

export const UBOPercentage = connect(
  mapStateToProps,
  mapDispatchToProps,
)((props: TUBOPercentageProps) => <UBOPercentageScreen {...props} />)
