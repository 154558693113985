import React from "react"
import { Box, Image, Text, Heading } from "rebass"
import northOneWhite from "images/NorthOne-White.svg"
import cards from "images/cards.svg"
import { theme } from "theme/theme"
import { BackButton, Button } from "shared/button"
import { LoggedInStatus } from "shared"

export interface ICardInterstitialLayoutProps {
  title: string
  buttonOnClick: () => void
  buttonText: string
  onBackClick: () => void
  infoArray?: string[]
  showAddressToggle?: boolean
  addressToggleOnClick?: () => void
  subtitle?: string
  addressBlock?: React.ReactNode
  addressToggleText?: string
}

export const CardInterstitialLayout: React.SFC<ICardInterstitialLayoutProps> = ({
  title,
  buttonOnClick,
  buttonText,
  onBackClick,
  showAddressToggle = false,
  addressToggleOnClick,
  subtitle,
  addressBlock,
  addressToggleText,
}) => {
  return (
    <Box
      sx={{
        display: "grid",
        gridTemplateColumns: "repeat(14, 1fr)",
        gridTemplateRows: "repeat(7, 1fr)",
        height: "100vh",
        overflowY: "scroll",
        bg: theme.colors.blue,
      }}
    >
      <LoggedInStatus customColor={theme.colors.white} />
      <Box
        sx={{
          gridRowStart: 1,
          gridColumnStart: 3,
          gridColumnEnd: 10,
          marginTop: 35,
        }}
      >
        <Image sx={{ alignSelf: "start", height: 55 }} src={northOneWhite} />
      </Box>
      <Box
        sx={{
          gridRowStart: 2,
          gridRowEnd: 8,
          gridColumnStart: 1,
          gridColumnEnd: 1,
          marginBottom: 50,
          display: "flex",
          flexDirection: "column",
          justifyContent: "flex-end",
          alignItems: "flex-end",
        }}
      >
        <BackButton onBackClick={onBackClick} blueScreen />
      </Box>
      <Box
        sx={{
          gridRowStart: 2,
          gridRowEnd: 7,
          gridColumnStart: 3,
          gridColumnEnd: 7,
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
        }}
      >
        <Image src={cards} />
      </Box>
      <Box
        sx={{
          gridColumnStart: 7,
          gridColumnEnd: 15,
          gridRowStart: 2,
          gridRowEnd: 7,
          paddingBottom: 50,
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
          justifyContent: "center",
          marginLeft: 50,
        }}
      >
        <Heading
          sx={{
            textAlign: "left",
            color: theme.colors.white,
            ...theme.fontStyles.h1,
            paddingBottom: 75,
          }}
        >
          {title}
        </Heading>
        <Text
          sx={{
            textAlign: "left",
            color: theme.colors.white,
            ...theme.fontStyles.h2,
            fontWeight: 600,
            paddingBottom: 30,
          }}
        >
          {subtitle}
        </Text>
        <Box
          sx={{
            paddingBottom: 75,
            lineHeight: theme.fontStyles.h2.lineHeight,
          }}
        >
          {addressBlock}
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: ["column", "column", "column", "row"],
          }}
        >
          <Box
            sx={{
              paddingRight: [0, 0, 0, 10],
              paddingBottom: [35, 35, 35, 0],
            }}
          >
            <Button
              onClick={buttonOnClick}
              type={"white"}
              withEnter={true}
              noSimulatedSavingOnClick
            >
              {buttonText}
            </Button>
          </Box>
          {showAddressToggle ? (
            <Button
              onClick={addressToggleOnClick ? addressToggleOnClick : () => null}
              type={"whiteOutline"}
              noSimulatedSavingOnClick
            >
              {addressToggleText}
            </Button>
          ) : null}
        </Box>
      </Box>
      <Box
        sx={{ gridRowStart: [9, 9, 9, 9], gridRowEnd: 9, gridColumnStart: 5, gridColumnEnd: 8 }}
        style={{
          display: "flex",
          flexDirection: "row",
          flexWrap: "wrap",
          marginTop: "2rem",
        }}
      ></Box>
    </Box>
  )
}
