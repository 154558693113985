import React from "react"

import { IRootState, TRootDispatch } from "core"
import { KYBActions, WebsiteType } from "../redux"
import { connect } from "react-redux"

import { KYBWebsiteTypeScreen } from "./kyb-website-type.screen"

export interface IWebsiteTypeStateProps {
  websiteType: WebsiteType
  website: string
}

export interface IWebsiteTypeDispatchProps {
  setWebsiteType: (payload: WebsiteType) => void
}
export type TWebsiteTypeProps = IWebsiteTypeStateProps & IWebsiteTypeDispatchProps

const mapDispatchToProps = (dispatch: TRootDispatch): IWebsiteTypeDispatchProps => ({
  setWebsiteType: (payload: WebsiteType) => {
    dispatch(KYBActions.setWebsiteType(payload))
  },
})

const mapStateToProps = (state: IRootState): IWebsiteTypeStateProps => ({
  website: state.KYB.website,
  websiteType: state.KYB.websiteType,
})

export const KYBWebsiteType = connect(
  mapStateToProps,
  mapDispatchToProps,
)((props: IWebsiteTypeStateProps & IWebsiteTypeDispatchProps) => (
  <KYBWebsiteTypeScreen {...props} />
))
