import React, { useState } from "react"
import { useTranslation } from "react-i18next"
import { Box } from "rebass"
import { BaseLayout, BaseScreen, SidePanel, ButtonRow } from "shared"
// @ts-ignore
import { CountryDropdown } from "react-country-region-selector"

interface IUnsupportedCountryProps {
  unsupportedCountry: string
  setUnsupportedCountry: (unsupportedCountry: string) => void
  prev: () => void
  next: () => void
}

export const UnsupportedCountryScreen: React.FC<IUnsupportedCountryProps> = ({
  unsupportedCountry,
  setUnsupportedCountry,
  prev,
  next,
}) => {
  const { t } = useTranslation()
  const [newUnsupportedCountry, setNewUnsupportedCountry] = useState(unsupportedCountry)
  const valueIsValid = () => newUnsupportedCountry

  return (
    <BaseLayout
      sidePanel={<SidePanel section={2} />}
      body={
        <BaseScreen
          title={t("shared.screens.unsupportedCountry.title")}
          subtitle={t("shared.screens.unsupportedCountry.subtitle")}
        >
          <Box style={{ marginTop: "2rem" }}>
            <CountryDropdown value={newUnsupportedCountry} onChange={setNewUnsupportedCountry} />
          </Box>
        </BaseScreen>
      }
      buttonRow={
        <ButtonRow
          buttonOnClick={() => {
            setUnsupportedCountry(newUnsupportedCountry)
            next()
          }}
          buttonText={t("shared.screens.unsupportedCountry.buttonText")}
          buttonDisabled={!valueIsValid()}
        />
      }
      onBackClick={prev}
    />
  )
}
