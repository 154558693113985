import { logger } from "utils"

type LocalStorageKeys = "accessToken"

class LocalStorageService {
  public setAccessToken(token: string): void {
    this.setItem("accessToken", token)
  }

  public getAccessToken(): string | null {
    return this.getItem("accessToken")
  }

  public removeAccessToken(): void {
    logger.info("removing access token from localStorage")
    this.removeItem("accessToken")
  }

  public clearAll(): void {
    logger.info("LocalStorageService: clearing all of localStorage")
    localStorage.clear()
  }

  private setItem(key: LocalStorageKeys, value: string) {
    localStorage.setItem(key, value)
  }

  private getItem(key: LocalStorageKeys): string | null {
    return localStorage.getItem(key)
  }

  private removeItem(key: LocalStorageKeys) {
    localStorage.removeItem(key)
  }
}

export const _localStorage = new LocalStorageService()
