import { navigate } from "core/navigation/utils"

export const navigator = {
  pages: {
    BackgroundCheckSubmit: {
      prev: ({ withDebitFunding }: { withDebitFunding: boolean }) => {
        withDebitFunding ? navigate("DEBIT_FUNDING_SUCCESS") : navigate("DEBIT_FUNDING_LANDING")
      },
      next: () => navigate("BACKGROUND_CHECK_REJECT"),
    },
  },
}
