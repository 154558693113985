import { createAction, TActionsUnion } from "core"
import { IAddress } from "types"

export enum ControlPersonActionEnums {
  SET_FIRSTNAME = "CONTROL_PERSON_SET_FIRSTNAME",
  SET_LASTNAME = "CONTROL_PERSON_SET_LASTNAME",
  SET_EMAIL = "CONTROL_PERSON_SET_EMAIL",
  SET_PHONE = "CONTROL_PERSON_SET_PHONE",
  SET_BIRTHDATE = "CONTROL_PERSON_SET_BIRTHDATE",
  SET_HOME_ADDRESS = "CONTROL_PERSON_SET_HOME_ADDRESS",
  SET_JOB_TITLE = "CONTROL_PERSON_SET_JOB_TITLE",
  SET_ROLE = "CONTROL_PERSON_SET_ROLE",
  SET_RESIDENCY = "CONTROL_PERSON_SET_RESIDENCY",
  SET_SSN = "CONTROL_PERSON_SET_SSN",
  SET_UNSUPPORTED_COUNTRY = "CONTROL_PERSON_SET_UNSUPPORTED_COUNTRY",
  SELECT_CONTROL_PERSON = "CONTROL_PERSON_SELECT",
}

export const ControlPersonActions = {
  setFirstName: (firstName: string) =>
    createAction(ControlPersonActionEnums.SET_FIRSTNAME, firstName),
  setLastName: (lastName: string) => createAction(ControlPersonActionEnums.SET_LASTNAME, lastName),
  setEmail: (email: string) => createAction(ControlPersonActionEnums.SET_EMAIL, email),
  setPhone: (phone: string) => createAction(ControlPersonActionEnums.SET_PHONE, phone),
  setBirthDate: (birthDate: string) =>
    createAction(ControlPersonActionEnums.SET_BIRTHDATE, birthDate),
  setHomeAddress: (homeAddress: IAddress) =>
    createAction(ControlPersonActionEnums.SET_HOME_ADDRESS, homeAddress),
  setJobTitle: (jobTitle: string) => createAction(ControlPersonActionEnums.SET_JOB_TITLE, jobTitle),
  setRole: (role: string) => createAction(ControlPersonActionEnums.SET_ROLE, role),
  setResidency: (residency: string) =>
    createAction(ControlPersonActionEnums.SET_RESIDENCY, residency),
  setSSN: (SSN: string) => createAction(ControlPersonActionEnums.SET_SSN, SSN),
  setUnsupportedCountry: (unsupportedCountry: string) =>
    createAction(ControlPersonActionEnums.SET_UNSUPPORTED_COUNTRY, unsupportedCountry),
  selectControlPerson: (email: string) =>
    createAction(ControlPersonActionEnums.SELECT_CONTROL_PERSON, email),
}

export type TControlPersonActions = TActionsUnion<typeof ControlPersonActions>
