import React from "react"
import { TKYBAddressProps } from "./kyb-address.container"
import { nav } from "core/navigation"
import { AddressScreen } from "shared"
import { useTranslation } from "react-i18next"
const { prev, next } = nav.onboarding.kyb.pages.KYBAddress

export const KYBAddressScreen: React.FC<TKYBAddressProps> = ({
  businessAddress,
  setBusinessAddress,
}) => {
  const { t } = useTranslation()
  return (
    <AddressScreen
      title={t("kyb.address.title")}
      defaultAddress={businessAddress}
      setAddress={setBusinessAddress}
      next={next}
      prev={prev}
      analyticEventStringOnSubmit={"KYC Verification Manual Home Address Confirm Tap"}
    />
  )
}
