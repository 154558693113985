import React, { useState, useEffect } from "react"
import { Button as RButton, Box, Text, ButtonProps, SxStyleProp } from "rebass"
import { theme } from "theme/theme"
import { useTranslation } from "react-i18next"
import { INPUT_TAG_NAME } from "./back-button.component"

export type TButtonType =
  | "primary"
  | "secondary"
  | "blueOutline"
  | "white"
  | "whiteOutline"
  | "principal"
  | "error"
interface IProps extends ButtonProps {
  disabled?: boolean
  type?: TButtonType
  withEnter?: boolean
  onClick: () => void
  isCircle?: boolean
  isRadio?: boolean
  customRadioStyle?: SxStyleProp
  noSimulatedSavingOnClick?: boolean
  buttonSupertext?: string
  buttonSupertextAction?: () => void
  isLoading?: boolean
  noBorder?: boolean
}

const {
  colors: { blue, white, grey, red, orange },
} = theme

const buttonColors = {
  disabled: {
    borderColor: grey,
    color: grey,
    backgroundColor: white,
    ":hover": {
      cursor: "default",
    },
  },
  primary: {
    borderColor: blue,
    color: white,
    backgroundColor: blue,
    ":hover": {
      cursor: "pointer",
    },
  },
  white: {
    borderColor: white,
    color: blue,
    backgroundColor: white,
    ":hover": {
      cursor: "pointer",
    },
  },
  whiteOutline: {
    borderColor: white,
    color: white,
    backgroundColor: blue,
    ":hover": {
      cursor: "pointer",
    },
  },
  secondary: {
    borderColor: grey,
    color: grey,
    backgroundColor: white,
    ":hover": {
      borderColor: blue,
      color: blue,
      cursor: "pointer",
    },
  },
  blueOutline: {
    borderColor: blue,
    color: blue,
    backgroundColor: white,
    ":hover": {
      borderColor: grey,
      cursor: "pointer",
    },
  },
  principal: {
    borderColor: orange,
    color: white,
    backgroundColor: orange,
    ":hover": {
      cursor: "pointer",
    },
  },
  error: {
    borderColor: red,
    color: red,
    backgroundColor: white,
    ":hover": {
      cursor: "pointer",
    },
  },
}

export const SIMULATED_SAVING_MS = 300
const ENTER_KEY_CODE = 13
const RIGHT_ARROW_KEY_CODE = 39

const getButtonColors = (type: TButtonType, disabled: boolean) => {
  if (disabled) {
    return buttonColors.disabled
  }
  return buttonColors[type]
}

export const Button: React.FC<IProps> = (props) => {
  const {
    disabled = false,
    children,
    type = "primary",
    withEnter = false,
    isCircle = false,
    isRadio = false,
    customRadioStyle = {},
    onClick,
    noSimulatedSavingOnClick,
    buttonSupertext,
    buttonSupertextAction,
    isLoading = false,
    noBorder = false,
  } = props
  const [simulatedIsSaving, setSimulatedOnSaving] = useState(false)
  const { t } = useTranslation()

  const onSubmit = () => {
    if (!disabled && !simulatedIsSaving && !isLoading) {
      if (noSimulatedSavingOnClick) {
        onClick()
      } else {
        setSimulatedOnSaving(true)
        setTimeout(() => {
          setSimulatedOnSaving(false)
          onClick()
        }, SIMULATED_SAVING_MS)
      }
    }
  }
  useEffect(() => {
    const onKeyDown = (event: KeyboardEvent) => {
      const target = event.target as Element

      if (type === "primary" || type === "white") {
        if (
          !disabled &&
          !simulatedIsSaving &&
          !isLoading &&
          (event.keyCode === ENTER_KEY_CODE ||
            (event.keyCode === RIGHT_ARROW_KEY_CODE && target.tagName !== INPUT_TAG_NAME))
        ) {
          onSubmit()
        }
      }
    }
    document.addEventListener("keydown", onKeyDown)
    return () => {
      document.removeEventListener("keydown", onKeyDown)
    }
  })

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "center",
        flexDirection: "column",
      }}
    >
      {buttonSupertext ? (
        <Text
          style={{
            position: "absolute",
            ...(buttonSupertextAction ? { textDecoration: "underline", cursor: "pointer" } : {}),
          }}
          mt="-3rem"
          fontSize="text"
          color={theme.colors.grey}
          onClick={buttonSupertextAction}
        >
          {buttonSupertext}
        </Text>
      ) : null}
      <RButton
        sx={
          {
            ...theme.fontStyles.buttonText,
            borderWidth: noBorder ? 0 : 1.3,
            borderStyle: "solid",
            borderRadius: theme.borderRadius,
            outline: "none",
            height: "3rem",
            width: isCircle ? "3rem" : "18rem",
            ...(isRadio
              ? {
                  width: "28rem",
                  marginRight: "1rem",
                  marginBottom: "0.5rem",
                  marginTop: "0.5rem",
                  ...customRadioStyle,
                }
              : null),
            padding: 0,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            ...getButtonColors(type, disabled),
          } as SxStyleProp
        }
        {...props}
        onClick={onSubmit}
      >
        {simulatedIsSaving || isLoading ? t("buttons.saving") : children}
      </RButton>
      {withEnter && !disabled ? (
        <Text
          sx={{
            paddingTop: "11.5px",
            position: "relative",
            fontWeight: theme.fontStyles.smallText.fontWeight,
            fontSize: theme.fontStyles.smallText.fontSize,
            color: type === "white" ? theme.colors.white : theme.colors.darkGrey,
          }}
        >
          {t("buttons.orPress")} <span style={{ fontWeight: 600 }}>{t("buttons.enter")}</span>
        </Text>
      ) : null}
    </Box>
  )
}
