import { initialUBOState, IUBOState, initialNewOwner } from "./ubo.state"
import { UBOActionEnums, TUBOActions } from "./ubo.actions"
import uuid from "uuid"

/* SENSITIVE VALUES LOGIC NO LONGER REQUIRED - was an old requirement when persisting the entire state
 TODO: Refactor to put SSNs directly on UBO
 */
export const UBOReducer = (state: IUBOState = initialUBOState, action: TUBOActions): IUBOState => {
  switch (action.type) {
    case UBOActionEnums.UBO_SET_VALUE:
      return {
        ...state,
        newOwner: {
          ...state.newOwner,
          [action.payload.key]: action.payload.value,
        },
      }
    case UBOActionEnums.UBO_SET_SENSITIVE_VALUE:
      return {
        ...state,
        sensitive: {
          ...state.sensitive,
          newOwner: {
            ...state.sensitive.newOwner,
            [action.payload.key]: action.payload.value,
          },
        },
      }

    case UBOActionEnums.UBO_ADD_OWNER:
      const additionalOwner = {
        ...state.newOwner,
        id: uuid(),
      }

      const additionOwnerSensitive = { ssn: state.sensitive.newOwner.ssn }

      return {
        ...state,
        newOwner: initialNewOwner,
        additionalOwners: [...state.additionalOwners, additionalOwner],
        sensitive: {
          ...state.sensitive,
          newOwner: { ssn: "" },
          additionalOwners: {
            ...state.sensitive.additionalOwners,
            [additionalOwner.id]: additionOwnerSensitive,
          },
        },
      }

    case UBOActionEnums.UBO_EDIT_OWNER:
      return {
        ...state,
        additionalOwners: state.additionalOwners.map((owner) => {
          if (owner.id === action.payload.ownerId) {
            return { ...owner, ...action.payload.owner }
          }
          return owner
        }),
        sensitive: {
          ...state.sensitive,
          additionalOwners: {
            ...state.sensitive.additionalOwners,
            [action.payload.ownerId]: action.payload.sensitive,
          },
        },
      }

    case UBOActionEnums.UBO_DELETE_OWNER:
      const newState = {
        ...state,
        additionalOwners: state.additionalOwners.filter((owner) => owner.id !== action.payload),
      }

      delete newState.sensitive.additionalOwners[action.payload]

      return newState

    default:
      return state
  }
}
