import React, { ReactNode } from "react"
import { HeadingWithIcon } from "../heading-with-icon"
import { Box } from "rebass"
import { theme } from "theme/theme"

export interface IBaseScreenProps {
  title: string
  subtitle?: string | ReactNode
  multilineSubtitle?: string[]
  buttonDisabled?: boolean
}

export const BaseScreen: React.SFC<IBaseScreenProps> = ({
  title,
  subtitle,
  multilineSubtitle,
  children,
}) => {
  return (
    <Box
      sx={{
        marginBottom: theme.spacing.buttonRowToBody,
      }}
    >
      <HeadingWithIcon title={title} subtitle={subtitle} multilineSubtitle={multilineSubtitle} />
      {children}
    </Box>
  )
}
