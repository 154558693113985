import React, { useState } from "react"
import { useTranslation } from "react-i18next"
import { Box } from "rebass"
import { BaseLayout, BaseScreen, TextInput, SidePanel, ButtonRow } from "shared"
import { isValidEIN } from "utils/validators"
import validator from "validator"
import { TKYBEINProps } from "./kyb-ein.container"
import { nav } from "core/navigation"
import { Analytics } from "../../../../core/analytics/actions"

export const KYBEINScreen: React.FC<TKYBEINProps> = ({ EIN, setEIN, businessType }) => {
  const { t } = useTranslation()
  const [newEIN, setNewEIN] = useState(EIN)
  const valueIsValid = () => isValidEIN(newEIN)
  const { prev, next } = nav.onboarding.kyb.pages.KYBEIN

  return (
    <BaseLayout
      sidePanel={<SidePanel section={2} />}
      body={
        <BaseScreen title={t("kyb.ein.title")} subtitle={t("kyb.ein.subtitle")}>
          <Box style={{ marginTop: "2rem" }}>
            <TextInput
              placeholderText={t("kyb.ein.placeholder")}
              onChange={(newValue) => (newValue.length <= 10 ? setNewEIN(newValue) : null)}
              value={newEIN}
              autoFocus={true}
              validationType={newEIN && !validator.isNumeric(newEIN) ? "error" : "info"}
              validationText={
                newEIN && !validator.isNumeric(newEIN.replace("-", ""))
                  ? t("errors.onlyDigits")
                  : ""
              }
              withEINMask={true}
            />
          </Box>
        </BaseScreen>
      }
      buttonRow={
        <ButtonRow
          buttonOnClick={() => {
            setEIN(newEIN)
            Analytics.track(`KYB Business EIN Continue Tap`)
            next()
          }}
          buttonDisabled={!valueIsValid()}
        />
      }
      onBackClick={() => prev(businessType)}
    />
  )
}
