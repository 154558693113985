import React from "react"
import { connect } from "react-redux"
import { IRootState, TRootDispatch } from "core"
import { TermsAndConditionsScreen } from "./terms-and-conditions.screen.gql"
import { ErrorsActions } from "components/errors/redux/errors.actions"

interface ITermsAndConditionsStateProps {
  phone: string
  email: string
}

interface ITermsAndConditionsDispatchProps {
  setCurrentErrorMessage: (errorMessage: string) => void
}

export type TTermsAndConditionsProps = ITermsAndConditionsStateProps &
  ITermsAndConditionsDispatchProps

const mapStateToProps = (state: IRootState): ITermsAndConditionsStateProps => ({
  phone: state.signUp.phone,
  email: state.signUp.email,
})

const mapDispatchToProps = (dispatch: TRootDispatch): ITermsAndConditionsDispatchProps => ({
  setCurrentErrorMessage: (errorMessage: string) =>
    dispatch(ErrorsActions.setCurrentErrorMessage(errorMessage)),
})

export const TermsAndConditions = connect(
  mapStateToProps,
  mapDispatchToProps,
)((props: TTermsAndConditionsProps) => <TermsAndConditionsScreen {...props} />)
