export const routes = {
  KYC_LANDING: "/kyc",
  KYC_NAME: "/kyc/name",
  KYC_BIRTHDATE: "/kyc/birthdate",
  KYC_ADDRESS: "/kyc/address",
  KYC_PHONE: "/kyc/phone",
  KYC_RESIDENCY: "/kyc/residency",
  KYC_ROLE: "/kyc/role",
  KYC_SSN: "/kyc/ssn",
  KYC_COMPLETE: "/kyc/complete",
  KYC_UNSUPPORTED_COUNTRY: "/kyc/unsupported-country",
  KYC_UNSUPPORTED_COUNTRY_SUCCESS: "/kyc/unsupported-country-success",
  KYC_SSN_MISSING: "/kyc/ssn-missing/",
}

type RoutesAction = keyof typeof routes

export const components: Record<RoutesAction, string> = {
  KYC_NAME: "KYCName",
  KYC_LANDING: "KYCLanding",
  KYC_BIRTHDATE: "KYCBirthDate",
  KYC_ADDRESS: "KYCAddress",
  KYC_PHONE: "KYCPhone",
  KYC_RESIDENCY: "KYCResidency",
  KYC_ROLE: "KYCRole",
  KYC_SSN: "KYCSSN",
  KYC_COMPLETE: "KYCComplete",
  KYC_UNSUPPORTED_COUNTRY: "KYCUnsupportedCountry",
  KYC_UNSUPPORTED_COUNTRY_SUCCESS: "KYCUnsupportedCountrySuccess",
  KYC_SSN_MISSING: "KYCSSNMissing",
}
