import { logger } from "utils"
import { analytics } from "@northone/segment-js"

export const Analytics = {
  reset: () => {
    logger.info("Analytics Reset")
    analytics().reset()
  },
  track: (eventName: string, properties?: any) => {
    logger.info("Analytics Event: " + eventName)
    analytics().track(`Desktop - ${eventName}`, properties)
  },
  alias: (userId: string) => {
    logger.info("Analytics Alias")
    analytics().alias(userId)
  },
  page: (pageName: string, properties?: any) => {
    logger.info("Analytics Page: " + pageName)
    analytics().page(pageName, properties)
  },
  identify: (userId?: string | Record<string, any>, traits?: Record<string, any>) => {
    logger.info("Analytics Identify: " + JSON.stringify(traits || userId))
    analytics().identify(userId, traits)
  },
  phoneNumber: (phone: string) => {
    logger.info("Analytics Phone: " + phone)
    analytics().identify({ phone })
  },
}
