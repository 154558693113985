/* tslint:disable:max-line-length */

export const termsAndConditionsData = [
  {
    text: "NORTHONE’S TERMS OF SERVICE  LAST UPDATED DATE: February 19, 2020",
    centered: true,
  },
  {
    text:
      "NorthOne Inc., a Delaware corporation ('NorthOne', 'we' or 'us'), operates a service  accessible through an mobile application titled 'NorthOne', (the 'App' and the service made available via the App, the 'Service') that enables end users to access certain financial products and banking services made available by Radius Bank, a federal savings bank (the “Bank”)",
    centered: true,
  },
  {
    text:
      "These Terms of Service (collectively, “Terms of Service”) set forth the terms and conditions under which you may access and use the Service.",
    hasInitialIndent: true,
  },
  {
    text: `BY ACCESSING, REGISTERING WITH, USING THE SERVICES OF NORTHONE OR DOWNLOADING THE APP, YOU AGREE
      TO THESE TERMS OF SERVICE. IF
      YOU DO NOT AGREE TO THESE TERMS OF SERVICE (WITHOUT MODIFICATION), YOU ARE NOT AUTHORIZED TO ACCESS, REGISTER WITH OR USE THE SERVICES.`,
    hasInitialIndent: true,
  },
  {
    text: `
      ALL USERS OF THE SERVICES OFFERED BY NORTHONE
      THROUGH THE NORTHONE APPLICATION MUST BE EIGHTEEN (18) YEARS OF AGE OR OLDER TO ACCESS SUCH SERVICES. PLEASE BE AWARE THAT SECTION 12
      OF THIS AGREEMENT, BELOW, CONTAINS PROVISIONS GOVERNING HOW CLAIMS THAT YOU
      AND WE HAVE AGAINST EACH OTHER ARE RESOLVED, INCLUDING, WITHOUT LIMITATION, ANY CLAIMS THAT AROSE OR WERE ASSERTED PRIOR TO THE
      EFFECTIVE DATE OF THIS AGREEMENT. IN PARTICULAR, IT CONTAINS AN
      ARBITRATION AGREEMENT WHICH WILL, WITH LIMITED EXCEPTIONS, REQUIRE DISPUTES BETWEEN US TO BE
      SUBMITTED TO BINDING AND FINAL ARBITRATION.  UNLESS YOU OPT OUT OF THE ARBITRATION AGREEMENT: (1) YOU WILL ONLY BE
      PERMITTED TO PURSUE CLAIMS AND SEEK RELIEF AGAINST US ON AN INDIVIDUAL BASIS, NOT AS A PLAINTIFF OR CLASS MEMBER
      IN ANY CLASS OR REPRESENTATIVE ACTION OR PROCEEDING; AND (2) YOU ARE WAIVING YOUR RIGHT TO SEEK RELIEF
      IN A COURT OF LAW AND TO HAVE A JURY TRIAL ON YOUR CLAIMS.`,
    hasInitialIndent: true,
  },
  {
    text: `
      NorthOne reserves the right to modify or change these Terms of Service at any time without prior notice to you.  If
      NorthOne modifies or changes these Terms of Service, it will also revise the “Last Updated Date” at the top of the
      page.  NorthOne may also send you an email address you provided, if any. It is your sole responsibility to ensure that
       you have provided us with the most up-to-date email address. PLEASE NOTE THAT, NOTWITHSTANDING ANYTHING CONTAINED IN
       THESE TERMS OF SERVICE, THE SERVICE IS PROVIDED WITHOUT ANY WARRANTIES AND SUBJECT TO LIMITATIONS ON NORTHONE’S
        LIABILITY.`,
    hasInitialIndent: true,
  },
  {
    text: `Your use of, and participation in, certain Services may be subject to additional terms (“Supplemental Terms”) and such
      Supplemental Terms will either be listed in the Terms of Service or will be presented to you for your acceptance when you
       sign up to use the supplemental Service.`,
    hasInitialIndent: true,
  },
  {
    text: `If the Terms of Service are inconsistent with the Supplemental Terms, the Supplemental Terms shall control with respect to such Service.  The
    Terms of Service and any applicable Supplemental
   Terms are referred to herein as the “Terms”.`,
    hasInitialIndent: true,
  },
  {
    text: "1.      THE SERVICE",
    hasInitialIndent: true,
  },
  {
    text: `1.1     General.   NorthOne’s Services act as a portal that enables end users to access certain financial products, such as business checking
      accounts, mobile check deposit and BillPay features, and other banking services made available by the Bank. Our App acts as an interface between you and the Bank. NorthOne
      IS NOT a bank, regulated or otherwise, and DOES NOT provide, sponsor, manage, maintain, offer, support or otherwise made available any financial
      products or banking services made available via the Services, as all such financial products are made available solely by, and are the sole responsibility of, the Bank.  Rather, NorthOne
      merely acts as a interface to enable end users to access the products and services of the Bank. NorthOne IS NOT a
      licensed money transmitter and does not hold, transfer or store any funds you may have  1.2     Bank Terms.  By using any financial products or any banking services made available through the Service, you hereby acknowledge and agree that you are bound by and subject to the terms of the Bank (https://radiusbank.com).  By accessing or using any of the financial products and/or banking services provided by the Bank, you acknowledge and agree that you are bound by the applicable terms and conditions, agreements
      and policies available at https://radiusbank.com/legal, and Radius Bank’s privacy policy at https://radiusbank.com/legal/privacy.  We encourage
      you to read such terms and conditions, agreements and policies before
      using our Services. 1.3     Disclaimer.  THE SERVICE ACTS AS A VENUE ONLY THROUGH WHICH, END USERS MAY ACCESS CERTAIN FINANCIAL PRODUCTS AND BANKING SERVICES MADE AVAILABLE BY THE BANK.  WE URGE ALL USERS TO BE RESPONSIBLE ABOUT
      THEIR USE OF THE SERVICE AND ANY TRANSACTION ENTERED INTO WITH A BANK.  YOU UNDERSTAND THAT NORTHONE IS NOT A BANK OR A LICENSED MONEY TRANSMITTER. NORTHONE DOES NOT AND CANNOT PROVIDE OR CONTROL ANY FINANCIAL OR BANKING PRODUCTS OR SERVICES, AND SUCH PRODUCTS AND SERVICES ARE
      THE SOLE RESPONSIBILITY OF THE BANK WITH WHOM YOU TRANSACT.`,
    hasInitialIndent: true,
  },
  {
    text: "2.      GENERAL USE AND RESTRICTIONS",
    hasInitialIndent: true,
  },
  {
    text:
      "2.1     Use of Service. You agree not to access and/or use the Services for any purpose that is not expressly permitted by these Terms or is otherwise unlawful.",
    hasInitialIndent: true,
  },
  {
    text:
      "2.2     Registration.  In order to access certain features of Services you may be required to become a Registered User.  For purposes of the Terms, a “Registered User” is a User who has registered an account on the App (“Account”). If you provide any information to us, you agree to provide only true, accurate, current and complete information, and you will keep such information true, accurate, current and complete.   You are responsible for all activities that occur under your Account.   You agree that you shall monitor your Account to restrict use by minors, and you will accept full responsibility for any unauthorized use of App by minors.  You may not share your Account or password with anyone, and you agree to (1) notify NorthOne immediately of any unauthorized use of your password or any other breach of security; and (2) exit from your Account at the end of each session.  If you provide any information that is untrue, inaccurate, not current or incomplete, or NorthOne has reasonable grounds to suspect that such information is untrue, inaccurate, not current or incomplete, NorthOne has the right to suspend or terminate your Account and refuse any and all current or future use of App and Services (or any portion thereof).  You agree not to create an Account using a false identity or information, or on behalf of someone other than yourself.",
    hasInitialIndent: true,
  },
  {
    text:
      "2.3     Restrictions. The rights granted to you in the Terms are subject to the following restrictions: (a) you shall not license, sell, rent, lease, transfer, assign, reproduce, distribute, host or otherwise commercially exploit App or Services (“NorthOne Properties”) or any portion of NorthOne Properties, (b) you shall not frame or utilize framing techniques to enclose any trademark, logo, or other NorthOne Properties (including images, text, page layout or form) of NorthOne; (c) you shall not use any metatags or other “hidden text” using NorthOne’s name or trademarks; (d) you shall not modify, translate, adapt, merge, make derivative works of, disassemble, decompile, reverse compile or reverse engineer any part of NorthOne Properties except to the extent the foregoing restrictions are expressly prohibited by applicable law; (e) you shall not access NorthOne Properties in order to build a similar or competitive website, application or service; (f) except as expressly stated herein, no part of NorthOne Properties may be copied, reproduced, distributed, republished, downloaded, displayed, posted or transmitted in any form or by any means; and (g) you shall not remove or destroy any copyright notices or other proprietary markings contained on or in NorthOne Properties. Any future release, update or other addition to NorthOne Properties shall be subject to the Terms.  NorthOne, its suppliers and service providers reserve all rights not granted in the Terms.  Any unauthorized use of NorthOne Properties terminates the licenses granted by NorthOne pursuant to the Terms.",
    hasInitialIndent: true,
  },
  {
    text: "3.      FEES AND PAYMENT",
    hasInitialIndent: true,
  },
  {
    text: "3.1     NorthOne Fees.",
    hasInitialIndent: true,
  },
  {
    text:
      "(a) Payments. You agree to pay all fees or charges to your Account in accordance with the fees, charges and billing terms in effect at the time a fee or charge is due and payable.  You must provide NorthOne with a valid credit card (Visa, MasterCard, or any other issuer accepted by us) (“Payment Provider”) as a condition to signing up for the Services.  Your Payment Provider agreement governs your use of the designated credit card, and you must refer to that agreement and not the Terms to determine your rights and liabilities.  By providing NorthOne with your credit card number and associated payment information, you agree that NorthOne is authorized to immediately invoice your Account for all fees and charges due and payable to NorthOne hereunder and that no additional notice or consent is required.  You agree to immediately notify NorthOne of any change in your billing address or the credit card used for payment hereunder.  NorthOne reserves the right at any time to change its prices and billing methods, either immediately upon posting on NorthOne Properties or by e-mail delivery to you",
    hasInitialIndent: true,
  },
  {
    text:
      "(b)     Taxes.  NorthOne’s fees are net of any applicable Sales Tax.  If any Services, or payments for any Services, under the Terms are subject to Sales Tax in any jurisdiction and you have not remitted the applicable Sales Tax to NorthOne, you will be responsible for the payment of such Sales Tax and any related penalties or interest to the relevant tax authority, and you will indemnify NorthOne for any liability or expense we may incur in connection with such Sales Taxes.  Upon our request, you will provide us with official receipts issued by the appropriate taxing authority, or other such evidence that you have paid all applicable taxes.  For purposes of this section, “Sales Tax” shall mean any sales or use tax, and any other tax measured by sales proceeds, that NorthOne is permitted to pass to its customers, that is the functional equivalent of a sales tax where the applicable taxing jurisdiction does not otherwise impose a sales or use tax",
    hasInitialIndent: true,
  },
  {
    text:
      "3.2     Bank Fees. You are solely responsible for any and all fees, penalties, costs, expenses and other amounts due in connection with your account with the Bank, and will indemnify, defend and hold NorthOne harmless from any and all such fees, penalties, costs, expenses and amounts.  In the event you have any dispute regarding such amounts, please contact the Bank at:customerservices@radiusbank.com.",
    hasInitialIndent: true,
  },
  {
    text:
      "4.      USER CONDUCT. In connection with your use of NorthOne Properties, you shall not:",
    hasInitialIndent: true,
  },
  {
    text:
      "4.1     Display, transmit, distribute, post or otherwise make available (collectively, “Make Available”) any information, data, text, software, music, sound, photographs, graphics, video, messages, tags and/or other materials accessible through NorthOne Properties (“Content”) that (i) is unlawful, tortious, defamatory, vulgar, obscene, libelous, or racially, ethnically or otherwise objectionable; (ii) violates, or encourages any conduct that would violate, any applicable law or regulation or would give rise to civil liability; (iii) promotes discrimination, bigotry, racism, hatred, harassment or harm against any individual or group; (iv) is violent or threatening, or promotes violence or actions that are threatening to any other person; or (v) promotes illegal or harmful activities;",
    hasInitialIndent: true,
  },
  {
    text: "4.2     Harm minors in any way;",
    hasInitialIndent: true,
  },
  {
    text:
      "4.3     Impersonate any person or entity, including, but not limited to, NorthOne personnel, or falsely state or otherwise misrepresent your affiliation with a person or entity;",
    hasInitialIndent: true,
  },
  {
    text:
      "4.4     Make available any Content that you do not have a right to Make Available under any law or under contractual or fiduciary relationships (such as inside information, proprietary and confidential information learned or disclosed as part of employment relationships or under non-disclosure agreements);",
    hasInitialIndent: true,
  },
  {
    text:
      "4.5     Make Available any Content that infringes the rights of any person or entity, including without limitation, any patent, trademark, trade secret, copyright, privacy, publicity or other proprietary or contractual rights;",
    hasInitialIndent: true,
  },
  {
    text:
      "4.6     Intentionally or unintentionally violate any applicable local, state, national or international law or regulation, or any order of a court;",
    hasInitialIndent: true,
  },
  {
    text:
      "4.7     Register for more than one Account or register for an Account on behalf of an individual other than yourself;",
    hasInitialIndent: true,
  },
  {
    text: "4.8     Stalk or otherwise harass any other user of our NorthOne Properties; or",
    hasInitialIndent: true,
  },
  {
    text:
      "4.9     Advocate, encourage or assist any third party in doing any of the foregoing activities in this section.",
    hasInitialIndent: true,
  },
  {
    text:
      "Please note that your use of the Services may be subject to additional restrictions as set forth in Radius Bank’s applicable terms and conditions, agreements and policies available at https://radiusbank.com/legal. You are solely responsible for reading and understanding such restrictions.",
    hasInitialIndent: true,
  },
  {
    text: "5.      REMEDIES",
    hasInitialIndent: true,
  },
  {
    text:
      "5.1     Violations.  If NorthOne becomes aware of any possible violations by you of the Terms, NorthOne reserves the right to investigate such violations.  If, as a result of the investigation, NorthOne believes that criminal activity has occurred, NorthOne reserves the right to refer the matter to, and to cooperate with, any and all applicable legal authorities.  NorthOne is entitled, except to the extent prohibited by applicable law, to disclose any information or materials on or in NorthOne Properties in NorthOne’s possession in connection with your use of NorthOne Properties, to (1) comply with applicable laws, legal process or governmental request; (2) enforce the Terms, (3) respond to your requests for customer service, or (4) protect the rights, property or personal safety of NorthOne, its users or the public, and all enforcement or other government officials, as NorthOne in its sole discretion believes to be necessary or appropriate.",
    hasInitialIndent: true,
  },
  {
    text: `5.2     Breach.  In the event that NorthOne determines, in its sole discretion, that you have breached any portion
       of the Terms, or have otherwise demonstrated conduct inappropriate for NorthOne Properties, NorthOne
       reserves the right to:
      a.         Warn you via e-mail (to any e-mail address you have provided to NorthOne) that you have violated the Terms;
      b.         Notify and/or send Content to and/or fully cooperate with the proper law enforcement authorities for further action; and/or
      c.          Pursue any other action which NorthOne deems to be appropriate.`,
    hasInitialIndent: true,
  },
  {
    text: "6.                           TERM AND TERMINATION",
    hasInitialIndent: true,
  },
  {
    text: `1.         Term.  The Terms commence on the date when you accept them (as described in the preamble above) and remain in full force and effect while you use NorthOne Properties, unless terminated earlier in accordance with the Terms.
      2.         Prior Use.  Notwithstanding the foregoing, if you used NorthOne Properties prior to the date you accepted the Terms, you hereby acknowledge and agree that the Terms commenced on the date you first used NorthOne Properties (whichever is earlier) and will remain in full force and effect while you use NorthOne Properties, unless earlier terminated in accordance with the Terms.
      3.         Termination of Services by NorthOne.  NorthOne may terminate these Terms at any time and for any reason, with or without notice, including if you have materially breached any provision of the Terms, if NorthOne is required to do so by law (e.g., where the provision of the the App, or the Services is, or becomes, unlawful), or if NorthOne otherwise determines such termination or suspension is appropriate (in NorthOne’s sole discretion) NorthOne has the right to, immediately and without notice, suspend or terminate any Services provided to you. You agree that all terminations for cause shall be made in NorthOne’s sole discretion and that NorthOne shall not be liable to you or any third party for any termination of your Account.
      4.         Termination of Services by You.  If you want to terminate the Services provided by NorthOne, you may do so by (a) notifying NorthOne at any time and (b) closing your Account for all of the Services that you use. Your notice should be sent, in writing, to NorthOne's address set forth below.
      5.         Effect of Termination.  Termination of any Service includes removal of access to such Service and barring of further use of the Service.  Termination of all Services also includes deletion of your password and all related information, files and Content associated with or inside your Account (or any part thereof).  Upon termination of any Service, your right to use such Service will automatically terminate immediately. You understand that any termination of Services may involve deletion of Content associated therewith from our live databases.  NorthOne will not have any liability whatsoever to you for any suspension or termination, including for deletion of such Content.  All provisions of the Terms which by their nature should survive, shall survive termination of Services, including without limitation, ownership provisions, warranty disclaimers, and limitation of liability.
      6.         No Subsequent Registration.  If your registration(s) with or ability to access NorthOne Properties, then you agree that you shall not attempt to re-register with or access the NorthOne Properties through use of a different member name or otherwise.  In the event that you violate the immediately preceding sentence, NorthOne reserves the right, in its sole discretion, to immediately take any or all of the actions set forth herein without any notice or warning to you.`,
    hasInitialIndent: true,
  },
  {
    text: "7.                           OWNERSHIP",
    hasInitialIndent: true,
  },
  {
    text: ` 1.         NorthOne Properties.  Except with respect to any Content you Make Available (“Your Content”), you agree that NorthOne and its suppliers own all rights, title and interest in NorthOne Properties.  You will not remove, alter or obscure any copyright, trademark, service mark or other proprietary rights notices incorporated in or accompanying the NorthOne Properties.
    2.         Your Content.  NorthOne does not claim ownership of Your Content.  However, when you post or publish Your Content on or in NorthOne Properties, you represent that you own and/or have a royalty-free, perpetual, irrevocable, worldwide, non-exclusive right (including any moral rights) and license to use, license, reproduce, modify, adapt, publish, translate, create derivative works from, distribute, derive revenue or other remuneration from, and communicate to the public, perform and display Your Content (in whole or in part) worldwide and/or to incorporate it in other works in any form, media or technology now known or later developed, for the full term of any worldwide intellectual property right that may exist in Your Content.
    3.         License to Your Content.  Subject to any applicable account settings that you select, you grant NorthOne a fully paid, royalty-free, perpetual, irrevocable, worldwide, royalty-free, non-exclusive and fully sublicensable right (including any moral rights) and license to use, license, distribute, reproduce, modify, adapt, publicly perform, and publicly display, Your Content (in whole or in part) for the purposes of operating and providing NorthOne Properties.  You warrant that the holder of any worldwide intellectual property right, including moral rights, in Your Content, has completely and effectively waived all such rights and validly and irrevocably granted to you the right to grant the license stated above.  You agree that you, not NorthOne, are responsible for all of Your Content that you Make Available on or in NorthOne Properties.
    4.         Feedback.  You agree that submission of any ideas, suggestions, documents, and/or proposals to NorthOne through its suggestion, feedback, wiki, forum or similar pages (“Feedback”) is at your own risk and that NorthOne has no obligations (including without limitation obligations of confidentiality) with respect to such Feedback.  You represent and warrant that you have all rights necessary to submit the Feedback.  You hereby grant to NorthOne a fully paid, royalty-free, perpetual, irrevocable, worldwide, non-exclusive, and fully sublicensable right and license to use, reproduce, perform, display, distribute, adapt, modify, re-format, create derivative works of, and otherwise commercially or non-commercially exploit in any manner, any and all Feedback, and to sublicense the foregoing rights, in connection with the operation and maintenance of NorthOne Properties`,
    hasInitialIndent: true,
  },
  {
    text:
      "8.      INDEMNIFICATION. You hereby agree to indemnify and hold harmless NorthOne, the Bank and each of our affiliates, officers, members, managers, directors, employees, equity holders, information providers, suppliers, licensors and licensees, and their respective successors and assigns (“Covered Persons”) from and against any and all losses, damages, liabilities, deficiencies, judgments, interest, awards, fines, costs or expenses of whatever kind, including, without limitation, amounts paid in settlement and reasonable attorneys’ fees incurred by any Covered Person in connection with any claim, action, arbitration, litigation, subpoena or investigation of any nature (whether at law or in equity) arising out or caused by (a) your access and/or use of the NorthOne Application or Services, (b) any claim that Your Content misappropriates, infringes or otherwise violates any third party's rights, including privacy or intellectual property right of any third party, and/or (c) any breach, or alleged breach, of any of these Terms by you.",
    hasInitialIndent: true,
  },
  {
    text:
      "9.      DISCLAIMER OF WARRANTIES. You expressly acknowledge and agree that your use of the Service is at your sole risk. The Service is provided on an 'AS IS', 'WITH ALL FAULTS', and 'AS AVAILABLE' basis. NorthOne uses commercially reasonable industry standard measures designed to prevent any unauthorized access to and use of your data. However, no form of online security is 100% secure. As such, NorthOne makes no representation that the Service will be secure.  In addition, NorthOne makes no representations or warranties relating to the Service including, without limitation, representations or warranties that (a) the Service shall meet your requirements, (b) the operation of the Service will be uninterrupted or error free, or (c) any defects in the Service will be corrected. TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, THE SERVICE IS PROVIDED 'AS IS' AND NORTHONE HEREBY DISCLAIMS ALL WARRANTIES, WHETHER EXPRESS, IMPLIED, STATUTORY OR OTHER, AND PROVIDER SPECIFICALLY DISCLAIMS ALL IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE (WHETHER OR NOT NORTHONE KNOWS, HAS REASON TO KNOW, HAS BEEN ADVISED, OR IS OTHERWISE IN FACT AWARE OF ANY SUCH PURPOSE), TITLE, QUIET ENJOYMENT AND NON-INFRINGEMENT, AND ALL WARRANTIES ARISING FROM COURSE OF DEALING, USAGE OR TRADE PRACTICE. WITHOUT LIMITING THE FOREGOING, NORTHONE MAKES NO WARRANTY OF ANY KIND THAT THE SERVICE, OR RESULTS OF THE USE THEREOF, WILL MEET YOUR OR ANY OTHER PERSON'S REQUIREMENTS, OPERATE WITHOUT INTERRUPTION, ACHIEVE ANY INTENDED RESULT, BE COMPATIBLE OR WORK WITH ANY SOFTWARE, SYSTEM, OR BE SECURE, ACCURATE, COMPLETE, FREE OF HARMFUL CODE OR ERROR FREE.",
    hasInitialIndent: true,
  },
  {
    text:
      "10.     LIMITATION OF LIABILITY. IN NO EVENT SHALL ANY COVERED PERSON BE LIABLE FOR ANY (A) PERSONAL INJURY OR PROPERTY DAMAGE ARISING FROM ANY USER’S USE OF OR PARTICIPATION IN THE SERVICES, OR (B) INCIDENTAL, INDIRECT, SPECIAL OR CONSEQUENTIAL DAMAGES WHATSOEVER, INCLUDING, WITHOUT LIMITATION, DAMAGES FOR LOSS OF PROFITS, LOSS OF DATA, BUSINESS INTERRUPTION, COSTS OF PROCUREMENT OF SUBSTITUTE PRODUCTS OR SERVICES OR ANY OTHER DAMAGES OR LOSSES, ARISING OUT OF, OR IN ANY WAY CONNECTED WITH, THESE TERMS OF SERVICE AND/OR THE SERVICE, WHETHER FOR BREACH OF CONTRACT, IN TORT OR OTHERWISE, EVEN IF SUCH COVERED PERSON IS ADVISED OF THE POSSIBILITY OF SUCH DAMAGES AND WHETHER OR NOT SUCH DAMAGES ARE A REASONABLY FORESEEABLE CONSEQUENCE OF A BREACH OF THIS AGREEMENT OR A TORT THAT FALLS WITHIN THE SCOPE OF THIS AGREEMENT. IN NO EVENT SHALL ANY COVERED PERSON’S TOTAL LIABILITY TO YOU FOR ANY DAMAGES ARISING OUT OF OR IN ANY WAY CONNECTED WITH THESE TERMS OF SERVICE OR THE SERVICE, WHETHER FOR BREACH OF CONTRACT, IN TORT OR OTHERWISE, EXCEED ONE HUNDRED U.S. DOLLARS ($100). THE FOREGOING LIMITATIONS APPLY NOTWITHSTANDING THE FAILURE OF ANY AGREED OR OTHER REMEDY OF ITS ESSENTIAL PURPOSE.",
    hasInitialIndent: true,
  },
  {
    text:
      "11.     MODIFICATIONS TO THE SERVICE. NorthOne may modify the use of all or any portion of the Services at any time for any reason (or for no reason) and without notice or liability.  NorthOne suspend, discontinue and/or restrict the use of all or any portion of the Services at any time for any reason (or for no reason) without liability upon thirty (30) days’ notice by emailing registered users.",
    hasInitialIndent: true,
  },
  {
    text: `12.                     DISPUTE RESOLUTION.  Please read the following arbitration agreement in this Section (“Arbitration Agreement”) carefully.  It requires you to arbitrate disputes with NorthOne and limits the manner in which you can seek relief from us.
    1.         Applicability of Arbitration Agreement. You agree that any dispute or claim relating in any way to your access or use of the Service, or to any aspect of your relationship with NorthOne, will be resolved by binding arbitration, rather than in court, except that (1) you may assert claims in small claims court if your claims qualify; and (2) you or NorthOne may seek equitable relief in court for infringement or other misuse of intellectual property rights (such as trademarks, trade dress, domain names, trade secrets, copyrights, and patents).  This Arbitration Agreement shall apply, without limitation, to all claims that arose or were asserted before the Effective Date of this Terms of Service or any prior version of this Terms of Service.
    2.         Arbitration Rules and Forum.  The Federal Arbitration Act governs the interpretation and enforcement of this Arbitration Agreement.  To begin an arbitration proceeding, you must send a letter requesting arbitration and describing your claim to us at NorthOne, Inc., 325 Front Street West, Suite 4, OneEleven (NorthOne), Toronto, Ontario, M5V 2Y1, Canada.  The arbitration will be conducted by JAMS, an established alternative dispute resolution provider.   Disputes involving claims and counterclaims under $250,000, not inclusive of attorneys’ fees and interest, shall be subject to JAMS’s most current version of the Streamlined Arbitration Rules and procedures available at http://www.jamsadr.com/rules-streamlined-arbitration/; all other claims shall be subject to JAMS’s most current version of the Comprehensive Arbitration Rules and Procedures, available at http://www.jamsadr.com/rules-comprehensive-arbitration/.  JAMS’s rules are also available at www.jamsadr.com or by calling JAMS at 800-352-5267.  If JAMS is not available to arbitrate, the parties will select an alternative arbitral forum.  If the arbitrator finds that you cannot afford to pay JAMS’s filing, administrative, hearing and/or other fees and cannot obtain a waiver from JAMS, NorthOne will pay them for you.  In addition, NorthOne will reimburse all such JAMS’s filing, administrative, hearing and/or other fees for claims totaling less than $10,000 unless the arbitrator determines the claims are frivolous. You may choose to have the arbitration conducted by telephone, based on written submissions, or in person in the country where you live or at another mutually agreed location.  Any judgment on the award rendered by the arbitrator may be entered in any court of competent jurisdiction
    3.         Authority of Arbitrator.  The arbitrator shall have exclusive authority to
              (a) determine the scope and enforceability of this Arbitration Agreement and
              (b) resolve any dispute related to the interpretation, applicability, enforceability or formation of this Arbitration Agreement including, but not limited to any claim that all or any part of this Arbitration Agreement is void or voidable.  The arbitration will decide the rights and liabilities, if any, of you and NorthOne.
                 The arbitration proceeding will not be consolidated with any other matters or joined with any other cases or parties.
                 The arbitrator shall have the authority to grant motions dispositive of all or part of any claim.
                The arbitrator shall have the authority to award monetary damages
                and to grant any non-monetary remedy or relief available to an individual under applicable law, the arbitral forum’s rules, and the Terms of Service
               (including the Arbitration Agreement). The arbitrator shall issue a written award and statement of decision
              describing the essential findings and conclusions on which the award is based, including the calculation of any damages awarded.
              The arbitrator has the same authority to award relief on an individual basis that a judge in a court of law would have.  The award of the arbitrator is final and binding upon you and us.
    4.         Waiver of Jury Trial.  YOU AND NORTHONE HEREBY WAIVE ANY CONSTITUTIONAL AND STATUTORY RIGHTS TO SUE IN COURT AND HAVE A TRIAL IN FRONT OF A JUDGE OR A JURY.  You and NorthOne are instead electing
              that all claims and disputes shall be resolved by arbitration under this Arbitration Agreement, except as specified in Section 12.1 above.  An arbitrator can award on an individual basis the same
              damages and relief as a court and must follow this Terms of Service as a court would.   However, there is no judge or jury in arbitration, and court
              review of an arbitration award is subject to very limited review.
    5.         Waiver of Class or Other Non-Individualized Relief.  ALL CLAIMS AND DISPUTES WITHIN THE SCOPE OF THIS ARBITRATION AGREEMENT MUST BE ARBITRATED ON AN INDIVIDUAL BASIS AND NOT ON A CLASS OR COLLECTIVE BASIS, ONLY INDIVIDUAL
               RELIEF IS AVAILABLE, AND CLAIMS OF MORE THAN ONE CUSTOMER OR USER CANNOT BE ARBITRATED OR CONSOLIDATED WITH THOSE OF ANY OTHER CUSTOMER OR USER.
                If a decision is issued stating that applicable law precludes enforcement of any of this subsection’s limitations as to a given claim for relief,
                than then claim must be severed from the arbitration and brought into the courts located in Wilmington, Delaware.  All other claims shall be arbitrated.
    6.         30-Day Right to Opt Out. You have the right to opt out of the provisions of this Arbitration Agreement by sending written notice of your decision to opt
               out to the following address: support@northone.com within 30 days after first becoming subject to this Arbitration Agreement.
                 Your notice must include your name and address, your NorthOne username (if any), the email address you used to set up your NorthOne account
                (if you have one), and an unequivocal statement that you want to opt out of this Arbitration Agreement.    If you opt out of this Arbitration Agreement, all other parts of this Terms of Service will continue to apply to you.  Opting out of this Arbitration Agreement has no effect on any other arbitration agreements that you may currently have, or may enter in the future, with us.
    7.         Severability. Except as provided in subsection 12.5, if any part or parts of this Arbitration Agreement are found under the law to be invalid or unenforceable, then such specific part or parts shall be of no force and effect and shall be severed and the remainder of the Arbitration Agreement shall continue in full force and effect
    8.         Survival of Agreement. This Arbitration Agreement will survive the termination of your relationship with NorthOne.
    9.         Modification.  Notwithstanding any provision in this Terms of Service to the contrary, we agree that if NorthOne makes any future material change to this Arbitration Agreement, you may reject that change within thirty (30) days of such change becoming effective by writing NorthOne at the following address: support@northone.com.`,
    hasInitialIndent: true,
  },
  {
    text: `13.                     GENERAL PROVISIONS
    1.         Electronic Communications.  The communications between you and NorthOne use electronic means, whether you visit NorthOne Properties or send NorthOne e-mails, or whether NorthOne posts notices on NorthOne Properties or communicates with you via e-mail.  For contractual purposes, you (1) consent to receive communications from NorthOne in an electronic form; and (2) agree that all terms and conditions, agreements, notices, disclosures, and other communications that NorthOne provides to you electronically satisfy any legal requirement that such communications would satisfy if it were to be in writing.
                The foregoing does not affect your statutory rights.
    2.         Release.  You hereby release Covered Persons and their successors from claims, demands, any and all losses, damages, rights, and actions of any kind, including personal injuries, death, and property damage, that is either directly or indirectly related to or arises from your use of NorthOne Properties, including but not limited to, any interactions with or conduct of other Users or third-party websites of any kind arising in connection with or as a result of the Terms or your use of NorthOne Properties.  If you are a California resident, you hereby waive California Civil Code Section 1542, which states, “A general release does not extend to claims that the creditor or releasing party does not know or suspect to exist in his or her favor at the time of executing the release and that, if known by him or her, would have materially affected his or her settlement with the debtor or released party.  The foregoing release does not apply to any claims, demands, or any losses, damages, rights and actions of any kind, including personal injuries, death or property damage for any unconscionable commercial practice by a Covered Person or for such person’s fraud, deception, false, promise, misrepresentation or concealment, suppression or omission of any material fact in connection with the any Services provided hereunder.
    3.         Assignment.  The Terms, and your rights and obligations hereunder, may not be assigned, subcontracted, delegated or otherwise transferred by you without NorthOne’s prior written consent, and any attempted assignment, subcontract, delegation, or transfer in violation of the foregoing will be null and void
    4.         Force Majeure.  NorthOne shall not be liable for any delay or failure to perform resulting from causes outside its reasonable control, including, but not limited to, acts of God, war, terrorism, riots, embargos, acts of civil or military authorities, fire, floods, accidents, strikes or shortages of transportation facilities, fuel, energy, labor or materials.
    5.         Questions, Complaints, Claims.  If you have any questions, complaints or claims with respect to NorthOne Properties, please contact us at: support@northone.com. We will do our best to address your concerns.  If you feel that your concerns have been addressed incompletely, we invite you to let us know for further investigation.
    6.         Limitation Period.  YOU AND NORTHONE AGREE THAT ANY CAUSE OF ACTION ARISING OUT OF OR RELATED TO THE TERMS, NORTHONE PROPERTIES OR THE CONTENT MUST COMMENCE WITHIN ONE (1) YEAR AFTER THE CAUSE OF ACTION ACCRUES.  OTHERWISE, SUCH CAUSE OF ACTION IS PERMANENTLY BARRED.
    7.         Exclusive Venue.  To the extent the parties are permitted under this Agreement to initiate litigation in a court, both you and NorthOne agree that all claims and disputes arising out of or relating to the Agreement will be litigated exclusively in the courts located in Wilmington, Delaware.
    8.         Governing Law.  THE TERMS AND ANY ACTION RELATED THERETO WILL BE GOVERNED AND INTERPRETED BY AND UNDER THE LAWS OF THE STATE OF DELAWARE, CONSISTENT WITH THE FEDERAL ARBITRATION ACT, WITHOUT GIVING EFFECT TO ANY PRINCIPLES THAT PROVIDE FOR THE APPLICATION OF THE LAW OF ANOTHER JURISDICTION.  THE UNITED NATIONS CONVENTION ON CONTRACTS FOR THE INTERNATIONAL SALE OF GOODS DOES NOT APPLY TO THESE TERMS.
    9.         Notice.  Where NorthOne requires that you provide an e-mail address, you are responsible for providing NorthOne with your most current e-mail address.  In the event that the last e-mail address you provided to NorthOne is not valid, or for any reason is not capable of delivering to you any notices required/ permitted by the Terms, NorthOne’s dispatch of the e-mail containing such notice will nonetheless constitute effective notice.  You may give notice to NorthOne at the following address:  support@northone.com. Such notice shall be deemed given when received by NorthOne by letter delivered by nationally recognized overnight delivery service or first class postage prepaid mail at the above address.
    10.   Waiver.  Any waiver or failure to enforce any provision of the Terms on one occasion will not be deemed a waiver of any other provision or of such provision on any other occasion.
    11.   Severability.  If any portion of this Agreement is held invalid or unenforceable, that portion shall be construed in a manner to reflect, as nearly as possible, the original intention of the parties, and the remaining portions shall remain in full force and effect.
    12.   App Store Terms. You acknowledge and agree that the availability of the Application and the Services is dependent on the third party from whom you received the Application license, e.g., the Apple App Store or Google Play (“App Store”).  You acknowledge that the Terms are between you and NorthOne and not with the App Store.  NorthOne, not the App Store, is solely responsible for NorthOne Properties, including the Application, the content thereof, maintenance, support services, and warranty therefor, and addressing any claims relating thereto (e.g., product liability, legal compliance or intellectual property infringement).  In order to use the Application, you must have access to a wireless network, and you agree to pay all fees associated with such access.  You also agree to pay all fees (if any) charged by the App Store in connection with NorthOne Properties, including the Application.  You agree to comply with, and your license to use the Application is conditioned upon your compliance with, all applicable third-party terms of agreement (e.g., the App Store’s terms and policies) when using NorthOne Properties, including the Application. You acknowledge that the App Store (and its subsidiaries) are third-party beneficiaries of the Terms and will have the right to enforce them.
    13.   Consumer Complaints.  In accordance with California Civil Code §1789.3, you may report complaints to the Complaint Assistance Unit of the Division of Consumer Services of the California Department of Consumer Affairs by contacting them in writing at 400 R Street, Sacramento, CA 95814, or by telephone at (800) 952-5210.
    14.   Entire Agreement.  The Terms are the final, complete and exclusive agreement of the parties with respect to the subject matter hereof and supersedes and merges all prior discussions between the parties with respect to such subject matter.`,
    hasInitialIndent: true,
  },
  {
    text:
      "14.     HOW TO CONTACT US. If you have any questions or comments about these Terms of Service or this Site, please contact us at: support@northone.com",
    hasInitialIndent: true,
  },
  {
    text: `NORTHONE, Inc. Privacy and Cookie Policy
    Last Updated: March 19, 2019
    Introduction
    This privacy and cookie policy (“Policy”) describes how NorthOne, Inc. (“NorthOne,” “we,” and “our”) collects, uses and shares personal data when using NorthOne’s applications, including our application known as “NorthOne” (each, a “App” and collectively, the “Apps”) the services
     provided in connection therewith (the “Services”). Our Services act as a portal or interface that enables users to access certain financial products and banking services made available by Radius Bank, a federal savings bank
      (the “Bank”). Please read the following information carefully to understand our views and practices regarding your personal data and
      how we will treat it.
      Particularly Important Information
      Who we are: For the purpose of applicable data protection legislation,
      the data controller of your personal data is NorthOne, Inc. of 325 Front Street West, Suite 4, OneEleven (NorthOne), Toronto, Ontario, M5V 2Y1, Canada.`,

    hasInitialIndent: true,
  },
  {
    text:
      "Must Read Sections: We draw your attention in particular to the sections entitled “International Data Transfer” and “Your Rights.”",
    hasInitialIndent: true,
  },

  {
    text: `Changes to this Policy: We will post any modifications or changes to the Policy on our Apps/Services. We reserve the right to modify the Policy at any time, so we encourage you to review it frequently. The “Last Updated” legend above indicates when this Policy was last changed. If we make any material change(s) to the Policy, we will notify you via email and/or post a notice
     on our Apps/Services prior to such changes(s) taking effect.

     `,

    hasInitialIndent: true,
  },

  {
    text: "1.                             Purposes of Processing",
    hasInitialIndent: true,
  },

  {
    text: `What is personal data?
    We collect information about you in a range of forms, including personal data. As used in this Policy, “personal data” is as defined in the UK Data Protection Act 1998/the European Data Protection Directive 95/46/EC/General Data Protection Regulation 2018 (“GDPR”) and any successor legislation, this includes any information which, either alone or in combination with other information we hold about you, identifies you as an individual, including, for example, your name, postal address, email address and telephone number.
    Why do we need your personal data?
    We will only process your personal data in accordance with applicable data protection and privacy laws. We need certain personal data in order to provide you with access to the Apps/Services.`,
    hasInitialIndent: true,
  },
  {
    text: `2.                             Collecting Your Personal Data,
    We collect information about you in the following ways:
    Information You Give Us. This includes:
    ·             the personal data you provide when you sign-up for, and/or login to, your account, such biometric data used for facial and/or touch/fingerprint ID recognition purposes, as well as any other information you submit during the registration process, including name, email, and company name;
    ·             the personal data you provide when you access and use the financial and banking products and services made available by the Bank via our Apps/Services;
    ·             the personal data you provide when you report a problem with our Apps/Services or when we provide you with customer support; and
    ·             the personal data you provide when you correspond with us by email or otherwise.
  Information We Get from the Bank. We may also get information about you from the Bank, for example, your financial accountant information and transaction history,
   in order to provide you the Services and information you request from the Bank.
    Information Automatically Collected. We automatically log information about you and your computer or mobile device
     when you access our Apps/Services. For example, when visiting our Apps/Services, we log your computer or mobile device operating system name and version,
      manufacturer and model, browser type, browser language, screen resolution, the website you visited before browsing to our Apps/Services, pages you viewed, how
       long you spent on a page, access times and information about your use of and actions on our Apps/Services. We collect this information
       about you using cookies. Please refer to the sections on cookies.
    Automated Decision Making and Profiling.
    We may use automated decision making and/or profiling in regard to your personal data for some services and products, for example if you opt-in to see if you pre-qualify for certain financial products of the Bank, we may use the information you provide to automatically determine whether you pre-qualify for such products.
     You can request a manual review of the accuracy of an automated decision that you are unhappy with or limit or object to such automated decision making and/or profiling by contacting us at: support@northone.com.`,
    hasInitialIndent: true,
  },
  {
    text: `3.                             COOKIES
    What are cookies?
    We may collect information using 'cookies.' Cookies are small data files stored on the hard drive of your computer or mobile device by a website. We may use both session cookies (which expire once you close your web browser) and persistent cookies (which stay on your computer or mobile device until you delete them) to help analyze how you use our Apps/Services to improve their design, efficiency and use.
    We use two broad categories of cookies: (1) first party cookies, served directly by us to your computer or mobile device, which are used only by us to recognize your computer or mobile device when it revisits our Apps/Services; and (2) third party cookies, which are served by service providers on our Apps/Services, and can be used by such service providers to recognize your computer or mobile device when it visits other websites.
    Cookies we use
    Our Apps/Services use the following types of cookies for the purposes set out below:
    Type of cookie
    Purpose
    Essential Cookies
    These cookies are essential to provide you with services available through our Apps/Services and to enable you to use some of its features. For example, they allow us to identify whether you have downloaded the App, or determine whether you qualify for certain features offered through the Apps/Services.Without these cookies, the services that you have asked for cannot be provided, and we only use these cookies to provide you with those services.
    Functionality Cookies
    These cookies allow our Apps/Services to remember choices you make when you use our Apps/Services, such as remembering your language preferences, remembering your login details and remembering the changes you make to other parts of our Apps/Services which you can customize. The purpose of these cookies is to provide you with a more personal experience and to avoid you having to re-enter your preferences every time you visit our Apps/Services.
    Analytics and Performance Cookies
    These cookies are used to collect information about traffic to our Apps/Services and how users use our Apps/Services. The information gathered does not identify any individual visitor.
     It includes the number of visitors to our Apps/Services, the websites that referred them to our Apps/Services, the pages they visited on our Apps/Services, what time of day they visited our Apps/Services, whether they have visited our Apps/Services before, and other similar information.
    We use this information to help operate our Apps/Services more efficiently, to gather broad demographic information and to monitor the level of activity on our Apps/Services.
    We use Google Analytics, Mixpanel and Segment for this purpose
    Google Analytics, Mixpanel and Segment each use their own cookies. These cookies are only used to improve how our Apps/Services works.
    You can find out more information about Google Analytics cookies here:  https://developers.google.com/analytics/resources/concepts/gaConceptsCookies
    You can find out more about how Google protects your data here: www.google.com/analytics/learn/privacy.html.
    You can prevent the use of Google Analytics relating to your use of our Apps/Services by downloading and installing the browser plugin available via this link: http://tools.google.com/dlpage/gaoptout?hl=en-GB
    You can find out more information about how Mixpanel processes your data here: https://mixpanel.com/legal/privacy-policy
    You can find out more information about how Segment processes your data here: https://segment.com/docs/legal/privacy
    Referral cookies
    We may place cookies on the websites of our third party partners to track those users who click on links to our website and/or download our Apps. We use this information to help identify the effectiveness of our partners’ links and comply with our requirements with such partners.
    Disabling cookies
    You can typically remove or reject cookies via your browser settings. In order to do this, follow the instructions provided by your browser (usually located within the “settings,” “help” “tools” or “edit” facility).  Many browsers are set to accept cookies until you change your settings.`,
    hasInitialIndent: true,
  },
  {
    text: `Further information about cookies, including how to see what cookies have been set on your computer or mobile device and how to manage and delete them, visit www.allaboutcookies.org and www.youronlinechoices.com.uk.
    If you do not accept our cookies, you may experience some inconvenience in your use of our Apps/Services.`,
    hasInitialIndent: true,
  },
  {
    text: `4.                             DO NOT TRACK SIGNALS
    Some Internet browsers may be configured to send 'Do Not Track' signals to the online services that you visit. We currently do not respond to do not track signals. To find out more about 'Do Not Track,' please visit http://www.allaboutdnt.com.`,
    hasInitialIndent: true,
  },
  {
    text: `5.                             Using Your Personal Data
    We may use your personal data as follows:
    ·             to operate, maintain, and improve our Apps/Services, products, and services;
    ·             to respond to your comments and questions and to provide customer service;
    ·             to recommend, and/or prequalify you for, certain financial and banking products and services to you;
    ·             to send information including technical notices, updates, security alerts, and support and administrative messages;
    ·             with your consent, to send you marketing e-mails about upcoming promotions, and other news, including information about products and services offered by us and our affiliates.  You may opt-out of receiving such information at any time: such marketing emails tell you how to “opt-out.” Please note, even if you opt out of receiving marketing emails, we may still send you non-marketing emails. Non-marketing emails include emails about your account with us (if you have one) and our business dealings with you;
    ·             as we believe necessary or appropriate (a) to comply with applicable laws; (b) to comply with lawful requests and legal process, including to respond to requests from public and government authorities; (c) to enforce our Policy; and (d) to protect our rights, privacy, safety or property, and/or that of you or others; and
    ·             as described in the “Sharing of your Personal Data” section below.`,
    hasInitialIndent: true,
  },
  {
    text: `6.                             Sharing Your Personal Data
    We may share your personal data as follows:
·             With the Bank.   Our Service acts as a portal/interface between you and the Bank.  As such, we will share the personal data you provide via the Apps/Services with the Bank so that the Bank may provide you with the products and services you request, and to comply with any audit requests of the Bank.  By using our Services, you acknowledge and agree that the Bank’s use of your personal data will be subject to its Privacy Policy at: https://radiusbank.com/legal/privacy. We encourage you to read the Bank’s Privacy Policy in its entirety.
·             Permission Based Sharing. We may share read-only (i.e. only permitted to view and not make any changes) access to your personal data with those persons you designate. For example, if you instruct us to provide your accountant with read-only access to your account, we will provide your accountant with such access for the time period(s) you designate. You may revoke any permissions you’ve granted at any time.
·             Our Third Party Service Providers. We may share your personal data with our third party service providers who provide services such as data analysis, payment processing, information technology and related infrastructure provision, customer service, email delivery, auditing and other similar services. These third parties are only permitted to use your personal data to the extent necessary to enable them to provide their services to us. They are required to follow our express instructions and to comply with appropriate security measures to protect your personal data.
·             Third Party Advertisers. We may share personal data with advertisers that require the data to identify new target audiences for our Apps/Services.
·             Affiliates. We may share some or all of your personal data with our affiliates, in which case we will require our affiliates to comply with this Policy. In particular, you may let us share personal data with our affiliates where you wish to receive marketing communications from them.
·             Corporate Restructuring. We may share personal data when we do a business deal, or negotiate a business deal, involving the sale or transfer of all or a part of our business or assets. These deals can include any merger, financing, acquisition, or bankruptcy transaction or proceeding.
·             Other Disclosures. We may share personal data as we believe necessary or appropriate: (a) to comply with applicable laws; (b) to comply with lawful requests and legal process, including to respond to requests from public and government authorities to meet national security or law enforcement requirements; (c) to enforce our Policy; and (d) to protect our rights, privacy, safety or property, and/or that of you or others.`,
    hasInitialIndent: true,
  },
  {
    text: ` 7.                             Anonymous Data
    When we use the term “anonymous data,” we are referring to data and information that does not permit you to be identified or identifiable, either alone or when combined with any other information available to a third party.
    We may create anonymous data from the personal data we receive about you and other individuals whose personal data we collect. Anonymous data might include analytics information and information collected by us using cookies.  We make personal data into anonymous data by excluding information (such as your name) that makes the data personally identifiable to you.  We use this anonymous data to analyses usage patterns in order to make improvements to our Apps/Services.`,
    hasInitialIndent: true,
  },
  {
    text: ` 7.                             Anonymous Data
    When we use the term “anonymous data,” we are referring to data and information that does not permit you to be identified or identifiable, either alone or when combined with any other information available to a third party.
    We may create anonymous data from the personal data we receive about you and other individuals whose personal data we collect. Anonymous data might include analytics information and information collected by us using cookies.  We make personal data into anonymous data by excluding information (such as your name) that makes the data personally identifiable to you.  We use this anonymous data to analyses usage patterns in order to make improvements to our Apps/Services.`,
    hasInitialIndent: true,
  },
  {
    text: `8.                             User Generated Content
    If you provide feedback to us, we may use and disclose such feedback on our Apps/Services , provided we do not associate such feedback with your personal data. If you have provided your consent to do so, we may post your first name and last initial along with your feedback on our Apps/Services. We will collect any information contained in such feedback and will treat the personal data in it in accordance with this Policy.>`,
    hasInitialIndent: true,
  },
  {
    text: ` 9.                             International Data Transfer
    Your information, including personal data that we collect from you, may be transferred to, stored at and processed by us and the Bank outside the country in which you reside, including, but not limited to the United States, where data protection and privacy regulations may not offer the same level of protection as in other parts of the world. [By using [our App(s)/ Services], you agree to this transfer, storing or processing.] We will take all steps reasonably necessary to ensure that your data is treated securely and in accordance with this Policy.`,
    hasInitialIndent: true,
  },
  {
    text: ` 10.                        Security
    We seek to use reasonable organizational, technical and administrative measures to protect personal data within our organization. Unfortunately, no transmission or storage system can be guaranteed to be completely secure, and transmission of information via the internet is not completely secure. If you have reason to believe that your interaction with us is no longer secure (for example, if you feel that the security of any account you might have with us has been compromised), please immediately notify us of the problem by contacting us using the details in Section 15 below.`,
    hasInitialIndent: true,
  },
  {
    text: `11.                        Retention
    We will only retain your personal data as long reasonably required for you to use the Apps and/or to provide you with the Services unless a longer retention period is required or permitted by law (for example for regulatory purposes).`,
    hasInitialIndent: true,
  },
  {
    text: `12.                        Sensitive Personal Data
    If you send or disclose any sensitive personal data to us when you log-in to your account, you consent to our processing and use of such sensitive personal data in accordance with this Policy.  If you do not consent to our processing and use of such sensitive personal data, you will not be able to create or log-in to an account.`,
    hasInitialIndent: true,
  },
  {
    text: ` 13.                        Your Rights
    ·             Opt-out.  You may contact us anytime to opt-out of: (i) direct marketing communications; (ii) automated decision-making and/or profiling (except in the case of automated decision-making for fraud prevention purposes); or (iii) any new processing of your personal data that we may carry out beyond the original purpose. Please note that your use of some of the Apps/Services may be ineffective upon opt-out.
    ·             Amend. You can also contact us to update or correct any inaccuracies in your personal data.
    ·             Move. Your banking transaction data is portable – i.e. you to have the flexibility to move your data to other service providers as you wish.
    ·             Erase and forget. In certain situations, for example when the information we hold about you is no longer relevant or is incorrect, you can request that we erase your data.
    If you wish to exercise any of these rights, please contact us using the details in Section 15 below. In your request, please make clear: (i) what personal data is concerned; and (ii) which of the above rights you would like to enforce.  For your protection, we may only implement requests with respect to the personal data associated with the particular email address that you use to send us your request, and we may need to verify your identity before implementing your request. We will try to comply with your request as soon as reasonably practicable and in any event, within one month of your request. Please note that we may need to retain certain information for regulatory or recordkeeping purposes and/or to complete any transactions that you began prior to requesting such change or deletion.`,
    hasInitialIndent: true,
  },
  {
    text: `14.                        Complaints
    We are committed to resolve any complaints about our collection or use of your personal data. If you would like to make a complaint regarding this Policy or our practices in relation to your personal data, please contact us at: support@northone.com. We will reply to your complaint as soon as we can and in any event, within 45 days. We hope to resolve any complaint brought to our attention, however if you feel that your complaint has not been adequately resolved, you reserve the right to contact your local data protection supervisory authority, which for the UK, is the Information Commissioner’s Office.`,
    hasInitialIndent: true,
  },
  {
    text: `15.                        Contact Information
    We welcome your comments or questions about this Policy. You may contact us in writing at support@northone.com or 325 Front Street West, Suite 4, OneEleven (NorthOne), Toronto, Ontario, M5V 2Y1, Canada.`,
    hasInitialIndent: true,
  },
  {
    text: `BUSINESS DEPOSIT ACCOUNT AGREEMENT
    TERMS AND CONDITIONS OF YOUR ACCOUNT
    Thank you for banking with Radius Bank. We look forward to serving you.
    IMPORTANT ACCOUNT OPENING INFORMATION
    Due to the passage of the “USA PATRIOT Act,” we are required to notify customers of the following information:
    Federal laws and regulations require us to request information from you prior to opening an account or adding an additional signatory to an account. The information we request may vary depending on the circumstances, but at a minimum, will include your name, address, an identification number such as your social security or taxpayer identification number, and for individuals, your date of birth. We are also required to verify the information you provide to us. This verification process may require you to provide us with supporting documentation that we deem appropriate. We may also seek to verify the information by other means. We reserve the right to request additional information and/or signatures from you from time to time.`,
    hasInitialIndent: true,
  },
  {
    text: `AGREEMENT
    This document, along with any other documents we give you pertaining to your account(s), is a contract that establishes rules which control your account(s) with us. Please read this carefully and retain it for future reference. If you sign the signature card or open or continue to use the account, you agree to these rules. You will receive a separate schedule of rates, qualifying balances, and fees if they are not included in this document. If you have any questions, please call us.
    Interest rates and annual percentage yields (APY) on our interest-bearing accounts are established at our discretion based on market conditions and may change without notice.
    This agreement is subject to applicable federal laws, the laws of the state of Massachusetts and other applicable rules such as the operating letters of the Federal Reserve Banks and payment processing system rules (except to the extent that this agreement can and does vary such rules or laws). The body of state and federal law that governs our relationship with you, however, is too large and complex to be reproduced here. The purpose of this document is to:
    The purpose of this document is to:
    1. summarize some laws that apply to common transactions;
    2. establish rules to cover transactions or events which the law does not regulate;
    3.establish rules for certain transactions or events which the law regulates but permits variation by agreement;
    4. give you disclosures of some of our policies to which you may be entitled or in which you may be interested.
    If any provision of this document is found to be unenforceable according to its terms, all remaining provisions will continue in full force and effect. We may permit some variations from our standard agreement, but we must agree to any variation in writing either on the signature card for your account or in some other document. Nothing in this document is intended to vary our duty to act in good faith and with ordinary care when required by law.
    As used in this document the words “we,” “our,” and “us” mean the financial institution and the words “you” and “your” mean the account holder(s) and anyone else with the authority to deposit, withdraw, or exercise control over the funds in the account. However, this agreement does not intend, and the terms “you” and “your” should not be interpreted, to expand an individual’s responsibility for an organization’s liability. If this account is owned by a corporation, partnership or other organization, individual liability is determined by the laws generally applicable to that type of organization. The headings in this document are for convenience or reference only and will not govern the interpretation of the provisions. Unless it would be inconsistent to do so, words and phrases used in this document should be construed so the singular includes the plural and the plural includes the singular.`,
    hasInitialIndent: true,
  },
  {
    text: `LIABILITY
        You agree, for yourself (and the person or entity you represent if you sign as a representative of another) to the terms of this account and the schedule of charges. You authorize us to deduct these charges, without notice to you, directly from the account balance as accrued. You will pay any additional reasonable charges for services you request which are not covered by this agreement.
        Each of you also agrees to be jointly and severally (individually) liable for any account shortage resulting from charges or overdrafts, whether caused by you or another with access to this account. This liability is due immediately, and can be deducted directly from the account balance whenever sufficient funds are available. You have no right to defer payment of this liability, and you are liable regardless of whether you signed the item or benefited from the charge or overdraft.
        You will be liable for our costs as well as for our reasonable attorneys’ fees, to the extent permitted by law, whether incurred as a result of collection or in any other dispute involving your account. This includes, but is not limited to, disputes between you and another joint owner; you and an authorized signer or similar party; or a third party claiming an interest in your account. This also includes any action that you or a third party takes regarding the account that causes us, in good faith, to seek the advice of an attorney, whether or not we become involved in the dispute. All costs and attorneys’ fees can be deducted from your account when they are incurred, without notice to you.`,
    hasInitialIndent: true,
  },
  {
    text: ` DEPOSITS
    We will give only provisional credit until collection is final for any items, other than cash, we accept for deposit (including items drawn “on us”). Before settlement of any item becomes final, we act only as your agent, regardless of the form of endorsement or lack of endorsement on the item and even though we provide you provisional credit for the item. We may reverse any provisional credit for items that are lost,
     stolen, or returned. Unless prohibited by law, we also reserve the right to charge back to your account the amount of any item deposited to your account or cashed for you which was initially paid by the payor bank and which is later returned to us due to an allegedly forged, unauthorized or missing endorsement, claim of alteration, encoding error or other problem which in our judgment justifies reversal of credit. You
      authorize us to attempt to collect previously returned items without giving you notice, and in attempting to collect we may permit the payor bank to hold an item beyond the midnight deadline. Actual credit for deposits of, or payable in, foreign currency will be at the exchange rate in effect on final collection in U.S. dollars. We are not responsible for transactions by mail or outside depository until we actually record them.
    If you deliver a deposit to us and you will not be present when the deposit is counted, you must provide us an itemized list of the deposit (deposit slip). To process the deposit, we will verify and record the deposit, and credit the deposit to the account. If there are any discrepancies between the amounts shown on the itemized list of the deposit and the amount we determine to be the actual deposit, we will notify you of the discrepancy. You will be entitled to credit only for the actual deposit as determined by us, regardless of what is stated on the itemized deposit slip. We will treat and record all transactions
     received after our “daily cutoff time” on a business day we are open, or received on a day we are not open for business, as if initiated on the next business day that we are open. At our option, we may take an item for collection rather than for deposit. If we accept a third-party check for deposit, we may require any third-party endorsers to verify or guarantee their endorsements, or endorse in our presence.`,
    hasInitialIndent: true,
  },
  {
    text: `WITHDRAWALS
        Generally – Unless clearly indicated otherwise on the account records, any of you, acting alone, who signs to open the account or has authority to make withdrawals may withdraw or transfer all or any part of the account balance at any time. Each of you (until we receive written notice to the contrary) authorizes each other person who signs or has authority to make withdrawals to endorse any item payable to you or your order for deposit to this account or any other transaction with us.
        Postdated checks – A postdated check is one which bears a date later than the date on which the check is written. We may properly pay and charge your account for a postdated check even though payment was made before the date of the check, unless we have received written notice of the postdating in time to have a reasonable opportunity to act. Because we process checks mechanically, your notice will not be effective and we will not be liable for failing to honor your notice unless it precisely identifies the number, date, amount and payee of the item.
        Checks and withdrawal rules – If you do not purchase your check stocks from us, you must be certain that we approve the check stocks you purchase. We may refuse any withdrawal or transfer request which you attempt on forms not approved by us or by any method we do not specifically permit. We may refuse any withdrawal or transfer request which is greater in number than the frequency permitted, or which is for an amount greater or less than any withdrawal limitations. We will use the date the transaction is completed by us (as opposed to the date you initiate it) to apply the frequency limitations. In addition, we may place limitations on the account until your identity is verified. Even if we honor a nonconforming request, we are not required to do so later. If you violate the stated transaction limitations (if any), in our discretion we may close your account or reclassify it as a transaction account. If we reclassify your account, your account will be subject to the fees and earnings rules of the new account classification. If we are presented with an item drawn against your account that would be a “substitute check,” as defined by law, but for an error or defect in the item introduced in the substitute check creation process, you agree that we may pay such item.
        See the funds availability policy disclosure for information about when you can withdraw funds you deposit. For those accounts to which our funds availability policy disclosure does not apply, you can ask us when you make a deposit when those funds will be available for withdrawal. An item may be returned after the funds from the deposit of that item are made available for withdrawal. In that case, we will reverse the credit of the item. We may determine the amount of available funds in your account for the purpose of deciding whether to return an item for insufficient funds at any time between the time we receive the item and when we return the item or send a notice in lieu of return. We need only make one determination, but if we choose to make a subsequent determination, the account balance at the subsequent time will determine whether there are insufficient available funds.
        A temporary debit authorization hold affects your account balance – On debit card purchases, merchants may request a temporary hold on your account for a specified sum of money, which may be more than the actual amount of your purchase. When this happens, our processing system cannot determine that the amount of the hold exceeds the actual amount of your purchase. This temporary hold, and the amount charged to your account, will eventually be adjusted to the actual amount of your purchase, but it may be up to five days before the adjustment is made. Until the adjustment is made, the amount of funds in your account available for other transactions will be reduced by the amount of the temporary hold. If another transaction is presented for payment in an amount greater than the funds left after the deduction of the temporary hold amount, that transaction will be a nonsufficient funds (NSF) transaction if we do not pay it or an overdraft transaction if we do pay it. You will be charged an NSF or overdraft fee according to our NSF or overdraft fee policy. You will be charged the fee even if you would have had sufficient funds in your account if the amount of the hold had been equal to the amount of your purchase.
        As an example, some merchants may request an authorization for the amount that they estimate you will spend and place a temporary hold for that amount. This estimated amount is likely to be different (either more or less) than the actual transaction amount. Typically, temporary holds are placed for up to three business days but exact times may vary. If a temporary hold is placed, please note that it may impact your available balance by an amount either more or less than you expected until the transaction posts. We are not responsible for damages or losses of any type, including wrongful dishonor, for any transaction that is not authorized or that is returned unpaid because of a hold.
        Overdrafts –You understand that we may, at our discretion, honor withdrawal requests that overdraw your account. However, the fact that we may honor withdrawal requests that overdraw the account balance does not obligate us to do so later. So you can NOT rely on us to pay overdrafts on your account regardless of how frequently or under what circumstances we have paid overdrafts on your account in the past. We can change our practice of paying overdrafts on your account without notice to you. You can ask us if we have other account services that might be available to you where we commit to paying overdrafts under certain circumstances, such as an overdraft protection line-of-credit or a plan to sweep funds from another account you have with us. You agree that we may charge fees for overdrafts. For consumer accounts, we will not charge fees for overdrafts caused by ATM withdrawals or one-time debit card transactions if you have not opted-in to that service. We may use subsequent deposits, including direct deposits of social security or other government benefits, to cover such overdrafts and overdraft fees.
        Multiple signatures, electronic check conversion, and similar transactions – An electronic check conversion transaction is a transaction where a check or similar item is converted into an electronic fund transfer as defined in the Electronic Fund Transfers regulation. In these types of transactions the check or similar item is either removed from circulation (truncated) or given back to you. As a result, we have no opportunity to review the check to examine the signatures on the item. You agree that, as to these or any items as to which we have no opportunity to examine the signatures, you waive any requirement of multiple signatures.
        Notice of withdrawal – We reserve the right to require not less than 7 days’ notice in writing before each withdrawal from an interest-bearing account other than a time deposit or demand deposit, or from any other savings account as defined by Regulation D. (The law requires us to reserve this right, but it is not our general policy to use it.) Withdrawals from a time account prior to maturity or prior to any notice period may be restricted and may be subject to penalty. See your notice of penalty for early withdrawal.`,
    hasInitialIndent: true,
  },
  {
    text: ` BUSINESS, ORGANIZATION AND ASSOCIATION ACCOUNTS
    Earnings in the form of interest, dividends, or credits will be paid only on collected funds, unless otherwise provided by law or our policy. You represent that you have the authority to open and conduct business on this account on behalf of the entity. We may require the governing body of the entity opening the account to give us a separate authorization telling us who is authorized to act on its behalf. We will honor the authorization until we actually receive written notice of a change from the governing body of the entity.`,
    hasInitialIndent: true,
  },
  {
    text: `STOP PAYMENTS
    Unless otherwise provided, the rules in this section cover stopping payment of items such as checks and drafts. Rules for stopping payment of other types of transfers of funds, such as consumer electronic fund transfers, may be established by law or our policy. If we have not disclosed these rules to you elsewhere, you may ask us about those rules.
    We may accept an order to stop payment on any item from any one of you. You must make any stop-payment order in the manner required by law and we must receive it in time to give us a reasonable opportunity to act on it before our stop-payment cutoff time. Because stop-payment orders are handled by computers, to be effective, your stop-payment order must precisely identify the number, date, and amount of the item, and the payee. You may stop payment on any item drawn on your account whether you sign the item or not. Generally, if your stop-payment order is given to us in writing it is effective for six months. Your order will lapse after that time if you do not renew the order in writing before the end of the six-month period. If the original stop- payment order was oral your stop-payment order will lapse after 14 calendar days if you do not confirm your order in writing within that time period. We are not obligated to notify you when a stop-payment order expires. A release of the stop-payment request may be made only by the person who initiated the stop-payment order.
    If you stop payment on an item and we incur any damages or expenses because of the stop payment, you agree to indemnify us for those damages or expenses, including attorneys’ fees. You assign to us all rights against the payee or any other holder of the item. You agree to cooperate with us in any legal actions that we may take against such persons. You should be aware that anyone holding the item may be entitled to enforce payment against you despite the stop-payment order.
    Our stop-payment cutoff time is one hour after the opening of the next banking day after the banking day on which we receive the item. Additional limitations on our obligation to stop payment are provided by law (e.g., we paid the item in cash or we certified the item).`,
    hasInitialIndent: true,
  },
  {
    text: ` TELEPHONE TRANSFERS
    A telephone transfer of funds from this account to another account with us, if otherwise arranged for or permitted, may be made by the same persons and under the same conditions generally applicable to withdrawals made in writing. Unless a different limitation is disclosed in writing, we restrict the number of transfers from a savings account to another account or to third parties, to a maximum of six per month (less the number of “preauthorized transfers” during the month). Other account transfer restrictions may be described elsewhere.`,
    hasInitialIndent: true,
  },
  {
    text: ` AMENDMENTS AND TERMINATION
    We may change any term of this agreement. Rules governing changes in interest rates are provided separately in the Truth-in-Savings disclosure or in another document. For other changes, we will give you reasonable notice in writing or by any other method permitted by law. We may also close this account at any time upon reasonable notice to you and tender of the account balance personally or by mail. Items presented for payment after the account is closed may be dishonored. When you close your account, you are responsible for leaving enough money in the account to cover any outstanding items to be paid from the account. Reasonable notice depends on the circumstances, and in some cases such as when we cannot verify your identity or we suspect fraud, it might be reasonable for us to give you notice after the change or account closure becomes effective. For instance, if we suspect fraudulent activity with respect to your account, we might immediately freeze or close your account and then give you notice. If we have notified you of a change in any term of your account and you continue to have your account after the effective date of the change, you have agreed to the new term(s).`,
    hasInitialIndent: true,
  },
  {
    text: `NOTICES
    Any written notice you give us is effective when we actually receive it, and it must be given to us according to the specific delivery instructions provided elsewhere, if any. We must receive it in time to have a reasonable opportunity to act on it. If the notice is regarding a check or other item, you must give us sufficient information to be able to identify the check or item, including the precise check or item number, amount, date and payee. Written notice we give you is effective when it is deposited in the United States Mail with proper postage and addressed to your mailing address we have on file. Notice to any of you is notice to all of you.`,
    hasInitialIndent: true,
  },
  {
    text: ` STATEMENTS
    Your duty to report unauthorized signatures, alterations and forgeries – You must examine your statement of account with “reasonable promptness.” If you discover (or reasonably should have discovered) any unauthorized signatures or alterations, you must promptly notify us of the relevant facts. As between you and us, if you fail to do either of these duties, you will have to either share the loss with us, or bear the loss entirely yourself (depending on whether we used ordinary care and, if not, whether we substantially contributed to the loss). The loss could be not only with respect to items on the statement but other items with unauthorized signatures or alterations by the same wrongdoer.
    You agree that the time you have to examine your statement and report to us will depend on the circumstances, but will not, in any circumstance, exceed a total of 30 days from when the statement is first sent or made available to you.\
    You further agree that if you fail to report any unauthorized signatures, alterations or forgeries in your account within 60 days of when we first send or make the statement available, you cannot assert a claim against us on any items in that statement, and as between you and us the loss will be entirely yours. This 60-day limitation is without regard to whether we used ordinary care. The limitation in this paragraph is in addition to that contained in the first paragraph of this section
    Your duty to report other errors – In addition to your duty to review your statements for unauthorized signatures, alterations and forgeries, you agree to examine your statement with reasonable promptness for any other error – such as an encoding error. In addition, if you receive or we make available either your items or images of your items, you must examine them for any unauthorized or missing endorsements or any other problems. You agree that the time you have to examine your statement and items and report to us will depend on the circumstances. However, this time period shall not exceed 60 days. Failure to examine your statement and items and report any errors to us within 60 days of when we first send or make the statement available precludes you from asserting a claim against us for any errors on items identified in that statement and as between you and us the loss will be entirely yours.`,
    hasInitialIndent: true,
  },
  {
    text: `ACCOUNT TRANSFER
    This account may not be transferred or assigned without our prior written consent.`,
    hasInitialIndent: true,
  },
  {
    text: `DIRECT DEPOSITS
        If we are required for any reason to reimburse the federal government for all or any portion of a benefit payment that was directly deposited into your account, you authorize us to deduct the amount of our liability to the federal government from the account or from any other account you have with us, without prior notice and at any time, except as prohibited by law. We may also use any other legal remedy to recover the amount of our liability.`,
    hasInitialIndent: true,
  },
  {
    text: ` TEMPORARY ACCOUNT AGREEMENT
    If the account documentation indicates that this is a temporary account agreement, each person who signs to open the account or has authority to make withdrawals (except as indicated to the contrary) may transact business on this account. However, we may at some time in the future restrict or prohibit further use of this account if you fail to comply with the requirements we have imposed within a reasonable time.`,
    hasInitialIndent: true,
  },
  {
    text: `SETOFF
    We may (without prior notice and when permitted by law) set off the funds in this account against any due and payable debt any of you owe us now or in the future. If this account is owned by one or more of you as individuals, we may set off any funds in the account against a due and payable debt a partnership owes us now or in the future, to the extent of your liability as a partner for the partnership debt. If your debt arises from a promissory note, then the amount of the due and payable debt will be the full amount we have demanded, as entitled under the terms of the note, and this amount may include any portion of the balance for which we have properly accelerated the due date.
    This right of setoff does not apply to this account if prohibited by law. For example, the right of setoff does not apply to this account if: (a) it is an Individual Retirement Account or similar tax-deferred account, or (b) the debt is created by a consumer credit transaction under a credit card plan (but this does not affect our rights under any consensual security interest), or (c) the debtor’s right of withdrawal only arises in a representative capacity, or (d) setoff is prohibited by the Military Lending Act or its implementing regulations. We will not be liable for the dishonor of any check when the dishonor occurs because we set off a debt against this account. You agree to hold us harmless from any claim arising as a result of our exercise of our right of setoff.`,
    hasInitialIndent: true,
  },
  {
    text: `RESTRICTIVE LEGENDS OR ENDORSEMENTS
        The automated processing of the large volume of checks we receive prevents us from inspecting or looking for restrictive legends, restrictive endorsements or other special instructions on every check. Examples of restrictive legends placed on checks are “must be presented within 90 days” or “not valid for more than $1,000.00.” The payee’s signature accompanied by the words “for deposit only” is an example of a restrictive endorsement. For this reason, we are not required to honor any restrictive legend or endorsement or other special instruction placed on checks you write unless we have agreed in writing to the restriction or instruction. Unless we have agreed in writing, we are not responsible for any losses, claims, damages, or expenses that result from your placement of these restrictions or instructions on your checks.`,
    hasInitialIndent: true,
  },
  {
    text: `PAYMENT ORDER OF ITEMS
        The order in which items are paid is important if there is not enough money in your account to pay all of the items that are presented. The payment order can affect the number of items overdrawn or returned unpaid and the amount of the fees you may have to pay. To assist you in managing your account, we are providing you with the following information regarding how we process those items.
        Checks are processed in check or serial number order. ACH transactions are posted highest dollar amount to lowest. All other items, such as debit/ATM transactions and deposits (in person or mailed) are posted when received.
        If a check, item or transaction is presented without sufficient funds in your account to pay it, we may, at our discretion, pay the item (creating an overdraft) or return the item for insufficient funds (NSF). We will not charge you a fee for paying an overdraft of an ATM or everyday debit card transaction if this is a consumer account and you have not opted-in to that service. The amounts of the overdraft and NSF fees are disclosed elsewhere, as are your rights to opt in to overdraft services for ATM and everyday debit card transactions, if applicable. We encourage you to make careful records and practice good account management. This will help you to avoid creating items without sufficient funds and potentially incurring the resulting fees.`,
    hasInitialIndent: true,
  },
  {
    text: `FACSIMILE SIGNATURES
        We have no obligation to honor facsimile signatures on your checks or other orders. If we do agree to honor items containing facsimile signatures, you authorize us, at any time, to charge you for all checks, drafts, or other orders, for the payment of money, that are drawn on us. You give us this authority regardless of by whom or by what means the facsimile signature(s) may have been affixed so long as they resemble the facsimile signature specimen filed with us, and contain the required number of signatures for this purpose. You must notify us at once if you suspect that your facsimile signature is being or has been misused.`,
    hasInitialIndent: true,
  },
  {
    text: `CHECK PROCESSING
        We process items mechanically by relying solely on the information encoded in magnetic ink along the bottom of the items. This means that we do not individually examine all of your items to determine if the item is properly completed, signed and endorsed or to determine if it contains any information other than what is encoded in magnetic ink. You agree that we have exercised ordinary care if our automated processing is consistent with general banking practice, even though we do not inspect each item. Because we do not inspect each item, if you write a check to multiple payees, we can properly pay the check regardless of the number of endorsements unless you notify us in writing that the check requires multiple endorsements. We must receive the notice in time for us to have a reasonable opportunity to act on it, and you must tell us the precise date of the check, amount, check number and payee. We are not responsible for any unauthorized signature or alteration that would not be identified by a reasonable inspection of the item. Using an automated process helps us keep costs down for you and all account holders.`,
    hasInitialIndent: true,
  },
  {
    text: ` CHECK CASHING
    We may charge a fee for anyone that does not have an account with us who is cashing a check, draft or other instrument written on your account. We may also require reasonable identification to cash such a check, draft or other instrument. We can decide what identification is reasonable under the circumstances and such identification may be documentary or physical and may include collecting a thumbprint or fingerprint.`,
    hasInitialIndent: true,
  },
  {
    text: ` ENDORSEMENTS
    We may accept for deposit any item payable to you or your order, even if they are not endorsed by you. We may give cash back to any one of you. We may supply any missing endorsement(s) for any item we accept for deposit or collection, and you warrant that all endorsements are genuine.
    To ensure that your check or share draft is processed without delay, you must endorse it (sign it on the back) in a specific area. Your entire endorsement (whether a signature or a stamp) along with any other endorsement information (e.g. additional endorsements, ID information, driver’s license number, etc.) must fall within 11/2” of the “trailing edge” of a check. Endorsements must be made in blue or black ink, so that they are readable by automated check processing equipment.
    As you look at the front of a check, the “trailing edge” is the left edge. When you flip the check over, be sure to keep all endorsement information within 1 1/2″ of that edge.
    It is important that you confine the endorsement information to this area since the remaining blank space will be used by others in the processing of the check to place additional needed endorsements and information. You agree that you will indemnify, defend, and hold us harmless for any loss, liability, damage or expense that occurs because your endorsement, another endorsement or information you have printed on the back of the check obscures our endorsement.`,
    hasInitialIndent: true,
  },
  {
    text: ` DEATH OR INCOMPETENCE
    You agree to notify us promptly if any person with a right to withdraw funds from your account(s) dies or is adjudicated (determined by the appropriate official) incompetent. We may continue to honor your checks, items, and instructions until: (a) we know of your death or adjudication of incompetence, and (b) we have had a reasonable opportunity to act on that knowledge. You agree that we may pay or certify checks drawn on or before the date of death or adjudication of incompetence for up to ten (10) days after your death or adjudication of incompetence unless ordered to stop payment by someone claiming an interest in the account.`,
    hasInitialIndent: true,
  },
  {
    text: `FIDUCIARY ACCOUNTS
        Accounts may be opened by a person acting in a fiduciary capacity. A fiduciary is someone who is appointed to act on behalf of and for the benefit of another. We are not responsible for the actions of a fiduciary, including the misuse of funds. This account may be opened and maintained by a person or persons named as a trustee under a written trust agreement, or as executors, administrators, or conservators under court orders. You understand that by merely opening such an account, we are not acting in the capacity of a trustee in connection with the trust nor do we undertake any obligation to monitor or enforce the terms of the trust or letters.`,
    hasInitialIndent: true,
  },
  {
    text: `TRANSACTIONS BY MAIL
    You may deposit checks by mail. You should endorse the check being sent through the mail with the words “For Deposit Only” and should include your correct account number underneath to ensure the check is credited to the correct account. You should use the pre-encoded checking deposit slips found behind your checks in your checkbook. If you do not use your deposit slip or provide us with instructions indicating how or where the check should be credited, we may apply it to any account or any loan balance you have with us or we may return the check to you. Receipts for such transactions will be mailed to you only if a self-addressed stamped envelope is provided. Following your deposit, examine your statement carefully or call us to ensure that we received the item. Do not send cash through the mail for deposit.`,
    hasInitialIndent: true,
  },
  {
    text: `CREDIT VERIFICATION
    You agree that we may verify credit and employment history by any necessary means, including preparation of a credit report by a credit reporting agency.`,
    hasInitialIndent: true,
  },
  {
    text: `LEGAL ACTIONS AFFECTING YOUR ACCOUNT
        If we are served with a subpoena, restraining order, writ of attachment or execution, levy, garnishment, search warrant, or similar order relating to your account (termed “legal action” in this section), we will comply with that legal action. Or, in our discretion, we may freeze the assets in the account and not allow any payments out of the account until a final court determination regarding the legal action. We may do these things even if the legal action involves less than all of you. In these cases, we will not have any liability to you if there are insufficient funds to pay your items because we have withdrawn funds from your account or in any way restricted access to your funds in accordance with the legal action. Any fees or expenses we incur in responding to any legal action (including, without limitation, attorneys’ fees and our internal expenses) may be charged against your account. The list of fees applicable to your account(s) provided elsewhere may specify additional fees that we may charge for certain legal actions.`,
    hasInitialIndent: true,
  },
  {
    text: `SECURITY
        It is your responsibility to protect the account numbers and electronic access devices (e.g., an ATM card) we provide you for your account(s). Do not discuss, compare, or share information about your account number(s) with anyone unless you are willing to give them full use of your money. An account number can be used by thieves to issue an electronic debit or to encode your number on a false demand draft which looks like and functions like an authorized check. If you furnish your access device and grant actual authority to make transfers to another person (a family member or coworker, for example) who then exceeds that authority, you are liable for the transfers unless we have been notified that transfers by that person are no longer authorized.\
        Your account number can also be used to electronically remove money from your account, and payment can be made from your account even though you did not contact us directly and order the payment.\
        You must also take precaution in safeguarding your blank checks. Notify us at once if you believe your checks have been lost or stolen. As between you and us, if you are negligent in safeguarding your checks, you must bear the loss entirely yourself or share the loss with us (we may have to share some of the loss if we failed to use ordinary care and if we substantially contributed to the loss).\
        Except for consumer electronic funds transfers subject to Regulation E, you agree that if we offer you services appropriate for your account to help identify and limit fraud or other unauthorized transactions against your account, such as positive pay or commercially reasonable security procedures, and you reject those services, you will be responsible for any fraudulent or unauthorized transactions which could have been prevented by the services we offered, unless we acted in bad faith or to the extent our negligence contributed to the loss. If we offered you a commercially reasonable security procedure which you reject, you agree that you are responsible for any payment order, whether authorized or not, that we accept in compliance with an alternative security procedure that you have selected.`,
    hasInitialIndent: true,
  },
  {
    text: `TELEPHONIC INSTRUCTIONS
        Unless required by law or we have agreed otherwise in writing, we are not required to act upon instructions you give us via facsimile transmission or leave by voice mail or on a telephone answering machine.`,
    hasInitialIndent: true,
  },
  {
    text: `MONITORING AND RECORDING TELEPHONE CALLS AND CONSENT TO RECEIVE COMMUNICATIONS
        We may monitor or record phone calls for security reasons, to maintain a record and to ensure that you receive courteous and efficient service. You consent in advance to any such recording. We need not remind you of our recording before each phone conversation.
        To provide you with the best possible service in our ongoing business relationship for your account we may need to contact you about your account from time to time by telephone, text messaging or email. However, we must first obtain your consent to contact you about your account because we must comply with the consumer protection provisions in the federal Telephone Consumer Protection Act of 1991 (TCPA), CAN-SPAM Act and their related federal regulations and orders issued by the Federal Communications Commission (FCC).
        Your consent is limited to this account, and as authorized by applicable law and regulations.
        Your consent does not authorize us to contact you for telemarketing purposes (unless you otherwise agreed elsewhere).
        With the above understandings, you authorize us to contact you regarding this account throughout its existence using any telephone numbers or email addresses that you have previously provided to us or that you may subsequently provide to us.
        This consent is regardless of whether the number we use to contact you is assigned to a landline, a paging service, acellular wireless service, a specialized mobile radio service, other radio common carrier service or any other service for which you may be charged for the call. You further authorize us to contact you through the use of voice, voicemail and text messaging, including the use of pre-recorded or artificial voice messages and an automated dialing device.
        If necessary, you may change or remove any of the telephone numbers or email addresses at any time using any reasonable means to notify us.`,
    hasInitialIndent: true,
  },
  {
    text: `CLAIM OF LOSS
    If you claim a credit or refund because of a forgery, alteration, or any other unauthorized withdrawal, you agree to cooperate with us in the investigation of the loss, including giving us an affidavit containing whatever reasonable information we require concerning your account, the transaction, and the circumstances surrounding the loss. You will notify law enforcement authorities of any criminal act related to the claim of lost, missing, or stolen checks or unauthorized withdrawals. We will have a reasonable period of time to investigate the facts and circumstances surrounding any claim of loss. Unless we have acted in bad faith, we will not be liable for special or consequential damages, including loss of profits or opportunity, or for attorneys’ fees incurred by you. You agree that you will not waive any rights you have to recover your loss against anyone who is obligated to repay, insure, or otherwise reimburse you for your loss. You will pursue your rights or, at our option, assign them to us so that we may pursue them. Our liability will be reduced by the amount you recover or are entitled to recover from these other sources.
    EARLY WITHDRAWAL PENALTIES (and involuntary withdrawals)
    We may impose early withdrawal penalties on a withdrawal from a time account even if you don’t initiate the withdrawal. For instance, the early withdrawal penalty may be imposed if the withdrawal is caused by our setoff against funds in the account or as a result of an attachment or other legal process . We may close your account and impose the early withdrawal penalty on the entire account balance in the event of a partial early withdrawal. See your notice of penalty for early withdrawals for additional information.`,
    hasInitialIndent: true,
  },
  {
    text: `ADDRESS OR NAME CHANGES
    You are responsible for notifying us of any change in your address or your name. Unless we agree otherwise, change of address or name must be made in writing by at least one of the account holders. Informing us of your address or name change on a check reorder form is not sufficient. We will attempt to communicate with you only by use of the most recent address you have provided to us.`,
    hasInitialIndent: true,
  },
  {
    text: `RESOLVING ACCOUNT DISPUTES
        We may place an administrative hold on the funds in your account (refuse payment or withdrawal of the funds) if it becomes subject to a claim adverse to (1) your own interest; (2) others claiming an interest as survivors or beneficiaries of your account; or (3) a claim arising by operation of law. The hold may be placed for such period of time as we believe reasonably necessary to allow a legal proceeding to determine the merits of the claim or until we receive evidence satisfactory to us that the dispute has been resolved. We will not be liable for any items that are dishonored as a consequence of placing a hold on funds in your account for these reasons.`,
    hasInitialIndent: true,
  },
  {
    text: `WAIVER OF NOTICES
    To the extent permitted by law, you waive any notice of non-payment, dishonor or protest regarding any items credited to or charged against your account. For example, if you deposit a check and it is returned unpaid or we receive a notice of nonpayment, we do not have to notify you unless required by federal Regulation CC or other law.`,
    hasInitialIndent: true,
  },
  {
    text: `ACH AND WIRE TRANSFERS
    This agreement is subject to Article 4A of the Uniform Commercial Code – Fund Transfers as adopted in the state in which you have your account with us. If you originate a fund transfer and you identify by name and number a beneficiary financial institution, an intermediary financial institution or a beneficiary, we and every receiving or beneficiary financial institution may rely on the identifying number to make payment. We may rely on the number even if it identifies a financial institution, person or account other than the one named. You agree to be bound by automated clearing house association rules. These rules provide, among other things, that payments made to you, or originated by you, are provisional until final settlement is made through a Federal Reserve Bank or payment is otherwise made as provided in Article 4A-403(a) of the Uniform Commercial Code. If we do not receive such payment, we are entitled to a refund from you in the amount credited to your account and the party originating such payment will not be considered to have paid the amount so credited. Credit entries may be made by ACH. If we receive a payment order to credit an account you have with us by wire or ACH, we are not required to give you any notice of the payment order or credit.`,
    hasInitialIndent: true,
  },
  {
    text: `  TRUNCATION, SUBSTITUTE CHECKS, AND OTHER CHECK IMAGES
    If you truncate an original check and create a substitute check, or other paper or electronic image of the original check, you warrant that no one will be asked to make payment on the original check, a substitute check or any other electronic or paper image, if the payment obligation relating to the original check has already been paid. You also warrant that any substitute check you create conforms to the legal requirements and generally accepted specifications for substitute checks. You agree to retain the original check in conformance with our internal policy for retaining original checks. You agree to indemnify us for any loss we may incur as a result of any truncated check transaction you initiate. We can refuse to accept substitute checks that have not previously been warranted by a bank or other financial institution in conformance with the Check 21 Act. Unless specifically stated in a separate agreement between you and us, we do not have to accept any other electronic or paper image of an original check.`,
    hasInitialIndent: true,
  },
  {
    text: `REMOTELY CREATED CHECKS
    Like any standard check or draft, a remotely created check (sometimes called a telecheck, preauthorized draft or demand draft) is a check or draft that can be used to withdraw money from an account. Unlike a typical check or draft, however, a remotely created check is not issued by the paying bank and does not contain the signature of the account owner (or a signature purported to be the signature of the account owner). In place of a signature, the check usually has a statement that the owner authorized the check or has the owner’s name typed or printed on the signature line.
    You warrant and agree to the following for every remotely created check we receive from you for deposit or collection: (1) you have received express and verifiable authorization to create the check in the amount and to the payee that appears on the check; (2) you will maintain proof of the authorization for at least 2 years from the date of the authorization, and supply us the proof if we ask; and (3) if a check is returned you owe us the amount of the check, regardless of when the check is returned. We may take funds from your account to pay the amount you owe us, and if there are insufficient funds in your account, you still owe us the remaining balance.`,
    hasInitialIndent: true,
  },
  {
    text: ` UNLAWFUL INTERNET GAMBLING NOTICE
    Restricted transactions as defined in Federal Reserve Regulation GG are prohibited from being processed through this account or relationship. Restricted transactions generally include, but are not limited to, those in which credit, electronic fund transfers, checks, or drafts are knowingly accepted by gambling businesses in connection with the participation by others in unlawful Internet gambling.`,
    hasInitialIndent: true,
  },
  {
    text: `FUNDS TRANSFERS
    The terms used in this section have the meaning given to them in Article 4A of the Uniform Commercial Code – Funds Transfers (UCC 4A). This section will generally not apply to you if you are a consumer. However, even if you are a consumer, this section will apply to that part of any funds transfer that is conducted by Fedwire. This section is subject to UCC 4A as adopted in the state in which you have your deposit with us. This agreement is also subject to all clearing house association rules, rules of the Board of Governors of the Federal Reserve System and their operating circulars. If any part of this agreement is determined to be unenforceable, the rest of the agreement remains effective. This agreement controls funds transfers unless supplemented or amended in a separate written agreement signed by us. This agreement does not apply to a funds transfer if any part of the transfer is governed by the Electronic Fund Transfer Act of 1978 (EFTA), except this agreement does apply to a funds transfer that is a remittance transfer as defined in EFTA unless the remittance transfer is an electronic fund transfer as defined in EFTA.
    Funds transfer – A funds transfer is the transaction or series of transactions that begin with the originator’s payment order, made for the purpose of making payment to the beneficiary of the order. A funds transfer is completed by the acceptance by the beneficiary’s bank of a payment order for the benefit of the beneficiary of the originator’s order. You may give us a payment order orally, electronically, or in writing, but your order cannot state any condition to payment to the beneficiary other than the time of payment. Credit entries may be made by ACH.
    Authorized account – An authorized account is a deposit account you have with us that you have designated as a source of payment of payment orders you issue to us. If you have not designated an authorized account, any account you have with us is an authorized account to the extent that payment of the payment order is not inconsistent with the use of the account.
    Acceptance of your payment order – We are not obligated to accept any payment order that you give us, although we normally will accept your payment order if you have a withdrawable credit in an authorized account sufficient to cover the order. If we do not execute your payment order, but give you notice of our rejection of your payment order after the execution date or give you no notice, we are not liable to pay you as restitution any interest on a withdrawable credit in a non-interest-bearing account.
    Cutoff time – If we do not receive your payment order or communication canceling or amending a payment order before our cutoff time on a funds transfer day for that typeof order or communication, the order or communication will be deemed to be received at the opening of our next funds transfer business day.
    Payment of your order – If we accept a payment order you give us, we may receive payment by automatically deducting from any authorized account the amount of the payment order plus the amount of any expenses and charges for our services in execution of your payment order. We are entitled to payment on the payment or execution date. Unless your payment order specifies otherwise, the payment or execution date is the funds transfer date we receive the payment order. The funds transfer is completed upon acceptance by the beneficiary’s bank. Your obligation to pay your payment order is excused if the funds transfer is not completed, but you are still responsible to pay us any expenses and charges for our services. However, if you told us to route the funds transfer through an intermediate bank, and we are unable to obtain a refund because the intermediate bank that you designated has suspended payments, then you are still obligated to pay us for the payment order. You will not be entitled to interest on any refund you receive because the beneficiary’s bank does not accept the payment order.
    Security procedure – As described more fully in a separate writing, the authenticity of a payment order or communication canceling or amending a payment order issued in your name as sender may be verified by a security procedure. You affirm that you have no circumstances which are relevant to the determination of a commercially reasonable security procedure unless those circumstances are expressly contained in a separate writing signed by us. You may choose from one or more security procedures that we have developed, or you may develop your own security procedure if it is acceptable to us. If you refuse a commercially reasonable security procedure that we have offered you, you agree that you will be bound by any payment order issued in your name, whether or not authorized, that we accept in good faith and in compliance with the security procedure you have chosen.
    Duty to report unauthorized or erroneous payment – You must exercise ordinary care to determine that all payment orders or amendments to payment orders that we accept that are issued in your name are authorized, enforceable, in the correct amount, to the correct beneficiary, and not otherwise erroneous. If you discover (or with reasonable care should have discovered) an unauthorized, unenforceable, or erroneously executed payment order or amendment, you must exercise ordinary care to notify us of the relevant facts. The time you have to notify us will depend on the circumstances, but that time will not in any circumstance exceed 14 days from when you are notified of our acceptance or execution of the payment order or amend mentor that your account was debited with respect to the order or amendment. If you do not provide us with timely notice you will not be entitled to interest on any refundable amount. If we can prove that you failed to perform either of these duties with respect to an erroneous payment and that we incurred a loss as a result of the failure, you are liable to us for the amount of the loss not exceeding the amount of your order.
    Identifying number –If your payment order identifies an intermediate bank, beneficiary bank, or beneficiary by name and number, we and every receiving or beneficiary bank may rely upon the identifying number rather than the name to make payment, even if the number identifies an intermediate bank or person different than the bank or beneficiary identified by name. Neither we nor any receiving or beneficiary bank have any responsibility to determine whether the name and identifying number refer to the same financial institution or person.
    Record of oral or telephone orders – You agree that we may, if we choose, record any oral or telephone payment order or communication of amendment or cancelation.
    Notice of credit – If we receive a payment order to credit an account you have with us, we are not required to provide you with any notice of the payment order or the credit.
    Provisional credit – You agree to be bound by the automated clearing house association operating rules that provide that payments made to you or originated by you by funds transfer through the automated clearing house system are provisional until final settlement is made through a Federal Reserve Bank or otherwise payment is made as provided in Article 4A-403(a) of the Uniform Commercial Code.
    Refund of credit – You agree that if we do not receive payment of an amount credited to your account, we are entitled to a refund from you in the amount credited and the party originating such payment will not be considered to have paid the amount so credited.
    Amendment of funds transfer agreement – From time to time we may amend any term of this agreement by giving you reasonable notice in writing. We may give notice to anyone who is authorized to send payment orders to us in your name, or to anyone who is authorized to accept service.
    Cancelation or amendment of payment order – You may cancel or amend a payment order you give us only if we receive the communication of cancelation or amendment before our cutoff time and in time to have a reasonable opportunity to act on it before we accept the payment order. The communication of cancelation or amendment must be presented in conformity with the same security procedure that has been agreed to for payment orders.
Intermediaries – We are not liable for the actions of any intermediary, regardless of whether or not we selected the intermediary. We are not responsible for acts of God, outside agencies, or nonsalaried agents.
Limit on liability – You waive any claim you may have against us for consequential or special damages, including loss of profit arising out of a payment order or funds transfer, unless this waiver is prohibited by law. We are not responsible for attorney fees you might incur due to erroneous execution of payment order.
Erroneous execution – If we receive an order to pay you, and we erroneously pay you more than the amount of the payment order, we are entitled to recover from you the amount in excess of the amount of the payment order, regardless of whether you may have some claim to the excess amount against the originator of the order.
Objection to payment – If we give you a notice that reasonably identifies a payment order issued in your name as sender that we have accepted and received payment for, you cannot claim that we are not entitled to retain the payment unless you notify us of your objection to the payment within one year of our notice to you.
New account limitations - As a new account holder, you will be placed into a “NorthOne Base” account. The scope of the account restricts outgoing wires and has spend thresholds put in place. These terms will be set for an indefinite period of time, though you may be granted access to additional features in the future. You may opt out of those at any time. Additionally, you agree to a limitation of ATM deposits while you are classified as a new account holder. As a new account holder you agree to lower transmission limits and card spend limits. NorthOne and Radius Bank reserve the sole right to determine and classify an account or account holder’s status and movement from a NorthOne Base account to a NorthOne standard account. The scope of feature differences, which may change from time to time, can be viewed at https://www.northone.com under FAQ.
You agree to review the full scope of Radius Bank’s policies and terms here, which may change from time to time, here: https://radiusbank.com/legal/`,
    hasInitialIndent: true,
  },
]
