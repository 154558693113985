import React from "react"
import { Box, Flex } from "rebass"
import { Button } from "./button.component"
import { IconWithToolTip } from "./radio-button/icon-with-tooltip.component"
import { EditIcon } from "./radio-button/edit-icon.component"
import { theme } from "theme/theme"
import { Checkbox } from "shared"

interface IProps {
  isSelected: boolean
  label: string
  onClick: () => void
  error?: boolean
  errorText?: string
  infoText?: string
  infoLine2?: string
  infoLine3?: string
  withEditIcon?: boolean
  noBorder?: boolean
}

export const CheckboxButton: React.FC<IProps> = ({
  isSelected = true,
  label,
  onClick,
  error,
  errorText,
  infoText,
  infoLine2,
  infoLine3,
  withEditIcon,
  noBorder,
}) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
      }}
    >
      <Button
        noSimulatedSavingOnClick
        onClick={onClick}
        type={isSelected ? "blueOutline" : error ? "error" : "secondary"}
        isRadio
        noBorder={noBorder}
        fontWeight={theme.fontStyles.h2.fontWeight}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            width: "100%",
            marginX: "0.8rem",
          }}
        >
          <Box
            sx={{
              marginTop: "-1.8rem",
            }}
          >
            <Checkbox
              key={label}
              label={label}
              onClick={() => {
                isSelected = !isSelected
              }}
              isSelected={isSelected}
            />
          </Box>
          <Flex alignItems="right">
            {error ? (
              <Flex>
                {withEditIcon ? <EditIcon error /> : null}
                <IconWithToolTip errorText={errorText} />
              </Flex>
            ) : null}
            {!error && infoText ? (
              <Flex>
                {withEditIcon ? <EditIcon /> : null}
                <IconWithToolTip infoText={infoText} infoLine2={infoLine2} infoLine3={infoLine3} />
              </Flex>
            ) : null}
          </Flex>
        </Box>
      </Button>
    </Box>
  )
}
