/*
password regex should match https://manage.auth0.com/#/connections/database/con_ZinHHEnw3Ia5irfV/security

Passwords will contain at least 1 upper case letter
Passwords will contain at least 1 lower case letter
Passwords will contain at least 1 number or special character
Passwords will contain at least 8 characters in length

password regex based on: https://www.thepolyglotdeveloper.com/2015/05/use-regex-to-test-password-strength-in-javascript/
*/

export const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*])(?=.{8,})/

export const passwordLiveValidationRegexes = {
  characters: /^.{8,}$/,
  uppercase: /^.*[A-Z]/,
  lowercase: /^.*[a-z]/,
  specialCharacter: /^.*[!@#$%^&*]/,
}

export const phoneRegex = /^[+1 ]{3,3}[0-9]{1,3}[-]{1,1}[0-9]{1,3}[-]{1,1}[0-9]{4}$/

export const onlyNumbersRegex = /^\d+$/

export const usStates = /^(?:(A[KLRZ]|C[AOT]|D[CE]|FL|GA|HI|I[ADLN]|K[SY]|LA|M[ADEINOST]|N[CDEHJMVY]|O[HKR]|P[AR]|RI|S[CD]|T[NX]|UT|V[AIT]|W[AIVY]))$/

export const allSameCharacterRegex = /^(.)\1+$/

export const printableChars = /^[ -~]+$/

export const onlyLetters = /^[a-zA-Z\u00C0-\u00FF]*$/
