import React, { useState } from "react"
import { Box } from "rebass"
import { useTranslation } from "react-i18next"
import { BaseLayout, BaseScreen, SidePanel, ButtonRow, RadioButton, Checkbox } from "shared"
import { nav } from "core/navigation"
import { Analytics } from "../../../../core/analytics/actions"
import { WebsiteType } from "../redux"
import { TWebsiteTypeProps } from "./kyb-website-type.container"

export const KYBWebsiteTypeScreen: React.FC<TWebsiteTypeProps> = ({ setWebsiteType, website }) => {
  const { t } = useTranslation()
  const [newWebsiteType, setNewWebsiteType] = useState<WebsiteType | null>()
  const [selection, setSelection] = useState(false)
  const [newWebsite, setNewWebsite] = useState(website)
  const [isSelected, setSelected] = useState<number | null>(null)
  const valueIsValid = () => newWebsiteType || selection
  const hasNoWebsite = !newWebsiteType && selection
  const { prev, next } = nav.onboarding.kyb.pages.KYBWebsiteType
  const websiteType = [
    { type: "Facebook", value: WebsiteType.FACEBOOK, index: 0 },
    { type: "Instagram", value: WebsiteType.INSTAGRAM, index: 1 },
    { type: "LinkedIn", value: WebsiteType.LINKEDIN, index: 2 },
    { type: "Twitter", value: WebsiteType.TWITTER, index: 3 },
    { type: "Other", value: WebsiteType.OTHER, index: 4 },
  ]

  return (
    <BaseLayout
      sidePanel={<SidePanel section={2} />}
      body={
        <BaseScreen
          title={t("kyb.additionalWebsite.title")}
          subtitle={t("kyb.additionalWebsite.subTitle")}
        >
          <Box style={{ marginTop: "2rem", display: "flex", flexWrap: "wrap" }}>
            <Box>
              {websiteType.slice(0, 3).map((type) => (
                <RadioButton
                  label={t(`kyb.additionalWebsite.${type.type}`)}
                  isSelected={isSelected === type.index}
                  customRadioStyle={{ width: "18rem" }}
                  onClick={() => {
                    setSelection(false)
                    setSelected(type.index)
                    setNewWebsiteType(type.value)
                    Analytics.track(`KYB Business Online Platform ${type.value} Continue Tap`)
                  }}
                />
              ))}
            </Box>
            <Box>
              {websiteType.slice(3).map((type) => (
                <RadioButton
                  label={t(`kyb.additionalWebsite.${type.type}`)}
                  isSelected={isSelected === type.index}
                  customRadioStyle={{ width: "18rem" }}
                  onClick={() => {
                    setSelection(false)
                    setSelected(type.index)
                    setNewWebsiteType(type.value)
                    Analytics.track(`KYB Business Online Platform ${type.value} Continue Tap`)
                  }}
                />
              ))}
            </Box>
          </Box>
          <Box>
            <Checkbox
              label={t("kyb.additionalWebsite.validation")}
              onClick={() => {
                setSelected(null)
                setNewWebsite("")
                setSelection(!selection)
                setNewWebsiteType(null)
              }}
              isSelected={selection}
            />
          </Box>
        </BaseScreen>
      }
      buttonRow={
        <ButtonRow
          buttonOnClick={() => {
            if (newWebsiteType) {
              setWebsiteType(newWebsiteType)
            }
            if (newWebsite) {
              setNewWebsite(newWebsite)
            }
            Analytics.track("KYB Business Online Platform Continue Tap")
            next(hasNoWebsite)
          }}
          buttonDisabled={!valueIsValid()}
        />
      }
      onBackClick={prev}
    />
  )
}
