import React, { useState } from "react"
import { useTranslation } from "react-i18next"
import { Box } from "rebass"
import { BaseLayout, BaseScreen, SidePanel, RadioButton, ButtonRow } from "shared"
import { CorporationType } from "types"
import { TKYBBusinessTypeProps } from "./kyb-business-type.container"
import { nav } from "core/navigation"
import { Analytics } from "../../../../core/analytics/actions"

export const KYBBusinessTypeScreen: React.FC<TKYBBusinessTypeProps> = ({
  firstName,
  setBusinessType,
  setMultipleOwners,
}) => {
  const { t } = useTranslation()
  const [newBusinessType, setNewBusinessType] = useState<CorporationType | null>(null)
  const valueIsValid = () => newBusinessType
  const { prev, next } = nav.onboarding.kyb.pages.KYBBusinessType
  const _handleClick = () => {
    if (newBusinessType) {
      setBusinessType(newBusinessType)
      if (newBusinessType === CorporationType.Soleprop) {
        setMultipleOwners(false)
      }
      next(newBusinessType)
    }
  }
  return (
    <BaseLayout
      sidePanel={<SidePanel section={2} />}
      body={
        <BaseScreen title={t("kyb.businessType.title", { firstName })}>
          <Box
            style={{
              marginTop: "2rem",
              flexDirection: "column",
              display: "flex",
            }}
          >
            {[
              { type: "LLC", value: CorporationType.Llc },
              { type: "soleProp", value: CorporationType.Soleprop },
              { type: "partnership", value: CorporationType.Partnership },
            ].map(({ type, value }, index) => (
              <RadioButton
                key={index}
                label={t(`kyb.businessType.${type}`)}
                isSelected={newBusinessType === value}
                onClick={() => {
                  setNewBusinessType(value)
                  Analytics.track(`KYB Business Type ${value} Select`)
                }}
                infoText={t(`kyb.businessType.infoText.${type}`)}
              />
            ))}
          </Box>
        </BaseScreen>
      }
      buttonRow={
        <ButtonRow
          buttonOnClick={() => {
            Analytics.track("KYB Business Type Continue Tap")
            _handleClick()
          }}
          buttonDisabled={!valueIsValid()}
        />
      }
      onBackClick={prev}
    />
  )
}
