import React from "react"
import { Image } from "rebass"
import Edit from "images/Edit.svg"
import EditError from "images/EditError.svg"

interface IProps {
  error?: boolean
}

export const EditIcon: React.FC<IProps> = ({ error }) => (
  <Image marginRight="1rem" src={error ? EditError : Edit} />
)
