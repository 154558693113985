import React, { useState } from "react"
import { useTranslation } from "react-i18next"
import { BaseLayout, SingleTextInputScreen, SidePanel, ButtonRow } from "shared"
import { TKYBDescriptionProps } from "./kyb-description.container"
import { nav } from "core/navigation"
import { Analytics } from "../../../../core/analytics/actions"
import { VerifyBusinessDescriptionQuery } from "generated/graphql"
import gql from "graphql-tag"
import { logger } from "utils"
import { ApolloConsumer, ApolloClient } from "@apollo/client"

export const BUSINESS_DESCRIPTION_QUERY = gql`
  query verifyBusinessDescription($description: String!) {
    verifyBusinessDescription(description: $description) {
      isValidDescription
      suggestedDescription
    }
  }
`

const MIN_DESCRIPTION_LENGTH = 10

export const KYBDescriptionScreen: React.FC<TKYBDescriptionProps> = ({
  description,
  setDescription,
  setCurrentErrorMessage,
}) => {
  const { t } = useTranslation()
  const [newDescription, setNewDescription] = useState(description)
  const [suggestedDescription, setSuggestedDescription] = useState("")
  const [loading, setLoading] = useState(false)
  const [nonValidBusinessDescriptions, setNonValidBusinessDescriptions] = useState<string[]>([])

  const numberOfCharactersRemaining = MIN_DESCRIPTION_LENGTH - newDescription.trim().length

  const descriptionIsValid =
    numberOfCharactersRemaining <= 0 && !nonValidBusinessDescriptions.includes(newDescription)
  const { prev, next } = nav.onboarding.kyb.pages.KYBDescription

  const businessDescriptionValidation = async (
    client: ApolloClient<object>,
    description: string,
  ) => {
    setLoading(true)

    try {
      const { errors, data } = await client.query<VerifyBusinessDescriptionQuery>({
        query: BUSINESS_DESCRIPTION_QUERY,
        variables: {
          description,
        },
      })
      setLoading(false)

      if (errors) {
        // Not sure if this code runs
        logger.error(errors)
        setCurrentErrorMessage("Error")
      }

      if (data) {
        const isValidDescription =
          data.verifyBusinessDescription && data.verifyBusinessDescription.isValidDescription
        if (isValidDescription) {
          next()
        } else if (
          !isValidDescription &&
          data.verifyBusinessDescription &&
          data.verifyBusinessDescription.suggestedDescription
        ) {
          setSuggestedDescription(data.verifyBusinessDescription.suggestedDescription)
          setNonValidBusinessDescriptions([...nonValidBusinessDescriptions, description])
        } else {
          logger.info("No valid description provided or suggestion provided by server")
        }
      }
    } catch (e) {
      setLoading(false)
      logger.error("Error message from server: ", e)
      setCurrentErrorMessage(t("errors.generic"))
    }
  }

  return (
    <ApolloConsumer>
      {(client) => (
        <BaseLayout
          sidePanel={<SidePanel section={2} />}
          body={
            <SingleTextInputScreen
              subtitle={t("kyb.description.subtitle")}
              title={t("kyb.description.title")}
              onChange={setNewDescription}
              value={newDescription}
              placeholderText={t("kyb.description.placeholder")}
              validationType={"info"}
              validationText={
                !descriptionIsValid && suggestedDescription === ""
                  ? t("kyb.description.validationText", {
                      numberOfCharacters: numberOfCharactersRemaining,
                    })
                  : descriptionIsValid && suggestedDescription === ""
                  ? t("kyb.description.characterCount", {
                      numberOfCharacters: newDescription.trim().length,
                    })
                  : suggestedDescription !== ""
                  ? t("kyb.description.warningMessage") +
                    description +
                    t("kyb.description.warningMessage2") +
                    suggestedDescription
                  : "null"
              }
              textInputContainerStyles={{
                paddingBottom: suggestedDescription ? [35, 25, 22.5, 3] : 0,
              }}
            />
          }
          buttonRow={
            <ButtonRow
              buttonOnClick={async () => {
                setDescription(newDescription)
                Analytics.track(`KYB Business Description Continue Tap`)
                await businessDescriptionValidation(client, newDescription)
              }}
              buttonDisabled={!descriptionIsValid || loading}
            />
          }
          onBackClick={prev}
        />
      )}
    </ApolloConsumer>
  )
}
