import React, { useState } from "react"
import styled from "@emotion/styled"
import {
  typography,
  color,
  space,
  layout,
  border,
  fontSize,
  TypographyProps,
  BorderProps,
  SpaceProps,
} from "styled-system"
import { theme } from "theme/theme"
import { Box, Image, SxStyleProp } from "rebass"
import ShowIcon from "images/show-icon.svg"
import HideIcon from "images/hide-icon.svg"
import { ValidationText, TValidationTypes } from "shared/validation-text"
import { TextInputMask } from "shared/text-input"

import { useObfuscatedInput } from "./useObfuscatedInput"
import { onlyNumbersRegex } from "utils"

interface IProps {
  onChange: (value: any) => void
  value: string | number
  placeholderText: string
  isPassword?: boolean
  maxLength?: number
  valueIsValid?: () => void
  validationText?: string
  validationType?: TValidationTypes
  withPhoneNumberMask?: boolean
  withDateMask?: boolean
  withEINMask?: boolean
  withDollarMask?: boolean
  withCardExpiryMask?: boolean
  withOnlyNumbers?: boolean
  withObfuscation?: boolean
  obfuscationChar?: string
  autoFocus?: boolean
  disabled?: boolean
  small?: boolean
  customStyle?: SxStyleProp
  customWidth?: string
  disableMinimizedPlaceholderText?: boolean
}

export const ENTER_KEY_CODE = 13

const Input = styled.input<TypographyProps & BorderProps & SpaceProps>`
  ::-webkit-input-placeholder {
    color: ${theme.colors.darkGrey}
  }
  ${space}
  ${typography}
  ${color}
  ${layout}
  ${border}
  ${fontSize}
`

const INPUT_WIDTH = "27rem"

export const TextInput: React.FC<IProps> = ({
  onChange,
  value,
  placeholderText,
  isPassword = false,
  maxLength,
  validationText,
  validationType = "error",
  withPhoneNumberMask,
  withDateMask,
  withDollarMask,
  withCardExpiryMask,
  withOnlyNumbers,
  withObfuscation = false,
  obfuscationChar,
  withEINMask,
  autoFocus,
  disabled = false,
  small,
  customWidth,
  disableMinimizedPlaceholderText = false,
}) => {
  const [isHidden, setIsHidden] = useState(isPassword || withObfuscation)
  const obfuscate = useObfuscatedInput({
    isHidden,
    obfuscationChar,
  })

  const toDigits = (input: string | number): string => input.toString().replace(/\$/g, "")
  const acceptOnlyNumbers = (input: string | number) =>
    onlyNumbersRegex.test(input.toString()) ? input : ""

  const handleChange = (value: string | number) => {
    if (withDollarMask) {
      onChange(toDigits(value))
      return
    }
    if (!maxLength || value.toString().length <= maxLength) {
      withOnlyNumbers ? onChange(acceptOnlyNumbers(value)) : onChange(value)
    }
  }

  const getMask = (): string | null => {
    if (withPhoneNumberMask) {
      return "+\\1 999-999-9999"
    }
    if (withDateMask) {
      return "99/99/9999"
    }
    if (withEINMask) {
      return "99-9999999"
    }
    if (withDollarMask) {
      return "$999999999"
    }
    if (withCardExpiryMask) {
      return "99/99"
    }
    return null
  }

  const inputMask = getMask()

  return (
    <Box
      sx={{
        height: theme.inputField.height,
      }}
    >
      <Box
        sx={{
          borderBottom: "1px solid",
          borderColor:
            validationText && validationType === "error" ? theme.colors.red : theme.colors.grey,
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          width: small ? "22rem" : INPUT_WIDTH,
          height: 60,
        }}
        width={customWidth ? customWidth : small ? "22rem" : INPUT_WIDTH}
      >
        <Box
          sx={{
            color: theme.colors.darkGrey,
            ...theme.fontStyles.placeholderTextMinimized,
          }}
        >
          {!disableMinimizedPlaceholderText && value ? placeholderText : ""}
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
          }}
        >
          {inputMask ? (
            <TextInputMask
              mask={inputMask}
              maskChar={""}
              pb="0.6rem"
              onChange={(event) => {
                handleChange(event.target.value)
              }}
              placeholder={placeholderText}
              value={value}
              disabled={disabled}
              autoFocus={autoFocus}
            />
          ) : (
            <Input
              type={isPassword && isHidden ? "password" : undefined}
              fontSize="subtitle"
              autoFocus={autoFocus}
              pb="0.6rem"
              onSelect={withObfuscation ? obfuscate.onSelect : undefined}
              onChange={
                withObfuscation
                  ? obfuscate.onChange(handleChange, value)
                  : (event) => handleChange(event.target.value)
              }
              placeholder={placeholderText}
              color={theme.colors.black}
              width={small ? "22rem" : INPUT_WIDTH}
              borderWidth={0}
              style={{
                outline: "none",
                ...theme.fontStyles.placeholderText,
              }}
              value={withObfuscation ? obfuscate.display(value) : value}
              disabled={disabled}
            />
          )}
          {isPassword || withObfuscation ? (
            <Image
              onClick={() => setIsHidden(!isHidden)}
              sx={{ height: "1.5rem" }}
              src={isHidden ? HideIcon : ShowIcon}
            />
          ) : null}
        </Box>
      </Box>
      {validationText ? (
        <Box
          sx={{
            marginTop: "3px",
            height: 25,
          }}
        >
          <ValidationText validationType={validationType} text={validationText} />
        </Box>
      ) : null}
    </Box>
  )
}
