import React, { useState } from "react"
import { useTranslation } from "react-i18next"
import { Box } from "rebass"
import { BaseLayout, BaseScreen, SidePanel, ButtonRow } from "shared"
import validator from "validator"
import { isValidSSN } from "utils/validators"
import { TextInput } from "shared/text-input"

interface ISSNScreenProps {
  ssn: string
  firstName: string
  setSSN: (ssn: string) => void
  onSubmit?: () => void
  prev: () => void
  next: () => void
}

export const SSNScreen: React.FC<ISSNScreenProps> = ({
  ssn,
  setSSN,
  firstName,
  onSubmit,
  prev,
  next,
}) => {
  const { t } = useTranslation()
  const [newOwnerSSN, setNewOwnerSSN] = useState(ssn)
  const valueIsValid = () => isValidSSN(newOwnerSSN)
  return (
    <BaseLayout
      sidePanel={<SidePanel section={2} />}
      body={
        <BaseScreen
          title={t("shared.screens.ssn.title", { firstName })}
          subtitle={t("shared.screens.ssn.subTitle")}
        >
          <Box style={{ marginTop: "2rem" }}>
            <TextInput
              withObfuscation
              placeholderText={t("shared.screens.ssn.placeholder")}
              onChange={(newValue) => (newValue.length <= 9 ? setNewOwnerSSN(newValue) : null)}
              value={newOwnerSSN}
              autoFocus={true}
              validationType={newOwnerSSN && !validator.isNumeric(newOwnerSSN) ? "error" : "info"}
              validationText={
                newOwnerSSN && !validator.isNumeric(newOwnerSSN) ? t("errors.onlyDigits") : ""
              }
            />
          </Box>
        </BaseScreen>
      }
      buttonRow={
        <ButtonRow
          buttonOnClick={() => {
            setSSN(newOwnerSSN)
            if (onSubmit) {
              onSubmit()
            }
            next()
          }}
          buttonDisabled={!valueIsValid()}
        />
      }
      onBackClick={prev}
    />
  )
}
