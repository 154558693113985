import { GlobalActionEnums, IAction } from "./actions.types"

export const GlobalActions = {
  resetReduxState: (): IAction<{}> => ({
    type: GlobalActionEnums.RESET_REDUX_STATE,
  }),
  resetReduxStateExcludingSignUp: (): IAction<{}> => ({
    type: GlobalActionEnums.RESET_REDUX_STATE_EXCLUDING_SIGNUP,
  }),
}
