import React, { useState } from "react"
import { BaseLayout, SidePanel, BaseScreen, TextInput, ButtonRow } from "shared"
import { useTranslation } from "react-i18next"
import validator from "validator"
import { Box } from "rebass"

interface IEmailScreenProps {
  firstName: string
  email: string
  setEmail: (value: string) => void
  allOwnerEmails: string[]
  prev: () => void
  next: () => void
}

export const EmailScreen: React.FC<IEmailScreenProps> = ({
  firstName,
  email,
  setEmail,
  allOwnerEmails,
  prev,
  next,
}) => {
  const { t } = useTranslation()
  const [newOwnerEmail, setNewOwnerEmail] = useState(email)
  const valueIsValid = (): boolean =>
    validator.isEmail(newOwnerEmail) &&
    !allOwnerEmails
      .map((ownerEmail) => ownerEmail.toLowerCase())
      .includes(newOwnerEmail.toLowerCase())

  return (
    <BaseLayout
      sidePanel={<SidePanel section={2} />}
      body={
        <BaseScreen title={t("shared.screens.email.title", { firstName })}>
          <Box style={{ marginTop: "2rem" }}>
            <TextInput
              placeholderText={t("shared.screens.email.placeholder")}
              onChange={setNewOwnerEmail}
              value={newOwnerEmail}
              autoFocus={true}
            />
          </Box>
        </BaseScreen>
      }
      buttonRow={
        <ButtonRow
          buttonOnClick={() => {
            setEmail(newOwnerEmail)
            next()
          }}
          buttonDisabled={!valueIsValid()}
        />
      }
      onBackClick={prev}
    />
  )
}
