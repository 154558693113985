import React from "react"
import InputMask from "react-input-mask"
import { theme } from "theme/theme"

interface ITextInputMaskProps {
  mask: string
  maskChar: string
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void
  value: string | number
  placeholder: string
  pb: string
  disabled?: boolean
  autoFocus?: boolean
}

export const TextInputMask: React.SFC<ITextInputMaskProps> = ({
  mask,
  maskChar,
  onChange,
  value,
  placeholder,
  pb,
  disabled,
  autoFocus,
}) => (
  <InputMask
    style={{
      outline: "none",
      border: "none",
      paddingBottom: pb,
      ...theme.fontStyles.placeholderText,
      color: theme.colors.black,
      width: "25rem",
    }}
    mask={mask}
    maskChar={maskChar}
    onChange={onChange}
    value={value}
    placeholder={placeholder}
    disabled={disabled}
    autoFocus={autoFocus}
  />
)
