import {
  OnboardingNavigator,
  routes as onboardingRoutes,
  components as onboardingComponents,
} from "./onboarding"

import { navigate, navigateByApplicationStatus } from "./utils"

import { NOT_FOUND } from "redux-first-router"
type ReduxFirstNotFound = typeof NOT_FOUND

/**
 * map of redux routing action to component name
 * ** note component name must match the name of the exported component
 */
export const routes = {
  ...onboardingRoutes,
}

export type RoutesActionType = keyof typeof routes | ReduxFirstNotFound

export type RouteToComponentMap = Record<RoutesActionType, string>
export const components: RouteToComponentMap = {
  ...onboardingComponents,
  [NOT_FOUND]: "NotFound",
}

export const INITIAL_ROUTE: RoutesActionType = "SIGN_UP_PHONE"

/**
 * root navigator for project
 * navigation functions for all features live here
 */
export const nav = {
  utils: {
    navigate,
    navigateByApplicationStatus,
  },
  onboarding: OnboardingNavigator,
}
