import React, { useState } from "react"
import { useTranslation } from "react-i18next"
import { Box, Text } from "rebass"

import { BaseLayout, BaseScreen, SidePanel, ButtonRow } from "shared"
import { theme } from "theme/theme"
import { termsAndConditionsData } from "./data"
import gql from "graphql-tag"

import { TTermsAndConditionsProps } from "./terms-and-conditions.container"
import { useRegisterUserAndTermsAcceptMutation } from "generated/graphql"

export const REGISTER_USER_AND_ACCPT_TERMS = gql`
  mutation registerUserAndTermsAccept($email: String!, $phone: String!) {
    registerUserAndTermsAccept(input: { email: $email, phone: $phone }) {
      code
      success
      user {
        id
        email
      }
    }
  }
`
interface IParagraph {
  text: string
  indents?: number
  hasInitialIndent?: boolean
  centered?: boolean
}

const TermsAndConditionsText: React.SFC<IParagraph> = ({
  text,
  indents = 0,
  hasInitialIndent = false,
  centered = false,
}) => {
  const initialIndent = hasInitialIndent ? "      " : ""
  return (
    <Text
      style={{
        fontSize: theme.fontStyles.h2.fontSize,
        lineHeight: theme.fontStyles.h2.lineHeight,
        color: theme.colors.grey,
        margin: "13px",
        padding: 2,
        paddingLeft: 5 * indents,
        alignSelf: centered ? "center" : "auto",
        textAlign: "left",
        boxSizing: "border-box",
      }}
    >
      {initialIndent + text}
    </Text>
  )
}

export const TermsAndConditionsScreen: React.FC<TTermsAndConditionsProps> = ({
  setCurrentErrorMessage,
  email,
  phone,
}) => {
  const [loading, setIsLoading] = useState(false)

  const { t } = useTranslation()

  const [registerAndAcceptTerms] = useRegisterUserAndTermsAcceptMutation({
    variables: { phone: phone.replace("+", "").replace(" ", "-"), email },
    onCompleted: () => window.location.reload(),
    onError: () => {
      setIsLoading(false)
      setCurrentErrorMessage(t("errors.generic"))
    },
  })

  return (
    <BaseLayout
      sidePanel={<SidePanel section={1} />}
      noBackButton
      body={
        <BaseScreen
          title={t("signUp.termsAndConditions.title")}
          subtitle={t("signUp.termsAndConditions.subtitle")}
        >
          <Box
            sx={{
              width: [350, 440, 585, 721],
              height: 223,
              borderStyle: "solid",
              borderWeight: 1,
              borderColor: theme.colors.defaultBorderColor,
              borderRadius: 6,
              backgroundColor: theme.colors.lightGrey,
              boxSizing: "border-box",
              overflowY: "scroll",
              overflowX: "hidden",
            }}
          >
            {termsAndConditionsData.map((paragraph) => (
              <TermsAndConditionsText {...paragraph} />
            ))}
          </Box>
        </BaseScreen>
      }
      buttonRow={
        <ButtonRow
          buttonIsLoading={loading}
          buttonText={t("buttons.agree")}
          buttonOnClick={() => {
            setIsLoading(true)
            registerAndAcceptTerms()
          }}
        />
      }
    />
  )
}
