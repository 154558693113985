import { IOwner } from "types"

export interface ISensitive {
  ssn: string
}

export type Owner = Omit<IOwner, "ssn">

export interface IUBOState {
  additionalOwners: Owner[]
  newOwner: Owner
  editOwner: IOwner

  /* SENSITIVE VALUES LOGIC NO LONGER REQUIRED - was an old requirement when persisting the entire state
 TODO: Refactor to put SSNs directly on UBO
 */
  sensitive: {
    newOwner: ISensitive

    additionalOwners: {
      [ownerId: string]: ISensitive
      // i.e) "82b310ab-2294-4d86-ab76-cf9fd0dd8022": { ssn: "123456789"}
    }
  }
}

export const initialNewOwner = {
  id: "",
  iovationBlackbox: "",
  firstName: "",
  lastName: "",
  birthDate: "",
  homeAddress: {
    streetAddressLine1: "",
    streetAddressLine2: "",
    city: "",
    provinceState: "",
    postalCode: "",
    country: "",
  },
  citizenship: "",
  emailAddress: "",
  ownershipPercentage: "",
  phoneNumber: "",
  role: "",
  jobTitle: "",
  ssn: "",
  unsupportedCountry: "",
}

export const initialUBOState: IUBOState = {
  additionalOwners: [],
  newOwner: initialNewOwner,
  editOwner: initialNewOwner,
  sensitive: {
    newOwner: { ssn: "" },
    additionalOwners: {},
  },
}
