import React, { useState } from "react"
import { useTranslation } from "react-i18next"
import { Box } from "rebass"
import { BaseLayout, BaseScreen, TextInput, SidePanel, ButtonRow } from "shared"
import { isValidName } from "utils"

interface INameScreenProps {
  initialFirstName: string
  initialLastName: string
  setFirstName: (value: string) => void
  setLastName: (value: string) => void
  title: string
  prev: () => void
  next: () => void
}

export const NameScreen: React.FC<INameScreenProps> = ({
  initialFirstName,
  initialLastName,
  setFirstName,
  setLastName,
  prev,
  next,
  title,
}) => {
  const { t } = useTranslation()
  const [firstName, setFirstNameValue] = useState(initialFirstName)
  const [lastName, setLastNameValue] = useState(initialLastName)
  const valueIsValid = (): boolean => Boolean(firstName && lastName)

  return (
    <BaseLayout
      sidePanel={<SidePanel section={2} />}
      body={
        <BaseScreen title={title}>
          <Box style={{ marginTop: "2rem" }}>
            <TextInput
              placeholderText={t("shared.screens.name.firstName")}
              onChange={(name: string) =>
                isValidName(name) || !name.length ? setFirstNameValue(name) : null
              }
              value={firstName}
              autoFocus={true}
            />
          </Box>
          <Box style={{ marginTop: "3rem" }}>
            <TextInput
              placeholderText={t("shared.screens.name.lastName")}
              onChange={(name: string) =>
                isValidName(name) || !name.length ? setLastNameValue(name) : null
              }
              value={lastName}
            />
          </Box>
        </BaseScreen>
      }
      buttonRow={
        <ButtonRow
          buttonOnClick={() => {
            setFirstName(firstName)
            setLastName(lastName)
            next()
          }}
          buttonDisabled={!valueIsValid()}
        />
      }
      onBackClick={prev}
    />
  )
}
