import React from "react"
import { connect } from "react-redux"
import { IRootState, TRootDispatch } from "core"

import { nav } from "core/navigation"
import { PhoneScreen } from "shared"
import { Analytics } from "core/analytics/actions"
import { signUpActions } from "components/onboarding/sign-up/redux"

interface IStateProps {
  phone: string
}

interface IDispatchProps {
  setPhone: (phone: string) => void
}

export type TConnectProps = IStateProps & IDispatchProps

const mapStateToProps = (state: IRootState): IStateProps => ({
  phone: state.signUp.phone,
})

const mapDispatchToProps = (dispatch: TRootDispatch): IDispatchProps => ({
  // NOTE: fires signUpAction **
  setPhone: (phone: string) => dispatch(signUpActions.setPhone(phone)),
})

const handleAnalytics = (phoneNumber: string) => {
  Analytics.phoneNumber(phoneNumber)
  // TODO: update event to KYC phone event when exists!
  Analytics.track("Create Profile Phone Number Continue Tap", { phone: phoneNumber })
}

const { prev, next } = nav.onboarding.kyc.pages.KYCPhone
export const KYCPhone = connect(
  mapStateToProps,
  mapDispatchToProps,
)((props: TConnectProps) => (
  <PhoneScreen {...props} goToNext={next} goToPrev={prev} handleAnalytics={handleAnalytics} />
))
