export const theme = {
  breakpoints: ["834px", "1112px", "1280px", "1440px"],
  borderRadius: "50px",
  colors: {
    orange: "#F2994A",
    blue: "#009BFF",
    green: "#00F6A3",
    black: "#24343E",
    grey: "#818D95",
    darkGrey: "#A5ABB7",
    lightGrey: "#F7F7F7",
    defaultBorderColor: "#EDF2F8",
    red: "#E41B24",
    white: "#FFFFFF",
    accent: "#E8974F",
  },
  fonts: {
    body: "Cerebri Sans",
    heading: "Cerebri Sans",
    monospace: "Cerebri Sans",
    graphik: "Graphik",
    plantin: "Plantin MT Pro",
  },
  shadows: {
    tooltip: "4px 4px 15px rgba(0, 0, 0, 0.2)",
  },
  spacing: {
    inputFormSpacing: 10,
    inputFieldSpacing: 20,
    headingToBody: 40,
    buttonRowToBody: 60,
    verticalElementSpacing: 50,
  },
  inputField: {
    height: 60,
    width: {
      regular: "27rem",
      small: "200px",
    },
  },

  fontStyles: {
    h1: {
      fontWeight: 600,
      fontSize: 24,
      lineHeight: "28px",
    },

    h2: {
      fontWeight: 400,
      fontSize: 16,
      lineHeight: "22px",
    },

    h3: {
      fontFamily: "Plantin MT Pro",
      fontWeight: 300,
      fontSize: "28px",
      lineHeight: "32px",
    },

    h3Small: {
      fontFamily: "Plantin MT Pro",
      fontWeight: 300,
      fontSize: "21px",
      lineHeight: "28px",
    },

    bodyLargeBold: {
      fontFamily: "Graphik",
      fontWeight: 500,
      fontSize: "16px",
      lineHeight: "23px",
    },

    placeholderText: {
      fontWeight: 400,
      fontSize: 18,
      lineHeight: "23px",
    },

    placeholderTextMinimized: {
      fontWeight: 400,
      fontSize: 14,
      lineHeight: "18px",
    },

    smallText: {
      fontWeight: 400,
      fontSize: 12,
      lineHeight: "18px",
      marginTop: "10px",
    },

    buttonText: {
      fontWeight: 700,
      fontSize: 16,
      lineHeight: "22px",
    },
  },
}
