import React from "react"
import { connect } from "react-redux"

import { IRootState, TRootDispatch } from "core"

import { KYBPercentageScreen } from "./kyb-percentage.screen"

import { KYBActions } from "../redux"
import { CorporationType } from "types"
import { getTotalUBOOwnershipPercentage } from "components/onboarding/ubo/redux/ubo.selectors"

interface IKYBPercentageStateProps {
  firstName: string
  percentOwnership: string
  businessType: CorporationType
  uboOwnershipPercentage: number
}

interface IKYBPercentageDispatchProps {
  setPercentOwnership: (percent: string) => void
}

export type TKYBPercentageProps = IKYBPercentageStateProps & IKYBPercentageDispatchProps

const mapStateToProps = (state: IRootState): IKYBPercentageStateProps => ({
  firstName: state.KYC.firstName,
  percentOwnership: state.KYB.percentOwnership,
  businessType: state.KYB.businessType,
  uboOwnershipPercentage: getTotalUBOOwnershipPercentage(state.UBO.additionalOwners),
})

const mapDispatchToProps = (dispatch: TRootDispatch): IKYBPercentageDispatchProps => ({
  setPercentOwnership: (percent: string) => dispatch(KYBActions.setPercentOwnership(percent)),
})

export const KYBPercentage = connect(
  mapStateToProps,
  mapDispatchToProps,
)((props: TKYBPercentageProps) => <KYBPercentageScreen {...props} />)
