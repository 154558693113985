export const backgroundCheck = {
  backgroundCheckSubmit: {
    title: "Submit your application",
    subtitle: "We need to verify your information by running a background check.",
    text:
      "This is a standard process for opening up a bank account and it will not affect your credit score.",
    button: "Submit application",
  },
  backgroundCheckSuccess: {
    title: "Your application has been submitted!",
    subtitle:
      "Most applications are approved within a few minutes. \n We'll email you as soon as your account is ready. \n In the meantime, download the app and let's get to work.",
  },
  backgroundCheckReject: {
    title: "The status of your application has been updated",
    subtitle: "For more information about your application, please check your email inbox.",
  },
}
