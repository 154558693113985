import React from "react"
import { useTranslation } from "react-i18next"
import { Box } from "rebass"
import { BaseLayout, BaseScreen, SidePanel, RadioButton, ButtonRow } from "shared"
import { TOwnershipListProps } from "./ownership-list.container"
import { nav } from "core/navigation"
import { IOwner } from "types"

export const OwnershipListScreen: React.FC<TOwnershipListProps> = ({
  firstOwner,
  additionalOwners,
  businessType,
}) => {
  const { t } = useTranslation()
  const {
    prev,
    startNewOwner,
    uboComplete,
    goToOwnerReview,
  } = nav.onboarding.ubo.pages.OwnershipList

  return (
    <BaseLayout
      sidePanel={<SidePanel section={2} />}
      body={
        <BaseScreen title={t("ubo.ownershipList.title")} subtitle={t("ubo.ownershipList.subtitle")}>
          <Box style={{ marginTop: "2rem", flexDirection: "column", display: "flex" }}>
            <RadioButton
              label={firstOwner.firstName + " " + firstOwner.lastName}
              isSelected={true}
              onClick={() => null}
              infoText={
                firstOwner.homeAddress.streetAddressLine1 +
                ", " +
                firstOwner.homeAddress.city +
                ", " +
                firstOwner.homeAddress.postalCode
              }
              infoLine2={firstOwner.emailAddress}
              infoLine3={firstOwner.phoneNumber}
              ownerList
              editIcon={false}
            />
            {additionalOwners.map((owner) => {
              const { id, firstName, lastName, homeAddress, emailAddress, phoneNumber } = owner
              return (
                <RadioButton
                  label={firstName + " " + lastName}
                  isSelected={false}
                  onClick={() => {
                    goToOwnerReview(id)
                  }}
                  infoText={
                    homeAddress.streetAddressLine1 +
                    ", " +
                    homeAddress.city +
                    ", " +
                    homeAddress.postalCode
                  }
                  infoLine2={emailAddress}
                  infoLine3={phoneNumber}
                  ownerList
                  editIcon
                  error={!_isOwnerValid(owner)}
                  errorText={_isOwnerValid(owner) ? "" : t("ubo.ownershipList.missingInfo")}
                />
              )
            })}
            <RadioButton
              label={t("ubo.ownershipList.buttonText")}
              isSelected={false}
              onClick={startNewOwner}
              infoText={""}
              ownerList
              addIcon
            />
          </Box>
        </BaseScreen>
      }
      buttonRow={
        <ButtonRow
          buttonOnClick={() => {
            uboComplete()
          }}
          buttonDisabled={_hasInvalidOwner(additionalOwners)}
        />
      }
      onBackClick={() => prev(businessType)}
    />
  )
}

const _isOwnerValid = (owner: IOwner): boolean =>
  Boolean(
    owner.firstName &&
      owner.lastName &&
      owner.homeAddress &&
      owner.emailAddress &&
      owner.phoneNumber &&
      owner.role &&
      owner.jobTitle &&
      owner.ssn &&
      owner.birthDate &&
      owner.ownershipPercentage,
  )

const _hasInvalidOwner = (owners: IOwner[]) => {
  let hasInvalidOwner = false
  owners.forEach((owner) => {
    if (!_isOwnerValid(owner)) {
      hasInvalidOwner = true
    }
  })
  return hasInvalidOwner
}
