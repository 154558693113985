import React, { useState } from "react"
import { useAuthUserQuery } from "generated/graphql"
import { nav } from "core/navigation"
import { auth } from "./auth-service"
import { TRootDispatch, IRootState } from "core"
import { signUpActions, ErrorsActions } from "components"
import { connect } from "react-redux"
import { LoadingScreen } from "shared"
import { useTranslation } from "react-i18next"
import { getApplicationStatus } from "utils"
import { Analytics } from "core/analytics/actions"

interface IPostAuthWrapperStateProps {
  phone: string
}

const mapStateToProps = (state: IRootState): IPostAuthWrapperStateProps => ({
  phone: state.signUp.phone,
})

interface IPostAuthWrapperDispatchProps {
  setEmail: (email: string) => void
  setErrorMessage: (message: string) => void
}

const mapDispatchToProps = (dispatch: TRootDispatch): IPostAuthWrapperDispatchProps => ({
  setEmail: (email) => dispatch(signUpActions.setEmail(email)),
  setErrorMessage: (message) => dispatch(ErrorsActions.setCurrentErrorMessage(message)),
})

type TPostAuthWrapperProps = IPostAuthWrapperStateProps & IPostAuthWrapperDispatchProps

export const PostAuthWrapperComponent: React.FC<TPostAuthWrapperProps> = ({
  children,
  setEmail,
  phone,
  setErrorMessage,
}) => {
  const { t } = useTranslation()
  const [isLoading, setIsLoading] = useState(true)
  const [hasError, setHasError] = useState(false)

  useAuthUserQuery({
    onCompleted: async (data) => {
      const user = await auth.getUser()
      const userId = data && data.me && data.me.id ? data.me.id : null
      setEmail(user.email)
      if (userId) {
        Analytics.alias(userId)
        Analytics.identify(userId, { email: user.email })
      }
      if (!data.me || !data.me.acceptedTermsAndConditions) {
        nav.utils.navigate(phone ? "SIGN_UP_TERMS_AND_CONDITIONS" : "SIGN_UP_PHONE")
        setIsLoading(false)
        return
      }

      if (data.me.businessBackgroundCheck) {
        const applicationStatus = getApplicationStatus(
          data.me.businessBackgroundCheck.status,
          data.me.ownerBusinesses.length > 0,
        )
        nav.utils.navigateByApplicationStatus(applicationStatus, user.email_verified)
        setIsLoading(false)
        return
      }
      setHasError(true)
    },
    onError: () => {
      setHasError(true)
      setErrorMessage(t("errors.generic"))
    },
  })

  if (hasError || isLoading) {
    return <LoadingScreen />
  }

  return <>{children}</>
}

export const PostAuthWrapper = connect(
  mapStateToProps,
  mapDispatchToProps,
)((props: TPostAuthWrapperProps) => <PostAuthWrapperComponent {...props} />)
