import { IRootState } from "core"
import { CorporationType } from "types"
import { createSelector } from "reselect"
import { logger } from "utils"
import moment from "moment"

/**
 * converts date from MM/DD/YYYY format to YYYY-MM-DD
 */
export const _formatDate = (date: string) => moment(date, "MM/DD/YYYY").format("YYYY-MM-DD")

const _formatPhoneNumber = (phone: string) => phone.replace("+", "").replace(" ", "-")

const _formatEIN = (ein: string) => ein.replace("-", "")

const getSignUp = (state: IRootState) => state.signUp
const getKYC = (state: IRootState) => state.KYC
const getKYB = (state: IRootState) => state.KYB
const getUBO = (state: IRootState) => state.UBO
const getControlPerson = (state: IRootState) => state.controlPerson

export const getOnboarding = createSelector(
  getSignUp,
  getKYC,
  getKYB,
  getUBO,
  getControlPerson,
  (signUp, kyc, kyb, ubo, controlPerson) => ({
    signUp,
    KYC: kyc,
    KYB: kyb,
    UBO: ubo,
    controlPerson,
  }),
)

const _isSoleprop = (KYB: IRootState["KYB"]) => KYB.businessType === CorporationType.Soleprop

export const getBusinessForBackgroundCheck = createSelector(
  getOnboarding,
  ({ KYB, KYC }) => ({
    corporationType: KYB.businessType,
    incorporationState: KYB.state,
    incorporationDate: _formatDate(KYB.date),
    description: KYB.description,
    ein: _isSoleprop(KYB) ? KYC.SSN : _formatEIN(KYB.EIN),
    naicsCode: KYB.nature,
    legalBusinessName: _isSoleprop(KYB) ? `${KYC.firstName} ${KYC.lastName}` : KYB.legalName,
    location: KYB.businessAddress,
    phoneNumber: _formatPhoneNumber(KYB.phone),
    dba: KYB.dba,
    websiteUrl: KYB.website,
    mailingAddress: KYB.mailingAddress,
  }),
)

export const getOwnersForBackgroundCheck = createSelector(
  getOnboarding,
  ({ KYC, KYB, UBO, signUp, controlPerson }) => {
    const controlPersonCandidate = _formatControlPersonCandidate(controlPerson)

    const isSoleprop = _isSoleprop(KYB)

    const KYCOwnerEmail = signUp.email

    const KYCOwner = {
      iovationBlackbox: "N/A",
      firstName: KYC.firstName,
      lastName: KYC.lastName,
      dateOfBirth: _formatDate(KYC.birthDate),
      homeAddress: KYC.homeAddress,
      citizenship: "US",
      emailAddress: KYCOwnerEmail.toLowerCase(),
      ownershipPercentage: isSoleprop ? 100 : Number(KYB.percentOwnership),
      phoneNumber: _formatPhoneNumber(signUp.phone),
      role: KYC.role,
      jobTitle: KYC.jobTitle,
      ssn: KYC.SSN,
      isControlPerson: controlPerson.selected.email.toLowerCase() === KYCOwnerEmail.toLowerCase(),
    }
    // return early if soleprop, don't want additional owners returned)
    if (isSoleprop) {
      return [{ ...KYCOwner, isControlPerson: true }]
    }

    const additionalOwners = UBO.additionalOwners.map((owner) => {
      return {
        iovationBlackbox: "N/A", // TODO: Add real blackbox?
        firstName: owner.firstName,
        lastName: owner.lastName,
        dateOfBirth: _formatDate(owner.birthDate),
        homeAddress: owner.homeAddress,
        citizenship: owner.citizenship,
        emailAddress: owner.emailAddress.toLowerCase(),
        ownershipPercentage: Number(owner.ownershipPercentage),
        phoneNumber: _formatPhoneNumber(owner.phoneNumber),
        role: owner.role,
        jobTitle: owner.jobTitle,
        ssn: UBO.sensitive.additionalOwners[owner.id]
          ? UBO.sensitive.additionalOwners[owner.id].ssn
          : "",
        isControlPerson:
          controlPerson.selected.email.toLowerCase() === owner.emailAddress.toLowerCase(),
        inviteToJoin: owner.inviteToJoin,
      }
    })

    if (
      controlPerson.selected.email.toLowerCase() === controlPerson.candidate.email.toLowerCase()
    ) {
      logger.info("submitting controlPerson candidate (non-signing owner) as control person")
      return [KYCOwner, ...additionalOwners, controlPersonCandidate]
    } else {
      return [KYCOwner, ...additionalOwners]
    }
  },
)

const _formatControlPersonCandidate = (controlPerson: IRootState["controlPerson"]) => {
  const { candidate, selected } = controlPerson
  return {
    iovationBlackbox: "N/A", // TODO: Add real blackbox?
    firstName: candidate.firstName,
    lastName: candidate.lastName,
    dateOfBirth: _formatDate(candidate.birthDate),
    homeAddress: candidate.homeAddress,
    citizenship: "US",
    emailAddress: candidate.email.toLowerCase(),
    ownershipPercentage: 0,
    phoneNumber: _formatPhoneNumber(candidate.phone),
    role: candidate.jobTitle, // TODO confirm if we should collect role
    jobTitle: candidate.jobTitle,
    ssn: candidate.SSN,
    isControlPerson: candidate.email.toLowerCase() === selected.email.toLowerCase(),
  }
}

export const getTotalOwnershipPercentage = (
  originalOwnerPercentage: string,
  additionalOwners: IRootState["UBO"]["additionalOwners"],
): number => {
  return additionalOwners.reduce(
    (accumulator, current) => accumulator + Number(current.ownershipPercentage),
    Number(originalOwnerPercentage),
  )
}
