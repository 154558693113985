import React from "react"
import { connect } from "react-redux"

import { IRootState, TRootDispatch } from "core"

import { KYCNameScreen } from "./kyc-name.screen"

import { KYCActions } from "../redux"

interface IKYCNameStateProps {
  firstName: string
  lastName: string
}

interface IKYCNameDispatchProps {
  setFirstName: (firstName: string) => void
  setLastName: (lastName: string) => void
}

export type TKYCNameProps = IKYCNameStateProps & IKYCNameDispatchProps

const mapStateToProps = (state: IRootState): IKYCNameStateProps => ({
  firstName: state.KYC.firstName,
  lastName: state.KYC.lastName,
})

const mapDispatchToProps = (dispatch: TRootDispatch): IKYCNameDispatchProps => ({
  setFirstName: (firstName: string) => dispatch(KYCActions.setFirstName(firstName)),
  setLastName: (lastName: string) => dispatch(KYCActions.setLastName(lastName)),
})

export const KYCName = connect(
  mapStateToProps,
  mapDispatchToProps,
)((props: TKYCNameProps) => <KYCNameScreen {...props} />)
