import React from "react"
import { DebitFundingSuccessScreen } from "./debit-funding-success.screen"
import { connect } from "react-redux"
import { IRootState } from "core"
import { getDebitCardLast4 } from "../redux/debit-funding.selectors"

interface IDebitFundingSuccessStateProps {
  amount: number
  debitCardLastFourDigits: string
}

export type TDebitFundingSuccessStateProps = IDebitFundingSuccessStateProps

const mapStateToProps = (state: IRootState): IDebitFundingSuccessStateProps => ({
  amount: state.debitFunding.amount,
  debitCardLastFourDigits: getDebitCardLast4(state),
})

export const DebitFundingSuccess = connect(
  mapStateToProps,
  null,
)((props: IDebitFundingSuccessStateProps) => <DebitFundingSuccessScreen {...props} />)
