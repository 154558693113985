export const routes = {
  CONTROL_PERSON_LANDING: "/control-person/landing",
  // to avoid bug caused by action name clash TODO: add a ROUTE distinguishing prefix to all route actions
  CONTROL_PERSON_SELECT_ROUTE: "/control-person/select",
  CONTROL_PERSON_NAME: "/control-person/name",
  CONTROL_PERSON_ROLE: "/control-person/role",
  CONTROL_PERSON_EMAIL: "/control-person/email",
  CONTROL_PERSON_ADDRESS: "/control-person/address",
  CONTROL_PERSON_PHONE: "/control-person/phone",
  CONTROL_PERSON_BIRTHDATE: "/control-person/birthdate",
  CONTROL_PERSON_RESIDENCY: "/control-person/residency",
  CONTROL_PERSON_SSN: "/control-person/ssn",
  CONTROL_PERSON_UNSUPPORTED_COUNTRY: "/control-person/unsupported-country",
  CONTROL_PERSON_UNSUPPORTED_COUNTRY_SUCCESS: "/control-person/unsupported-country-success",
  CONTROL_PERSON_INVITE: "/control-person/invite",
}

type RoutesAction = keyof typeof routes

export const components: Record<RoutesAction, string> = {
  CONTROL_PERSON_LANDING: "ControlPersonLanding",
  CONTROL_PERSON_SELECT_ROUTE: "ControlPersonSelect",
  CONTROL_PERSON_NAME: "ControlPersonName",
  CONTROL_PERSON_BIRTHDATE: "ControlPersonBirthDate",
  CONTROL_PERSON_ADDRESS: "ControlPersonAddress",
  CONTROL_PERSON_PHONE: "ControlPersonPhone",
  CONTROL_PERSON_RESIDENCY: "ControlPersonResidency",
  CONTROL_PERSON_ROLE: "ControlPersonRole",
  CONTROL_PERSON_SSN: "ControlPersonSSN",
  CONTROL_PERSON_UNSUPPORTED_COUNTRY: "ControlPersonUnsupportedCountry",
  CONTROL_PERSON_UNSUPPORTED_COUNTRY_SUCCESS: "ControlPersonUnsupportedCountrySuccess",
  CONTROL_PERSON_EMAIL: "ControlPersonEmail",
  CONTROL_PERSON_INVITE: "ControlPersonInvite",
}
