import React from "react"
import { connect } from "react-redux"
import { IRootState, TRootDispatch } from "core"
import { UBOSSNScreen } from "./ubo-ssn.screen"
import { UBOActions } from "../redux"

interface IUBOSSNStateProps {
  ssn: string
  firstName: string
}

interface IUBOSSNDispatchProps {
  setValue: (key: "ssn", value: string) => void
  addNewOwner: () => void
}

export type TUBOSSNProps = IUBOSSNStateProps & IUBOSSNDispatchProps

const mapStateToProps = (state: IRootState): IUBOSSNStateProps => ({
  ssn: state.UBO.sensitive.newOwner.ssn,
  firstName: state.UBO.newOwner.firstName,
})

const mapDispatchToProps = (dispatch: TRootDispatch): IUBOSSNDispatchProps => ({
  setValue: (key: "ssn", value: string) => dispatch(UBOActions.setSensitiveValue(key, value)),
  addNewOwner: () => dispatch(UBOActions.addOwner()),
})

export const UBOSSN = connect(
  mapStateToProps,
  mapDispatchToProps,
)((props: TUBOSSNProps) => <UBOSSNScreen {...props} />)
