import React, { useState } from "react"
import { useTranslation } from "react-i18next"
import { Box } from "rebass"
import { BaseLayout, BaseScreen, TextInput, SidePanel, DropDown, ButtonRow } from "shared"

interface IRoleScreenProps {
  firstName: string
  initialJobTitle: string
  initialRole?: string
  withoutRole?: boolean
  setJobTitle: (value: string) => void
  setRole?: (value: string) => void
  prev: () => void
  next: () => void
}

export const RoleScreen: React.FC<IRoleScreenProps> = ({
  firstName,
  initialJobTitle,
  initialRole,
  setJobTitle,
  setRole,
  prev,
  next,
  withoutRole = false,
}) => {
  const { t } = useTranslation()
  const options = [
    t("shared.screens.role.director"),
    t("shared.screens.role.officerCoowner"),
    t("shared.screens.role.officerEmployee"),
    t("shared.screens.role.partner"),
  ]
  const [newJobTitle, setNewJobTitle] = useState(initialJobTitle)
  const [newRole, setNewRole] = useState(initialRole)
  const valueIsValid = () => (withoutRole ? Boolean(newJobTitle) : Boolean(newJobTitle && newRole))
  return (
    <BaseLayout
      sidePanel={<SidePanel section={2} />}
      body={
        <BaseScreen
          title={t("shared.screens.role.title", { firstName })}
          subtitle={t("shared.screens.role.subtitle")}
        >
          <Box style={{ marginTop: "2rem" }}>
            <TextInput
              placeholderText={t("shared.screens.role.jobTitlePlaceholder")}
              onChange={setNewJobTitle}
              value={newJobTitle}
              autoFocus={true}
            />
          </Box>
          {withoutRole ? null : (
            <DropDown
              options={options}
              newValue={newRole || ""}
              setNewValue={setNewRole}
              placeholder={t("shared.screens.role.rolePlaceholder")}
            />
          )}
        </BaseScreen>
      }
      buttonRow={
        <ButtonRow
          buttonOnClick={() => {
            setJobTitle(newJobTitle)

            if (!withoutRole && newRole && setRole) {
              setRole(newRole)
            }

            next()
          }}
          buttonDisabled={!valueIsValid()}
        />
      }
      onBackClick={prev}
    />
  )
}
