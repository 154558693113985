import { BusinessBackgroundCheckStatus } from "generated/graphql"

/**
 * determines where a user is in their application process
 */

export type TApplicationStatus = "NOT_SUBMITTED" | "SUBMITTED_AND_WAITING" | "REJECTED" | "APPROVED"

export const getApplicationStatus = (
  backgroundCheckStatus: BusinessBackgroundCheckStatus,
  userHasOwnerBusiness: boolean,
): TApplicationStatus => {
  if (userHasOwnerBusiness) {
    return "APPROVED"
  }
  return statusMap[backgroundCheckStatus]
}

const statusMap: { [key in BusinessBackgroundCheckStatus]: TApplicationStatus } = {
  [BusinessBackgroundCheckStatus.Approved]: "SUBMITTED_AND_WAITING",
  [BusinessBackgroundCheckStatus.Pending]: "SUBMITTED_AND_WAITING",
  [BusinessBackgroundCheckStatus.Processing]: "SUBMITTED_AND_WAITING",
  [BusinessBackgroundCheckStatus.Queued]: "SUBMITTED_AND_WAITING",
  [BusinessBackgroundCheckStatus.Submitted]: "SUBMITTED_AND_WAITING",
  [BusinessBackgroundCheckStatus.BillingError]: "REJECTED",
  [BusinessBackgroundCheckStatus.Rejected]: "REJECTED",
  [BusinessBackgroundCheckStatus.Ready]: "NOT_SUBMITTED",
}
