import React, { useState } from "react"
import { useTranslation } from "react-i18next"
import { BaseLayout, SingleTextInputScreen, SidePanel, ButtonRow } from "shared"
import { TKYCBirthDateProps } from "./kyc-birthdate.container"
import { nav } from "core/navigation"
import { Analytics } from "core/analytics/actions"
import { isValidDateAndAge } from "utils/validators"

export const KYCBirthDateScreen: React.FC<TKYCBirthDateProps> = ({
  firstName,
  birthDate,
  setBirthDate,
}) => {
  const { t } = useTranslation()
  const [newBirthDate, setNewBirthDate] = useState(birthDate)

  const validDate = () => isValidDateAndAge(newBirthDate, { maxAge: 200 })
  const isOver18 = () => isValidDateAndAge(newBirthDate, { minAge: 18 })
  const inputIsValid = () => validDate() && isOver18()

  const getValidationText = () => {
    if (newBirthDate.length >= 10) {
      if (!validDate()) {
        return t("kyc.birthDate.info")
      }
      if (!isOver18()) {
        return t("kyc.birthDate.error")
      }
    }
    return ""
  }

  const { prev, next } = nav.onboarding.kyc.pages.KYCBirthDate

  return (
    <BaseLayout
      sidePanel={<SidePanel section={1} />}
      body={
        <SingleTextInputScreen
          title={t("kyc.birthDate.title", { firstName })}
          onChange={setNewBirthDate}
          value={newBirthDate}
          placeholderText={t("kyc.birthDate.placeholder")}
          dateType={true}
          validationType={!inputIsValid() ? "error" : undefined}
          validationText={getValidationText()}
        />
      }
      buttonRow={
        <ButtonRow
          buttonOnClick={() => {
            setBirthDate(newBirthDate)
            Analytics.track("KYC Verification Manual Birthday Continue Tap", {
              birthdate: newBirthDate,
            })
            next()
          }}
          buttonDisabled={!inputIsValid()}
        />
      }
      onBackClick={prev}
    />
  )
}
