import React from "react"
import { ErrorBar } from "shared"
import { TGlobalErrorBarProps } from "./global-error-bar.container"

export const GlabalErrorBarComponent: React.FC<TGlobalErrorBarProps> = ({
  currentErrorMessage,
  clearCurrentErrorMessage,
}) => {
  const selfDestruct = () => new Promise(() => setTimeout(clearCurrentErrorMessage, 5000))
  if (currentErrorMessage) {
    selfDestruct()
    return <ErrorBar errorText={currentErrorMessage} />
  }
  return null
}
