import React from "react"
import { connect } from "react-redux"

import { IRootState, TRootDispatch } from "core"
import { NameScreen } from "shared/name-screen"
import { nav } from "core/navigation"
import { useTranslation } from "react-i18next"
import { ControlPersonActions } from "../redux"

interface IControlPersonNameStateProps {
  firstName: string
  lastName: string
}

interface IControlPersonNameDispatchProps {
  setFirstName: (firstName: string) => void
  setLastName: (lastName: string) => void
}

export type TControlPersonNameProps = IControlPersonNameStateProps & IControlPersonNameDispatchProps

const mapStateToProps = (state: IRootState): IControlPersonNameStateProps => ({
  firstName: state.controlPerson.candidate.firstName,
  lastName: state.controlPerson.candidate.lastName,
})

const mapDispatchToProps = (dispatch: TRootDispatch): IControlPersonNameDispatchProps => ({
  setFirstName: (firstName: string) => dispatch(ControlPersonActions.setFirstName(firstName)),
  setLastName: (lastName: string) => dispatch(ControlPersonActions.setLastName(lastName)),
})

export const ControlPersonNameScreen: React.FC<TControlPersonNameProps> = ({
  firstName,
  lastName,
  setFirstName,
  setLastName,
}) => {
  const { prev, next } = nav.onboarding.controlPerson.pages.ControlPersonName
  const { t } = useTranslation()

  return (
    <NameScreen
      prev={prev}
      next={next}
      title={t("controlPerson.name.title")}
      initialFirstName={firstName}
      initialLastName={lastName}
      setFirstName={setFirstName}
      setLastName={setLastName}
    />
  )
}

export const ControlPersonName = connect(
  mapStateToProps,
  mapDispatchToProps,
)((props: TControlPersonNameProps) => <ControlPersonNameScreen {...props} />)
