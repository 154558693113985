import React from "react"
import { connect } from "react-redux"
import { IRootState, TRootDispatch } from "core"
import { IOwner } from "types"
import { UBOReviewScreen } from "./ubo-review.screen"
import {
  UBOActions,
  getCompiledInitialOwner,
  getUBOAdditionalOwners,
  IEditOwnerActionPayload,
  getAllOwnerEmails,
} from "../redux"

import { getTotalOwnershipPercentage } from "components/onboarding/redux/ownership.selectors"

interface IUBOReviewStateProps {
  editOwnerData: IOwner
  currentTotalOwnershipPercentage: number
  additionalOwners: IOwner[]
  allOwnerEmails: string[]
}

interface IUBOReviewDispatchProps {
  editOwner: (payload: IEditOwnerActionPayload) => void
  deleteOwner: (ownerId: string) => void
}

export type TUBOReviewProps = IUBOReviewStateProps & IUBOReviewDispatchProps

const mapStateToProps = (state: IRootState): IUBOReviewStateProps => ({
  editOwnerData: state.UBO.editOwner ? state.UBO.editOwner : getCompiledInitialOwner(state),
  currentTotalOwnershipPercentage: getTotalOwnershipPercentage(
    state.KYB.percentOwnership,
    state.UBO.additionalOwners,
  ),
  additionalOwners: getUBOAdditionalOwners(state),
  allOwnerEmails: getAllOwnerEmails(state),
})

const mapDispatchToProps = (dispatch: TRootDispatch): IUBOReviewDispatchProps => ({
  editOwner: (payload: IEditOwnerActionPayload) => dispatch(UBOActions.editOwner(payload)),
  deleteOwner: (ownerId: string) => dispatch(UBOActions.deleteOwner(ownerId)),
})

export const UBOReview = connect(
  mapStateToProps,
  mapDispatchToProps,
)((props: TUBOReviewProps) => <UBOReviewScreen {...props} />)
